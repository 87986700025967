import React from "react";
import FIcon from "./FIcon";
import FButton from "./FButton";

interface BlankStateProps {
  title: string;
  subtitle: string;
  icon: string; // URL or icon name depending on how you handle icons
  onActionClick?: () => void; // Event handler for the action button
  actionLabel?: string; // Label for the action button
  noMarginTop?: boolean;
}

const BlankState: React.FC<BlankStateProps> = ({
  title,
  subtitle,
  icon,
  onActionClick,
  actionLabel,
  noMarginTop = false,
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center p-20 ${
        noMarginTop ? "p-8" : "mt-10 p-20 bg-highlight_red"
      } rounded-xl`}
    >
      <FIcon icon={icon} size={50} color="#000F45" className="mb-7" />
      <h1 className="text-xl font-bold mb-2 capitalize text-center">{title}</h1>
      {subtitle && <p className="mb-7 text-gray-600 text-center">{subtitle}</p>}
      {actionLabel ? (
        <div className="flex mt-2">
          <FButton
            onClick={onActionClick}
            label={actionLabel}
            primary={true}
            type="button"
          />
        </div>
      ) : null}
    </div>
  );
};
export default BlankState;
