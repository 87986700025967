import { ReactNode, ReactPortal } from "react";
import { AmbassadorContent, GiftTable, Creator } from "./apiTypes";
import { Permission, Role } from "@constants/roles";
import { CampaignBriefAsset } from "@types";
import { ViralPostsData } from "@contexts/types";

export * from "./brand";
export * from "./campaign";
export * from "./content";
export * from "./content-review";
export * from "./outreach";
export * from "./profile";
export * from "./bulletin";
export * from "./report";

export enum SocialMedialPlatform {
  INSTAGRAM = "instagram",
  TIKTOK = "tiktok",
  YOUTUBE = "youtube",
}

export interface Photo {
  card: string;
  large: string;
  original: string;
}

export type Children = ReactNode | ReactPortal | boolean | null | undefined;

export interface Membership {
  id: number;
  name: string;
  role: string | null;
  brand_id: number;
  user: User;
}

export interface User {
  id: number;
  testMode: boolean;
  first_name?: string;
  last_name?: string;
  email: string;
  brand_id: number;
  brand_name: string;
  avatar_url?: string;
  birthday?: string;
  creator_id?: number | null;
  user_id: number;
  role: Role;
  permissions: {
    fohr_admin?: Record<Permission, boolean>;
    brand_owner?: Record<Permission, boolean>;
    brand_manager?: Record<Permission, boolean>;
    brand_user?: Record<Permission, boolean>;
    influencer?: Record<Permission, boolean>;
    influencer_rep?: Record<Permission, boolean>;
    open_links?: Record<Permission, boolean>;
  };
  memberships: Membership[];
  user?: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
}

export interface Column {
  key: string;
  label: string;
  className?: string;
  isLink?: boolean;
  isMoney?: boolean;
  linkURL?: string;
  isStateBubble?: boolean;
  isDate?: boolean;
  isCategories?: boolean;
  isImage?: boolean;
  imageType?: "asset" | "profile";
  imageKey?: string;
  nestedKey?: string;
  isOutsideLink?: boolean;
  isFirstColumn?: boolean;
  isDifference?: boolean;
  isPercentage?: boolean;
  linkTarget?: string;
  linkOpensInModal?: boolean;
  defaultLabel?: string;
  type?: "platform" | unknown;
  isNumber?: boolean;
  getValue?: (entity: any) => any;
  getContent?: (entity: any) => any;
  hideContentForSubItem?: boolean;
  maxWidth?: number;
}

export interface CreateGroupRequest {
  name: string;
  featured: boolean;
  brand_id: number;
  user_id: number;
}

export interface Group {
  id: number;
  name: string;
  archived: boolean;
  featured: boolean;
  creators_count: number;
  created_at: string;
  updated_at: string;
  owner_user: {
    id: number;
    full_name: string;
  };
  brand: {
    id: number;
    name: string;
    slug: string;
  };
  state: string;
}

export interface FSidebarItem {
  active?: boolean;
  collapsed?: boolean;
  icon?: string;
  mainLink?: {
    icon: string;
    name: string;
  };
  name: string;
  href: string;
  isSubmenuItem?: boolean;
  isDefault?: boolean;
  secondaryHref?: string;
  items?: FSidebarItem[];
  onClick?: (e?: any) => void;
  target?: string;
  upgrade_required_label?: string;
  onToggle?: () => void;
  isExpanded?: boolean;
  activeLink?: string;
}

export interface FDropdownItem {
  active?: boolean;
  id?: number;
  name: string;
  link?: string;
  slug?: string;
  icon?: {
    name: string;
    color?: string;
  };
  target?: string;
  isSubmenuItem?: boolean;
  submenuItems?: FDropdownItem[];
  parent?: string;
  depth?: number;
  onClick?: (arg?: any) => void;
  children?: FDropdownItem[];
}

export interface IInfluencer {
  id: number;
  name: string;
  content_review_progress: number;
  fohr_profile_url: string;
  photo_url: string;
  status: string;
}

export interface ICampaign {
  id: number;
  category: string;
  created_at: string;
  name: string;
  title: string;
  slug: string;
  manager: string;
  manager_email: string;
  agency: string;
  brand: string;
  status: string;
  start_date: string;
  end_date: string;
  cover_image: string;
  accepted_participants_count: number;
  participants_count: number;
  brief_id: number;
  brief_ids: number[];
  master_brief_id: number;
  brand_slug: string;
  content_review_enabled: boolean;
  resource_type: string;
  offer_token: string;
  acceptance: any;
}

export interface ISocialMediaPost {
  content_type?: string;
  engagement?: number;
  impressions?: number;
  influencer_name?: string;
  influencer_last_name?: string;
  platform?: string;
  reach?: number;
  url?: string;
  permalink?: string;
  published_at?: string;
  status?: string;
  submitted?: string;
  submitted_on?: Date;
  deliverable?: string;
  live?: string;
  live_date?: string;
  video?: string;
}

export interface IReport {
  campaign_created_at: string;
  campaign_id: number;
  campaign_name: string;
  campaign_status: string;
  created_at: string;
  id: number;
  manager: string;
  updated_at: string;
  status: string;
  start_date: string;
  end_date: string;
}

export interface FilterSortOption {
  type: "sort" | "filter";
  label: string;
  value: string;
  selected: boolean;
}

export interface Tracking {
  participant_product_id: string;
  tracking_id: string;
  tracking_provider: string;
}

export interface ParticipantGroup {
  id: number;
  campaign_id: number;
  parent_id: number | null;
  status: string;
  name: string;
  created_at: string;
  updated_at: string;
  creator_review_description: string;
}

export interface Card {
  id: number;
  email?: string;
  tagline?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  location?: string;
  slug?: string;
  created_at?: string;
  profile_image?: string;
  overall_followers?: number;
  categories?: string[];
  pronouns?: string;
  ethnicity?: string;
  birthday?: string;
  age?: number;
  sexual_orientation?: string;
  gender?: string;
  dream_brands?: null; // Specify the type if it's not always null
  managed?: null; // Specify the type if it's not always null
  parent?: null; // Specify the type if it's not always null
  job?: string;
  relationship_status?: null; // Specify the type if it's not always null
  platforms?: string[];
  tiktok_followers?: number;
  instagram_followers?: number;
  youtube_followers?: number;
  specialties?: {
    types?: string[];
    min_gift_amount?: string;
  };
}

export interface ContentData {
  id?: number;
  image_url?: string;
  permalink?: string;
  username?: string;
  impressions?: number;
  interactions?: number;
  platform?: string;
  unique_stats?: {
    reach?: number;
    saved?: number;
    impressions?: number;
    video_views?: number;
    total_interactions?: number;
    likes_count?: number;
    comments_count?: number;
    views_count?: number;
  };
  media_type?: string;
  photo_url?: string;
  video_url?: string;
  published_at?: string;
  reach?: number;
  views_count?: number;
  engagements?: number;
}

export interface AboutProps {
  topDemographics: any[]; // Define more specific type if possible
  card: Card;
  categories: any[]; // Define more specific type if possible
  user: any; // Define more specific type if possible
  className?: string;
  isValidProfileHash: boolean;
}

export interface AudienceSurveyProps {
  className?: string;
  card?: Card;
  user?: any; // Specify a more detailed type if possible
  slug: string;
  isValidProfileHash: boolean;
}

export interface AudienceDemographicsProps {
  card?: Card;
  slug?: string;
  user?: any; // Specify a more detailed type if possible
  isValidProfileHash?: boolean;
}

export interface BestContentProps {
  card?: Card;
  content?: any;
  user?: any; // Specify a more detailed type if possible
  className?: string;
  cardId?: string;
  setContent?: (post: any) => void; // Specify a more detailed type for 'post' if possible
  isValidProfileHash: boolean;
}

export interface ProfileButtonsProps {
  membership: string;
  card?: Card;
  slug: string;
  isBrandUser: boolean;
  setView: (view: string) => void;
}

export interface CampaignsProps {
  card: Card;
  slug: string;
  isBrandUser: boolean;
  cardId: string;
}

export interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
  collapsible?: boolean;
  title?: string;
  subtitle?: string;
  headerRight?: React.ReactNode;
  headerLeft?: React.ReactNode;
  dropdownOptions?: any; // Specify a more detailed type if possible
  secondaryDropdownOptions?: any; // Specify a more detailed type if possible
  secondaryStateOptions?: any; // Specify a more detailed type if possible
  onTitleChange?: (newTitle: string) => void;
}

export interface ContentProps {
  card: Card;
  className?: string;
  cardId: string;
  user?: any; // Specify a more detailed type if possible
  setContent: (post: any) => void; // Specify a more detailed type for 'post' if possible
  isValidProfileHash: boolean;
}

export interface CoreMetricsProps {
  user: any; // Define more specific type if possible
  card: Card;
  cardId: string;
  isValidProfileHash: boolean;
  // Add other properties as needed
}

export interface CampaignDeliverable {
  asset_quantity?: number;
  content_review_ids?: number[];
  deliverable_start?: string;
  deliverable_end?: string;
  estimated_cpm?: number;
  estimated_flat_cost?: number;
  id?: number;
  forecasted_date?: string;
  submission_due_date?: string;
  kind?: string;
  media_type?: string;
  data?: {
    hashtags: string;
    media_type: string;
    mentions: string;
    name: string;
    notes: string;
    quantity: string;
  };
  name?: string;
  notes?: string;
  platform?: string;
  position?: number;
  status?: string;
  updated_at?: string;
  url?: string;
  hashtags?: string;
  mentions?: string;
  tagged_users?: string;
  geotags?: string;
  syndication_requirements?: string[];
  overwrite?: boolean;
  participant_id?: number;
  participant_group_id?: number;
  location?: string;
}

export enum KIND {
  PAID = "paid",
  GIFTING = "gifting",
}

export interface Campaign {
  agency_name: string;
  approved_by_finance?: boolean;
  created_at: string;
  participants_count: number;
  brand_content_approvers: string;
  brand_name: string;
  brief: CampaignBrief;
  budget: string;
  category: string;
  content_review_enabled: boolean;
  contract_template_id: number;
  content_review_id: number;
  cover_image_url: string;
  deliverables: CampaignDeliverable[];
  exclusive_rights_flat_cost: number;
  guaranteed_content: number;
  guaranteed_impressions: number;
  guaranteed_participant_count: number;
  id: number;
  include_bonus: boolean;
  auto_content_attribution: boolean;
  kind: string;
  other_flat_cost: number;
  owner_user: User;
  participants: Participant[];
  participant_groups: ParticipantGroup[];
  salesforce_project_number: string;
  products: CampaignProduct[];
  projected_ave_following: number;
  projected_engagement_percentage: number;
  skip_participant_mailers: boolean;
  status: string;
  title: string;
  phase_name: string;
  usage_rights_flat_cost: number;
  user_id: number;
  multi_phase: number;
  parent_id: number;
  payment_terms: string;
  brand_id: number;
  pod_id: number;
  pod: string;
  brief_attributes: {
    go_live_start: string;
    go_live_end: string;
  };
  contract?: ContractTemplate;
}

export interface CampaignContent {
  sections: CampaignContent[];
  id: number;
  active: boolean;
  campaign_id: number;
  caption: string;
  participant_id: number;
  deliverable_id: number;
  fohr_content_id: string;
  bonus: boolean;
  platform_uuid: string;
  image_file_name: string | null;
  image_content_type: string | null;
  image_file_size: number | null;
  image_updated_at: string | null;
  platform: string;
  type: string;
  approved: boolean;
  stats_screenshot: {
    original: string;
  };
  stats_screenshot_file_name: string;
  stats_screenshot_content_type: string;
  stats_screenshot_file_size: number;
  stats_screenshot_updated_at: string;
  data: {
    manually_created: boolean;
    uploaded_via_insight_uploader: boolean;
    initial_impressions: number;
    published_at: string;
    impressions: string;
    permalink: string;
    source: string;
    interactions: string;
    caption: string;
    video: string;
  };
  insights: {
    insight_categories: number[];
    insight_impressions: string;
    insight_interactions: string;
    insight_reach_count: string;
    insight_likes_count: string;
    insight_comments_count: string;
    insight_sends: string;
    insight_saves: string;
    insight_profile_visits: string;
    insight_website_clicks: string;
    insight_follows: string;
    insight_shares: string;
    insight_video_views: string;
  };
  published_at: string;
  created_at: string;
  updated_at: string;
  cardType: string;
  post: any;
  decoration: boolean;
  state: boolean;
  follower_count: number;
  reach_count: number;
  interactions: number;
  social_username: string;
  media_product_type: string;
  post_id?: string;
}

export interface AmbassadorProps {
  agency_name: string;
  brand_content_approvers: string;
  brand_name: string;
  budget: string;
  category: string;
  content_review_enabled: boolean;
  contract_template_id: boolean;
  exclusive_rights_flat_cost: number;
  guaranteed_content: number;
  guaranteed_impressions: number;
  guaranteed_participant_count: number;
  include_bonus: boolean;
  auto_content_attribution: boolean;
  kind: string;
  other_flat_cost: number;
  salesforce_project_number: string;
  projected_ave_following: number;
  projected_engagement_percentage: number;
  skip_participant_mailers: boolean;
  status: string;
  title: string;
  phase_name: string;
  usage_rights_flat_cost: number;
  user_id: number;
  multi_phase: number;
  parent_id: number;
  brand_id: number;
  pod_id: number;
}

export interface AmbassadorContentProps extends AmbassadorContent {
  cardType: string;
  post: any;
  decoration: boolean;
  state: boolean;
}

export interface AmbassadorContentListProps
  extends Array<AmbassadorContentProps> {}

export interface GiftTableListProps {
  agency_name: string;
  brand_content_approvers: string;
  brand_name: string;
  budget: string;
  category: string;
  content_review_enabled: boolean;
  contract_template_id: boolean;
  exclusive_rights_flat_cost: number;
  guaranteed_content: number;
  guaranteed_impressions: number;
  guaranteed_participant_count: number;
  include_bonus: boolean;
  auto_content_attribution: boolean;
  kind: string;
  other_flat_cost: number;
  salesforce_project_number: string;
  projected_ave_following: number;
  projected_engagement_percentage: number;
  skip_participant_mailers: boolean;
  status: string;
  title: string;
  phase_name: string;
  usage_rights_flat_cost: number;
  user_id: number;
  multi_phase: number;
  parent_id: number;
  brand_id: number;
  pod_id: number;
}

export interface GiftTableProps extends GiftTable {
  cardType: string;
  post: any;
  decoration: boolean;
  state: boolean;
}

export interface ParticipantPlatformStats {
  engagement_percentage: number;
  followers: number;
  views: number;
  views_percentage: number;
  reels_views_percentage?: number;
  stories_views_percentage?: number;
}

export enum ParticipantStatus {
  ACCEPTED = "accepted",
  ONBOARDING = "onboarding",
  VIEWED = "viewed",
  DECLINED = "declined",
  NON = "n/a",
  REMOVED = "removed",
  ARCHIVED = "archived",
  REVIEW_PENDING = "review_pending",
  REVIEW_REJECTED = "review_rejected",
  REVIEW_APPROVED = "review_approved",
}

export interface Participant {
  campaign_influencer_id: number;
  id: number;
  campaign_id: number;
  creator_id: number;
  influencer_id: number | string;
  status: string;
  ethnicity: string[];
  sexual_orientation: string[];
  gender?: string[];
  creator: Creator;
  brief_viewed_at: string | null;
  usage_rights: string | null;
  exclusive_rights: string | null;
  exclusive_notes: string | null;
  product_notes: string | null;
  legal: string | null;
  payment_terms: string | null;
  offer_token: string;
  created_at: string;
  updated_at: string;
  blogname: string | null;
  full_name: string;
  imported_from_list_name: string | null;
  paid: boolean | null;
  legal_group_old: Record<string, any>;
  paid_date: string | null;
  exclusive_rights_rate: number;
  usage_rights_rate: number;
  legal_group: Record<string, any>;
  accepted_at: string | null;
  usage_rights_flat_cost: number | null;
  exclusive_rights_flat_cost: number | null;
  other_flat_cost: number | null;
  archie_paid: boolean;
  participant_group_id: number;
  has_representative_entity: boolean;
  representative_entity_name: string | null;
  manager_first_name: string | null;
  manager_last_name: string | null;
  manager_email: string | null;
  representative_entity_address1: string | null;
  representative_entity_address2: string | null;
  representative_entity_city: string | null;
  representative_entity_state: string | null;
  representative_entity_country: string | null;
  representative_entity_relationship: string | null;
  representative_entity_representation_authorized: boolean;
  representative_entity_payment_authorized: boolean;
  representative_entity_zip_code: string | null;
  product_order_created: boolean;
  profile: Creator;
  slug?: string;
  deliverables: CampaignDeliverable[];
  creator_review_description: string;
  instagram_stats: ParticipantPlatformStats;
  tiktok_stats: ParticipantPlatformStats;
}

export interface ParticipantOffer {
  selected_price: number;
}

export interface PostProps {
  id: number;
  categories?: string[];
  likes_count?: number;
  comments_count?: number;
  views_count?: number;
  reach?: number;
  content_id?: number;
  decoration?: string;
  content_url?: string;
  title?: string;
  subtext?: string;
  permalink?: string;
  content_type?: string;
  profile_image?: string;
  photo_url?: string;
  video_url?: string;
  active: boolean;
  campaign_id: number;
  participant_id: number;
  deliverable_id?: number | null;
  fohr_content_id: string;
  bonus: boolean;
  profile_link: string;
  full_name: string;
  social_profile_url: string;
  username: string;
  caption: string;
  platform_uuid: string;
  image_file_name: string;
  image_content_type: string;
  image_file_size: number;
  image_updated_at: string;
  platform: string;
  type: string;
  approved: boolean;
  stats_screenshot_file_name?: string | null;
  stats_screenshot_content_type?: string | null;
  stats_screenshot_file_size?: number | null;
  stats_screenshot_updated_at?: string | null;
  data: {
    id: number;
    card_id?: number;
    post_id?: string;
    photo: string;
    photo_content_type: string;
    caption?: string;
    search_cache?: string;
    published_at: string;
    provider: string;
    interactions?: number;
    impressions?: number;
    permalink?: string;
    tagged_users?: string;
    tags?: string[];
    type: string;
    video?: string | null;
    video_content_type?: string | null;
    likes_count?: number;
    comments_count?: number;
    unique_stats?: {
      exits?: number;
      reach?: number;
      replies?: number;
      taps_back?: number;
      impressions?: number;
      taps_forward?: number;
      likes_count?: number;
      comments_count?: number;
      views_count?: number;
    };
    media_product_type?: string;
    initial_impressions?: number;
  };
  insights: {
    insight_exits?: number;
    insight_reach?: number;
    insight_replies?: number;
    insight_taps_back?: number;
    insight_impressions?: number;
    insight_taps_forward?: number;
    insight_likes_count?: number;
    insight_comments_count?: number;
    insight_views_count?: number;
    insight_saves?: number;
    insight_video_views?: number;
    insight_carousel_album_reach?: number;
    insight_carousel_album_engagement?: number;
    insight_carousel_album_impressions?: number;
  };
  published_at: string;
  created_at: string;
  updated_at: string;
  image: {
    thumb: string;
    poster: string;
    medium: string;
    large: string;
  };
  stats_screenshot: {
    thumb: string;
    poster: string;
    medium: string;
    large: string;
  };
  cardDropDownOptions: { label: string; value: string }[];
  checkBoxClickList?: number[];
  checkbox: boolean;
  onCheckBoxClick?: (id: number[]) => void;
  onOptionSelect?: (value: string) => void;
  statHelperText: string;
  state: boolean;
}

export interface BriefSection {
  id: number;
  body: string;
  position: number;
  campaign_brief_id: number;
  kind: string;
  created_at: string;
  updated_at: string;
  hidden: boolean;
  title?: string | null;
  custom: boolean;
  links?: {
    self: string;
  };
  assets: CampaignBriefAsset[];
  attributes: {
    archived: boolean;
    data: {
      approved: any[]; // Specify more detailed type if possible
      rejected: any[]; // Specify more detailed type if possible
    };
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    profile_image: string;
    slug: string;
    type: string;
  };
}

interface ReportParticipantList {
  data: ReportParticipants[];
  id: number;
  included: number;
  links: {
    self: string;
  };
  name: string;
  total: number;
}

interface ReportParticipants {
  brief: { id: string };
  cards: Record<string, unknown>; // Specify more detailed type if possible
  unverified: Record<string, unknown>; // Specify more detailed type if possible
  lists: ReportParticipantList[];
}

export interface CampaignProduct {
  id: number;
  campaign_id: number;
  name: string;
  notes: string;
  campaign_product_size: number;
  url: string;
  image: string | File | UploadedFile;
  image_url: string;
  image_file_name: string;
  image_content_type: string;
  image_file_size: number;
  image_updated_at: string;
  created_at: string;
  updated_at: string;
}

export interface ContractTemplate {
  default: boolean;
  id: number;
  name: string;
  sections: BriefSection[];
}

export interface BriefImage {
  large: string;
  original: string;
  thumb: string;
}

export interface CampaignBrief {
  id: number;
  invoice_template_url: string;
  sections?: BriefSection[];
  campaign_id: number;
  website: string | null;
  description: string | null;
  key_messaging: string | null;
  usage_rights: string;
  exclusive_rights: boolean;
  exclusive_notes: string;
  legal: string | null;
  payment_terms: string;
  product_details: string | null;
  go_live_start: string;
  go_live_end: string;
  submit_content: string | null;
  submit_application: string;
  shipping_start: string | null;
  shipping_end: string | null;
  cover_image: BriefImage;
  logo_image: BriefImage;
  mood_image: BriefImage;
  cover_image_file_name: string;
  cover_image_content_type: string;
  cover_image_file_size: number;
  cover_image_updated_at: string;
  logo_image_file_name: string | null;
  logo_image_content_type: string | null;
  logo_image_file_size: number | null;
  logo_image_updated_at: string | null;
  mood_image_file_name: string | null;
  mood_image_content_type: string | null;
  mood_image_file_size: number | null;
  mood_image_updated_at: string | null;
  created_at: string;
  updated_at: string;
  legal_group_old: any;
  legal_group: {
    ftc: string;
    sag: string;
    timing: string;
    posting: string;
    reshoots: string;
    licensing: string;
    reporting: string;
    competitors: string;
    termination: string;
    product_value: string;
    code_of_conduct: string;
    confidentiality: string;
    payment_details: string;
    content_approval: string;
    content_creation: string;
    follower_agreement: string;
  };
  participant_id: number;
  participant_group_id: number | null;
  title: string;
  invoice_email: string | null;
  cover_image_position: number;
  payment_date: string | null;
  campaign_brief_invoice_template?: string;
  invoice_template?: string;
  invoice_template_file_name: string | null;
  invoice_template_content_type: string | null;
  invoice_template_file_size: number | null;
  invoice_template_updated_at: string | null;
  logo_image_size: number;
  deal_memo: any;
  terms_conditions: any;
  code_of_conduct: any;
  loading?: boolean;
}

export interface ReportProps {
  id: number;
  title: string;
  createdBy: string;
  brand: string;
  date: string;
  status: string;
  start_date: string;
  end_date: string;
  background_url: string;
  description: string;
  platforms: string[];
  hashtags: string[];
  mentioned_users: string[];
  slug: string;
  hasParticipants: boolean;
  participants: ReportParticipants;
}

export interface Pagination {
  pageSize?: number;
  currentPage: number;
  total: number;
}

export type SortOrder = "asc" | "desc" | "none";

export interface Sort {
  key: string;
  direction: SortOrder;
}

export enum UserNotificationStatus {
  READ = "read",
  UNREAD = "unread",
}

export interface UserNotification {
  campaign_id: number;
  platform: string;
  campaign_name: string;
  category: string;
  created_at?: string;
  details: string;
  id?: number;
  link: string;
  participant?: { id: number; full_name: string; influencer_id: number };
  photo_url: string;
  status: UserNotificationStatus;
  updated_at: string;
}

export interface UserNotificationSetting {
  campaign_id: string;
  campaign: {
    title: string;
    id: number;
  };
  created_at: string;
  id: number;
  settings: {
    post: boolean;
    offer: boolean;
    connections: boolean;
    content_review: boolean;
    live_content_due: boolean;
  };
  updated_at: string;
  user_id: number;
}

export interface AdminBrand {
  id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
  fohr_id: number;
  parent_brand_id: number | null;
  instagram_handle: string;
  competitor_instagram_handles: string;
  keywords: string;
  reports_hidden: boolean;
  can_boost_tiktok_posts: boolean;
  visible: boolean;
  url: string;
  tagline: string;
  location: string;
  facebook_handle: string;
  youtube_handle: string;
  blog_url: string;
  about: string;
  looking_for: string;
  old_brand_id: number | null;
  expires_at: string;
  sales_contact: string;
  email_contact: string;
  directory_photo_file_name: string;
  directory_photo_content_type: string;
  directory_photo_file_size: number;
  directory_photo_updated_at: string;
  background_photo_file_name: string;
  background_photo_content_type: string;
  background_photo_file_size: number;
  background_photo_updated_at: string;
  logo_photo_file_name: string;
  logo_photo_content_type: string;
  logo_photo_file_size: number;
  logo_photo_updated_at: string;
  background_photo_width: number | null;
  background_photo_height: number | null;
  instagram_uid: string | null;
  trial: boolean;
  contract_expiration: string | null;
  contract_start: string | null;
  lite: boolean;
  amp: boolean;
  essential: boolean;
  refill_token_amount: number;
  remaining_tokens: number | null;
  has_reports: boolean;
  has_explore: boolean;
  has_opportunities: boolean;
  agency: boolean;
  started_as_trial: boolean;
  competitors: string;
  tiktok_handle: string;
  pixel: boolean;
  accepting_applications: boolean;
  brand_id: number | null;
  primary_connection: string | null;
  allow_manual_connections: boolean;
  required_connections: string[];
  stripe_connected_account_id: string | null;
}

export interface AdminBrandUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
}

export interface UploadedFile {
  file: File;
  url: string;
}

export interface Report {
  id: number | string;
  title: string;
  platforms: string[];
  brand_user_id: number | null;
  brand_brand_id: number | null;
  brand_name: string | null;
  created_by: string | null;
  archived: boolean;
  start_date: string | null;
  end_date: string | null;
  hashtags: string[];
  mentioned_users: string[];
  source_type: string;
  source_id: number;
  slug: string;
  created_at: string;
  updated_at: string;
  refreshing: boolean;
  emv_visible: boolean;
  influencer_params: any;
  post_params: any;
  new_app_brand_id: number;
  new_app_user_id: number;
  source: string;
  bonus_content: boolean;
  bonus_content_count: number;
  content_count: number;
  report_id: number;
  reports: {
    id: number;
    title: string;
    brand_name: string;
    created_at: string;
    source_type: string;
    platforms: string[];
  }[];
  content_summary: {
    followers: number;
    total_views: number;
    organic_impressions: number;
    paid_impressions: number;
    total_posts: number;
    influencers_count: number;
    organic_posts: number;
    paid_posts: number;
    engagements: number;
    organic_engagements: number;
    paid_engagements: number;
    total_likes: number;
    total_comments: number;
  };
  engagement_graph_data?: {
    posts_graph_data: Record<string, number>;
    reach_graph_data: Record<string, number>;
    engagement_graph_data: Record<string, number>;
  };
  metrics?: {
    posts: number;
    reach: number;
    impressions: number;
    engagement: number;
    creators: number;
    emv?: number;
    views?: number;
    views_count: number;
    engagement_rate: number;
    interactions: number;
    followers: number;
  };
}

export interface ReportMetrics {
  posts: number;
  reach: number;
  views_count: number;
  engagement_rate: number;
  creators: number;
  influencers_count: number;
  emv: number;
  interactions: number;
  followers: number;
  impressions: number;
  engagement: number;
}

export interface PerformanceGraphs {
  content_chart_data?: any[];
  posts?: Record<string, number>;
  viral_posts?: ViralPostsData;
}

export interface AudienceData {
  age: Array<{ name: string; percentage: number; count: number }>;
  gender: Array<{ name: string; percentage: number; count: number }>;
  ethnicity: Array<{ name: string; percentage: number; count: number }>;
  religion: Array<{ name: string; percentage: number; count: number }>;
  parental: Array<{ name: string; percentage: number; count: number }>;
  marital: Array<{ name: string; percentage: number; count: number }>;
  countries: Array<{ name: string; percentage: number; count: number }>;
  states: Array<{ name: string; percentage: number; count: number }>;
}
