import Modal from "@components/Modals/Modal";

import { ReactNode } from "react";
import FButton from "@components/FButton";

export interface AdvancedSearchModalProps {
  onAddToFilters: () => void;
  onClearFilters: () => void;
  onClose: () => void;
  children: ReactNode;
  title: string;
}

const AdvancedSearchModal = ({
  onClose,
  onAddToFilters,
  onClearFilters,
  children,
  title,
}: AdvancedSearchModalProps) => (
  <Modal
    className="w-[800px] h-[700px] overflow-auto"
    isOpen
    hasForm
    onClose={onClose}
    title={title}
    titleRight={
      <div className="flex items-center gap-2">
        <FButton label="Clear Filters" onClick={onClearFilters} />
        <FButton
          label="+ Add Filters to Query"
          onClick={onAddToFilters}
          primary
        />
      </div>
    }
  >
    <div className="flex flex-col gap-10 pt-8 pb-10 w-full">{children}</div>
  </Modal>
);

export default AdvancedSearchModal;
