import React, { useContext } from "react";
import { formatNumber } from "@utils/number_utilities";
import { NewReportContext } from "@contexts/index";

interface StatsSnapshotProps {
  metrics: {
    reach: number;
    views_count: number;
    engagement_rate: number;
    influencers_count?: number;
    emv: number;
    posts: number;
    creators: number;
    interactions: number;
    followers: number;
  };
  isLoading: boolean;
}

export const StatsSnapshot: React.FC<StatsSnapshotProps> = ({
  metrics,
  isLoading,
}) => {
  const context = useContext(NewReportContext);
  const report = context?.report;
  const contentSummary = report?.content_summary;

  const stats = [
    { label: "Posts", value: formatNumber(metrics.posts || 0) },
    {
      label: "Creators",
      value: formatNumber(
        Math.max(metrics.creators || 0, contentSummary?.influencers_count || 0)
      ),
    },
    { label: "Reach", value: formatNumber(metrics.reach || 0) },
    { label: "Views", value: formatNumber(metrics.views_count || 0) },
    {
      label: "Engagement Rate",
      value: `${(metrics.engagement_rate || 0).toFixed(2)}%`,
    },
    { label: "EMV", value: `$${formatNumber(metrics.emv || 0)}` },
  ];

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg p-6 space-y-4">
        <h2 className="text-lg font-semibold mb-4">Campaign Stats</h2>
        <div className="animate-pulse space-y-3">
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <div key={i} className="h-6 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="pr-8">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-xl font-medium">Report Stats</h2>
      </div>
      <div className="p-4 space-y-3 border border-light_border rounded-lg bg-light_red shadow-md">
        {stats.map((stat) => (
          <div
            key={stat.label}
            className="flex justify-between items-center text-sm"
          >
            <span className="text-neutral_700">{stat.label}</span>
            <span className="font-medium">{stat.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
