import { HTMLAttributeAnchorTarget } from "react";
import FIcon from "./FIcon";
import FSpinner from "./FSpinner";

interface ButtonProps {
  primary?: boolean;
  disabled?: boolean;
  className?: string;
  width?: number | string;
  height?: number | string;
  label?: string;
  link?: string;
  loading?: boolean;
  icon?: {
    name: string;
    className?: string;
    size: number;
    color?: string;
    align?: string;
    hoverColor?: string;
  };
  iconLeft?: {
    name: string;
    className?: string;
    size: number;
    color?: string;
    align?: string;
    hoverColor?: string;
  };
  iconButton?: boolean;
  onClick?: (arg?: any) => void;
  style?: object;
  target?: HTMLAttributeAnchorTarget | undefined;
  type?: "button" | "submit" | "reset";
}

const FButton = ({
  className = "",
  primary = false,
  disabled = false,
  label = "",
  loading = false,
  link,
  icon,
  iconLeft,
  width = "auto",
  height = 40,
  iconButton,
  ...props
}: ButtonProps) => {
  const baseClass = `inline-block rounded flex text-sm items-center justify-center text-center px-[${iconButton ? 8 : 16}px] py-[10px] pb-[8px]`;
  let mode =
    primary && !disabled
      ? "bg-dark_night_sky text-white"
      : !disabled
        ? `${iconButton ? "bg-transparent" : "bg-white"} text-dark_night_sky hover:bg-hover_light_red border border-dark_night_sky active:bg-press_light_red`
        : "hover:bg-hover_light_red active:bg-press_light_red";

  if (disabled) {
    if (primary) {
      mode += " bg-neutral_400 text-white";
    } else {
      mode += " text-neutral_500 border border-neutral_500";
    }

    mode += " pointer-events-none";
  }

  if (iconButton) {
    mode += " border-none";
  }

  const buttonIconColor = disabled
    ? "#B0B5C4"
    : icon
      ? icon.color || "#000F45"
      : "";

  return (
    <div className={width === "100%" ? "w-full" : ""}>
      {link ? (
        <a
          href={link}
          className={`${baseClass} ${mode} ${className} `}
          style={{
            height: height,
            width: width,
          }}
          target=""
          {...props}
        >
          {!loading ? (
            <>
              {label}
              {icon && (
                <FIcon
                  icon={icon.name}
                  size={icon.size}
                  color={buttonIconColor}
                  className={icon.className}
                  hoverColor={icon?.hoverColor}
                />
              )}
            </>
          ) : (
            <FSpinner
              width={20}
              height={20}
              borderWidth={2}
              borderColor="transparent"
              backgroundColor="#fff"
            />
          )}
        </a>
      ) : (
        <button
          type={props?.type || "button"}
          disabled={disabled}
          className={`${baseClass} ${mode} ${className}`}
          style={{
            height: height,
            width: width,
          }}
          {...props}
        >
          {!loading ? (
            <>
              {iconLeft && (
                <div className="mr-[6px]">
                  <FIcon
                    icon={iconLeft.name}
                    size={iconLeft.size}
                    color={disabled ? "#000F45" : iconLeft.color || "#000F45"}
                    className={iconLeft.className}
                    hoverColor={iconLeft?.hoverColor}
                  />
                </div>
              )}
              {label}
              {icon && (
                <FIcon
                  icon={icon.name}
                  size={icon.size}
                  color={disabled ? "#000F45" : buttonIconColor}
                  className={icon.className}
                  hoverColor={icon?.hoverColor}
                />
              )}
            </>
          ) : (
            <FSpinner
              width={iconButton ? icon.size : 20}
              height={iconButton ? icon.size : 20}
              borderWidth={2}
              borderColor="transparent"
              backgroundColor={iconButton ? "gray" : primary ? "#fff" : "#333"}
            />
          )}
        </button>
      )}
    </div>
  );
};

export default FButton;
