import React, { InputHTMLAttributes } from "react";
import FIcon from "../FIcon";

export const baseInputStyles =
  "border rounded-[5px] ::placeholder-neutral_400 p-2 text-dark_night_sky w-full text-sm min-h-[40px]";

export interface FBaseInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  prefix?: string;
  type?: "text" | "number" | "email" | "password" | "date" | "input" | "file";
  id?: string;
  icon?: {
    name?: string;
    color?: string;
    size?: number;
  };
  width?: number | string;
  defaultValue?: string;
  value?: string;
  error: string | string[];
  onEnter?: () => void;
  onChange: (value: string) => void;
}

const FBaseInput = ({
  prefix,
  type = "text",
  icon = {},
  onEnter,
  onChange,
  value,
  error,
  ...props
}: FBaseInputProps) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && onEnter) {
      onEnter();
    }
  };

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const inputClassName = `${icon.name ? "pl-[40px]" : ""} ${prefix ? "pl-[20px]" : ""} ${baseInputStyles} ${error ? "border-error_red" : "border-default_weak"}`;

  const handleChange = (e) => {
    const newValue =
      type === "number" ? removeNonNumeric(e.target.value) : e.target.value;
    onChange(newValue);
  };

  return (
    <div className="flex items-center relative">
      {icon?.name && (
        <FIcon
          icon={icon.name}
          color={icon.color || "#000F45"}
          size={icon.size || 20}
          className="absolute left-[8px] top-0 bottom-0 m-auto"
        />
      )}
      {prefix ? (
        <span className="absolute left-[8px] top-1.52 m-auto">{prefix}</span>
      ) : null}
      <input
        {...props}
        type={type !== "number" ? type : null}
        value={type === "number" ? addCommas(value) : value}
        className={inputClassName}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default FBaseInput;
