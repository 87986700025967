import { getCountries, getRegions } from "@api/Geo";
import FDropdown from "@components/FDropdown";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";

interface CountryRegionPicker {
  value: {
    country: string;
    region: string;
  };
  onChange: (value: { country?: string; region?: string }) => void;
}

const CountryRegionPicker = ({ value, onChange }) => {
  const [countries, setCountries] = useState<[string, string][]>([]);
  const [isoCodesForCountries, setIsoCodesForCountries] = useState<
    Record<string, string>
  >({});

  const [regions, setRegions] = useState<[string, string][]>([]);

  useEffect(() => {
    const loadCountries = async () => {
      const response = await getCountries();

      const { countries } = response;

      setCountries(countries);

      setIsoCodesForCountries(
        countries.reduce(
          (result, country) => ({
            ...result,
            [country[1]]: country[0],
          }),
          {}
        )
      );

      if (value.country) {
        const country = countries.find(
          ([, countryName]) => countryName === value.country
        );

        handleUpdateCountry(value.country, country[0], value.state);
      }
    };

    loadCountries();
  }, []);

  const handleUpdateCountry = async (value, isoCode?, selectedState?) => {
    const isoCodeToUse = isoCode || isoCodesForCountries[value];

    const response = await getRegions(isoCodeToUse);

    const { regions } = response;

    setRegions(uniqBy(regions, ([code]) => code));

    onChange({ country: value, state: selectedState || regions[0][1] });
  };

  return (
    <>
      <FDropdown
        className="mb-2"
        selectedValue={value.country}
        options={countries.map(([, label]) => ({
          value: label,
          label,
        }))}
        onChange={(value) => handleUpdateCountry(value)}
        label="Country"
        required
        width="100%"
      />
      <FDropdown
        className="mb-4"
        selectedValue={value.state}
        options={regions.map(([, label]) => ({ value: label, label }))}
        onChange={(value) => onChange({ state: value })}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            handleUpdateCountry(value.country);
          }
        }}
        label="State"
        required
        width="100%"
      />
    </>
  );
};

export default CountryRegionPicker;
