import { AdvancedSearchParams } from "../types";

import AdvancedSearchModal from "./components/AdvancedSearchModal";

import InfluencerDetails from "./components/InfluencerDetails";
import InfluencerDemographics from "./components/InfluencerDemographics";

import { useEffect, useState } from "react";
import ContentDetails from "./components/ContentDetails";
import InfluencerCategories from "./components/InfluencerCategories";
import AudienceFilters from "./components/AudienceFilters";
import { isEmpty } from "lodash";
import SocialAnalytics from "./components/social-analytics";

interface AdvancedContentSearchModalProps {
  onUpdateFilters: (filters: Partial<AdvancedSearchParams>) => void;
  onClose: () => void;
  filters: AdvancedSearchParams;
  selectedPlatforms: string[];
}

const AdvancedContentSearchModal = ({
  onClose,
  filters,
  onUpdateFilters,
  selectedPlatforms,
}: AdvancedContentSearchModalProps) => {
  const [pendingFilters, setPendingFilters] = useState<
    Partial<AdvancedSearchParams>
  >({});

  useEffect(() => {
    setPendingFilters({
      ...filters,
    });
  }, []);

  const handleAddFilters = () => {
    onUpdateFilters(pendingFilters);
  };

  const handleUpdatePendingFilters = (updates: Partial<AdvancedSearchParams>) =>
    setPendingFilters({
      ...pendingFilters,
      ...updates,
    });

  return (
    <AdvancedSearchModal
      onClose={onClose}
      onAddToFilters={handleAddFilters}
      onClearFilters={() => onUpdateFilters({})}
      title="Content Filters"
    >
      <>
        <ContentDetails
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
        />
        <SocialAnalytics
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
          onSetFilters={setPendingFilters}
          platforms={selectedPlatforms}
        />
        <InfluencerDetails
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
          hideLocation
        />
        <InfluencerDemographics
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
        />

        <InfluencerCategories
          filters={pendingFilters}
          onFiltersUpdate={handleUpdatePendingFilters}
        />
        {!isEmpty(selectedPlatforms) ? (
          <AudienceFilters
            filters={pendingFilters}
            onFiltersUpdate={handleUpdatePendingFilters}
          />
        ) : null}
      </>
    </AdvancedSearchModal>
  );
};

export default AdvancedContentSearchModal;
