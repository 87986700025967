import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  numberWithKMBFormat,
  formatEngagementRate,
} from "@utils/number_utilities";

interface TimeSeriesPoint {
  date: Date;
  value: number;
}

interface KPIWithSparklineProps {
  title: string;
  value: number | string;
  valueType: "number" | "percent" | "dollars";
  timeSeriesData: TimeSeriesPoint[];
}

export default function KPIWithSparkline({
  title,
  value,
  valueType,
  timeSeriesData,
}: KPIWithSparklineProps) {
  const formatValue = (val: number | string) => {
    if (valueType === "percent") {
      return formatEngagementRate(val);
    }
    if (valueType === "dollars") {
      return `$${numberWithKMBFormat(Number(val))}`;
    }
    return numberWithKMBFormat(Number(val));
  };

  const getYAxisMinMax = () => {
    const values = timeSeriesData.map((point) => point.value);
    const min = Math.min(...values);
    const max = Math.max(...values);
    const range = max - min;

    // For very small ranges relative to the values, create a more visible range
    if (range === 0 || (range / max < 0.01 && max !== 0)) {
      return {
        min: min * 0.9,
        max: max * 1.1,
      };
    }

    // Add padding to make the slope more visible
    return {
      min: Math.max(0, min - range * 0.1), // 10% padding below, but never go below 0
      max: max + range * 0.1, // 10% padding above
    };
  };

  const { min, max } = getYAxisMinMax();

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "spline",
      height: 60,
      backgroundColor: "transparent",
      margin: [5, 2, 5, 2],
      spacing: [0, 0, 0, 0],
      style: {
        overflow: "visible",
      },
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      type: "datetime",
      min: timeSeriesData[0]?.date.getTime(),
      max: timeSeriesData[timeSeriesData.length - 1]?.date.getTime(),
      ordinal: false,
      minPadding: 0,
      maxPadding: 0,
      startOnTick: true,
      endOnTick: true,
      tickInterval: undefined,
    },
    yAxis: {
      visible: false,
      min,
      max,
      startOnTick: false,
      endOnTick: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: "spline",
        data: timeSeriesData.map((point) => [
          point.date.getTime(),
          point.value,
        ]),
        color: "#E47667",
      },
    ],
  };

  console.log(timeSeriesData, "timeSeriesData");

  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center p-4 w-full">
      <div className="mb-2 sm:mb-0 sm:mr-2 w-[20%] min-w-[120px]">
        <div className="text-[16px] mb-1">{title}</div>
        <div className="text-3xl truncate">{formatValue(value)}</div>
      </div>
      <div className="w-full sm:w-[80%] h-[60px] -ml-4 sm:ml-0">
        <div className="w-full h-full -ml-6">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </div>
    </div>
  );
}
