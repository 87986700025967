import React, { useContext } from "react";
import { NewReportContext } from "@contexts/index";
import ReportBonusContentTable from "./components/ReportBonusContentOverview";
import LoadingSpinner from "@components/LoadingSpinner";
import GeneralPostModal from "@components/Modals/GeneralPostModal";

export default function BonusContent() {
  const {
    report,
    isLoading,
    activePost,
    contentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
  } = useContext(NewReportContext);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="">
      <ReportBonusContentTable
        report_id={report?.id}
        onCardClick={handleContentModalClick}
      />
      {activePost && (
        <GeneralPostModal
          post={activePost}
          isOpen={contentModalOpen}
          onClose={handleContentModalClose}
        />
      )}
    </div>
  );
}
