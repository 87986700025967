import React, { useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { FInput } from "@components/FInputs";

import FDropdown from "@components/FDropdown";
import { Bulletin, UploadedFile } from "@types";
import {
  createBulletin,
  deleteBulletinCoverImage,
  updateBulletin,
} from "@api/Bulletins/Bulletins";
import useAuth from "@hooks/useAuth";
import Modal from "@components/Modals/Modal";
import { capitalize } from "lodash";
import RichTextEditor from "@components/RichTextEditor";
import FormField from "@components/Form/FormField";
import moment from "moment";
import FRangePicker from "@components/FDatePicker/FRangePicker";
import FFileUpload from "@components/FFileUpload/FFileUpload";

const CATEGORIES = ["survey", "opportunity", "gifting", "event"];

interface Props {
  entity?: Bulletin;
  onClose: () => void;
  onUpdated?: (campaign: Bulletin) => void;
  onCreated?: (campaign: Bulletin) => void;
}

export default function CreateOrUpdateBulletinModal({
  onClose,
  onUpdated,
  onCreated,
  entity,
}: Props) {
  const [loading, setLoading] = useState(false);

  const { image_url } = entity;

  const hasCoverImage = image_url && !image_url.endsWith("missing_default.png");

  const [uploadedImage, setUploadedImage] = useState<UploadedFile>(
    hasCoverImage
      ? {
          file: null,
          url: image_url,
        }
      : null
  );

  const [isSubmitted, setSubmitted] = useState(false);

  const [values, setValues] = useState<Partial<Bulletin>>({
    name: "",
    description: "",
    category: CATEGORIES[0],
    start_date: null,
    end_date: null,
    brand: {
      id: null,
    },
    ...(entity || {}),
  });

  const { user } = useAuth();

  const handleUpdate = (updates: Partial<Bulletin>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const {
      name,
    } = values;

    if (!name) {
      return;
    }

    setLoading(true);

    try {
      if (entity?.id) {
        let response = await updateBulletin(entity.id, {
          ...values,
          image: uploadedImage?.file || null,
        });

        if (hasCoverImage && !uploadedImage) {
          response = await deleteBulletinCoverImage(entity.id);
        }

        onUpdated(response.data);

        toast.success("Bulletin Updated");
      } else {
        const params = {
          ...values,
          user_id: user.id,
          brand_id: user.brand_id,
          image: uploadedImage?.file || null,
        };

        delete params.brand;

        const response = await createBulletin(params);

        onCreated(response.data);
      }
    } catch (e) {
      toast.error(`Error ${entity ? "updating" : "creating"} bulletin`);
    } finally {
      setLoading(false);
    }
  };

  console.log("Values", values);

  return (
    <Modal
      actionLabel="Save"
      className="w-[800px] h-[660px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title={`${entity?.id ? "Edit" : "Create"} Bulletin`}
      waiting={loading}
    >
      <div className="flex flex-col gap-6 m-auto w-full">
        <FInput
          name="title"
          onChange={(value) => handleUpdate({ name: value })}
          label="Bulletin Name"
          value={values.name}
          required
          validate={isSubmitted}
          width="100%"
        />
        <FormField label="Body" required width="100%">
          <RichTextEditor
            value={values.description}
            id="bulletin_description"
            onChange={(value) =>
              console.log("Updated value", value) ||
              handleUpdate({ description: value })
            }
          />
        </FormField>
        <div className="flex gap-4">
          <FDropdown
            label="Category"
            options={CATEGORIES.map((category) => ({
              label: capitalize(category),
              value: category,
            }))}
            required
            selectedValue={values.category}
            onChange={(value) => handleUpdate({ category: value as string })}
            height="40"
            validate={isSubmitted}
            width="100%"
          />
        </div>
        <FRangePicker
          minDate={moment().format()}
          value={{
            from: values.start_date,
            to: values.end_date,
          }}
          onChange={({ from, to }) =>
            handleUpdate({
              start_date: from,
              end_date: to,
            })
          }
        />
        <FormField label="Cover image" width="100%">
          <FFileUpload
            className="w-full"
            onUpload={(files) => setUploadedImage(files[0])}
            uploadedFiles={[uploadedImage]}
            onDelete={() => setUploadedImage(null)}
          />
        </FormField>
      </div>
    </Modal>
  );
}
