import React from "react";
import { formatNumber } from "@utils/number_utilities";
import { ViralPostsData } from "@contexts/types";

interface ViralPostsProps {
  viralPosts: ViralPostsData;
  parentLoading?: boolean;
}

const ViralPosts = ({ viralPosts, parentLoading = false }: ViralPostsProps) => {
  if (parentLoading) return null;

  const posts = [
    {
      title: "Top 10%",
      engagement: viralPosts.ten_post_count,
      reach: viralPosts.ten_reach_count,
      platform: "All Platforms",
    },
    {
      title: "Top 5%",
      engagement: viralPosts.five_post_count,
      reach: viralPosts.five_reach_count,
      platform: "All Platforms",
    },
    {
      title: "Top 2%",
      engagement: viralPosts.two_post_count,
      reach: viralPosts.two_reach_count,
      platform: "All Platforms",
    },
  ];

  const topPost = posts[0];

  return (
    <div className="pr-8 mt-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-medium">Top Performing Content</h2>
      </div>
      <div className="border border-light_border rounded-lg bg-light_red shadow-md">
        <div className="p-4 flex justify-between items-center border-b border-light_border">
          <h3 className="text-lg font-medium">Viral Posts</h3>
          <p className="text-lg">
            {formatNumber(topPost?.engagement || 0)} posts
          </p>
        </div>
        <div className="space-y-4 p-4">
          {posts.map((post, index) => (
            <div
              key={index}
              className="flex justify-between items-center border-b border-light_border last:border-0 pb-4 last:pb-0"
            >
              <div className="flex flex-col">
                <span className="text-sm font-medium truncate max-w-[200px]">
                  {post.title}
                </span>
                <span className="text-xs text-neutral_700">
                  {post.platform}
                </span>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-sm font-medium">
                  {formatNumber(post.engagement)} posts
                </span>
                <span className="text-xs text-neutral_700">
                  {formatNumber(post.reach)} reach
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViralPosts;
