import React from "react";

import ManageQuestions from "@components/questions/ManageQuestions";
import {
  createBulletinQuestion,
  deleteBulletinQuestion,
  listBulletinQuestions,
  updateBulletinQuestion,
} from "@api/Bulletins/BulletinQuestions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { QuestionnaireQuestionRequest } from "types/questionnaire";
import FIcon from "@components/FIcon";

const getBaseUrl = (bulletinId: string) => `/bulletins/${bulletinId}/questions`;

const getUpdateParams = (originalParams: QuestionnaireQuestionRequest) => {
  const params = {
    ...originalParams,
  };

  if (typeof params.data === "string") {
    params.data = { options: params.data.split(",") };
  }

  return params;
};

const BulletinQuestions = () => {
  const { bulletin_id, question_id } = useParams();

  const navigate = useNavigate();

  return (
    <div className="w-full px-[15px] sm:px-[40px] mt-[40px] z-10">
      {!question_id ? (
        <Link
          className="flex items-center mb-4"
          to={`/bulletins/${bulletin_id}`}
        >
          <FIcon icon="back" size={18} color="#000F45" />
          Go back to bulletin
        </Link>
      ) : null}
      <ManageQuestions
        entityName="Bulletin Questionnaire"
        listQuestions={() =>
          listBulletinQuestions({ bulletin_id: parseInt(bulletin_id, 10) })
        }
        createQuestion={(originalParams) => {
          return createBulletinQuestion({
            ...getUpdateParams(originalParams),
            bulletin_id: parseInt(bulletin_id, 10),
          });
        }}
        updateQuestion={(questionId, originalParams) =>
          updateBulletinQuestion(
            questionId,
            getUpdateParams(originalParams as QuestionnaireQuestionRequest)
          )
        }
        deleteQuestion={deleteBulletinQuestion}
        onAddQuestion={() => navigate(`${getBaseUrl(bulletin_id)}/new`)}
        onQuestionUpdated={() => navigate(getBaseUrl(bulletin_id))}
        hideChildQuestions
      />
    </div>
  );
};

export default BulletinQuestions;
