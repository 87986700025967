import React from "react";
import moment from "moment";
import { Report, ReportMetrics } from "types/types";
import { numberWithKMBFormat } from "@utils/number_utilities";

interface ReportsAnalyticsMessageProps {
  report: Report | null;
  metrics: ReportMetrics;
}

export const ReportsAnalyticsMessage: React.FC<
  ReportsAnalyticsMessageProps
> = ({ report, metrics }) => {
  if (!report) return null;

  const derivedMetrics = {
    reachPerPost: metrics.posts > 0 ? (metrics.reach || 0) / metrics.posts : 0,
    viewsPerPost:
      metrics.posts > 0 ? (metrics.views_count || 0) / metrics.posts : 0,
    emvPerPost: metrics.posts > 0 ? (metrics.emv || 0) / metrics.posts : 0,
  };

  const formatDateRange = (startDate: string, endDate: string) => {
    const start = moment(startDate);
    const end = moment(endDate);

    if (start.year() === end.year()) {
      // Same year
      return (
        <strong>
          {start.format("MMM D")} and {end.format("MMM D YYYY")}
        </strong>
      );
    }
    // Different years
    return (
      <strong>
        {start.format("MMM D YYYY")} and {end.format("MMM D YYYY")}
      </strong>
    );
  };

  const getContentAndMetrics = () => {
    const platformText =
      report.platforms?.length > 0 ? (
        <>
          {" "}
          from{" "}
          <strong>
            {report.platforms.length > 1
              ? `${report.platforms.slice(0, -1).join(", ")}, and ${report.platforms.slice(-1)}`
              : report.platforms[0]}
          </strong>
        </>
      ) : null;

    const dateText =
      report.start_date && report.end_date ? (
        <> between {formatDateRange(report.start_date, report.end_date)}</>
      ) : null;

    const hashtagText =
      report.hashtags?.length > 0 ? (
        <>
          {" "}
          with <strong>{report.hashtags.join(", ")}</strong>
        </>
      ) : null;

    if (!metrics.posts) {
      return (
        <>
          Featuring content{hashtagText}
          {platformText}
          {dateText}.
        </>
      );
    }

    const messages = [
      // High performance
      {
        condition: () =>
          derivedMetrics.viewsPerPost > 500000 &&
          (metrics.engagement_rate || 0) > 5,
        message: () => (
          <>
            Featuring{" "}
            <strong className="text-red">
              {numberWithKMBFormat(metrics.posts || 0)} posts
            </strong>
            {platformText}
            {dateText}. Each post averaged{" "}
            <strong className="text-red">
              {numberWithKMBFormat(derivedMetrics.viewsPerPost)} views
            </strong>{" "}
            with a{" "}
            <strong className="text-red">
              {(metrics.engagement_rate || 0).toFixed(1)}%
            </strong>{" "}
            engagement rate, worth{" "}
            <strong className="text-red">
              ${numberWithKMBFormat(derivedMetrics.emvPerPost)}
            </strong>{" "}
            in EMV.
          </>
        ),
      },
      // Default
      {
        condition: () => true,
        message: () => (
          <>
            Featuring{" "}
            <strong className="text-red">
              {numberWithKMBFormat(metrics.posts || 0)} posts
            </strong>
            {platformText}
            {dateText}. Each post averaged{" "}
            <strong className="text-red">
              {numberWithKMBFormat(derivedMetrics.viewsPerPost)} views
            </strong>{" "}
            per post with{" "}
            <strong className="text-red">
              {(metrics.engagement_rate || 0).toFixed(1)}%
            </strong>{" "}
            engagement, worth{" "}
            <strong className="text-red">
              ${numberWithKMBFormat(derivedMetrics.emvPerPost)}
            </strong>{" "}
            in EMV.
          </>
        ),
      },
    ];

    return messages.find((m) => m.condition())?.message();
  };

  return (
    <p className="text-[18px] font-light max-w-[800px] mb-8">
      {getContentAndMetrics()}
    </p>
  );
};
