import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Campaign } from "@types";
import apiClient from "@apiClient";

import UpdateCampaignForm from "./components/UpdateCampaignForm";
import LoadingSpinner from "@components/LoadingSpinner";
import CommentInput from "@components/comments/CommentInput";
import CampaignPageHeader from "./components/CampaignPageHeader";
import FButton from "@components/FButton";
import { approveBudget } from "@api/Campaign/Campaigns";
import toast from "react-hot-toast";

const { campaigns } = apiClient;

export default function CampaignFinanceApprovalPage() {
  const { campaign_id } = useParams<{
    campaign_id: string;
    brief_id: string;
    participant_id: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState<Campaign>(null);

  const [waiting, setWaiting] = useState(false);

  const navigate = useNavigate();

  const [pendingComment, setPendingComment] = useState("");

  const [isRejecting, setRejecting] = useState(false);

  const handleApprove = async () => {
    setWaiting(true);

    try {
      await approveBudget(campaign.id, pendingComment);

      navigate(`/campaigns/${campaign.id}`);
    } catch {
      toast.error("Error approving budget");

      setWaiting(false);
    }
  };

  const handleSubmitRejection = () => {};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const campaignResponse = await campaigns.get(campaign_id);

        setCampaign(campaignResponse.data);
      } catch (e) {
        console.log("E", e);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [campaign_id]);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  return (
    <div className="h-[calc(100vh-40px)] flex flex-col w-full">
      <CampaignPageHeader campaign={{ ...campaign }} />
      <div className="h-[calc(100%-150px)] flex flex-1 pt-12">
        <div className="flex-1 overflow-auto pb-12">
          <UpdateCampaignForm entity={campaign} />
        </div>
        <div className="h-full flex flex-col justify-end pl-[40px] w-[400px] pb-8">
          <div>
            {isRejecting ? (
              <CommentInput
                label="Request media changes"
                pendingComment={pendingComment}
                isOptional
                onChange={setPendingComment}
                onSubmit={handleApprove}
              />
            ) : null}
            <div className="flex justify-end space-x-4 mt-[40px]">
              <FButton
                onClick={isRejecting ? handleSubmitRejection : handleApprove}
                label={isRejecting ? "Confirm" : "Approve"}
                primary
                loading={waiting}
              />
              <FButton
                onClick={() => setRejecting(!isRejecting)}
                label={isRejecting ? "Cancel" : "Reject"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
