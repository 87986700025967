import { capitalize } from "lodash";

interface FilterSummaryProps {
  filters: Record<string, string>;
}

const NAMES_FOR_FILTER_KEYS = {
  list: "Groups",
  exclude_list: "Exclude Groups",
};

const getLabelForKey = (key: string) => {
  if (NAMES_FOR_FILTER_KEYS[key]) {
    return NAMES_FOR_FILTER_KEYS[key];
  }

  return key
    .split("_")
    .map((part) => capitalize(part))
    .join(" ");
};

const FilterSummary = ({ filters }: FilterSummaryProps) => (
  <span>
    {Object.keys(filters)
      .map((key) => `${getLabelForKey(key)}: ${filters[key]}`)
      .join(", ")}
  </span>
);

export default FilterSummary;
