import FormField from "@components/Form/FormField";

import ModalSection from "./ModalSection";
import { FRadioGroup } from "@components/FInputs";
import FMultiSelect from "@components/FMultiSelect";
import {
  AGE_RANGES_OPTIONS,
  INCOME_OPTIONS,
  PARENTAL_OPTIONS,
} from "@pages/search/constants";
import { AdvancedSearchParams } from "@pages/search/types";
import {
  ETHNICITIES,
  GENDERS,
  MARITAL_STATUSES,
} from "@constants/demographics";
import { capitalize } from "lodash";
import GooglePlacesAutocomplete from "@components/google-places-autocomplete";

interface AudienceFiltersProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const AudienceFilters = ({
  filters,
  onFiltersUpdate,
}: AudienceFiltersProps) => (
  <ModalSection icon="user" title="Audience Filters">
    <div className="flex flex-col gap-8">
      <FormField label="Search by location" width={400}>
        <GooglePlacesAutocomplete
          location={filters.audience_custom_locations}
          onLocationChange={(value) =>
            onFiltersUpdate({ audience_custom_locations: value })
          }
        />
      </FormField>
      <div className="flex items-start gap-8 w-full">
        <FMultiSelect
          width="250px"
          label="Income"
          selected={filters.audience_income}
          options={INCOME_OPTIONS}
          onChange={(value) => onFiltersUpdate({ audience_income: value })}
        />
        <FMultiSelect
          width="250px"
          label="Age"
          selected={filters.audience_age}
          options={AGE_RANGES_OPTIONS}
          onChange={(value) => onFiltersUpdate({ audience_age: value })}
        />
      </div>
      <div className="flex items-start justify-between gap-8 w-full">
        <FMultiSelect
          width="225px"
          label="Gender"
          selected={filters.audience_gender}
          options={GENDERS}
          onChange={(value) => onFiltersUpdate({ audience_gender: value })}
        />
        <FMultiSelect
          width="225px"
          label="Race and enthnicity"
          selected={filters.audience_ethnicity}
          options={ETHNICITIES}
          onChange={(value) => onFiltersUpdate({ audience_ethnicity: value })}
        />
        <FMultiSelect
          width="250px"
          label="Marital Status"
          selected={filters.audience_marital_status}
          options={MARITAL_STATUSES.map(({ value, label }) => ({
            value,
            label: label || capitalize(value),
          }))}
          onChange={(value) =>
            onFiltersUpdate({ audience_marital_status: value })
          }
        />
      </div>
      <FormField label="Parental Status">
        <FRadioGroup
          direction="column"
          options={PARENTAL_OPTIONS}
          name="AudienceParentalStatus"
          value={filters.audience_parental_status}
          onChange={(value) =>
            onFiltersUpdate({ audience_parental_status: value })
          }
        />
      </FormField>
    </div>
  </ModalSection>
);

export default AudienceFilters;
