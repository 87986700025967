import FIcon from "@components/FIcon";
import { platformList, allSearchCategories } from "@constants/constants";
import React, { useContext, useEffect, useState } from "react";

import { isEmpty } from "lodash";

import { CreatorFromSearch } from "@apiTypes";
import ResultsGrid from "@components/ResultsGrid";
import { searchPosts } from "@api/AmbassadorSearch";

import { CREATOR_CATEGORIES, POST_CATEGORIES } from "@api/Search";
import useDebouncedValue from "@hooks/useDebouncedValue";

import FPagination from "@components/FPagination";
import LoadingSpinner from "@components/LoadingSpinner";
import GeneralPostModal from "@components/Modals/GeneralPostModal";
import { AmbassadorsContext } from "@contexts/index";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const getOptions = (categories: {
  [key: number]: string;
}): { value: string; label: string }[] =>
  Object.keys(categories).map((key) => ({
    value: key,
    label: categories[key],
    icon: allSearchCategories.find((category) => category.value === `${key}`)
      ?.icon,
  }));

export default function Search() {
  const { allApplicants } = useContext(AmbassadorsContext);

  const [searchTerm, setSearchTerm] = useState("");

  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(false);

  const [hoveredPlatform, setHoveredPlatform] = useState<string>(null);

  const [loading, setLoading] = useState(false);

  const [creators, setCreators] = useState<CreatorFromSearch[]>([]);
  const [posts, setPosts] = useState<CreatorFromSearch[]>([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(null);

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);
  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const resource = "content";
  const { trackEvent } = useContext(MixpanelContext);

  trackEvent(EVENTS.PAGE_VIEW.AMBASSADORS, { section: "content" });

  useEffect(() => {
    const handleSearch = async () => {
      const hashtags = [];
      const mentions = [];
      const keywords = [];

      if (
        !debouncedSearchTerm &&
        isEmpty(selectedCategories) &&
        isEmpty(selectedPlatforms)
      ) {
        setCreators([]);
        setPosts([]);

        return;
      }

      if (debouncedSearchTerm) {
        debouncedSearchTerm.split(" ").forEach((word) => {
          if (word.startsWith("#")) {
            hashtags.push(word.slice(1));
          } else if (word.startsWith("@")) {
            mentions.push(word.slice(1));
          } else {
            keywords.push(word);
          }
        });
      }

      setLoading(true);

      const response = await searchPosts({
        hashtags,
        mentions,
        any: keywords,
        platforms: selectedPlatforms,
        categories: selectedCategories,
        creator_ids: allApplicants.map(({ id }) => id),
        p: page,
      });

      const { results, total } = response.data;

      setPosts(results);
      setTotal(total);

      setLoading(false);
    };

    handleSearch();
  }, [
    debouncedSearchTerm,
    resource,
    selectedCategories,
    selectedPlatforms,
    page,
    allApplicants,
  ]);

  const searchCategories = getOptions(
    resource === "content" ? POST_CATEGORIES : CREATOR_CATEGORIES
  );

  const handleContentModalClick = (content) => {
    console.log("Content", content);
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  return (
    <div className="">
      {/* <Heading title={"Search and Discover"} className="pb-[14%]" /> */}
      <div className="flex items-center border-b border-b-dark gap-[16px] py-[16px]">
        {/* search */}
        <div className="flex flex-row items-center w-full">
          <FIcon icon="search" size={20} color="#000F45" className="mb-2" />
          <input
            className="pl-[16px] bg-transparent border-none w-full h-[30px] text-[20px] tracking-[0.6px] outline-none "
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setPage(1);

              setSearchTerm(e.target.value);
            }}
            placeholder="Search by name, keyword, hashtag, and more"
          />
          <div
            onClick={() => {
              setPage(1);
              setSearchTerm("");
            }}
            className="cursor-pointer"
          >
            <FIcon icon="exit" size={20} color="#000F45" className="" />
          </div>
        </div>
        {/* divider */}
        <div className="w-[1px] h-[26px] bg-[#000F45]"></div>
        {/* platforms */}
        <div className="flex gap-[8px]">
          {platformList.map((platform) => {
            const selected = selectedPlatforms.indexOf(platform.value) !== -1;

            return (
              <div
                key={platform.value}
                className={`
									cursor-pointer flex items-center justify-center
									border border-[#000721] rounded-full w-[32px]
									h-[32px] hover:bg-[#000721] ${selected ? "bg-[#000721]" : "bg-transparent"}
								`}
                onClick={() => {
                  if (selected) {
                    setSelectedPlatforms(
                      selectedPlatforms.filter((p) => p !== platform.value)
                    );
                  } else {
                    setSelectedPlatforms([
                      ...selectedPlatforms,
                      platform.value,
                    ]);
                  }

                  setPage(1);
                }}
                onMouseEnter={() => setHoveredPlatform(platform.value)}
                onMouseLeave={() => setHoveredPlatform(null)}
              >
                <FIcon
                  icon={platform.icon}
                  size={16}
                  color={
                    selected || hoveredPlatform === platform.value
                      ? "#fff"
                      : "#000721"
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="relative mt-[16px]">
        <div
          className={`flex flex-wrap items-center gap-[12px] ${
            !categoriesExpanded ? "h-[35px]" : "h-full"
          } overflow-hidden w-[calc(100%-40px)]`}
        >
          <p>Include {resource} tagged:</p>
          {searchCategories.map((category) => {
            return (
              <p
                className={`
										cursor-pointer border border-neutral_400 rounded-full px-[12px] pb-[2px] pt-[4px] text-[14px]
										${selectedCategories.indexOf(category.value) !== -1 ? "bg-dark_night_sky text-white border-dark_night_sky" : ""}`}
                key={category.value}
                onClick={() => {
                  if (selectedCategories.indexOf(category.value) !== -1) {
                    setSelectedCategories(
                      selectedCategories.filter((c) => c !== category.value)
                    );
                  } else {
                    setSelectedCategories([
                      ...selectedCategories,
                      category.value,
                    ]);
                  }

                  setPage(1);
                }}
              >
                {category.label}
              </p>
            );
          })}
          <div
            className="absolute right-0 top-[7px] cursor-pointer transition duration-300"
            style={{
              transform: categoriesExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={() => setCategoriesExpanded(!categoriesExpanded)}
          >
            <FIcon icon="chevron" size={16} color="#000721" />
          </div>
        </div>
      </div>
      {/* results */}
      {loading ? (
        <LoadingSpinner className="w-full h-full mt-[20%]" />
      ) : (
        <>
          <div>
            {resource === "content" ? (
              <ResultsGrid
                cardData={posts}
                cardType="content"
                onCardClick={(content) => handleContentModalClick(content)}
              />
            ) : (
              <ResultsGrid
                cardData={creators}
                cardType="individual"
                isProfile={false}
              />
            )}
          </div>
          {total &&
          total > 0 &&
          total > (resource === "content" ? posts.length : creators.length) &&
          (resource === "content" ? posts.length : creators.length) > 0 ? (
            <div className="mt-[28px]">
              <FPagination
                page={page}
                pageSize={24}
                total={total}
                onPageChange={setPage}
              />
            </div>
          ) : null}
          {activePost && (
            <GeneralPostModal
              post={activePost}
              isOpen={contentModalOpen}
              onClose={handleContentModalClose}
            />
          )}
        </>
      )}
    </div>
  );
}
