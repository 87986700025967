import { Bulletin } from "@types";

import moment from "moment";
import FilterSummary from "./FilterSummary";

interface SearchFilterHistoryProps {
  bulletin: Bulletin;
}

const SearchFilterHistory = ({ bulletin }: SearchFilterHistoryProps) => {
  const { filter_history } = bulletin;

  if (!filter_history) {
    return null;
  }

  return (
    <div className="mt-4 mb-10">
      <h3 className="text-2xl mt-8 mb-4">Filters history</h3>
      <div className="flex flex-col gap-4">
        {filter_history.map((item, index) => {
          const { added_at, added_by, filters } = item;

          return (
            <div
              className="flex flex-col gap-4 p-4 border border-light_border rounded-xl"
              key={index}
            >
              <div>
                <span>{added_by}</span>
                <p className="text-sm text-default_weak">
                  {moment(added_at).format("MMM DD, YYYY")}
                </p>
              </div>
              <FilterSummary filters={filters} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchFilterHistory;
