export enum FieldType {
  TEXT = "text",
  TEXTAREA = "textarea",
  SINGLE_SELECT = "single_select",
  MULTI_SELECT = "multi_select",
}

interface QuestionnaireQuestionBase {
  brand_id?: number;
  bulletin_id?: number;
  id?: number;
  question: string;
  conditional_answer?: string;
  description?: string;
  required: boolean;
  position: number;
  visible_on_form?: boolean;
  visible_search?: boolean;
  field_type: FieldType;
  question_id?: number;
  search_question?: string;
}

export interface QuestionnaireQuestion extends QuestionnaireQuestionBase {
  data?: {
    options?: string[];
  };
  children?: {
    [key: string]: QuestionnaireQuestion[];
  };
}

export interface QuestionnaireQuestionRequest
  extends QuestionnaireQuestionBase {
  data?: string | { options: string[] };
}
