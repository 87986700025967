import React, { useEffect } from "react";
import KPIWithSparkline from "@components/Dashboard/KPIWithSparkline";
import KPIWithSparklineSkeleton from "@components/Dashboard/KPIWithSparklineSkeleton";
import { CampaignContent, ICampaign } from "types/types";
import {
  calculateOverallEngagement,
  calculateTotalImpressions,
  getUniqueInfluencerCount,
  generateTimeSeriesPoints,
  TimeSeriesData,
  CampaignMetrics,
} from "@utils/dashboardUtils";

interface CampaignStatsProps {
  campaigns: ICampaign[];
  campaignContent: CampaignContent[];
  isLoading: boolean;
  dateRange: string;
  setMetrics: (metrics: CampaignMetrics) => void;
}

export default function CampaignStats({
  campaigns,
  campaignContent,
  isLoading,
  dateRange,
  setMetrics,
}: CampaignStatsProps) {
  const [timeSeriesData, setTimeSeriesData] = React.useState<TimeSeriesData>({
    engagements: [],
    posts: [],
    influencers: [],
    impressions: [],
  });

  useEffect(() => {
    const newTimeSeriesData = generateTimeSeriesPoints(
      campaignContent,
      campaigns,
      dateRange
    );
    setTimeSeriesData(newTimeSeriesData);

    const currentMetrics: CampaignMetrics = {
      campaigns: campaigns.length,
      engagementRate: parseFloat(calculateOverallEngagement(campaignContent)),
      impressions: calculateTotalImpressions(campaignContent),
      posts: campaignContent.length,
      influencers: getUniqueInfluencerCount(campaigns),
    };

    setMetrics(currentMetrics);
  }, [campaigns, campaignContent, dateRange, setMetrics]);

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <KPIWithSparklineSkeleton />
        <KPIWithSparklineSkeleton />
        <KPIWithSparklineSkeleton />
        <KPIWithSparklineSkeleton />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      <KPIWithSparkline
        title="Engagement"
        value={calculateOverallEngagement(campaignContent)}
        valueType="percent"
        timeSeriesData={timeSeriesData.engagements}
      />
      <KPIWithSparkline
        title="Posts"
        value={campaignContent.length}
        valueType="number"
        timeSeriesData={timeSeriesData.posts}
      />
      <KPIWithSparkline
        title="Influencers"
        value={getUniqueInfluencerCount(campaigns)}
        valueType="number"
        timeSeriesData={timeSeriesData.influencers}
      />
      <KPIWithSparkline
        title="Impressions"
        value={calculateTotalImpressions(campaignContent)}
        valueType="number"
        timeSeriesData={timeSeriesData.impressions}
      />
    </div>
  );
}
