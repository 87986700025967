import React, { useEffect, useState } from "react";

import usePlacesService from "./use-places-service";
import { FInput } from "@components/FInputs";

import getSuggestions from "./fetch-suggestions";
import { FDropdownOption } from "@components/FDropdown";
import { CustomLocation } from "@pages/search/types";
import useDebouncedValue from "@hooks/useDebouncedValue";

interface Suggestion extends FDropdownOption {
  place_id: string;
}

interface GooglePlacesAutocompleteProps {
  location: CustomLocation;
  onLocationChange: (value: CustomLocation) => void;
}

const GooglePlacesAutocomplete = ({
  location,
  onLocationChange,
}: GooglePlacesAutocompleteProps): React.ReactElement => {
  const [value, setValue] = useState("");

  const { autoCompleteService, placesService, sessionToken } =
    usePlacesService();

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const debouncedValue = useDebouncedValue(value, 500);

  const { formatted_address } = location || {};

  const handleSuggestionClick = (placeId: string) => {
    placesService.getDetails({ placeId }, (result) => {
      const {
        geometry: { location },
        name,
        formatted_address,
      } = result;

      onLocationChange({
        geo_lat: location.lat(),
        geo_lng: location.lng(),
        geo_text: name,
        formatted_address,
      });

      setValue(name);
      setSuggestions([]);
    });
  };

  useEffect(() => {
    setValue(formatted_address || "");
  }, [formatted_address]);
  /* 
  useEffect(() => {
    getSuggestions(
      {
        autoCompleteService,
        sessionToken,
      },
      debouncedValue,
      (suggestions) => {
        setSuggestions(suggestions);
      }
    );
  }, [debouncedValue, autoCompleteService, sessionToken]); */

  return (
    <div className="relative flex gap-4">
      <FInput
        onChange={(value) => {
          setValue(value);

          getSuggestions(
            {
              autoCompleteService,
              sessionToken,
            },
            value,
            (suggestions) => {
              setSuggestions(suggestions);
            }
          );
        }}
        onBlur={() => {
          if (!value) {
            onLocationChange(null);
          }
        }}
        placeholder="Type to search location"
        value={value}
        width={400}
      />
      <div id="google-autocomplete-attribution" className="h-[40px]s" />
      <div
        className={`bg-white absolute border-neutral_400 cursor-pointer font-graphik text-sm font-normal overflow-hidden`}
        style={{
          boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.05)",
          top: "40px",
          zIndex: 50,
          width: "100%",
          maxWidth: "100%",
          maxHeight: "400px",
        }}
      >
        <div className={`flex flex-col flex-nowrap`}>
          {suggestions.map((item, index) => (
            <div
              key={index}
              className="px-[12px] py-[8px] hover:bg-neutral_100 text-[15px]"
              onClick={() => handleSuggestionClick(item.place_id as string)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GooglePlacesAutocomplete;
