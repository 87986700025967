import React from "react";
import SkeletonLoader from "@components/Dashboard/SkeletonLoader";

interface SearchResultsSkeletonProps {
  type: "creators" | "content";
}

export default function SearchResultsSkeleton({
  type,
}: SearchResultsSkeletonProps) {
  // Create a 6x4 grid of skeleton items
  return (
    <div className="grid grid-cols-6 gap-4 mt-10">
      {Array(12)
        .fill(null)
        .map((_, index) => (
          <div
            key={index}
            className="flex flex-col gap-2 border border-light_border shadow-sm rounded-lg"
          >
            {/* Image placeholder */}
            <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 aspect-square w-full" />
            <div className="flex flex-col gap-1 px-4">
              {/* Title placeholder */}
              <SkeletonLoader type="text" width="w-3/4" />

              {/* Subtitle/stats placeholder */}
              <SkeletonLoader type="text" width="w-1/2" />
            </div>
          </div>
        ))}
    </div>
  );
}
