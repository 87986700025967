import { ApiResponse, GroupData } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { CreateGroupRequest, Group, Sort, User } from "@types";

export interface GroupListRequestParams {
  search: string;
  user_id_eq: number;
  archived_eq?: boolean;
  featured_eq?: boolean;
}

const getGroup = async (
  group_id: string | number
): Promise<ApiResponse<GroupData>> => {
  return apiWrapper(`/groups/${group_id}`, "GET");
};

const getGroupList = async (
  admin?: boolean,
  page?: number,
  sort?: Sort,
  params?: GroupListRequestParams
): Promise<ApiResponse<{ groups: GroupData[]; total_items: number }>> => {
  const { search, ...rest } = params || {};

  const paramsToSend = {
    ...rest,
    name_i_cont: search,
  };

  const queryParams = getQueryParamsForSearchRequest(page, sort, paramsToSend);

  if (admin) {
    return apiWrapper(`/admin/groups?${queryParams.toString()}`, "GET");
  } else {
    return apiWrapper(`/groups?${queryParams.toString()}`, "GET");
  }
};

export const getGroupOwners = async (
  search?: string
): Promise<ApiResponse<{ group_owners: User[]; total_items: number }>> => {
  const queryParams = getQueryParamsForSearchRequest(null, null, {
    per_page: 0,
  });

  if (search) {
    queryParams.append("q[full_name_or_email_cont]", search);
  }

  const response = await apiWrapper(
    `/group_owners?${queryParams.toString()}`,
    "GET"
  );

  return response;
};

const downloadGroupCSV = async (groupId: string | number): Promise<Blob> => {
  const response = await apiWrapper(`/groups/${groupId}/csv_download`, "GET");
  return new Blob([response.data], { type: "text/csv" });
};

const createGroup = async (
  params: CreateGroupRequest
): Promise<ApiResponse<Group>> => {
  return apiWrapper("/groups", "POST", params);
};

const combineGroup = async (
  name: string,
  groupIds: number[]
): Promise<ApiResponse<Group>> => {
  return apiWrapper(`/groups/merge`, "POST", {
    name: name,
    group_ids: groupIds,
  });
};

const duplicateGroup = async (
  group_id: string | number
): Promise<ApiResponse<Group>> => {
  return apiWrapper(`/groups/${group_id}/duplicate`, "POST");
};

const updateGroup = async (
  group_id: string | number,
  params: Partial<GroupData>
): Promise<ApiResponse<Group>> => {
  return apiWrapper(`/groups/${group_id}`, "PUT", params);
};

const deleteGroup = async (group_id: string | number) => {
  return apiWrapper(`/groups/${group_id}`, "DELETE");
};

const removeCreator = async (
  groupId: number | string,
  groupCreatorsIds: (number | string)[]
): Promise<ApiResponse<GroupData>> => {
  const params = {
    group: {
      group_creators_attributes: groupCreatorsIds.map((id) => ({
        id,
        _destroy: true,
      })),
    },
  };

  return apiWrapper(`/groups/${groupId}`, "PATCH", params);
};

export const bulkCreate = (params: {
  group_id?: number;
  name?: string;
  resource_type: string;
  resource_id: number;
}) => {
  return apiWrapper("/groups/bulk_create", "POST", params);
};

const addCreator = async (
  groupId: number | string,
  creatorIds: (number | string)[]
): Promise<ApiResponse<GroupData>> => {
  const params = {
    group: {
      group_creators_attributes: creatorIds.map((creatorId) => ({
        creator_id: creatorId,
      })),
    },
  };

  return apiWrapper(`/groups/${groupId}`, "PUT", params);
};

export {
  getGroup,
  getGroupList,
  createGroup,
  duplicateGroup,
  combineGroup,
  updateGroup,
  deleteGroup,
  removeCreator,
  addCreator,
  downloadGroupCSV,
};
