import { Campaign, KIND } from "@types";

const CONTENT_REVIEW_SUBPAGES = [
  {
    value: "review",
    label: "Review",
  },
  {
    value: "attribution",
    label: "Attribution",
  },
  {
    value: "bonus-content",
    label: "Bonus",
  },
  {
    value: "attributed-content",
    label: "All Content",
  },
];

export const getContentReviewSubpages = (campaign: Campaign) => {
  const { content_review_enabled, kind } = campaign;

  return CONTENT_REVIEW_SUBPAGES.filter(
    ({ value }) =>
      (content_review_enabled && kind !== KIND.GIFTING) || value !== "review"
  );
};

export default CONTENT_REVIEW_SUBPAGES;
