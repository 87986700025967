import FIcon from "@components/FIcon";
import { ReactNode } from "react";

const ModalSection = ({
  icon,
  children,
  title,
}: {
  icon: string;
  title: string;
  children: ReactNode;
}) => (
  <div className="border-b border-light_border pb-4">
    <div className="flex gap-2 mb-4">
      <FIcon color="#000F45" icon={icon} size={20} />
      <h3 className="text-[1.25rem]">{title}</h3>
    </div>
    {children}
  </div>
);

export default ModalSection;
