import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NewReportContext } from "@contexts/index";
import {
  getCampaignReportSummary,
  getCampaignReportPerformanceGraphs,
  getCampaignPostsEngagementGraph,
  getCampaignReportsAudienceData,
  getReport,
} from "../../api/Campaign/CampaignReports";
import { getReportContentSummary } from "@api/Reports";
import { ReportHeader } from "../reports/components/ReportHeader";
import Overview from "../reports/Overview";
import toast from "react-hot-toast";
import LoadingSpinner from "@components/LoadingSpinner";

// Default values for metrics to prevent undefined errors
const defaultMetrics = {
  posts: 0,
  creators: 0,
  influencers_count: 0,
  views: 0,
  reach: 0,
  engagement: 0,
  engagement_rate: 0,
  emv: 0,
  total_followers: 0,
};

const CampaignReports: React.FC = () => {
  const { campaign_id } = useParams<{ campaign_id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [metrics, setMetrics] = useState<any>(defaultMetrics);
  const [report, setReport] = useState<any>({
    id: campaign_id,
    title: "Campaign Report",
    platforms: [],
  });
  const [performanceGraphs, setPerformanceGraphs] = useState<any>({
    reach: null,
    viewership: null,
    engagement: null,
    emv: null,
    content_chart_data: null,
    posts: null,
    viral_posts: null,
  });
  const [engagementGraphData, setEngagementGraphData] = useState<any>({
    posts: [],
    reach: [],
    views: [],
  });
  const [audience, setAudience] = useState<any>({
    age: [],
    gender: [],
    ethnicity: [],
    religion: [],
    parental: [],
    marital: [],
    countries: [],
    states: [],
  });
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const [audienceDataFetched, setAudienceDataFetched] = useState(false);
  const [availablePlatforms, setAvailablePlatforms] = useState<any>([]);
  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleContentModalClick = (content: any) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!campaign_id) {
        setError("No campaign ID provided");
        setIsLoading(false);
        setIsPageLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        // Fetch report data
        const reportResponse = await getReport(campaign_id);
        if (reportResponse?.data?.reports?.[0]) {
          const reportData = reportResponse.data.reports[0];

          // Fetch content summary using the actual report ID
          const contentSummaryResponse = await getReportContentSummary(
            reportData.id
          );

          setReport({
            ...report,
            ...reportData,
            id: campaign_id,
            report_id: reportData.id,
            title: reportData.title || "Campaign Report",
            reports: [
              {
                id: reportData.id,
                title: reportData.title,
                brand_name: reportData.brand_name,
                created_at: reportData.created_at,
                source_type: reportData.source_type,
                platforms: reportData.platforms,
              },
            ],
            content_summary: contentSummaryResponse?.data || null,
          });

          const platforms = reportData.platforms || [];
          setAvailablePlatforms(
            platforms.map((platform: string) => ({
              label: platform,
              value: platform,
            }))
          );
        }

        // Fetch summary
        const summaryResponse = await getCampaignReportSummary(campaign_id);
        if (summaryResponse.success) {
          setMetrics({
            ...defaultMetrics,
            ...summaryResponse.data,
          });
        }

        // Fetch performance graphs
        const performanceResponse =
          await getCampaignReportPerformanceGraphs(campaign_id);
        setPerformanceGraphs(performanceResponse.data || {});

        // Fetch engagement data
        const engagementResponse = await getCampaignPostsEngagementGraph(
          campaign_id,
          selectedPlatform
        );
        setEngagementGraphData(
          engagementResponse.data || {
            posts: [],
            reach: [],
            views: [],
          }
        );

        // Fetch audience data
        const audienceResponse = await getCampaignReportsAudienceData(
          campaign_id,
          selectedPlatform
        );
        setAudience(
          audienceResponse.data || {
            age: [],
            gender: [],
            ethnicity: [],
            religion: [],
            parental: [],
            marital: [],
            countries: [],
            states: [],
          }
        );
        setAudienceDataFetched(true);
      } catch (error) {
        console.error("Error fetching report data:", error);
        setError("Failed to load report data");
        toast.error("Failed to load report data");
      } finally {
        setIsLoading(false);
        setIsPageLoading(false);
      }
    };

    fetchData();
  }, [campaign_id, selectedPlatform]);

  const contextValue = {
    report,
    setReport,
    metrics,
    setMetrics,
    isLoading,
    setIsLoading,
    isPageLoading,
    setIsPageLoading,
    activePost,
    setActivePost,
    contentModalOpen,
    setContentModalOpen,
    handleContentModalClick,
    handleContentModalClose,
    performanceGraphs,
    engagementGraphData,
    audience,
    selectedPlatform,
    setSelectedPlatform,
    audienceDataFetched,
    availablePlatforms,
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="text-blue-500 hover:underline"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  if (isPageLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <NewReportContext.Provider value={contextValue}>
      <ReportHeader
        campaignReport
        report={report}
        metrics={metrics}
        performanceGraphs={performanceGraphs}
        isLoading={isLoading}
      />

      <Overview campaignReport />
    </NewReportContext.Provider>
  );
};

export default CampaignReports;
