import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Attachment, Outreach, Sort } from "@types";

const getOutreachList = async (
  page?: number,
  sort?: Sort,
  params?: object,
  admin?: boolean
): Promise<
  ApiResponse<{ communications: Outreach[]; total_items: number }>
  > => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  if (admin) {
    return apiWrapper(`/admin/outreach/communications?${queryParams.toString()}`, "GET");
  } else {
    return apiWrapper(
      `/outreach/communications?${queryParams.toString()}`, "GET");
  }
};

const getOutreach = async (outreach_id: string | number): Promise<Outreach> => {
  const response = await apiWrapper(
    `/outreach/communications/${outreach_id}`,
    "GET"
  );

  return response.data;
};

export const updateOutreachAssets = (
  outreach_id: string | number,
  assets: Attachment[],
  assetsToRemove?: Attachment[]
) => {
  const formData = new FormData();

  assets.forEach((attachment, index) => {
    formData.append(
      `communication[assets_attributes][${index}][file]`,
      attachment.file
    );
  });

  (assetsToRemove || []).forEach((attachment, index) => {
    formData.append(
      `communication[assets_attributes][${index}][id]`,
      `${attachment.id}`
    );

    formData.append(
      `communication[assets_attributes][${index}][_destroy]`,
      "true"
    );
  });
  return apiWrapper(
    `/outreach/communications/${outreach_id}`,
    "PATCH",
    formData
  );
};

const createOutreach = async (
  params: Partial<Outreach>
): Promise<ApiResponse<Outreach>> => {
  const { assets, message, ...rest } = params;

  const paramsToSend = { ...rest, message_attributes: message };

  const response = await apiWrapper(
    "/outreach/communications",
    "POST",
    paramsToSend
  );

  if (assets?.length) {
    await updateOutreachAssets(response.data.id, assets);
  }

  return response;
};

const updateOutreach = async (
  outreach_id: string | number,
  params: Partial<Outreach>,
  assets?: Attachment[],
  assetsToRemove?: Attachment[]
): Promise<ApiResponse<Outreach>> => {
  const { message, ...rest } = params;

  const paramsToSend = { ...rest };

  if (message) {
    paramsToSend.message_attributes = message;
  }

  let response = await apiWrapper(
    `/outreach/communications/${outreach_id}`,
    "PATCH",
    paramsToSend
  );

  if (assets?.length || assetsToRemove?.length) {
    response = await updateOutreachAssets(outreach_id, assets, assetsToRemove);
  }

  return response;
};

export const sendOutreach = async (
  outreach_id: string | number
): Promise<ApiResponse<string>> => {
  const response = await apiWrapper(
    `/outreach/communications/${outreach_id}/send_emails`,
    "POST"
  );

  return response;
};

export const sendTestOutreach = async (
  outreach_id: string | number,
  emails: string
): Promise<ApiResponse<string>> => {
  const response = await apiWrapper(
    `/admin/outreach/communications/${outreach_id}/send_test_emails`,
    "POST",
    { emails: emails }
  );

  return response;
};

export const deleteOutreachParticipants = async (
  outreach_id: string | number,
  selectedRecipientIds: number[]
): Promise<ApiResponse<Outreach>> => {
  return apiWrapper(`/outreach/communications/${outreach_id}`, "PUT", {
    communication: {
      recipients_attributes: selectedRecipientIds.map((id) => ({
        id,
        _destroy: true,
      })),
    },
  });
};

const deleteOutreach = async (
  outreach_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/outreach/communications/${outreach_id}`, "DELETE");
};

export {
  getOutreachList,
  getOutreach,
  createOutreach,
  updateOutreach,
  deleteOutreach,
};
