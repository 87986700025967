import React, { useEffect, useState } from "react";

import RichTextEditor from "@components/RichTextEditor";
import FInput from "@components/FInputs/FInput";
import { CampaignDeliverable } from "@types";
import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import FDatePicker from "@components/FDatePicker/FDatePicker";
import { FCheckbox } from "@components/FInputs";
import { uniq } from "lodash";

const platformOptions = [
  { label: "TikTok", value: "tiktok" },
  { label: "Instagram", value: "instagram" },
  { label: "YouTube", value: "youtube" },
  { label: "Blog", value: "blog" },
  { label: "Facebook", value: "facebook" },
  { label: "Pinterest", value: "pinterest" },
  { label: "RSS", value: "rss" },
  { label: "Tumblr", value: "tumblr" },
  { label: "Twitter", value: "twitter" },
];

const mediaTypeOptions = [
  { label: "Select a media type", value: "" },
  { label: "Image", value: "image" },
  { label: "Video", value: "video" },
  { label: "Text", value: "text" },
];

interface SocialDeliverableProps {
  onSubmit: (values: any) => void;
  onClose: () => void;
  deliverable: CampaignDeliverable;
  waiting: boolean;
}

const SocialDeliverable = ({
  onSubmit,
  deliverable,
  onClose,
  waiting,
}: SocialDeliverableProps) => {
  const [values, setValues] = useState<CampaignDeliverable>({
    asset_quantity: 0,
    estimated_cpm: 0,
    estimated_flat_cost: 0,
    id: null,
    name: "",
    media_type: "",
    notes: "",
    platform: platformOptions[0].value,
    position: 0,
    forecasted_date: "",
    submission_due_date: "",
    status: "",
    url: "",
    hashtags: "",
    mentions: "",
    tagged_users: "",
    geotags: "",
    syndication_requirements: [],
    overwrite: false,
  });

  useEffect(() => {
    if (deliverable) {
      setValues({ ...values, ...deliverable });
    }
  }, []);

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  const { syndication_requirements } = values;

  return (
    <div className="max-h-[60vh] overflow-y-auto">
      <div className="space-y-4">
        <FInput
          value={values.name}
          onChange={(value) => handleUpdate({ name: value })}
          type="text"
          required
          label="Name"
          width="100%"
        />
        <div className="flex w-full gap-4">
          <FDropdown
            hasNoDefault
            label="Platform"
            selectedValue={values.platform}
            onChange={(value) => handleUpdate({ platform: value })}
            options={platformOptions}
            width="100%"
          />
          <FDropdown
            hasNoDefault
            label="Media Type"
            selectedValue={values.media_type}
            onChange={(value) => handleUpdate({ media_type: value })}
            options={mediaTypeOptions}
            width="100%"
          />
        </div>
        <div className="flex w-full gap-4">
          <FInput
            value={values.hashtags}
            onChange={(value) => handleUpdate({ hashtags: value })}
            type="text"
            label="Hashtags"
            width="100%"
          />
          <FInput
            value={values.mentions}
            onChange={(value) => handleUpdate({ mentions: value })}
            type="text"
            label="Mentions"
            width="100%"
          />
        </div>
        <div className="flex w-full gap-4">
          <FInput
            value={values.url}
            onChange={(value) => handleUpdate({ url: value })}
            type="text"
            label="URL"
            width="100%"
          />
          <FInput
            value={`${values.position}`}
            onChange={(value) =>
              handleUpdate({ position: parseInt(value, 10) })
            }
            type="number"
            label="Position"
            width="100%"
          />
        </div>
        <div>
          <label
            className="mb-[6px] inline-block text-sm font-medium leading-[18px]"
            htmlFor="notes"
          >
            Notes
          </label>
          <div className="bg-white rounded-lg">
            <RichTextEditor
              value={values.notes}
              id="notes"
              onChange={(value) => handleUpdate({ notes: value })}
            />
          </div>
        </div>
        <div className="flex w-full gap-4">
          <FDatePicker
            disabled={{ before: new Date() }}
            label="Submission Due Date"
            value={values.submission_due_date}
            onSelectedValue={(value) =>
              handleUpdate({ submission_due_date: value })
            }
          />
          <FDatePicker
            disabled={{ before: new Date() }}
            label="Deliverable Forecast Date"
            value={values.forecasted_date}
            onSelectedValue={(value) =>
              handleUpdate({ forecasted_date: value })
            }
          />
        </div>
        <div className="flex w-full gap-4">
          <FInput
            value={`${values.asset_quantity}`}
            onChange={(value) =>
              handleUpdate({ asset_quantity: parseInt(value, 10) || 0 })
            }
            type="number"
            label="Asset Quantity"
            width="100%"
          />
          <FInput
            value={`${values.estimated_cpm}`}
            onChange={(value) =>
              handleUpdate({ estimated_cpm: parseInt(value, 10) || 0 })
            }
            type="number"
            label="Estimated CPM"
            width="100%"
          />
        </div>
        <FInput
          value={`${values.estimated_flat_cost}`}
          onChange={(value) =>
            handleUpdate({ estimated_flat_cost: parseInt(value, 10) || 0 })
          }
          type="number"
          label="Estimated Flat Cost"
          width="100%"
        />
        <div>
          <label
            className="mb-[6px] inline-block text-sm font-medium leading-[18px]"
            htmlFor="notes"
          >
            Syndication
          </label>
          <div className="grid grid-cols-3 gap-4 p-4 bg-white border border-neutral_400 rounded-lg">
            {platformOptions.map(({ value, label }) => (
              <FCheckbox
                key={value}
                label={label}
                id={value}
                onChange={(isChecked) =>
                  handleUpdate({
                    syndication_requirements: isChecked
                      ? uniq([...syndication_requirements, value])
                      : syndication_requirements.filter(
                          (item) => item !== value
                        ),
                  })
                }
                checked={syndication_requirements.includes(value)}
              />
            ))}
          </div>
        </div>
        <div className="flex w-full gap-4">
          <FInput
            value={values.tagged_users}
            onChange={(value) => handleUpdate({ tagged_users: value })}
            type="text"
            label="Tagged Users"
            width="100%"
          />
          <FInput
            value={values.geotags}
            onChange={(value) => handleUpdate({ geotags: value })}
            type="text"
            label="Geotags"
            width="100%"
          />
        </div>
        <div className="flex items-center">
          <FCheckbox
            className="w-full"
            label="Overwrite All Participants"
            id="checkbox1"
            onChange={(value) =>
              handleUpdate({
                overwrite: value,
              })
            }
            checked={values.overwrite}
          />
        </div>
      </div>
      <div className="flex justify-center space-x-2 mt-8 pb-3 pt-6 border-t border-light_border">
        <FButton onClick={onClose} label="Cancel" width="140px" height="40px" />
        <FButton
          onClick={() => onSubmit(values)}
          label="Save"
          loading={waiting}
          width="140px"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
};

export default SocialDeliverable;
