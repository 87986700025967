import React, { useState, useContext, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { CampaignContext, ContentReviewContext } from "@contexts/index";

import { getContentReviewForCampaign } from "@api/Campaign/ContentReview";
import { CampaignContentReview } from "types/content-review";

import LoadingSpinner from "@components/LoadingSpinner";

import ContentReviewSubNav from "./components/SubNav";
import ContentReview from "./ContentReview";
import ContentAttribution from "./ContentAttribution";
import AttributedContent from "./AttributedContent";
import BonusContent from "./BonusContent";
import { getContentReviewSubpages } from "./subpages";

const CampaignContentReviewPage = () => {
  const { campaign } = useContext(CampaignContext);

  const { subpage } = useParams<{
    subpage: string;
  }>();

  const { content_review_id: contentReviewId } = campaign;

  const availableSubPages = getContentReviewSubpages(campaign);

  let defaultActivePage = subpage || "review";

  if (!availableSubPages.find(({ value }) => value === defaultActivePage)) {
    defaultActivePage = availableSubPages[0].value;
  }

  const [activePage, setActivePage] = useState(defaultActivePage);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [contentReview, setContentReview] =
    useState<CampaignContentReview>(null);

  useEffect(() => {
    const getContent = async () => {
      try {
        const result = await getContentReviewForCampaign(contentReviewId);

        setContentReview(result);
      } catch (e) {
        console.log("Error loading content", e);
      } finally {
        setLoading(false);
      }
    };

    getContent();
  }, [contentReviewId]);

  if (loading) {
    return <LoadingSpinner className="h-[400px]" />;
  }

  return (
    <>
      <ContentReviewSubNav
        campaign={campaign}
        selectedValue={activePage}
        onChange={(page) => {
          setActivePage(page);

          navigate(`/campaigns/${campaign.id}/content/${page}`);
        }}
      />
      <ContentReviewContext.Provider
        value={{
          contentReview,
          setContentReview,
        }}
      >
        {activePage === "attribution" ? (
          <ContentAttribution />
        ) : activePage === "attributed-content" ? (
          <AttributedContent />
        ) : activePage === "bonus-content" ? (
          <BonusContent />
        ) : (
          <ContentReview />
        )}
      </ContentReviewContext.Provider>
    </>
  );
};

export default CampaignContentReviewPage;
