import FDropdown from "@components/FDropdown";
import STATUSES, { CONTENT_REVIEW_STATUS } from "../statuses";
import FButton from "@components/FButton";
import { useEffect, useState } from "react";
import { FTextarea } from "@components/FInputs";
import Table from "@components/Table";
import { addComment, getComments } from "@api/Campaign/ContentReview";
import useAuth from "@hooks/useAuth";
import {
  Content,
  ContentReviewAsset,
  ContentReviewComment,
  ContentReviewContent,
} from "@types";
import ContentReviewComments from "./ContentReviewComments";
import toast from "react-hot-toast";
import CommentInput from "../../../../components/comments/CommentInput";
import ModalContainer from "@components/Modals/ModalContainer";
import FIcon from "@components/FIcon";
import { ShowMore } from "@re-dev/react-truncate";

import { isVideoByExtension } from "@utils/file";
import ContentHighlight from "@components/media/content-highlight";
import { sortBy } from "lodash";

const getAssetsAsContent = (assets: ContentReviewAsset[]): Partial<Content>[] =>
  assets.map((asset) => {
    const { file_url, filename } = asset;

    const isVideo = isVideoByExtension(file_url, filename);

    return {
      video_url: isVideo && file_url,
      photo_url: !isVideo && file_url,
    };
  });

const TABLE_COLUMNS = [
  {
    key: "author_name",
    label: "Written by",
  },
  {
    key: "subject",
    label: "Type",
  },
  {
    key: "body",
    label: "Feedback",
    getContent: (item) => <p dangerouslySetInnerHTML={{ __html: item.body }} />,
  },
  {
    key: "created_at",
    label: "Created at",
    isDate: true,
  },
];

interface ContentReviewModalProps {
  onClose: () => void;
  post: ContentReviewContent;
  onUpdateStatus: (status: CONTENT_REVIEW_STATUS) => void;
  onReject: ({
    mediaFeedback,
    captionFeedback,
  }: {
    mediaFeedback: string;
    captionFeedback: string;
  }) => void;
  rejecting: boolean;
}

const ContentReviewModal = ({
  onClose,
  post,
  onUpdateStatus,
  onReject,
  rejecting,
}: ContentReviewModalProps) => {
  const [isRejectFormVisible, setRejectFormVisible] = useState(false);

  const [mediaFeedback, setMediaFeedback] = useState("");
  const [captionFeedback, setCaptionFeedback] = useState("");

  const [activeAssetIndex, setActiveAssetIndex] = useState(0);

  const [pendingComment, setPendingComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isDownloadingAsset, setIsDownloadingAsset] = useState(false);

  const [selectedRound, setSelectedRound] = useState(post.rounds[0].id);

  const [isLoadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState<ContentReviewComment[]>([]);

  const { user } = useAuth();

  const { campaignParticipant, rounds, status } = post;

  const { creator } = campaignParticipant;

  const { full_name, profile_image_url, slug } = creator;

  const { feedbacks } = rounds.find(({ id }) => id === selectedRound);

  useEffect(() => {
    const loadComments = async () => {
      setLoadingComments(true);

      const response = await getComments(post.id);

      const comments = response.data.comments;

      setLoadingComments(false);
      setComments(comments);
    };

    loadComments();
  }, []);

  const handleApprove = () => onUpdateStatus(CONTENT_REVIEW_STATUS.APPROVED);

  const handleAddComment = async () => {
    setIsSubmitted(true);

    if (!pendingComment) {
      return;
    }

    const response = await addComment({
      content_id: post.id,
      round_id: selectedRound,
      brand_user_id: user.id,
      body: pendingComment,
    });

    const newComment = response.data;

    setPendingComment("");

    toast.success("Comment added");

    setIsSubmitted(false);

    setComments([newComment, ...comments]);
  };

  const handleCommentDeleted = (commentId: number) => {
    const newComments = comments.filter(({ id }) => id !== commentId);

    setComments(newComments);
  };

  const handleCommentUpdated = (commentId: number, body: string) => {
    const newComments = comments.map((comment) => {
      if (comment.id !== commentId) {
        return comment;
      }

      return {
        ...comment,
        body,
      };
    });

    setComments(newComments);
  };

  const handleDownload = () => {
    setIsDownloadingAsset(true);

    fetch(post.file_url, {
      credentials: "include",
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");

        return response.blob().then((blob) => {
          const fileName = post.caption.split(" ")[0];

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            contentType === "video/quicktime" ? `${fileName}.mov` : fileName;

          link.click();
        });
      })
      .catch(console.error)
      .finally(() => {
        setIsDownloadingAsset(false);
      });
  };

  const commentsForRound = comments.filter(
    ({ round_id }) => round_id === selectedRound
  );

  const { assets } = rounds.find(({ id }) => id === selectedRound);

  return (
    <ModalContainer isOpen onClose={onClose} zIndex={100}>
      <div
        className={`bg-light_red rounded-2xl h-screen max-h-scree shadow-2xl overflow-hidden flex relative`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-[450px] h-full pr-0 relative overflow-hidden flex items-center justify-center bg-highlight_red">
          <ContentHighlight
            data={getAssetsAsContent(sortBy(assets, "position"))}
            currentSlide={activeAssetIndex}
            highlightRight={false}
            isStatic
            limit={null}
            onSlideChange={(index) => setActiveAssetIndex(index)}
            showVideoControls
            width={450}
          />
          {post.status === CONTENT_REVIEW_STATUS.APPROVED ? (
            <FButton
              className="absolute top-1 right-10"
              icon={{ color: "#FFFFFF", name: "download", size: 14 }}
              iconButton
              loading={isDownloadingAsset}
              onClick={handleDownload}
            />
          ) : null}
        </div>
        <div className="w-[450px] flex flex-col relative bg-light_red px-8 pt-12 pb-8">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={onClose}
          >
            <FDropdown
              options={STATUSES}
              onChange={(value) =>
                onUpdateStatus(value as CONTENT_REVIEW_STATUS)
              }
              selectedValue={status}
            />
          </div>
          <div className="flex gap-4 pb-4 w-full">
            <a
              href={`/card/${slug}`}
              className="relative z-10 bg-white border border-light_border cursor-pointer decoration-none flex justify-center items-center h-[52px] w-[52px] rounded-full hover:opacity-80"
              onClick={(e) => e.stopPropagation()}
              target="_blank"
            >
              {profile_image_url ? (
                <div
                  className="bg-cover bg-center rounded-3xl"
                  style={{
                    backgroundImage: `url(${profile_image_url})`,
                    height: "52px",
                    width: "52px",
                  }}
                />
              ) : (
                <FIcon
                  icon="user-bold"
                  size={16}
                  color="#000F45"
                  className="inline-block vertical-align-middle"
                />
              )}
            </a>
            <div className="text-[15px] text-dark_night_sky flex flex-col justify-center">
              <p className="font-medium">{full_name}</p>
              <a
                href={post.social_username}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[13px] text-neutral_500 hover:underline"
                onClick={(e) => e.stopPropagation()}
              >
                {"@" + post.social_username}
              </a>
            </div>
          </div>
          {post.caption && (
            <div className="border-t border-light_border py-4">
              <p className="text-[12px] uppercase font-medium text-neutral_500 mb-2">
                Caption
              </p>
              <ShowMore lines={2}>
                <p>{post.caption}</p>
              </ShowMore>
            </div>
          )}
          <div className="flex-1 flex flex-col overflow-auto">
            {isRejectFormVisible ? (
              <>
                <FTextarea
                  className="mb-4 w-full"
                  label="Request media changes"
                  value={mediaFeedback}
                  onChange={setMediaFeedback}
                  rows={3}
                  width="100%"
                />
                <FTextarea
                  className="mb-4 w-full"
                  label="Request caption changes"
                  value={captionFeedback}
                  onChange={setCaptionFeedback}
                  rows={3}
                  width="100%"
                />
              </>
            ) : (
              <>
                <div className="flex justify-end w-full mb-[30px]">
                  <FDropdown
                    options={rounds.map(({ id, stage }) => ({
                      value: id,
                      label: `R${stage}`,
                    }))}
                    selectedValue={selectedRound}
                    onChange={(value) => setSelectedRound(value as number)}
                  />
                </div>
                <div className="flex-1 overflow-y-auto mb-6">
                  {feedbacks?.length ? (
                    <Table columns={TABLE_COLUMNS} data={feedbacks} />
                  ) : null}
                  <div className="mb-6" />
                  {commentsForRound.length > 0 ? (
                    <ContentReviewComments
                      comments={commentsForRound}
                      onCommentDeleted={handleCommentDeleted}
                      onCommentUpdated={handleCommentUpdated}
                    />
                  ) : isLoadingComments ? null : (
                    <div className="flex items-center justify-center w-full my-8">
                      <span className="text-neutral_400">No comments yet</span>
                    </div>
                  )}
                </div>
                <CommentInput
                  pendingComment={pendingComment}
                  isSubmitted={isSubmitted}
                  onChange={setPendingComment}
                  onSubmit={handleAddComment}
                />
              </>
            )}
          </div>
          <div className="flex items-center justify-end gap-2 mt-4">
            {isRejectFormVisible ? (
              <>
                <FButton
                  label="Confirm"
                  onClick={() => onReject({ mediaFeedback, captionFeedback })}
                  loading={rejecting}
                  primary
                />
                <FButton
                  label="Cancel"
                  onClick={() => setRejectFormVisible(false)}
                />
              </>
            ) : (
              <>
                <FButton label="Approve" onClick={handleApprove} primary />
                <FButton
                  label="Reject"
                  onClick={() => setRejectFormVisible(true)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ContentReviewModal;
