import { Campaign, ParticipantGroup } from "@types";
import { useContext, useState } from "react";
import ParticipantsTable from "./ParticipantsTable";

import UpdateParticipantGroupModal from "./UpdateParticipantGroupModal";
import toast from "react-hot-toast";
import FButton from "@components/FButton";
import { Link } from "react-router-dom";
import { CampaignContext } from "@contexts/index";
import FIcon from "@components/FIcon";

interface ParticipantsByGroupProps {
  campaign: Campaign;
  selectedItems: number[];
  status?: string;
  showTableRationale?: boolean;
  onChangeSelectedItems: (ids: number[]) => void;
  onUpdateCampaign: (campaign: Partial<Campaign>) => void;
  onAddParticipants?: (groupId: number) => void;
  selectedGroup?: string;
  isParticipantReview?: boolean;
}

const ParticipantsByGroup = ({
  campaign,
  selectedItems,
  onChangeSelectedItems,
  onUpdateCampaign,
  onAddParticipants,
  status: selectedStatus,
  selectedGroup,
  isParticipantReview,
}: ParticipantsByGroupProps) => {
  const [groupToEdit, setGroupToEdit] = useState<ParticipantGroup>(null);

  const { briefs = [] } = useContext(CampaignContext);

  const { participant_groups, participants: allParticipants } = campaign;

  const allGroups = [
    { name: "No group", id: null } as ParticipantGroup,
    ...participant_groups,
  ];

  const handleParticipantGroupUpdated = (updates: Partial<Campaign>) => {
    onUpdateCampaign(updates);

    toast.success("Group updated");

    setGroupToEdit(null);
  };

  const groupsToShow = !selectedGroup
    ? allGroups
    : allGroups.filter((group) => group.id === parseInt(selectedGroup, 10));

  return (
    <>
      {groupsToShow.map((group) => {
        const { id, name } = group;
        const participants = allParticipants.filter(
          ({ participant_group_id, status }) =>
            participant_group_id === id &&
            (!selectedStatus || status === selectedStatus)
        );

        if (!id && participants.length === 0) {
          return null;
        }

        const groupBrief = group.id
          ? briefs.find((b) => b.participant_group_id === group.id)
          : null;

        return (
          <div
            key={id}
            className="flex flex-col gap-4 mb-8 pt-2 last:pb-[80px]"
          >
            <div>
              <div className="flex items-center h-[40px]">
                <Link
                  className="flex items-center gap-2 underline"
                  to={`/campaigns/${campaign.id}/brief/${groupBrief?.id || ""}`}
                >
                  <FIcon icon="link-arrow-2" color="#000F45" size={16} />
                  {name} ({participants.length})
                </Link>

                <FButton
                  icon={{
                    color: "#000F45",
                    name: "edit",
                    size: 12,
                  }}
                  iconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setGroupToEdit(group);
                  }}
                />
              </div>
            </div>
            <ParticipantsTable
              participants={participants}
              selectedItems={selectedItems}
              onChangeSelectedItems={onChangeSelectedItems}
              onUpdateParticipants={(participants) => {
                onUpdateCampaign({ participants });
              }}
              onAddParticipants={() => onAddParticipants(group.id)}
              isParticipantReview={isParticipantReview}
            ></ParticipantsTable>
          </div>
        );
      })}
      {groupToEdit ? (
        <UpdateParticipantGroupModal
          campaign={campaign}
          group={groupToEdit}
          onClose={() => setGroupToEdit(null)}
          onUpdated={handleParticipantGroupUpdated}
        />
      ) : null}
    </>
  );
};

export default ParticipantsByGroup;
