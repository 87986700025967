import { useParams } from "react-router-dom";
import BriefPreview from "./BriefPreview";

export default function BriefPreviewPage() {
  const { participant_group_id, participant_id } = useParams();

  return (
    <BriefPreview
      participant_group_id={parseInt(participant_group_id, 10)}
      participant_id={parseInt(participant_id, 10)}
    />
  );
}
