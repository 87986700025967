import React, { useEffect, ReactNode } from "react";

export interface ModalContainerProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  zIndex?: number;
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  isOpen,
  onClose,
  children,
  zIndex = 9000,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  // Handle the escape key
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={`fixed z-[${zIndex || 9999}] inset-0 bg-dark_night_sky bg-opacity-70 flex items-center justify-center p-4`}
    >
      <div
        className={`absolute left-0 top-0 w-full h-full`}
        onClick={onClose}
      />
      <div>{children}</div>
    </div>
  );
};

export default ModalContainer;
