import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Bulletin, Sort } from "@types";

const getBulletin = async (
  bulletin_id: string | number
): Promise<ApiResponse<Bulletin>> => {
  return await apiWrapper(`/bulletins/${bulletin_id}`, "GET");
};

const getBulletinList = async (
  admin?: boolean,
  page?: number,
  sort?: Sort,
  params?: object
): Promise<
  ApiResponse<{
    bulletins: Bulletin[];
    total_items: number;
  }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  // Check if params.admin is true
  if (admin === true) {
    return apiWrapper(`/admin/bulletins?${queryParams.toString()}`, "GET");
  } else {
    return apiWrapper(`/bulletins?${queryParams.toString()}`, "GET");
  }

  /*  const queryParams = new URLSearchParams();

  if (params?.q?.brand_id_eq) {
    queryParams.append("q[brand_id_eq]", params.q.brand_id_eq.toString());
  }
  if (params?.q?.name_cont) {
    queryParams.append("q[name_cont]", params.q.name_cont);
  }

  return apiWrapper(`/bulletins?${queryParams.toString()}`, "GET"); */
};

export const createBulletin = async (
  params: Partial<Bulletin>
): Promise<ApiResponse<Bulletin>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value !== null) {
      formData.append(`bulletin[${key}]`, value);
    }
  });

  return apiWrapper("/bulletins", "POST", formData);
};

export const updateBulletin = async (
  bulletin_id: string | number,
  params: Partial<Bulletin>
): Promise<ApiResponse<Bulletin>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value !== null) {
      formData.append(`bulletin[${key}]`, value);
    }
  });

  return apiWrapper(`/bulletins/${bulletin_id}`, "PUT", formData);
};

export const deleteBulletinCoverImage = async (
  bulletin_id: string | number
): Promise<ApiResponse<Bulletin>> => {
  return apiWrapper(`/bulletins/${bulletin_id}`, "PUT", {
    bulletin: { image: null },
  });
};

const deleteBulletin = async (bulletin_id: string | number) => {
  return apiWrapper(`/bulletins/${bulletin_id}`, "DELETE");
};

export { getBulletin, getBulletinList, deleteBulletin };
