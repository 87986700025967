import * as React from "react";
import { Link } from "react-router-dom";

export default function Admin() {
  return (
    <div>
      <h1>Admin</h1>
      <div className="flex flex-col gap-8 mt-10 p-8 bg-white rounded-lg shadow-md">
        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-bold">Reports</h4>
          <ul className="list-disc list-inside text-[15px]">
            <li>
              <Link
                to="/admin/revenue_recognition_report"
                className="underline"
              >
                Revenue Recognition Report
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-bold">Briefs</h4>
          <ul className="list-disc list-inside text-[15px]">
            <li>
              <Link to="/admin/contracts" className="underline">
                Contract Templates
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <h4 className="text-lg font-bold">Entities</h4>
          <ul className="list-disc list-inside text-[15px]">
            <li>
              <Link to="/admin/campaigns" className="underline">
                Campaigns
              </Link>
            </li>
            <li>
              <Link to="/admin/brands" className="underline">
                Brands
              </Link>
            </li>
            <li>
              <Link to="/admin/bulletins" className="underline">
                Bulletins
              </Link>
            </li>
            <li>
              <Link to="/admin/creators" className="underline">
                Creators
              </Link>
            </li>
            <li>
              <Link to="/admin/outreach" className="underline">
                Outreach
              </Link>
            </li>
            <li>
              <Link to="/admin/groups" className="underline">
                Groups
              </Link>
            </li>
            <li>
              <Link to="/admin/reports" className="underline">
                Reports
              </Link>
            </li>
            {/* <li>
              <Link to="/admin/e/brands" className="underline">
                Brands
              </Link>
            </li>
            <li>
              <Link to="/admin/e/campaigns" className="underline">
                Campaigns
              </Link>
            </li>
            <li>
              <Link to="/admin/e/assets" className="underline">
                Assets
              </Link>
            </li>
            <li>
              <Link to="/admin/e/creators" className="underline">
                Creators
              </Link>
            </li>
            <li>
              <Link to="/admin/ambassador_questions" className="underline">
                Ambassador questions
              </Link>
            </li>
            <li>Campaign Briefs</li>
						<li>Participant Briefs</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
