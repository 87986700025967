import OnboardingStepContainer from "../components/OnboardingStepContainer";

import { useEffect, useState } from "react";

import FButton from "@components/FButton";

import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import { updateProfile } from "@api/Profile";
import { Profile } from "@types";

import { isEmpty } from "lodash";
import useAuth from "@hooks/useAuth";
import { getCreator } from "@api/Creators/Creators";
import PersonalInfoForm from "./components/PersonalInfoForm";
import moment from "moment";
import FSpinner from "@components/FSpinner";

const DEFAULT_VALUES = {
  first_name: "",
  last_name: "",
  address: "",
  country: "United States of America",
  state: "",
  city: "",
  zipcode: "",
  gender: [],
  ethnicity: [],
  birthday: moment().format("YYYY-MM-DD"),
};

const AddPersonalInfo = () => {
  const { brand } = useParams<{ brand: string }>();

  const [values, setValues] = useState<Partial<Profile>>(DEFAULT_VALUES);

  const [image, setImage] = useState<{ file: File; url: string }>(null);
  const [loading, setLoading] = useState(true);

  const [isSaving, setSaving] = useState(false);

  const [isSubmitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setLoading(false);

      return;
    }

    const fetchCreatorData = async () => {
      const response = await getCreator(user.creator_id);

      const {
        about_you,
        birthday,
        firstName,
        lastName,
        gender,
        profile_image_url,
      } = response.data;

      const {
        address_city,
        address_country,
        address_line_1,
        address_state,
        address_zip,
        ethnicity,
      } = about_you;

      setValues({
        first_name: firstName || "",
        last_name: lastName || "",
        city: address_city || "",
        country: address_country || DEFAULT_VALUES.country,
        state: address_state || "",
        zipcode: address_zip,
        gender,
        ethnicity: ethnicity || [],
        address: address_line_1 || "",
        birthday: birthday || DEFAULT_VALUES.birthday,
      });

      setImage({ file: null, url: profile_image_url });

      setLoading(false);
    };

    fetchCreatorData();
  }, [user]);

  const handleSave = async () => {
    const { birthday, ...rest } = values;

    setSaving(true);

    if (Object.keys(rest).find((key) => !values[key] || isEmpty(values[key]))) {
      setSubmitted(true);

      return;
    }

    const params = image?.file
      ? { avatar: image?.file, birthday, ...rest }
      : { birthday, ...rest };

    try {
      await updateProfile(params);

      toast.success("Changes saved");

      const destination = brand
        ? `/${brand}/ambassador-onboarding/confirm-details`
        : "/signup/influencer/confirm-details";

      navigate(destination);

      setSaving(false);
    } catch (e) {
      console.error("E", e);
      toast.error("Error updating creator", e.response?.data);
    } finally {
      setSaving(false);
    }
  };

  return (
    <OnboardingStepContainer
      title={brand ? null : "Personal Info"}
      description="Add your personal info"
      currentStep={2}
      isBrand={!!brand}
      stepsCount={3}
    >
      {loading ? (
        <div className="h-[200px]">
          <FSpinner width={24} height={24} borderWidth={2} />
        </div>
      ) : (
        <PersonalInfoForm
          values={values}
          onUpdate={setValues}
          image={image}
          onUpdateImage={setImage}
          isSubmitted={isSubmitted}
        />
      )}
      <FButton
        loading={isSaving}
        height="48px"
        width="100%"
        primary
        label="Continue"
        onClick={handleSave}
      />
    </OnboardingStepContainer>
  );
};

export default AddPersonalInfo;
