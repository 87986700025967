import React, { useContext, useState } from "react";
import FButton from "@components/FButton";
import { toast } from "react-hot-toast";
import SelectCreatorsTable from "@components/Creator/SelectCreatorsTable";
import { Creator, GroupData } from "@apiTypes";
import SelectGroupsTable from "@components/Creator/SelectGroupsTable";

import { OffPlatformCreator } from "@pages/campaigns/components/AddOffPlatformCreatorForm";

import apiClient from "@apiClient";
import { useParams } from "react-router-dom";
import { GroupContext } from "@contexts/index";
import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";
import FButtonGroup from "@components/FButtonGroup";

const { add_creator, get } = apiClient.groups;

enum SOURCE {
  CREATORS = "creators",
  OFF_PLATFORM = "off-platform",
  GROUPS = "groups",
}

const SOURCES = [
  { value: SOURCE.CREATORS, label: "Add On-Platform" },
  { value: SOURCE.OFF_PLATFORM, label: "Add Off-Platform" },
  { value: SOURCE.GROUPS, label: "Add by Group" },
];

const defaultOffPlatformCreator = { firstName: "", lastName: "", email: "" };

interface Props {
  onClose: () => void;
}

const AddToGrouModal = ({ onClose }: Props) => {
  const { group_id } = useParams<{ group_id: string }>();
  const { setGroup } = useContext(GroupContext);

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  const [selectedSource, setSelectedSource] = useState(SOURCES[0].value);
  const [step, setStep] = useState(1);

  const [selectedCreators, setSelectedCreators] = useState<Creator[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupData[]>([]);

  const [offPlatformCreator, setOffPlatformCreator] =
    useState<OffPlatformCreator>(defaultOffPlatformCreator);

  const handleSubmit = async () => {
    setWaiting(true);
    setError(null);

    if (selectedSource === SOURCE.GROUPS) {
      try {
        for (const selectedGroup of selectedGroups) {
          const groupDetails = await get(selectedGroup.id);
          const creatorsInGroup = groupDetails.data.creators;

          for (const creator of creatorsInGroup) {
            try {
              const response = await add_creator(group_id, [creator.id]);
              setGroup(response.data);
            } catch (error) {
              console.error(
                `Failed to add creator ${creator.id} from group ${selectedGroup.id}:`,
                error
              );
            }
          }
        }

        toast.success("Saved");
        onClose();
      } catch (error) {
        console.error("Failed to add creators:", error);
        setError("Failed to add creators");
      }
      return;
    }

    let newCreator;

    const creatorsToAdd = newCreator ? [newCreator] : selectedCreators;

    try {
      const creatorIds = creatorsToAdd.map((creator) => creator.id);
      const response = await add_creator(group_id, creatorIds);

      setGroup(response.data);
      setWaiting(false);

      toast.success("Saved");

      if (selectedSource === SOURCE.OFF_PLATFORM) {
        setStep(1);
        setOffPlatformCreator(defaultOffPlatformCreator);
        return;
      }

      onClose();
    } catch (error) {
      setError("Failed to add participants");
      setWaiting(false);
    }
  };

  const extraCreatorColumns = [];

  return (
    <SelectEntitiesModal
      title="Add Creators"
      onClose={onClose}
      error={error}
      headerContent={
        step === 1 ? (
          <FButtonGroup
            options={SOURCES}
            selectedValue={selectedSource}
            onSelectedValue={(value) => setSelectedSource(value as SOURCE)}
          />
        ) : null
      }
      actionButton={
        <FButton
          loading={waiting}
          onClick={handleSubmit}
          primary
          label="Save"
          width="100%"
          height="40px"
        />
      }
    >
      <>
        {step === 1 ? (
          <div style={{ height: "calc(100% - 70px)" }}>
            {selectedSource === SOURCE.CREATORS ? (
              <SelectCreatorsTable
                extraColumns={extraCreatorColumns}
                selectedItems={selectedCreators}
                onChangeSelectedItems={setSelectedCreators}
              />
            ) : null}
            {selectedSource === SOURCE.GROUPS ? (
              <SelectGroupsTable
                selectedItems={selectedGroups}
                onChangeSelectedItems={setSelectedGroups}
              />
            ) : null}
            {selectedSource === SOURCE.OFF_PLATFORM ? (
              <SelectCreatorsTable
                extraColumns={extraCreatorColumns}
                selectedItems={selectedCreators}
                onChangeSelectedItems={setSelectedCreators}
                offPlatform={true}
              />
            ) : null}
          </div>
        ) : null}
      </>
    </SelectEntitiesModal>
  );
};

export default AddToGrouModal;
