import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ProfileLayout from "@layouts/ProfileLayout";
import FIcon from "@components/FIcon";
import { Link } from "react-router-dom";
import { CampaignContext, ProfileContext } from "@contexts/index";

import LoadingSpinner from "@components/LoadingSpinner";
import ProfileModal from "@components/Modals/ProfileModal";
import { KIND, Participant, ParticipantStatus } from "@types";
import useAuth from "@hooks/useAuth";
import { getOverviewData } from "@api/Profile";

import apiClient from "@apiClient";
import { searchPosts } from "@api/Search";
import ProfileContentReview from "@pages/profile/content-review/ProfileContentReview";
import PROFILE_ROUTES from "@pages/profile/routes";
import ProfileBrief from "@pages/profile/ProfileBrief";

const { profiles } = apiClient;

const SUB_NAV_ITEMS = [
  {
    label: "Brief",
    value: "brief",
    component: ProfileBrief,
  },
  ...PROFILE_ROUTES.slice(0, 2),
  {
    label: "Content Review",
    value: "review",
    component: ProfileContentReview,
  },
  ...PROFILE_ROUTES.slice(2),
];

export default function ParticipantProfile() {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);
  const { participant_id, campaign_id, subpage } = useParams<{
    participant_id: string;
    campaign_id: string;
    subpage: string;
  }>();

  const { campaign } = useContext(CampaignContext);

  const { user } = useAuth();

  const isLoggedIn = !!user;

  const { participants } = campaign;

  const [profile, setProfile] = useState<Participant>(null);
  const [loading, setLoading] = useState(false);
  const [isBriefNavFixed, setIsBriefNavFixed] = useState(false);

  const [contentHighlight, setContentHighlight] = useState(null);
  const [content, setContent] = useState(null);
  const [topContent, setTopContent] = useState([]);

  const onModalClose = () => {
    navigate(`/campaigns/${campaign_id}/participants`);
  };

  const handlePrevClick = () => {
    const currentIndex = participants.findIndex(
      (p) => p.id === Number(participant_id)
    );
    if (currentIndex === -1) {
      console.error("Current participant not found in the list");
      return;
    }
    const prevIndex =
      (currentIndex - 1 + participants.length) % participants.length;
    const prevParticipant = participants[prevIndex];
    navigate(
      !isLoggedIn
        ? `/campaigns/${campaign_id}/participant-review/${prevParticipant.id}`
        : `/campaigns/${campaign_id}/participants/${prevParticipant.id}`
    );
  };

  const handleNextClick = () => {
    const currentIndex = participants.findIndex(
      (p) => p.id === Number(participant_id)
    );
    if (currentIndex === -1) {
      console.error("Current participant not found in the list");
      return;
    }
    const nextIndex = (currentIndex + 1) % participants.length;
    const nextParticipant = participants[nextIndex];
    navigate(
      !isLoggedIn
        ? `/campaigns/${campaign_id}/participant-review/${nextParticipant.id}`
        : `/campaigns/${campaign_id}/participants/${nextParticipant.id}${subpage ? `/${subpage}` : ""}`
    );
  };

  useEffect(() => {
    setLoading(true);

    const loadData = async () => {
      const currentParticipant = participants.find(
        (p) => p.id === Number(participant_id)
      );

      if (currentParticipant) {
        const {
          creator: { id, slug },
        } = currentParticipant;

        const topContentResponse = await searchPosts({
          creator_ids: [id],
          page_size: 5,
          post_type: "video",
        });

        const { results } = topContentResponse.data;

        setContentHighlight(results);

        const overviewResponse = await getOverviewData(slug);

        const allContent = await profiles.all_content(slug);

        setContent(allContent.data);

        try {
          const topContent = await profiles.top_content(slug);

          setTopContent(topContent.data);
        } catch {
          setContent({});
        }

        setProfile({ ...currentParticipant, ...overviewResponse.data });
        setLoading(false);
      }
    };

    loadData();
  }, [participant_id, participants]);

  const subNavItems = SUB_NAV_ITEMS.filter((item) => {
    const { value } = item;

    if (value === "brief") {
      return (
        profile?.status === ParticipantStatus.REVIEW_APPROVED ||
        profile?.status === ParticipantStatus.ACCEPTED
      );
    }

    return campaign?.kind !== KIND.GIFTING || item.value !== "review";
  });

  const navActions = profile ? (
    <div className="w-full px-[40px] relative z-50">
      <div className="py-[14px] border-b w-full flex flex-row justify-between border-light_border">
        <Link
          to={
            !isLoggedIn
              ? `/campaigns/${campaign_id}/participant-review`
              : `/campaigns/${campaign_id}/participants`
          }
          className="flex items-center gap-2 border border-neutral_400 cursor-pointer hover:border-neutral_600 px-4 py-1 "
        >
          <FIcon icon="undo" size={18} color="#000F45" />
          <p className="text-sm text-dark_night_sky">Back</p>
        </Link>
        <div className="flex flex-row items-center justify-end gap-4">
          <div className="flex flex-row gap-2 items-center justify-center">
            <button
              className="border-neutral_400 border -rotate-90 rounded-full p-3 focus:outline-none hover:border-neutral_600 focus:border-neutral_600"
              onClick={handlePrevClick}
            >
              <FIcon icon="sort-up" size={13} color="#000F45" />
            </button>
            <button
              className="border-neutral_400 border -rotate-90 rounded-full p-3 focus:outline-none hover:border-neutral_600 focus:border-neutral_600"
              onClick={handleNextClick}
            >
              <FIcon icon="sort-down" size={13} color="#000F45" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  if (loading || !profile) {
    return <LoadingSpinner className="h-[100vh]" />;
  }

  if (isLoggedIn) {
    return (
      <ProfileContext.Provider
        value={{
          profile,
          collapsed,
          contentHighlight,
          setCollapsed,
          isBriefNavFixed,
          setIsBriefNavFixed,
          content,
        }}
      >
        <ProfileModal onClose={onModalClose}>
          <ProfileLayout
            highlightRight={false}
            navActions={navActions}
            subNavItems={subNavItems}
            user={user}
          />
        </ProfileModal>
      </ProfileContext.Provider>
    );
  }

  return (
    <div className={`${isLoggedIn ? "absolute top-0 bg-light_red" : ""}`}>
      <ProfileContext.Provider
        value={{
          profile,
          collapsed,
          contentHighlight,
          setCollapsed,
          content,
          topContent,
        }}
      >
        <ProfileLayout
          highlightRight={false}
          navActions={navActions}
          subNavItems={subNavItems}
        />
      </ProfileContext.Provider>
    </div>
  );
}
