import FButton from "@components/FButton";
import Modal from "@components/Modals/Modal";
import { ReactNode } from "react";

interface SelectEntitiesModalProps {
  title: string;
  subtitle?: string;
  onClose: () => void;
  onBack?: () => void;
  headerContent?: ReactNode;
  actionButton?: ReactNode;
  children: ReactNode;
  error?: string;
}

const SelectEntitiesModal = ({
  title,
  subtitle,
  headerContent,
  onBack,
  actionButton,
  children,
  error,
  onClose,
}: SelectEntitiesModalProps) => (
  <Modal
    title={title}
    subtitle={subtitle}
    className="w-[901px] h-screen"
    onClose={onClose}
    isOpen
    hasForm
    zIndex={9999}
  >
    <div
      className="flex flex-col gap-4"
      style={{ height: `calc(100vh - ${headerContent ? 150 : 100}px)` }}
    >
      {headerContent}
      {children}
    </div>
    <div className="flex items-center justify-between space-x-2 pb-8 sticky bottom-0 z-50 pt-4 w-full">
      <div>
        {onBack ? (
          <FButton
            onClick={onBack}
            label="Go Back"
            width="140px"
            height="40px"
          />
        ) : null}
      </div>
      {error ? (
        <div className="flex justify-center">
          <p className={"text-[16px] text-red"}>{error}</p>
        </div>
      ) : null}
      <div className="flex justify-center space-x-2">
        <FButton onClick={onClose} label="Cancel" height="40px" width="140px" />
        {actionButton}
      </div>
    </div>
  </Modal>
);

export default SelectEntitiesModal;
