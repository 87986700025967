import React, { CSSProperties, useState } from "react";
import FIcon from "../FIcon";
import FFile from "./FFile";

import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";
import { isVideo } from "@utils/file";
import { UploadedFile } from "@types";
import { size } from "lodash";

interface Props {
  onUpload: (files: UploadedFile[]) => void;
  onDelete?: (fileName: string) => void;
  accept?: string[];
  maxSize?: number; // in bytes (1mb = 1000000 bytes)
  style?: CSSProperties;
  multiple?: boolean;
  uploadedFiles?: UploadedFile[];
  className?: string;
  hideFileList?: boolean;
}

const FFileUpload = ({
  onUpload,
  onDelete,
  className = "",
  hideFileList,
  multiple,
  uploadedFiles = [],
  ...props
}: Props) => {
  const [uploadState, setUploadState] = useState<string>("empty");

  const baseClass =
    "relative rounded flex flex-col all-center w-full h-[150px]";

  const uploadStates = {
    empty: {
      text: `Drag-and-drop ${multiple ? "one or more files" : "file"}, or browse computer`,
      borderColor: "#9398AC",
      backgroundColor: "#FFFFFF",
      borderStyle: "dashed",
    },
    pending: {
      text: "Uploading file",
      borderColor: "#E3E6ED",
      backgroundColor: "#F8F9FC",
      borderStyle: "solid",
    },
    success: {
      text: "File upload complete!",
      borderColor: "#47B37F",
      backgroundColor: "#E9F3EE",
      borderStyle: "solid",
    },
    error: {
      text: "Unable to upload file",
      borderColor: "#E47667",
      backgroundColor: "#FFF5F3",
      borderStyle: "solid",
    },
  };

  const onDrop = async (files = []) => {
    const newFiles = [];

    for (const file of files) {
      if (["image/heic", "image/heif"].includes(file?.type)) {
        const blob = (await heic2any({
          blob: file, // Use the original file object
          toType: "image/jpeg",
          quality: 0.7, // adjust quality as needed
        })) as Blob;

        newFiles.push({
          file: new File([blob], file.name),
          url: URL.createObjectURL(blob),
        });
      } else {
        newFiles.push({
          file,
          url: URL.createObjectURL(file),
        });
      }
    }

    onUpload(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadedFile =
    (!multiple || size(uploadedFiles) === 1) && uploadedFiles[0];

  return (
    <div
      className={className}
      style={{
        width: "100%",
        overflow: "hidden",
        ...props?.style,
      }}
    >
      <div
        {...getRootProps()}
        className={`${baseClass} border relative cursor-pointer`}
        style={{
          backgroundColor: uploadStates[uploadState].backgroundColor,
          backgroundPosition: "center",
          backgroundImage:
            uploadedFile && !isVideo(uploadedFile.file)
              ? `url(${uploadedFile.url})`
              : null,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          border: `1px ${uploadStates[uploadState].borderStyle} ${uploadStates[uploadState].borderColor}`,
        }}
      >
        {onDelete && uploadedFile ? (
          <button
            onClick={(e) => {
              e.stopPropagation();

              onDelete(uploadedFile.file?.name);
            }}
            className="absolute top-2 right-0 z-10"
          >
            <FIcon icon="delete" size={20} color="#FF5C5C" className="mr-2" />
          </button>
        ) : null}
        <input {...getInputProps()} />
        {/* animated uploading dots */}
        {isDragActive ? (
          <p className="absolute">Drop the files here ...</p>
        ) : null}
        {uploadState === "pending" ? (
          <div className="upload-anim flex items-center h-[24px]">
            <div className="h-[4px] w-[4px] border-[0.5px] border-dark_night_sky rounded-full"></div>
            <div className="h-[4px] w-[4px] border-[0.5px] border-dark_night_sky rounded-full mx-[2.5px]"></div>
            <div className="h-[4px] w-[4px] border-[0.5px] border-dark_night_sky rounded-full"></div>
          </div>
        ) : uploadedFile ? null : (
          <FIcon icon={`upload-${uploadState}`} size={32} />
        )}
        {!uploadedFile ? (
          <p
            className="text-sm tracking-0075 mt-4 text-neutral_500 text-center w-[200px]"
            dangerouslySetInnerHTML={{
              __html: uploadStates[uploadState].text,
            }}
          ></p>
        ) : null}
        {uploadedFile && isVideo(uploadedFile.file) ? (
          <video
            src={uploadedFile.url}
            autoPlay
            playsInline
            controls
            style={{ height: "100%" }}
          />
        ) : null}
      </div>
      {/* file list */}
      {!hideFileList && multiple ? (
        <div className="ffilelist max-h-[90px] overflow-y-auto show-scroll">
          {uploadedFiles.map(({ file }, i) => {
            return (
              <FFile
                key={file.name}
                index={i}
                className={"mt-[8px]"}
                file={file}
                onDelete={(file) => {
                  onDelete(file.name);

                  setUploadState("empty");
                }}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default FFileUpload;
