import React, { useCallback, useEffect, useState } from "react";

import { Bulletin, Sort } from "@types";
import {
  getBulletinRecipientList,
  deleteBulletinRecipient,
  sendEmailToAllRecipients,
} from "@api/Bulletins/BulletinRecipients";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getBulletin, updateBulletin } from "@api/Bulletins/Bulletins";
import FButton from "@components/FButton";
import AddRecipientsToBulletinModal from "./components/AddRecipientsToBulletinModal";
import FIcon from "@components/FIcon";
import BlankState from "@components/BlankState";
import toast from "react-hot-toast";
import ConfirmModal from "@components/Modals/ConfirmModal";
import SearchFilterHistory from "./search-filter-history";
import FilterSummary from "./search-filter-history/FilterSummary";

const filterOptions = [
  { label: "All", static: "Status", value: null },
  { label: "New", static: "Status", value: "new" },
  { label: "Completed", static: "Status", value: "completed" },
  { label: "Declined", static: "Status", value: "declined" },
];

const actions = {
  delete: async (id: number) => deleteBulletinRecipient(id),
};

const tableColumns = [
  {
    key: "creator_full_name",
    label: "Name",
    isLink: false,
    isImage: true,
    imageType: "asset" as "asset" | "profile",
    imageKey: "creator_profile_image_url",
    isFirstColumn: true,
  },
  { key: "status", label: "Status", isStateBubble: true },
  { key: "email_last_sent_at", label: "Email Last Sent At", isDate: true },
];

export default function BulletinRecipientListPage() {
  const [bulletin, setBulletin] = useState<Bulletin>(null);
  const [showAddToGroupModal, setShowAddToGroupModal] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const [isConfirmPublishModalVisible, setConfirmPublishModalVisible] =
    useState(false);

  const { bulletin_id } = useParams<{ bulletin_id: string }>();
  const { can } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    getBulletin(bulletin_id).then((response) => {
      setBulletin(response.data);
    });
  }, []);

  const getBulletinRecipients = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const response = await getBulletinRecipientList(false, page, sort, {
        bulletin_id_eq: bulletin_id,
        status_eq: filter,
        creator_full_name_cont: search,
      });

      const { bulletin_recipients: items } = response.data;

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  const handleEmailRecipients = async () => {
    setWaiting(true);

    try {
      await sendEmailToAllRecipients(bulletin.id);

      toast.success("Email sent to all recipients");
    } catch (e) {
      toast.error("Error sending email");
    } finally {
      setWaiting(false);
    }
  };

  const handlePublish = async () => {
    setWaiting(true);

    const updates = {
      status: "active",
    };

    try {
      await updateBulletin(bulletin.id, updates);

      setBulletin({
        ...bulletin,
        ...updates,
      });

      setConfirmPublishModalVisible(false);

      toast.success("Bulletin published");
    } catch (e) {
      toast.error("Error publishing bulleting");
    } finally {
      setWaiting(false);
    }
  };

  const cardDropDownOptions = [
    can(Permission.DESTROY_BULLETINS) && { label: "Delete", value: "delete" },
  ].filter(Boolean);

  const isActive = bulletin?.status === "active";

  const { filter_history = [] } = bulletin || {};

  const currentFilter = filter_history[0]?.filters;

  return (
    <div className="w-full px-[15px] sm:px-[40px] mt-[40px] z-10">
      <Link className="flex items-center mb-4" to={`/bulletins/${bulletin_id}`}>
        <FIcon icon="back" size={18} color="#000F45" />
        Go back to bulletin
      </Link>
      <SinglePageLayoutWithData
        entityName="bulletin_recipient"
        pageTitle={`Recipients for bulletin "${bulletin?.name}"`}
        pageName="Bulletin Recipients"
        sortPathName="creator_full_name"
        sortPathDate="email_last_sent_at"
        filterOptions={filterOptions}
        filterPath="status"
        tableColumns={tableColumns}
        cardDropDownOptions={cardDropDownOptions}
        rowActions={cardDropDownOptions}
        asyncActions={actions}
        getItems={getBulletinRecipients}
        tableOnly
        summaryContent={
          currentFilter ? (
            <div className="flex flex-col gap-4 p-4 border border-light_border rounded-xl my-8">
              <p className="font-bold">Current Filters</p>
              <FilterSummary filters={currentFilter} />
            </div>
          ) : null
        }
        headerButtons={
          <div className="flex gap-2">
            <FButton
              primary={true}
              onClick={() => navigate(`/search?addToBulletinId=${bulletin_id}`)}
              label="Add Creators"
              iconLeft={{
                name: "add",
                size: 12,
                color: "#fff",
                className: "mr-2",
              }}
              width="max-content"
            />
            <FButton
              primary={true}
              loading={isActive && waiting}
              onClick={() =>
                isActive
                  ? handleEmailRecipients()
                  : setConfirmPublishModalVisible(true)
              }
              width="max-content"
              label={isActive ? "Email Recipients" : "Publish"}
            />
          </div>
        }
        customBlankState={
          <BlankState
            title="No Bulletin Recipients Found"
            subtitle="Cast gifting and paid, events, and survey opportunities, and have creators register their interest today."
            icon="user"
          />
        }
      />
      {bulletin ? <SearchFilterHistory bulletin={bulletin} /> : null}
      {showAddToGroupModal && (
        <AddRecipientsToBulletinModal
          onClose={() => setShowAddToGroupModal(false)}
        />
      )}
      {isConfirmPublishModalVisible ? (
        <ConfirmModal
          title="Publish bulletin"
          isOpen
          onClose={() => setConfirmPublishModalVisible(false)}
          onAction={handlePublish}
          actionLabel="Confirm"
          isWaiting={waiting}
          subtitle={`Are you sure you want to publish this bulletin?`}
        />
      ) : null}
    </div>
  );
}
