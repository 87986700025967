import React from "react";

export const StatsSnapshotSkeleton = () => {
  return (
    <div className="pr-8">
      <div className="flex justify-between items-center mb-6">
        <div className="h-6 w-24 bg-neutral_200 rounded animate-pulse"></div>
      </div>
      <div className="p-4 space-y-3 border border-light_border rounded-lg bg-light_red shadow-md">
        {[1, 2, 3, 4, 5, 6, 7].map((index) => (
          <div
            key={index}
            className="flex justify-between items-center animate-pulse"
          >
            <div className="h-4 w-20 bg-neutral_200 rounded"></div>
            <div className="h-4 w-16 bg-neutral_200 rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
};
