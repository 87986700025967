import React from "react";
import { maxBy, sortBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";
import TriangleChart from "@components/Graphs/TriangleChart";
import WidgetContainer from "./WidgetContainer";

interface KidsBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function KidsBreakdown({ items }: KidsBreakdownProps) {
  const maxValueItem = maxBy(items, "percentage");

  return (
    <WidgetContainer title="Parental Status" value={maxValueItem?.name || ""}>
      <TriangleChart data={sortBy(items || [], "percentage").reverse()} />
    </WidgetContainer>
  );
}
