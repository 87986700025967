import React, { useState, useEffect, useRef } from "react";

import BasicCard from "./Cards/BasicCard";
import FIcon from "./FIcon";

interface Props {
  children?: string | JSX.Element | JSX.Element[];
  total?: number;
  cardData: any[];
  numColumns?: number;
  page?: number;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  addAssetCard?: boolean;
  isProfile?: boolean;
  decorationPath?: string;
  cardType: string;
  cardMinWidth?: number;
  onCardClick?: (item: any) => void;
  onToggleItemSelection?: (id: number) => void;
  selectedItems?: string[] | number[];
  cardDropDownOptions?: { label: string; value: string }[];
  onTableRowDropDownSelect?: (value: string, id: number) => void;
  onAddNew?: () => void;
  onDelete?: (id: number) => void;
  onViewStatsScreenshot?: (entity: any) => void;
  addNewLabel?: string;
  highlightedId?: number;
}

const ResultsGrid = ({
  isProfile,
  cardData,
  decorationPath,
  cardType,
  cardMinWidth,
  onToggleItemSelection,
  selectedItems,
  cardDropDownOptions,
  onCardClick,
  onTableRowDropDownSelect,
  onAddNew,
  onDelete,
  onViewStatsScreenshot,
  addNewLabel,
  highlightedId,
  numColumns,
}: Props) => {
  const [gridItems, setGridItems] = useState<JSX.Element[]>([]);
  const [columns, setColumns] = useState(numColumns || 4);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateGrid = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const gap = 16; // gap-4 in Tailwind
        const minCardWidth = cardMinWidth || (numColumns === 6 ? 150 : 250);

        let cols = Math.floor((containerWidth + gap) / (minCardWidth + gap));
        cols = Math.max(1, Math.min(cols, numColumns || 4)); // Limit columns between 1 and 4

        setColumns(cols);

        const cardWidth = Math.floor(
          (containerWidth - (cols - 1) * gap) / cols
        );
        const cardHeight = Math.floor(cardWidth * (4 / 3)); // 4:3 aspect ratio

        const items = cardData.map((item) => (
          <BasicCard
            key={item.id}
            {...item}
            checkbox={!!onToggleItemSelection}
            isProfile={isProfile}
            cardHeight={cardHeight}
            cardWidth={cardWidth}
            decorationPath={decorationPath}
            cardType={cardType}
            onCheckBoxClick={onToggleItemSelection}
            checkBoxClickList={selectedItems}
            cardDropDownOptions={cardDropDownOptions}
            statHelperText="Influencers"
            onOptionSelect={onTableRowDropDownSelect}
            onCardClick={onCardClick}
            onDelete={onDelete}
            onViewStatsScreenshot={onViewStatsScreenshot}
            isHighlighted={item.id === highlightedId}
          />
        ));

        setGridItems(items);
      }
    };

    updateGrid();
    window.addEventListener("resize", updateGrid);
    return () => window.removeEventListener("resize", updateGrid);
  }, [
    cardData,
    isProfile,
    decorationPath,
    cardType,
    onCardClick,
    onToggleItemSelection,
    selectedItems,
    cardDropDownOptions,
    onTableRowDropDownSelect,
    onDelete,
    onViewStatsScreenshot,
    highlightedId,
    numColumns,
  ]);

  return (
    <div className="pagination-container mt-10" ref={containerRef}>
      <div
        className="grid gap-4 auto-rows-fr"
        style={{
          gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
        }}
      >
        {gridItems}
        {onAddNew ? (
          <button onClick={onAddNew}>
            <FIcon icon="plus" size={36} />
            <p className="mt-8">{addNewLabel}</p>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ResultsGrid;
