import React, { useCallback, useEffect, useState, useContext } from "react";
import { getGroupList, deleteGroup, getGroupOwners } from "@api/Groups/Groups";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import CreateOrUpdateGroupModal from "../../groups/CreateOrUpdateGroupModal";
import { UserContext } from "@contexts/index";
import { changeMembership } from "@api/user";
import toast from "react-hot-toast";

const cardDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const actions = {
  delete: async (id) => deleteGroup(id),
};

export default function GroupsAdmin() {
  const { user, setUser } = useContext(UserContext);
  const [groupOwners, setGroupOwners] = useState([]);
  const [groupState, setGroupState] = useState("all");

  useEffect(() => {
    const loadGroupOwners = async () => {
      try {
        const response = await getGroupOwners();
        setGroupOwners(response.data.group_owners);
      } catch (error) {
        console.error("Failed to load group owners:", error);
      }
    };

    loadGroupOwners();
  }, []);

  const tableColumns = [
    { key: "name", label: "Name", isLink: true, isFirstColumn: true, handleTableCellClick: () => {} },
    { key: "creators_count", label: "Creators" },
    { nestedKey: "owner_user", key: "full_name", label: "Owner" },
    { nestedKey: "brand", key: "name", label: "Company" },
    {
      key: "membership",
      label: "Your Membership",
      getValue: (item) => {
        const membership = user.memberships.find(
          (membership) => membership.brand_id === item.brand.id
        );
        return membership ? "User" : ""; // Return appropriate membership status
      },
    },
    { key: "archived", label: "Archived", isStateBubble: true },
    { key: "featured", label: "Featured", isStateBubble: true },
    { key: "created_at", label: "Created", isDate: true },
  ];

  const getGroups = useCallback(
    async (page, sort, params) => {
      const { search, filter } = params;
      const response = await getGroupList(true, page, sort, {
        brand_user_id_eq: filter,
        search,
        archived_eq: groupState === "archived" ? true : undefined,
        featured_eq: groupState === "featured" ? true : undefined,
      });

      return {
        ...response.data,
        items: response.data.groups,
      };
    },
    [groupState]
  );

  const filterOptions = [
    { label: "All Owners", static: "Groups", value: null },
    ...groupOwners.map(({ id, full_name }) => ({
      value: id,
      label: full_name,
    })),
  ];

  const pageSwitch = (
    <div className="flex justify-between border border-light_border bg-white h-[40px]">
      {["all", "featured", "archived"].map((state) => (
        <button
          key={state}
          className={`text-[15px] px-4 py-2 h-full flex items-center justify-center ${
            groupState === state ? "bg-highlight_red" : "opacity-80 hover:opacity-100"
          } ${state !== "all" ? "border-l border-light_border" : ""}`}
          onClick={() => setGroupState(state)}
        >
          {state.charAt(0).toUpperCase() + state.slice(1)} Groups
        </button>
      ))}
    </div>
  );

  const handleTableCellClick = (item, columnKey) => {
    const membership = user.memberships.find(
      (membership) => membership.brand_id === item.brand.id
    );

    if (user.brand_id === item.brand.id) {
      redirectToGroup(item.id);
    } else if (membership) {
      handleMembershipChange(membership, item);
    } else {
      toast.error(
        "You are not a member of this brand. Add yourself via the Brands page."
      );
    }
  };

  const handleMembershipChange = async (membership, item) => {
    try {
      const result = await changeMembership({ id: membership.id });
      if (result.data.brand_id === membership.brand_id) {
        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        redirectToGroup(item.id);
      } else {
        console.error("Failed to change membership");
      }
    } catch (error) {
      console.error("Error changing membership:", error);
    }
  };

  const redirectToGroup = (itemId) => {
    window.location.href = `${window.location.origin}/groups/${itemId}`;
  };

  return (
    <SinglePageLayoutWithData
      pageSwitch={pageSwitch}
      entityName="group"
      pageTitle="Groups"
      pageName="Groups"
      sortPathName="name"
      sortPathDate="created_at"
      filterOptions={filterOptions}
      filterPath="owner_user.full_name"
      tableColumns={tableColumns}
      rowActions={cardDropDownOptions}
      tableRowDropDownOptions={cardDropDownOptions}
      asyncActions={actions}
      onTableCellClick={handleTableCellClick}
      tableOnly
      getItems={getGroups}
      updateModal={CreateOrUpdateGroupModal}
    />
  );
}
