import React from "react";

export const SummaryGraphSkeleton = () => {
  return (
    <div className="space-y-8">
      {/* Insights Skeleton */}

      {/* Graph Skeleton */}
      <div className="h-[500px] rounded-lg p-4">
        <div className="h-full w-full bg-neutral_200 rounded animate-pulse"></div>
      </div>
      <div className="pt-8 space-y-4 border-t border-light_border">
        <div className="flex items-start space-x-6 p-4">
          {[1, 2, 3].map((index) => (
            <div key={index} className="flex-1 rounded-lg pr-2">
              <div className="h-6 w-32 bg-neutral_200 rounded mb-3 animate-pulse"></div>
              <div className="space-y-2">
                <div className="h-4 w-full bg-neutral_200 rounded animate-pulse"></div>
                <div className="h-4 w-3/4 bg-neutral_200 rounded animate-pulse"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
