import React, { ReactNode } from "react";
import OnboardingStepsButtons, {
  OnboardingStepsButtonsProps,
} from "./OnboardingStepsButtons";

interface OnboardingStepContainerProps extends OnboardingStepsButtonsProps {
  title: string;
  description?: string;
  children: ReactNode;
  isBrand?: boolean;
}

export default function OnboardingStepContainer({
  currentStep,
  title,
  description,
  children,
  isBrand,
  ...rest
}: OnboardingStepContainerProps) {
  return (
    <div className="flex flex-col items-center h-full w-full">
      {isBrand ? null : (
        <OnboardingStepsButtons currentStep={currentStep} {...rest} />
      )}
      <div className="text-center">
        {!isBrand ? (
          <p className="text-3xl mt-8 sm:mt-16">Step {currentStep}</p>
        ) : null}
        {title ? <p className="text-3xl mb-4 sm:mb-8">{title}</p> : null}
        {description ? <p className="mb-8 w-[318px]">{description}</p> : null}
      </div>
      {children}
    </div>
  );
}
