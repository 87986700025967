import React, { useCallback, useContext } from "react";
import SinglePageLayoutWithData from "../../../src/layouts/SinglePageLayoutWithData";
import { getCollaboratorsList } from "../../api/Collaborators";
import { Sort } from "@types";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isFirstColumn: true,
    linkTarget: "_blank",
  },
  {
    key: "campaign_name",
    label: "Campaign Name",
    isLink: true,
    linkURL: "/campaigns/{campaign_id}",
    linkTarget: "_blank",
  },
  {
    key: "accepted_at",
    label: "Accepted At",
    isDate: true,
  },
];

const CampaignCollaboratorsList = () => {
  const { trackEvent } = useContext(MixpanelContext);

  const fetchCollaborators = useCallback(
    async (page?: number, sort?: Sort, params?: { search?: string }) => {
      const { search } = params;
      const response = await getCollaboratorsList(page, sort, {
        creator_firstName_or_creator_lastName_cont: search,
      });
      const { participants: items } = response.data;
      trackEvent(EVENTS.PAGE_VIEW.COLLABORATORS, { section: 'campaigns'});

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <div>
      <SinglePageLayoutWithData
        entityName="creator"
        cardType="individual"
        tableOnly
        pageName="Campaign Participants"
        sortPathName="creator_lastName"
        sortPathDate="accepted_at"
        filterPath="state"
        tableColumns={tableColumns}
        hideCreateNewButton
        getItems={fetchCollaborators}
      />
    </div>
  );
};

export default CampaignCollaboratorsList;
