import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

import {
  QuestionnaireQuestion,
  QuestionnaireQuestionRequest,
} from "types/questionnaire";

const listAmbassadorQuestions = async (params?: {
  q?: {
    brand_id_eq?: number;
    question_id_eq?: number;
  };
}): Promise<QuestionnaireQuestion[]> => {
  const queryParams = new URLSearchParams();

  if (params?.q?.brand_id_eq) {
    queryParams.append("q[brand_id_eq]", params.q.brand_id_eq.toString());
  }
  if (params?.q?.question_id_eq) {
    queryParams.append("q[question_id_eq]", params.q.question_id_eq.toString());
  }

  queryParams.append("per_page", "1000");

  const response = await apiWrapper(
    `/ambassador_questions?${queryParams.toString()}`,
    "GET"
  );

  return response.data.ambassador_questions;
};

const createAmbassadorQuestion = async (
  params: QuestionnaireQuestionRequest
): Promise<ApiResponse<QuestionnaireQuestion>> => {
  return apiWrapper("/ambassador_questions", "POST", params);
};

const updateAmbassadorQuestion = async (
  question_id: number,
  params: Partial<QuestionnaireQuestionRequest>
): Promise<ApiResponse<QuestionnaireQuestion>> => {
  return apiWrapper(`/ambassador_questions/${question_id}`, "PUT", params);
};

const deleteAmbassadorQuestion = async (question_id: number) => {
  return apiWrapper(`/ambassador_questions/${question_id}`, "DELETE");
};

const showAmbassadorQuestion = async (
  question_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/ambassador_questions/${question_id}`, "GET");
};

export {
  listAmbassadorQuestions,
  createAmbassadorQuestion,
  updateAmbassadorQuestion,
  deleteAmbassadorQuestion,
  showAmbassadorQuestion,
};
