import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import FDropdown from "@components/FDropdown";

import { CampaignContext } from "@contexts/index";

import { Participant, ParticipantStatus } from "@types";

import { getOverviewData } from "@api/Profile";

import { searchPosts } from "@api/Search";
import FButton from "@components/FButton";

import useAuth from "@hooks/useAuth";

import ProfileNav from "@components/Profile/ProfileNav";
import Rationale from "./fragments/Rationale";
import { Creator } from "@apiTypes";
import {
  getParticipantSampleContent,
  updateCampaignParticipant,
} from "@api/Campaign/CampaignParticipants";
import NavigationButtons from "@components/buttons/NavigationButtons";
import toast from "react-hot-toast";
import CreatorReviewModalContainer from "../components/CreatorReviewModalContainer";
import { capitalize, isEmpty, omit, partition } from "lodash";
import ResultsGrid from "@components/ResultsGrid";
import BlankState from "@components/BlankState";
import FSubNav from "@components/FSubNav";
import CreatorReviewComments from "./fragments/CreatorReviewComments";
import { LazyRouteContent } from "@components/navigation/LazyRouteContent";
import ContentHighlight from "@components/media/content-highlight";
import { CREATOR_REVIEW_STATUSES } from "../constants";

// TODO: Remove this
const MOCK_PERMALINK_CONTENT = [
  {
    permalink: "https://www.instagram.com/reel/DDAYz9BupN2",
    platform: "instagram",
    isPermalink: true,
    id: 1,
  },
  {
    permalink: "https://www.instagram.com/reel/DC6L7nSOfc1",
    isPermalink: true,
    platform: "instagram",
    id: 2,
  },
];

const getContentForPermalinks = (permalinkContent) =>
  permalinkContent.map((item) => ({
    ...item,
    permalink_url: item.permalink,
  }));

const TABS = [
  {
    value: "content",
  },
  {
    value: "comments",
  },
];

interface CreatorReviewModalProps {
  onClose: () => void;
  onUpdate: (updates: Partial<Participant>) => void;
  stateFilter: ParticipantStatus | null;
}

export default function CreatorReviewModal({
  onClose,
  onUpdate,
  stateFilter,
}: CreatorReviewModalProps) {
  const navigate = useNavigate();

  const { campaign_id, participant_id } = useParams<{
    participant_id: string;
    campaign_id: string;
  }>();

  const { campaign } = useContext(CampaignContext);

  const { participants: allParticipants } = campaign;

  const [userDetails, setUserDetails] = useState<Creator>(null);
  const [sampleContent, setSampleContent] = useState([]);

  const [loading, setLoading] = useState(true);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [activeContentIndex, setActiveContentIndex] = useState(0);

  const [activeTab, setActiveTab] = useState(TABS[0].value);

  const { user } = useAuth();

  const participants = allParticipants.filter(
    ({ status }) => !stateFilter || status === stateFilter
  );

  const currentIndex = participants.findIndex(
    (p) => p.id === Number(participant_id)
  );

  const currentParticipant = participants[currentIndex];

  const { creator } = currentParticipant;

  const { id, firstName, lastName, slug } = creator;

  const handleUpdateStatus = async (status: string) => {
    try {
      await updateCampaignParticipant(participant_id, {
        status,
      });

      onUpdate({
        status,
      });

      toast.success("Status updated");

      handleGoToIndex((currentIndex + 1) % participants.length);
    } catch {
      toast.error("Error updating status");
    }
  };

  const handleGoToIndex = (index: number) => {
    handleGoToParticipant(participants[index]);
  };

  const handleGoToParticipant = (participant: Participant) =>
    navigate(`/campaigns/${campaign_id}/participant-review/${participant.id}`);

  useEffect(() => {
    setLoading(true);
    setUserDetails(null);

    const loadData = async () => {
      const topContentResponse = await searchPosts({
        creator_ids: [id],
        page_size: 10,
        post_type: "video",
      });

      const allSampleContent = [...topContentResponse.data.results];

      const [permalinkContent, platformContent] = partition(
        allSampleContent,
        "isPermalink"
      );

      /*  setSampleContent([
        ...getContentForPermalinks(permalinkContent),
        ...platformContent,
      ]); */

      const overviewResponse = await getOverviewData(slug);

      setUserDetails(overviewResponse.data);

      try {
        const response = await getParticipantSampleContent(participant_id);

        const { contents } = response.data;

        setSampleContent(contents);

        if (isEmpty(contents)) {
          setIsCollapsed(true);
        }
      } catch {
        setIsCollapsed(true);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [participant_id, id, slug]);

  const profile = {
    ...currentParticipant,
    ...omit(creator, "id"),
    first_name: firstName,
    last_name: lastName,
    platforms: userDetails?.platforms || [],
  };

  const navActions = profile ? (
    <div className="w-full px-[40px] relative z-50">
      <div className="py-[14px] border-b w-full flex flex-row justify-between border-light_border">
        <FButton
          className="border-neutral_400 hover:border-neutral_600"
          label="Back"
          iconLeft={{ name: "undo", size: 18 }}
          onClick={onClose}
          style={{ background: "transparent" }}
        />
        <div className="flex flex-row items-center justify-end gap-4">
          <FDropdown
            options={CREATOR_REVIEW_STATUSES}
            selectedValue={profile.status}
            onChange={handleUpdateStatus}
            height={40}
            width="max-content"
            className="text-[15px]"
          />
          <NavigationButtons
            currentIndex={currentIndex}
            items={participants}
            onGoToIndex={handleGoToIndex}
          />
        </div>
      </div>
    </div>
  ) : null;

  return (
    <CreatorReviewModalContainer onClose={onClose}>
      <div
        className={`grid ${
          isCollapsed ? "grid-cols-[40px_1fr]" : "grid-cols-[370px_1fr]"
        }`}
      >
        <ContentHighlight
          data={sampleContent}
          limit={null}
          highlightRight={false}
          collapsed={isCollapsed}
          onChangeCollapsed={setIsCollapsed}
          currentSlide={activeContentIndex}
          onSlideChange={(index) => setActiveContentIndex(index)}
        />
        <div
          className={`w-full overflow-x-auto mb-[24px] ${user ? "" : "pl-[235px]"}`}
        >
          {navActions}
          {profile ? (
            <ProfileNav
              profile={profile}
              user={user}
              isValidProfileHash={false}
              readOnly
            />
          ) : null}
          <div className="px-[40px]">
            <FSubNav
              className=""
              items={TABS.map((item) => ({
                ...item,
                label: capitalize(item.value),
                active: item.value === activeTab,
              }))}
              onChange={(value) => setActiveTab(value as string)}
            />
            {/*  <div className="border-t border-light_border mb-3"></div> */}
            <LazyRouteContent isActive={activeTab === "content"}>
              <>
                <div className="mt-3" />
                <Rationale
                  profile={profile}
                  onUpdate={(description) =>
                    onUpdate({
                      creator_review_description: description,
                    })
                  }
                />
                <div className="border-t border-light_border mt-4 mb-9" />
                {!isEmpty(sampleContent) ? (
                  <ResultsGrid
                    cardData={sampleContent}
                    cardType="content"
                    onCardClick={(content) =>
                      setActiveContentIndex(
                        sampleContent.findIndex(
                          (item) => item.id === content.id
                        )
                      )
                    }
                    highlightedId={sampleContent[activeContentIndex].id}
                  />
                ) : (
                  !loading && (
                    <BlankState
                      title="No sample content found"
                      subtitle="Try selecting items from creator's content in the profile section or add permalinks"
                      icon="content"
                      actionLabel="Add Sample Content"
                      onActionClick={() =>
                        navigate(
                          `/campaigns/${campaign_id}/participants/${participant_id}/content?showSampleContent=true`
                        )
                      }
                    />
                  )
                )}
              </>
            </LazyRouteContent>
            <LazyRouteContent isActive={activeTab === "comments"}>
              <CreatorReviewComments />
            </LazyRouteContent>
          </div>
        </div>
      </div>
    </CreatorReviewModalContainer>
  );
}
