import { useEffect, useState } from "react";
import FMultiSelect from "@components/FMultiSelect";
import { FDropdownOption } from "@components/FDropdown";
import { AdvancedSearchParams } from "@pages/search/types";
import { getGroupList } from "@api/Groups/Groups";

interface GroupFilterProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
  isExclude?: boolean;
}

const GroupFilter = ({
  filters,
  onFiltersUpdate,
  isExclude,
}: GroupFilterProps) => {
  const [groups, setGroups] = useState<FDropdownOption[]>([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const { data } = await getGroupList(false, 1, null);
        const formattedGroups = data.groups.map((group) => ({
          value: group.id,
          label: group.name,
        }));
        setGroups(formattedGroups);
      } catch (error) {
        console.error("Failed to load groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const key = isExclude ? "exclude_list" : "list";

  return (
    <FMultiSelect
      selected={filters[key]}
      options={groups}
      onChange={(value) => onFiltersUpdate({ [key]: value })}
      label={isExclude ? "Exclude Groups" : "Groups"}
      placeholder="Select all that apply"
    />
  );
};

export default GroupFilter;
