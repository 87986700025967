interface FormLabelProps {
  id?: string;
  label: string;
  required?: boolean;
}

const FormLabel = ({ id, label, required }: FormLabelProps) => (
  <div className="flex items-center">
    <label className="text-sm text-black leading-[18px]" htmlFor={id || label}>
      {label}
      {required ? "*" : ""}
    </label>
  </div>
);

export default FormLabel;
