import { Campaign } from "@types";
import { getContentReviewSubpages } from "../subpages";
import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";

interface ContentReviewSubNavProps {
  campaign: Campaign;
  selectedValue?: string;
  onChange?: (value: string) => void;
}

const ContentReviewSubNav = ({
  campaign,
  selectedValue,
  onChange,
}: ContentReviewSubNavProps) => {
  const { can } = useAuth();

  const subpages = getContentReviewSubpages(campaign).filter(({ value }) => {
    if (["attribution", "attributed-content"].includes(value)) {
      return can(Permission.VIEW_CONTENT_ATTRIBUTION);
    }

    return true;
  });

  return (
    <div className="bg-[#F7EFEE] h-[38px] w-full flex items-center overflow-x-auto gap-[40px] pl-2">
      {subpages.map((item, index) => {
        const key = `${item.value}-${index}`;

        const isSelected = item.value === selectedValue;

        return (
          <p
            key={key}
            className={`cursor-pointer py-[7px] text-center text-normal whitespace-nowrap ${isSelected ? "selected" : ""} text-${isSelected ? "black" : "default_weak"}`}
            onClick={() => onChange(item.value)}
          >
            {item.label}
          </p>
        );
      })}
    </div>
  );
};

export default ContentReviewSubNav;
