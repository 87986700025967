import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import apiClient from "@apiClient";

import LoadingSpinner from "@components/LoadingSpinner";
import Table from "@components/Table";
import FButton from "@components/FButton";
import { UserContext } from "@contexts/index";

import {
  getAdminBrand,
  getAdminBrandUsers,
  getAdminBrandCampaigns,
} from "@api/Admin/AdminBrands";
import { create } from "@api/Memberships";
import toast from "react-hot-toast";
const { memberships } = apiClient;

import CreateBrandUserModal from "../components/CreateBrandUserModal";
import CreateOrUpdateBrandModal from "../components/CreateOrUpdateBrandModal";

const BrandAdmin = () => {
  const { brand_id } = useParams();
  const { user } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState(null);
  const [brandUsers, setBrandUsers] = useState([]);
  const [brandCampaigns, setBrandCampaigns] = useState([]);
  const [isBrandUsersLoading, setBrandUsersLoading] = useState(true);
  const [isBrandCampaignsLoading, setBrandCampaignsLoading] = useState(true);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isUserModalVisible, setUserModalVisible] = useState(false);

  const memberStatus = user.memberships.find(
    (membership) => membership.brand_id === parseInt(brand_id)
  );

  const campaignsTableColumns = [
    { key: "title", label: "Title", isLink: true, linkURL: "/campaigns/{id}" },
    { key: "status", label: "Status", isStateBubble: true },
    { key: "budget", label: "Budget", isMoney: true, defaultLabel: "No budget specified" },
    { key: "go_live_start", label: "Start Date", isDate: true, nestedKey: "brief" },
    { key: "go_live_end", label: "End Date", isDate: true, nestedKey: "brief" },
    { key: "full_name", label: "Manager", nestedKey: "owner_user" },
  ];

  const fetchBrand = async () => {
    const response = await getAdminBrand(parseInt(brand_id));
    setBrand(response.data);
    setLoading(false);
  };

  const fetchBrandUsers = async () => {
    const response = await getAdminBrandUsers(parseInt(brand_id));
    setBrandUsers(response.data.brand_users);
    setBrandUsersLoading(false);
  };

  const fetchBrandCampaigns = async () => {
    const response = await getAdminBrandCampaigns(parseInt(brand_id));
    setBrandCampaigns(response.data.campaigns);
    setBrandCampaignsLoading(false);
  };

  const handleCreateMembership = async () => {
    try {
      await memberships.create({brand_id: brand_id, user_id: user.id, account_id: user.account_id });
      toast.success("You have been added to the brand!");
      setTimeout(() => window.location.reload(), 2000);
    } catch {
      toast.error("Failed to add membership.");
    }
  };

  const handleUpdateBrand = () => {
    setEditModalVisible(false);
    fetchBrand();
  };

  const handleCreateBrandUser = () => {
    setUserModalVisible(false);
    fetchBrandUsers();
  };

  useEffect(() => {
    fetchBrand();
    fetchBrandUsers();
    fetchBrandCampaigns();
  }, []);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  }

  return (
    <div className="flex flex-col gap-8 p-6 bg-gray-50">
      {/* Brand Overview */}
      <section className="p-6">
        <div className="flex justify-between items-center border-b pb-4 mb-4">
          <h1 className="text-2xl font-semibold">{brand?.name}</h1>
          {!memberStatus && (
            <FButton
              onClick={handleCreateMembership}
              label="Add Me to Brand"
              primary
            />
          )}
        </div>
        <p className="text-gray-600 mb-4">
          {memberStatus
            ? `You are a member of ${brand.name}.`
            : `You are not a member of ${brand.name}.`}
        </p>
        <table className="w-1/2 text-sm">
          <tbody>
            {[
              ["Parent Brand", brand?.parent_brand_name || "N/A"],
              ["Instagram Handle", brand?.instagram_handle || "N/A"],
              ["TikTok Handle", brand?.tiktok_handle || "N/A"],
              ["Created At", moment(brand?.created_at).format("MMM DD, YYYY")],
              ["Expires At", moment(brand?.expires_at).format("MMM DD, YYYY")],
              ["Users", `${brandUsers.length} users`],
              ["Campaigns", `${brandCampaigns.length} campaigns`],
            ].map(([label, value], idx) => (
              <tr key={idx} className="border-b">
                <td className="py-2 px-4 font-medium text-gray-800">{label}</td>
                <td className="py-2 px-4 text-gray-600">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <FButton
          className="mt-4"
          onClick={() => setEditModalVisible(true)}
          label="Edit Brand"
          primary
        />
      </section>

      {/* Brand Users */}
      <section className="p-6">
        <h2 className="text-xl font-semibold mb-4">Brand Users</h2>
        {isBrandUsersLoading ? (
          <LoadingSpinner className="w-full" />
        ) : brandUsers.length > 0 ? (
          <table className="w-1/2 border-collapse text-sm">
            <thead>
              <tr className="border-b">
                <th className="py-2 px-4 text-left font-medium">Name</th>
                <th className="py-2 px-4 text-left font-medium">Email</th>
              </tr>
            </thead>
            <tbody>
              {brandUsers.map((user) => (
                <tr key={user.id} className="border-b">
                  <td className="py-2 px-4 text-gray-800">{user.name}</td>
                  <td className="py-2 px-4 text-gray-600">{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-600">This brand has no users.</p>
        )}
        <FButton
          className="mt-4"
          onClick={() => setUserModalVisible(true)}
          label="Add User"
          primary
        />
      </section>

      {/* Brand Campaigns */}
      <section className="p-6">
        <h2 className="text-xl font-semibold mb-4">Brand Campaigns</h2>
        {isBrandCampaignsLoading ? (
          <LoadingSpinner className="w-full" />
        ) : brandCampaigns.length > 0 ? (
          <Table data={brandCampaigns} columns={campaignsTableColumns} />
        ) : (
          <p className="text-gray-600">This brand has no campaigns.</p>
        )}
      </section>

      {/* Modals */}
      {isEditModalVisible && (
        <CreateOrUpdateBrandModal
          onClose={() => setEditModalVisible(false)}
          onCreated={handleUpdateBrand}
          entity={brand}
        />
      )}
      {isUserModalVisible && (
        <CreateBrandUserModal
          isOpen={isUserModalVisible}
          onClose={() => setUserModalVisible(false)}
          onCreated={handleCreateBrandUser}
          brandId={parseInt(brand_id)}
        />
      )}
    </div>
  );
};

export default BrandAdmin;
