import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";
import Heading from "@components/Heading";
import { allSearchCategories } from "@constants/constants";
import React, { useEffect, useState, useRef, useContext } from "react";

import {
  capitalize,
  isArray,
  isEmpty,
  isObject,
  isPlainObject,
  size,
} from "lodash";

import { CreatorFromSearch } from "@apiTypes";
import ResultsGrid from "@components/ResultsGrid";
import { searchCreators, searchPosts } from "@api/Search";

import { CREATOR_CATEGORIES, POST_CATEGORIES } from "@api/Search";
import useDebouncedValue from "@hooks/useDebouncedValue";

import FPagination from "@components/FPagination";

import GeneralPostModal from "@components/Modals/GeneralPostModal";
import { useNavigate } from "react-router-dom";

import BlankState from "@components/BlankState";
import FButton from "@components/FButton";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import { Content } from "@types";
import CreateReportModal from "@components/Modals/CreateReportModal";
import useAuth from "@hooks/useAuth";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { Permission } from "@constants/roles";
import { FCheckbox } from "@components/FInputs";
import AdvancedCreatorSearchModal from "./advanced/AdvancedCreatorSearchModal";
import { AdvancedSearchParams } from "./types";
import { getSearchString } from "@utils/history";
import AdvancedContentSearchModal from "./advanced/AdvancedContentSearchModal";
import SearchResultsSkeleton from "@components/Search/SearchResultsSkeleton";
import { categoryImages, contentCategoryImages } from "./constants";
import toast from "react-hot-toast";
import {
  createBulletinRecipient,
  createBulletinRecipientFromSearch,
} from "@api/Bulletins/BulletinRecipients";
import PlatformSelect from "./advanced/components/PlatformSelect";
import { formatNumber } from "@utils/number_utilities";
import { MEDIA_PRODUCT_TYPES } from "@constants/content";

const getOptions = (categories: {
  [key: number]: string;
}): { value: string; label: string }[] =>
  Object.keys(categories).map((key) => ({
    value: key,
    label: categories[key],
    icon: allSearchCategories.find((category) => category.value === `${key}`)
      ?.icon,
  }));

const DEFAULT_FILTERS = {
  api_connected: false,
  media_product_type: null,
  searchTerm: null,
  selectedPlatforms: [],
  selectedCategories: [],
  page: 1,
  resource: "creators",
  sponsored: true,
  organic: true,
};

const DEFAULT_AUDIENCE_FILTERS = {
  audience_age: [],
  audience_gender: [],
  audience_ethnicity: [],
  audience_marital_status: [],
  audience_parental_status: "",
  audience_income: [],
  audience_custom_locations: {},
};

const DEFAULT_ADVANCED_FILTERS = {
  custom_locations: {},
  custom_followers: {},
  custom_age_range: {},
  date_from: "",
  date_to: "",
  gender: [],
  ethnicity: [],
  previously_contracted: false,
  api_connected: false,
  address_state: [],
  address_country: [],
  managed: "",
  target_opportunities: [],
  children: "",
  relationship_status: [],
  sexual_orientation: [],
  tags: [],
  list: [],
  exclude_list: [],
  ...DEFAULT_AUDIENCE_FILTERS,
};

const areAdvancedFiltersEmpty = (advancedFilters: AdvancedSearchParams) => {
  const a = Object.keys(advancedFilters).every((key) => {
    const value = advancedFilters[key];

    if (isObject(value)) {
      return isEmpty(value);
    }
    return !value;
  });

  return a;
};

const getDefaultFilters = (searchParams, initialValue) => {
  const result = Object.keys(initialValue).reduce((result, key) => {
    const paramValue = searchParams.get(key);

    if (paramValue) {
      let value = isArray(initialValue[key])
        ? paramValue.split(",")
        : paramValue;

      if (["list", "exclude_list"].includes(key)) {
        value = value.map((item) => parseInt(item, 10));
      }

      if (value && isPlainObject(initialValue[key])) {
        value = JSON.parse(value);
      }

      return {
        ...result,
        [key]: value,
      };
    } else {
      return result;
    }
  }, initialValue);

  return result;
};

// Add this helper function to reorder categories
const reorderCategories = (
  categories: typeof allSearchCategories,
  selectedValue: string
) => {
  const category = categories.find((c) => c.value === selectedValue);
  if (!category) return categories;

  const otherCategories = categories.filter((c) => c.value !== selectedValue);
  return [category, ...otherCategories];
};

export default function Search() {
  const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const [creators, setCreators] = useState<CreatorFromSearch[]>([]);
  const [posts, setPosts] = useState<Content[]>([]);

  const [selectedCreatorIds, setSelectedCreatorIds] = useState<number[]>([]);
  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);
  const [isCreateReportModalVisible, setCreateReportModalVisible] =
    useState(false);

  const searchParams = new URLSearchParams(window.location.search);

  const [addToBulletinId] = useState(searchParams.get("addToBulletinId"));
  const [isAddingToBulletin, setAddingToBulletin] = useState(false);

  const [
    isAdvancedCreatorSearchModalVisible,
    setAdvancedCreatorSearchModalVisible,
  ] = useState(false);

  const [
    isAdvancedContentSearchModalVisible,
    setAdvancedContentSearchModalVisible,
  ] = useState(false);

  const [total, setTotal] = useState(null);

  const [areAllCreatorsSelected, setAreAllCreatorsSelected] = useState(false);

  const navigate = useNavigate();

  const { can } = useAuth();

  let resources = [];

  if (!isAddingToBulletin) {
    resources = [{ label: "Content", icon: "content", value: "content" }];
  }

  if (can(Permission.SEARCH_INFLUENCERS)) {
    resources.push({ label: "Creators", icon: "audience", value: "creators" });
  }

  const defaultFilters = getDefaultFilters(searchParams, DEFAULT_FILTERS);

  if (!resources.find(({ value }) => value === defaultFilters.resource)) {
    defaultFilters.resource = resources[0].value;
  }

  const [filters, setFilters] = useState(defaultFilters);

  const [advancedFilters, setAdvancedFilters] = useState(
    getDefaultFilters(searchParams, DEFAULT_ADVANCED_FILTERS)
  );

  const {
    resource,
    searchTerm,
    selectedCategories,
    selectedPlatforms,
    page,
    media_product_type,
    sponsored,
    organic,
  } = filters;

  const debouncedSearchTerm = useDebouncedValue(
    searchTerm !== null ? searchTerm : searchParams.get("searchTerm"),
    500
  );

  const [activePost, setActivePost] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const { trackEvent } = useContext(MixpanelContext);

  // Add state for reordered categories
  const [orderedCategories, setOrderedCategories] =
    useState(allSearchCategories);

  useEffect(() => {
    trackEvent(EVENTS.PAGE_VIEW.SEARCH);
  }, []);

  useEffect(() => {
    const handleSearch = async () => {
      const queryParams = getSearchString({
        searchTerm: debouncedSearchTerm,
        page,
        selectedPlatforms,
        selectedCategories,
        media_product_type,
        resource,
        ...advancedFilters,
      });

      navigate(`/search?${queryParams.toString()}`);

      if (
        !debouncedSearchTerm &&
        isEmpty(selectedCategories) &&
        isEmpty(selectedPlatforms) &&
        areAdvancedFiltersEmpty(advancedFilters)
      ) {
        setCreators([]);
        setPosts([]);

        return;
      }

      setLoading(true);

      if (resource === "content") {
        const response = await searchPosts({
          searchTerm: debouncedSearchTerm,
          platforms: selectedPlatforms,
          categories: selectedCategories,
          sponsored,
          organic,
          media_product_type,
          p: page,
          ...advancedFilters,
        });

        const { results, total } = response.data;

        setPosts(results);
        setTotal(total);

        setLoading(false);
        trackEvent("Search Performed", {
          search_type: resource,
          total_results: total,
        });

        return;
      }

      const response = await searchCreators({
        ...advancedFilters,
        p: page,
        searchTerm: debouncedSearchTerm,
        platforms: selectedPlatforms,
        tags: selectedCategories,
        media_product_type,
      });
      const { results, total } = response.data;

      setCreators(results);
      setTotal(total);

      setLoading(false);
      trackEvent("Search Performed", {
        search_type: resource,
        total_results: total,
      });
    };

    handleSearch();
  }, [
    debouncedSearchTerm,
    resource,
    selectedCategories,
    selectedPlatforms,
    page,
    advancedFilters,
    sponsored,
    organic,
    media_product_type,
    navigate,
    trackEvent,
  ]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  // Update the searchCategories calculation
  const searchCategories = getOptions(
    resource === "content" ? POST_CATEGORIES : CREATOR_CATEGORIES
  ).sort((a, b) => {
    // Keep selected items at the front
    const aSelected = selectedCategories.includes(a.value);
    const bSelected = selectedCategories.includes(b.value);
    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return 0;
  });

  const handleContentModalClick = (content) => {
    setActivePost(content);
    setContentModalOpen(true);
  };

  const handleContentModalClose = () => {
    setContentModalOpen(false);
    setActivePost(null);
  };

  const handleToggleItemSelection = (id) => {
    let newSelectedItems = selectedCreatorIds || [];

    if (selectedCreatorIds.includes(id)) {
      newSelectedItems = newSelectedItems.filter((item) => item !== id);
    } else {
      newSelectedItems = [...selectedCreatorIds, id];
    }

    setSelectedCreatorIds(newSelectedItems);
  };

  const handleAddToGroup = () => setAddToGroupModalVisible(true);

  const handleAddToBulletin = async () => {
    try {
      setAddingToBulletin(true);

      if (areAllCreatorsSelected) {
        await createBulletinRecipientFromSearch({
          ...advancedFilters,
          bulletin_id: parseInt(addToBulletinId, 10),
          resource,
          searchTerm,
          platforms: selectedPlatforms,
          tags: selectedCategories,
        });
      } else {
        const promises = selectedCreatorIds.map((creator_id) => {
          return createBulletinRecipient({
            bulletin_id: parseInt(addToBulletinId, 10),
            creator_id,
          });
        });

        await Promise.all(promises);
      }

      setAddingToBulletin(false);

      toast.success("Added");

      navigate(`/bulletins/${addToBulletinId}/recipients`);
    } catch (e) {
      toast.error("Error adding to bulletin");
    } finally {
      setAddingToBulletin(false);
    }
  };

  const isEmptySearch =
    !debouncedSearchTerm &&
    isEmpty(selectedCategories) &&
    isEmpty(selectedPlatforms);

  const showAPIConnection =
    selectedPlatforms.length > 0 || resource === "content";

  const isInstagram =
    size(selectedPlatforms) === 1 && selectedPlatforms[0] === "instagram";

  return (
    <div className="pb-16">
      <div className="w-full mb-20">
        <Heading
          title={"Search & Discover"}
          className="pb-8"
          headerButtons={
            <div className="flex gap-4">
              {selectedCreatorIds.length > 0 &&
              resource === "creators" &&
              !addToBulletinId ? (
                <FButton
                  onClick={handleAddToGroup}
                  label={`(${selectedCreatorIds.length}) Add to group`}
                  width="max-content"
                ></FButton>
              ) : null}

              {posts.length > 0 ? (
                <FButton
                  onClick={() => setCreateReportModalVisible(true)}
                  label={`Create Report`}
                  width="max-content"
                ></FButton>
              ) : null}
              <FButton
                label="Advanced Search"
                primary
                iconLeft={{ name: "filter", size: 14, color: "#fff" }}
                onClick={() =>
                  resource === "creators"
                    ? setAdvancedCreatorSearchModalVisible(true)
                    : setAdvancedContentSearchModalVisible(true)
                }
              />
              {addToBulletinId ? (
                <FButton
                  label={`Add to Bulletin ${areAllCreatorsSelected ? `(${total})` : selectedCreatorIds.length ? `(${selectedCreatorIds.length})` : ""}`}
                  disabled={
                    !areAllCreatorsSelected && isEmpty(selectedCreatorIds)
                  }
                  primary
                  onClick={handleAddToBulletin}
                  loading={isAddingToBulletin}
                />
              ) : null}
            </div>
          }
        />
      </div>
      <div className="flex items-center border-b border-b-dark gap-[12px] pb-[10px]">
        <div className="flex items-center">
          <FIcon
            icon={resources.find(({ value }) => value === resource).icon}
            size={18}
            color="#000F45"
            className="mr-[10px] -mt-[2px]"
          />
          <FDropdown
            options={resources}
            transparent
            height={30}
            width="110px"
            className="text-[18px] w-auto"
            selectedValue={resource}
            titleDropDown={true}
            onChange={(value) => {
              setFilters({
                ...filters,
                page: 1,
                resource: value as string,
                selectedCategories: [],
              });
            }}
          />
        </div>
        {/* search */}
        <div className="w-[1px] h-[30px] bg-dark_night_sky" />
        <div className="flex items-center w-full">
          <FIcon icon="search" size={20} color="#000F45" className="mb-2" />
          <input
            ref={searchInputRef}
            className="pl-[16px] bg-transparent border-none w-full h-[30px] text-[20px] outline-none "
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setFilters({
                ...filters,
                page: 1,
                searchTerm: e.target.value,
              });
            }}
            placeholder="Search for one or more keywords, hashtags, or mentions, and any matching results will appear."
          />
          {searchTerm || selectedCategories.length > 0 ? (
            <div
              onClick={() => {
                setFilters({
                  ...filters,
                  page: 1,
                  searchTerm: "",
                  selectedCategories: [],
                });
              }}
              className="cursor-pointer h-[30px] w-auto px-4 gap-1 flex items-center justify-center mr-4 bg-highlight_red rounded-full"
            >
              <FIcon icon="exit" size={12} color="#000" className="-mt-[1px]" />
              <p className="text-[12px] text-dark_night_sky">Clear</p>
            </div>
          ) : null}
          {/* dropdown */}
          {/* <div className="min-w-[1px] max-w-[1px] w-[1px] h-[30px] bg-dark_night_sky mr-4" /> */}
          {/* divider */}
          <div className="w-[1px] h-[30px] bg-dark_night_sky ml-2 mr-[4px]" />
        </div>
        {/* platforms */}
        <PlatformSelect
          selectedPlatforms={selectedPlatforms}
          onChange={(newSelectedPlatforms) => {
            setFilters({
              ...filters,
              page: 1,
              selectedPlatforms: newSelectedPlatforms,
              media_product_type: null,
            });

            if (isEmpty(newSelectedPlatforms)) {
              setAdvancedFilters({
                ...advancedFilters,
                ...DEFAULT_AUDIENCE_FILTERS,
              });
            }
          }}
        />
      </div>
      <div className="relative mt-[16px]">
        <div
          className={`flex flex-wrap items-center gap-[10px] ${
            !categoriesExpanded ? "h-[35px]" : "h-full"
          } overflow-hidden w-[calc(100%-40px)]`}
        >
          <FIcon
            icon="tag"
            size={18}
            color="#000721"
            className="mr-[8px] -mt-[2px]"
          />
          {searchCategories.map((category) => {
            return (
              <p
                className={`cursor-pointer border border-neutral_400 rounded-full px-[13px] pb-[2px] pt-[4px] text-[14px]
                ${selectedCategories.indexOf(category.value) !== -1 ? "bg-dark_night_sky text-white border-dark_night_sky" : ""}`}
                key={category.value}
                onClick={() => {
                  let newSelectedCategories;

                  if (selectedCategories.indexOf(category.value) !== -1) {
                    newSelectedCategories = selectedCategories.filter(
                      (c) => c !== category.value
                    );
                  } else {
                    newSelectedCategories = [
                      category.value,
                      ...selectedCategories,
                    ];
                    // Reorder categories when selecting a new one
                    setOrderedCategories(
                      reorderCategories(orderedCategories, category.value)
                    );
                  }

                  setFilters({
                    ...filters,
                    page: 1,
                    selectedCategories: newSelectedCategories,
                  });
                }}
              >
                {category.label}
              </p>
            );
          })}
          <div
            className="absolute right-1 top-[7px] cursor-pointer transition duration-300"
            style={{
              transform: categoriesExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={() => setCategoriesExpanded(!categoriesExpanded)}
          >
            <FIcon icon="chevron" size={14} color="#000721" />
          </div>
        </div>
      </div>
      {showAPIConnection || isInstagram ? (
        <div className="flex justify-between items-center mt-4">
          {showAPIConnection ? (
            <div className="flex items-center gap-4">
              {selectedPlatforms.length > 0 ? (
                <FCheckbox
                  label="API Connected"
                  id="apiConnected"
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      api_connected: value,
                    })
                  }
                  checked={filters.api_connected}
                />
              ) : null}
              {resource === "content" ? (
                <>
                  <FCheckbox
                    label="Sponsored"
                    id="sponsored"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        sponsored: value,
                      })
                    }
                    checked={filters.sponsored}
                  />
                  <FCheckbox
                    label="Organic"
                    id="organic"
                    onChange={(value) =>
                      setFilters({
                        ...filters,
                        organic: value,
                      })
                    }
                    checked={filters.organic}
                  />
                </>
              ) : null}
            </div>
          ) : null}
          {isInstagram ? (
            <FDropdown
              options={[
                { label: "All product types", value: null },
                ...MEDIA_PRODUCT_TYPES.Instagram.map((value) => ({
                  value,
                  label: capitalize(value),
                })),
              ]}
              selectedValue={filters.media_product_type}
              onChange={(value) =>
                setFilters({
                  ...filters,
                  media_product_type: value,
                })
              }
            />
          ) : null}
        </div>
      ) : null}
      {/* results */}
      {loading ? (
        <SearchResultsSkeleton
          type={resource === "content" ? "content" : "creators"}
        />
      ) : (
        <>
          <div>
            {resource === "content" ? (
              !isEmpty(posts) ? (
                <ResultsGrid
                  cardData={posts.map((post, index) => {
                    if (index === 0) {
                      return { ...post, stats: null };
                    }

                    return post;
                  })}
                  cardType="content"
                  numColumns={6}
                  onCardClick={(content) => handleContentModalClick(content)}
                />
              ) : isEmptySearch ? (
                <div className="mt-10 h-full overflow-hidden">
                  <div className="flex gap-2 pb-4">
                    {contentCategoryImages.map((category, index) => (
                      <div
                        key={index}
                        className="cursor-pointer flex-1 min-h-[50vh] relative group overflow-hidden rounded-lg shadow-lg"
                        onClick={() => {
                          if (category.tag) {
                            setFilters({
                              ...filters,
                              page: 1,
                              selectedCategories: [category.tag],
                            });
                          }
                        }}
                      >
                        <img
                          src={category.image}
                          alt="Category"
                          className="w-full h-full object-cover transition-transform duration-500 ease-out group-hover:scale-105"
                        />
                        <div className="absolute z-0 inset-x-0 bottom-0 h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0.3)] to-transparent" />
                        <div className="absolute z-10 bottom-0 left-0 right-0 p-4 text-white text-lg font-semibold capitalize">
                          {POST_CATEGORIES[category.tag]}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <BlankState
                  title={`No content found`}
                  subtitle="Try updating your search or create one"
                  icon="content"
                />
              )
            ) : !isEmpty(creators) ? (
              <>
                {addToBulletinId ? (
                  <>
                    <div className="mt-4" />
                    <FCheckbox
                      checked={areAllCreatorsSelected}
                      id="select-all-rows"
                      label="Add all creators from this search"
                      onChange={setAreAllCreatorsSelected}
                    />
                  </>
                ) : null}
                <ResultsGrid
                  cardData={creators}
                  cardType="individual"
                  isProfile={false}
                  numColumns={6}
                  selectedItems={
                    areAllCreatorsSelected
                      ? creators.map(({ id }) => id)
                      : selectedCreatorIds
                  }
                  onToggleItemSelection={handleToggleItemSelection}
                />
              </>
            ) : isEmptySearch ? (
              <div className="mt-10 h-full overflow-hidden">
                <div className="flex gap-2 pb-4">
                  {(resource === "content"
                    ? contentCategoryImages
                    : categoryImages
                  ).map((category, index) => (
                    <div
                      key={index}
                      className="cursor-pointer flex-1 min-h-[50vh] relative group overflow-hidden rounded-lg shadow-lg"
                      onClick={() => {
                        if (category.tag) {
                          setFilters({
                            ...filters,
                            page: 1,
                            selectedCategories: [category.tag],
                          });
                        }
                      }}
                    >
                      <img
                        src={category.image}
                        alt="Category"
                        className="w-full h-full object-cover transition-transform duration-500 ease-out group-hover:scale-105"
                      />
                      <div className="absolute z-0 inset-x-0 bottom-0 h-[80px] bg-gradient-to-t from-[rgba(0,0,0,0.3)] to-transparent" />
                      <div className="absolute z-10 bottom-0 left-0 right-0 p-4 text-white text-lg font-semibold capitalize">
                        {resource === "content"
                          ? POST_CATEGORIES[category.tag]
                          : CREATOR_CATEGORIES[category.tag]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <BlankState
                title="No creators found"
                subtitle="Can't find the creator you're looking for? Add them via their Instagram or TikTok handle."
                icon="user"
                actionLabel="Add Creator"
                onActionClick={() => navigate("/off-platform-creator")}
              />
            )}
          </div>
          {total &&
          total > 0 &&
          total > (resource === "content" ? posts.length : creators.length) &&
          (resource === "content" ? posts.length : creators.length) > 0 ? (
            <div className="flex flex-col items-center mt-[28px]">
              <FPagination
                page={page}
                pageSize={24}
                total={total}
                onPageChange={(page) =>
                  setFilters({
                    ...filters,
                    page,
                  })
                }
              />
              <p>
                {formatNumber(total)} {resource} found
              </p>
            </div>
          ) : null}
          {resource === "creators" ? (
            <div className="flex flex-row items-center gap-3 mt-8 ">
              <button
                className="text-dark_night_sky px-4 pt-[6px] pb-[4px] m-auto border border-red_highlight rounded-full opacity-40 hover:opacity-100 transition-all duration-300 ease-out"
                onClick={() => navigate("/off-platform-creator")}
              >
                Can't find the creator you're looking for? Add them via their
                Instagram or TikTok handle.
              </button>
            </div>
          ) : null}
          {activePost && (
            <GeneralPostModal
              post={activePost}
              isOpen={contentModalOpen}
              onClose={handleContentModalClose}
            />
          )}
          {isAddToGroupModalVisible ? (
            <AddCreatorsToGroupModal
              creatorIds={selectedCreatorIds}
              onClose={() => setAddToGroupModalVisible(false)}
            />
          ) : null}
          {isCreateReportModalVisible ? (
            <CreateReportModal
              filters={filters}
              isOpen={isCreateReportModalVisible}
              onClose={() => setCreateReportModalVisible(false)}
            />
          ) : null}
        </>
      )}
      {isAdvancedCreatorSearchModalVisible ? (
        <AdvancedCreatorSearchModal
          filters={advancedFilters as unknown as AdvancedSearchParams}
          onClose={() => {
            setAdvancedCreatorSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          onUpdateFilters={(filters) => {
            setAdvancedFilters(filters);
            setAdvancedCreatorSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          selectedPlatforms={selectedPlatforms}
        />
      ) : null}
      {isAdvancedContentSearchModalVisible ? (
        <AdvancedContentSearchModal
          filters={advancedFilters as unknown as AdvancedSearchParams}
          onClose={() => {
            setAdvancedContentSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          onUpdateFilters={(filters) => {
            setAdvancedFilters(filters);
            setAdvancedContentSearchModalVisible(false);
            searchInputRef.current?.focus();
          }}
          selectedPlatforms={selectedPlatforms}
        />
      ) : null}
    </div>
  );
}
