import React, { useState } from "react";
import FButton from "@components/FButton";
import { toast } from "react-hot-toast";
import SelectCreatorsTable from "@components/Creator/SelectCreatorsTable";
import { Creator } from "@apiTypes";

import { useParams } from "react-router-dom";

import SelectEntitiesModal from "@components/SelectEntities/SelectEntitiesModal";

import { createBulletinRecipient } from "@api/Bulletins/BulletinRecipients";

interface Props {
  onClose: () => void;
}

async function createMultipleRecipients(
  bulletin_id: number,
  creatorIds: number[]
) {
  const promises = creatorIds.map((creator_id) => {
    return createBulletinRecipient({ bulletin_id, creator_id });
  });

  return Promise.all(promises);
}

const AddRecipientsToBulletinModal = ({ onClose }: Props) => {
  const { bulletin_id } = useParams<{ bulletin_id: string }>();

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);

  const [selectedCreators, setSelectedCreators] = useState<Creator[]>([]);

  const handleSubmit = async () => {
    setWaiting(true);
    setError(null);

    let newCreator;

    const creatorsToAdd = newCreator ? [newCreator] : selectedCreators;

    try {
      const creatorIds = creatorsToAdd.map((creator) => parseInt(creator.id));

      await createMultipleRecipients(parseInt(bulletin_id), creatorIds);

      setWaiting(false);

      toast.success("Saved");

      onClose();
    } catch (error) {
      setError("Failed to add participants");
      setWaiting(false);
    }
  };

  const extraCreatorColumns = [];

  return (
    <SelectEntitiesModal
      title="Add Creators"
      onClose={onClose}
      error={error}
      actionButton={
        <FButton
          loading={waiting}
          onClick={handleSubmit}
          primary
          label="Save"
          width="100%"
          height="40px"
        />
      }
    >
      <div style={{ height: "calc(100% - 70px)" }}>
        <SelectCreatorsTable
          extraColumns={extraCreatorColumns}
          selectedItems={selectedCreators}
          onChangeSelectedItems={setSelectedCreators}
        />
      </div>
    </SelectEntitiesModal>
  );
};

export default AddRecipientsToBulletinModal;
