import React, { useState } from "react";

import FIcon from "@components/FIcon";
import RichTextEditor from "@components/RichTextEditor";

import apiClient from "@api/index";
import { CampaignBrief } from "@types";
import { updateCampaignBrief } from "@api/CampaignBriefs/CampaignBriefs";

interface Props {
  brief: CampaignBrief;
  onUpdate: (updates: Partial<CampaignBrief>) => void;
}

export default function BriefPayment({ brief, onUpdate }: Props) {
  const [file, setFile] = useState<string>(brief.invoice_template_url);

  const { id, invoice_template: invoiceTemplateUrl, sections } = brief;

  const invoiceTermsSection = sections.find(({ kind }) => kind === "payment");

  const handleFile = async (e) => {
    if (e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));

      // update campaign brief on upload
      const formData = new FormData();
      formData.append(
        `campaign_brief[${e.target.name}]`,
        e.target.files[0],
        e.target.files[0].name
      );
      await apiClient.campaign_brief.upload(brief.id, formData);
    }
  };

  const handleUpdateSection = (sectionId, updates) => {
    const newSections = sections.map((section) =>
      section.id === sectionId ? { ...section, ...updates } : section
    );

    onUpdate({ sections: newSections });
  };

  const handleRemoveInvoiceTemplate = async () => {
    if (file) {
      setFile("");

      return;
    }

    await updateCampaignBrief(id, { campaign_brief_invoice_template: "" });

    onUpdate({ invoice_template: null });
  };

  return (
    <div>
      <div className="pb-10 border-t pt-10 border-light_border">
        <div className="mb-6">
          <h3 className="text-xl mb-2">Payment Terms</h3>
          <p className="text-neutral_600 text-[15px]">
            Describe how &amp; when payments are made.
          </p>
        </div>
        <div className="w-full bg-white">
          <RichTextEditor
            value={brief.payment_terms}
            id="payment_terms"
            onChange={(value) => onUpdate({ payment_terms: value })}
          />
        </div>
      </div>
      <div className="pb-10">
        <div className="w-full flex justify-between flex-row mb-6">
          <div className="justify-center flex">
            <div>
              <h3 className="text-xl mb-2">Invoicing</h3>
              <p
                className={`text-neutral_600 text-[15px] ${
                  file ? "mb-4" : "mb-0"
                }`}
              >
                Define invoicing requirements and criteria.
              </p>
            </div>
            {(file || invoiceTemplateUrl) && (
              <div className="flex w-fit flex-row items-center gap-2 px-4 py-2 justify-between ml-2 relative bg-highlight_red rounded-lg">
                <a
                  className="text-[15px] text-dark_night_sky underline font-medium"
                  href={file || invoiceTemplateUrl}
                  target="_blank"
                  download
                >
                  Uploaded Template
                </a>
                <div
                  className="cursor-pointer"
                  onClick={handleRemoveInvoiceTemplate}
                >
                  <FIcon
                    icon="exit-2"
                    color="#000F45"
                    className="cursor-pointer"
                    size={15}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="flex h-[40px] bg-light_red border-[1px] border-dark_night_sky px-4 items-center gap-3 cursor-pointer relative">
              <FIcon
                icon="upload"
                color="#000F45"
                className="inline-block"
                size={15}
              />
              <span className="text-[15px] text-dark_night_sky font-medium ">
                Add Template
              </span>
              <input
                accept="application/pdf"
                id="invoice_template"
                name="invoice_template"
                className="block h-full w-full absolute left-0 top-0"
                type="file"
                onChange={handleFile}
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-white">
          <RichTextEditor
            id="invoice_terms"
            value={invoiceTermsSection?.body}
            onChange={(value) =>
              handleUpdateSection(invoiceTermsSection.id, { body: value })
            }
          />
        </div>
      </div>
    </div>
  );
}
