import { ApiResponse } from "@apiTypes";
import { Campaign } from "@types";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

const getCampaign = async (
  campaignId: string | number
): Promise<ApiResponse<Campaign>> => {
  return apiWrapper(`/campaigns/${campaignId}`, "GET");
};

const getCampaignList = async (
  page?: number,
  sort?: Sort,
  admin?: boolean,
  params?: object
): Promise<
  ApiResponse<{
    campaigns: Campaign[];
    total_items: number;
  }>
> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  // Check if params.admin is true
  if (admin === true) {
    return apiWrapper(`/admin/campaigns?${queryParams.toString()}`, "GET");
  } else {
    return apiWrapper(`/campaigns?${queryParams.toString()}`, "GET");
  }
};

const createCampaign = async (
  campaignData: Partial<Campaign>
): Promise<ApiResponse<Campaign>> => {
  return apiWrapper("/campaigns", "POST", campaignData);
};

const updateCampaign = async (
  campaignId: string | number,
  campaignData: Partial<Campaign>
): Promise<ApiResponse<Campaign>> => {
  return apiWrapper(`/campaigns/${campaignId}`, "PUT", campaignData);
};

const deleteCampaign = async (campaignId: string | number) => {
  return apiWrapper(`/campaigns/${campaignId}`, "DELETE");
};

const duplicateCampaign = async (campaignId: string | number) => {
  return apiWrapper(`/campaigns/${campaignId}/duplicate`, "POST");
};

export const approveBudget = (campaignId: number, comment: string) =>
  apiWrapper(`/campaigns/${campaignId}/approve_budget`, "PATCH", {
    comment,
  });

export {
  createCampaign,
  getCampaign,
  getCampaignList,
  updateCampaign,
  deleteCampaign,
  duplicateCampaign,
};
