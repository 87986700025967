import React, { useState } from "react";
import { Link } from "react-router-dom";
import FDropdown from "../../../FDropdown";
import DecorationBadge from "../../CardAssets";
import FIcon from "../../../FIcon";
import { formatLargeNumber } from "../../../../utils/number_utilities";
import { IndividualCardProps } from "@components/Cards/BasicCard";
import { FTooltip } from "../../../FTooltip";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/themes/light.css";

const IndividualCard = ({
  decoration,
  cardHeight,
  cardWidth,
  className,
  checkBoxClickList,
  onCheckBoxClick,
  onCardClick,
  cardDropDownOptions,
  ...props
}: IndividualCardProps) => {
  const [hover, setHover] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const formatSlug = (slug: string) => {
    if (!slug) return "";
    return slug
      .split(/[-_.]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
      .trim();
  };

  const ProfileImage =
    props.profile_image ||
    props.creator?.profile_image_url ||
    props.profile_image_url;

  const shouldShowDefaultIcon = ProfileImage?.endsWith("user-icon-new.jpeg");

  const displayName =
    props.full_name?.trim().toLowerCase() || formatSlug(props.slug);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`flex flex-col w-full cursor-pointer shadow-lg bg-highlight_red relative overflow-hidden h-full min-h-[400px] rounded-xl ${className} ${
        checkBoxClickList && checkBoxClickList.includes(props.id)
          ? "border-2 border-dark_night_sky"
          : "border-1 border-light_red"
      }  ${isDropdownOpen ? "z-50" : "z-10"}`}
      key={props.id}
      style={{
        minHeight: `${cardHeight}px`,
        minWidth: `${cardWidth}px`,
      }}
    >
      {!onCardClick && (
        <Link
          to={`${window.location.origin}/card/${props.creator?.slug || props.slug}`}
          className={`w-full h-full absolute top-0 left-0 right-0 bottom-0 z-[10]`}
          target="_blank"
          key={props.id}
        />
      )}
      <div
        className="w-full flex-grow relative overflow-hidden flex items-center justify-center"
        onClick={() =>
          onCardClick &&
          onCardClick({
            ...props,
          })
        }
      >
        {ProfileImage && !shouldShowDefaultIcon ? (
          <div
            className={`w-full h-full bg-cover bg-center transition-all duration-500 ease-out ${
              hover ? "scale-105" : "scale-100"
            }`}
            style={{ backgroundImage: `url(${ProfileImage})` }}
          />
        ) : (
          <div className="w-[60px] h-[60px] m-auto bg-gray-100 flex items-center bg-[rgba(255,255,255,0.2)] justify-center p-10 border-2 border-[rgba(0,0,0,0.07)] rounded-full">
            <FIcon icon="user" size={30} color="rgba(0,0,0,0.16)" />
          </div>
        )}
      </div>
      {decoration && <DecorationBadge decoration={decoration} />}

      <div className="absolute flex flex-row top-4 left-4 z-20">
        {onCheckBoxClick && (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="w-5 h-5 text-white border-light_red focus:ring-black checked:bg-dark_night_sky focus:ring-none cursor-pointer hover:border-dark_night_sky"
              checked={
                checkBoxClickList && checkBoxClickList.includes(props.id)
              }
              onChange={(e) => {
                e.stopPropagation();
                onCheckBoxClick && onCheckBoxClick(props.id);
              }}
            />
          </div>
        )}
        {(decoration || props.status) && hover && (
          <div className="relative">
            <DecorationBadge decoration={decoration || props.status} />
          </div>
        )}
      </div>

      <div className="w-full relative mt-auto z-[10] ">
        <a
          className="px-4 pt-[8px] pb-[10px] bottom-0
          bg-white flex flex-col relative z-[2]"
        >
          <div className="flex flex-row mb-[1px] justify-between items-center w-full">
            {displayName && (
              <p className="text-[18px] text-blue_night_sky capitalize truncate w-[95%]">
                {displayName}
              </p>
            )}
            {cardDropDownOptions && (
              <FDropdown
                options={cardDropDownOptions}
                onChange={(value) =>
                  props.onOptionSelect && props.onOptionSelect(value as string)
                }
                menuUp
                width="auto"
                height="28"
                iconOnly={true}
                iconColor="black"
                icon="ellipsis"
                className="ml-auto relative z-[20]"
                zIndex={999}
                onOpenChange={setIsDropdownOpen}
              />
            )}
          </div>
          <div className="flex flex-row justify-between relative items-center w-full">
            <FTooltip label="Total Followers">
              <div className="flex flex-row gap-1 items-center cursor-pointer z-[20] relative">
                <FIcon
                  size={20}
                  icon="audience-bold"
                  color="black"
                  className="-mt-[2px]"
                />
                <p className="text-sm truncate text-dark_night_sky whitespace-nowrap">
                  {formatLargeNumber(props.overall_followers)}
                </p>
              </div>
            </FTooltip>
            {props.platforms && Object.keys(props.platforms).length > 0 && (
              <div className="flex flex-row items-center">
                {Object.values(props.platforms)
                  .filter((platform: any) => platform.followers_count > 0)
                  .sort(
                    (a: any, b: any) => b.followers_count - a.followers_count
                  )
                  .map((platform: any, index: number) => (
                    <div
                      className="flex items-center p-[5px] rounded-full border border-1 border-white bg-dark_night_sky -mr-1 last:mr-0"
                      key={platform.name}
                      style={{ zIndex: 9 - index }}
                    >
                      <FIcon size={12} icon={platform.name} color="white" />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  );
};

export default IndividualCard;
