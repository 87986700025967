import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "@components/LoadingSpinner";

import apiClient from "@apiClient";
import "App.css";
import FButton from "@components/FButton";
import FBadge from "@components/FBadge";
import { Permission } from "@constants/roles";
import FSubNav from "@components/FSubNav";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import { Bulletin } from "@types";

export default function BulletinsProfile() {
  const [bulletin, setBulletin] = useState<Bulletin>();
  const { bulletin_id } = useParams<{ bulletin_id: string }>();
  const loading = !bulletin;
  const { trackEvent } = useContext(MixpanelContext);

  const loadBulletin = async (bulletin_id) => {
    try {
      const result = await apiClient.bulletins.get(bulletin_id);
      console.log({ result });
  
      if (result.success) {
        setBulletin(result.data);
        trackEvent(EVENTS.PAGE_VIEW.BULLETIN, { bulletin: result.data.name });
      } else {
        // Handle other non-404 errors
        console.log("Error loading bulletin:", result.message || "Unknown error");
      }
    } catch (error) {
      // Log 404 error if the request fails with a 404 status
      if (error.response && error.response.status === 404) {
        console.error(`404 Error: Bulletin with ID ${bulletin_id} not found`);
      } else {
        console.error("Error loading bulletin:", error);
      }
    }
  };
  
  useEffect(() => {
    loadBulletin(bulletin_id);
  }, [bulletin_id]);

  if (loading) {
    return <LoadingSpinner className="w-full h-[90vh]" />;
  } else {
    return (
      <div>
        {Permission.UPDATE_BULLETINS && (
          <FSubNav
            className="ml-[40px] mb-[-56px] text-white"
            items={[
              {
                value: "recipients",
                label: "Recipients",
                link: `/bulletins/${bulletin_id}/recipients`,
              },
              {
                value: "responses",
                label: "Responses",
                link: `/bulletins/${bulletin_id}/responses`,
              },
              {
                value: "questions",
                label: "Questions",
                link: `/bulletins/${bulletin_id}/questions`,
              },
            ]}
          />
        )}
        <div
          className="p-[150px] h-[100vh] overflow-auto"
          style={{
            background: `linear-gradient(rgba(20,20,20, .6), rgba(20,20,20, .6)), url(${bulletin.image_url})`,
            backgroundSize: "cover",
          }}
        >
          <FBadge
            label={bulletin.category}
            backgroundColor="black"
            color="white"
            className="capitalize mb-[50px]"
          />

          <h1 className="text-white font-bold text-[100px] leading-[100px] pb-[3rem]">
            {bulletin.name}
          </h1>
          <div
            dangerouslySetInnerHTML={{ __html: bulletin.description }}
            className="bulletin-content text-white text-[22px]"
          ></div>

          <div className="pt-10 text-white flex gap-[1.5rem]">
            <FButton label="Continue" />
            <FButton label="Not Interested" />
          </div>
        </div>
      </div>
    );
  }
}
