import React from "react";
import FIcon from "../FIcon";

interface Props {
  className?: string;
  label?: string;
  id: string;
  error?: string;
  success?: string;
  size?: number;
  checked?: boolean;
  style?: any;
  onChange?: (isChecked: boolean) => void;
}

const FCheckbox = ({
  className = "",
  label = "",
  id = "",
  size = 18,
  checked = false,
  onChange,
  ...props
}: Props) => {
  const handleChange = (value: boolean) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={`flex flex-col ${className}`} style={props.style}>
      <div className="flex gap-[6px]">
        {onChange ? (
          <input
            className="hidden"
            type="checkbox"
            id={id}
            checked={checked}
            onChange={(e: any) => {
              handleChange(e.target.checked);
            }}
          />
        ) : null}
        <label
          className={`flex items-center justify-center ${checked ? "bg-dark_night_sky border-0" : "bg-white border border-black"} rounded-[2px] cursor-pointer`}
          htmlFor={id}
          style={{
            width: size,
            height: size,
          }}
        >
          <FIcon icon="brief-checkmark" size={size - 6} color="#fff" />
        </label>
        {label && (
          <label className="text-sm leading-[18px] cursor-pointer" htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export default FCheckbox;
