import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HorizontalDecorationBadge } from "@components/Cards/CardAssets";
import { formatDate } from "@utils/string_utilities";
import PlaceholderImage from "@public/images/fohr-logo-placeholder.svg";
import FDropdown from "@components/FDropdown";
import { has } from "lodash";

interface AssetCardProps {
  id: string;
  cardType: string;
  className?: string;
  cardHeight?: number;
  cardWidth?: number;
  cover_image_url?: string;
  large_cover_image_url?: string;
  imageUrl?: string;
  image_url?: string;
  brief?: {
    go_live_start?: Date | string;
    go_live_end?: Date | string;
  };
  decoration?: string;
  title?: string;
  name?: string;
  participants_count?: number;
  date?: string;
  cardDropDownOptions?: { label: string; value: string }[];
  status?: string;
  onOptionSelect?: (value: string, id: string) => void;
}

const AssetCard = ({
  className,
  cardHeight,
  cardWidth,
  cardDropDownOptions,
  onOptionSelect,
  ...props
}: AssetCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const bg_image =
    props.large_cover_image_url ||
    props.cover_image_url ||
    props.imageUrl ||
    props.image_url;
  const stockImage = bg_image && !bg_image.includes("missing_default");

  const dates =
    props.brief?.go_live_start && props.brief?.go_live_end
      ? `${formatDate(String(props.brief?.go_live_start))} - ${formatDate(
          String(props.brief?.go_live_end)
        )}`
      : "";

  return (
    <>
      <div
        className="cursor-pointer relative rounded-xl overflow-hidden shadow-lg border border-light_red hover:border-light_border transition-all duration-500 ease-out"
        key={props.id}
        style={{
          minHeight: `${cardHeight}px`,
          minWidth: `${cardWidth}px`,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => navigate(`${window.location.pathname}/${props.id}`)}
      >
        <div
          className={`flex flex-col justify-between h-full relative z-10 overflow-hidden ${className}`}
        >
          {stockImage ? (
            <div
              className={`w-full h-[50%] top-0 left-0 bg-cover bg-center overflow-hidden transition-all duration-500 ease-out ${
                isHovered ? "scale-105" : ""
              }`}
              style={{
                backgroundImage: `url(${bg_image})`,
              }}
            />
          ) : (
            <div
              className={`h-[50%] w-full bg-light_border relative transition-all duration-500 ease-out ${
                isHovered ? "scale-110" : ""
              }`}
            >
              <img src={PlaceholderImage} className="w-[70px] h-full mx-auto" />
            </div>
          )}
          <div className="h-[50%] w-full relative border-t border-light_border">
            <div className="bg-light_red_opacity backdrop-blur-xl z-10 w-full px-6 py-4 h-full flex flex-col justify-between items-start">
              <div className="flex items-start space-between w-full z-20">
                <div>
                  {props.status && (
                    <div className="relative w-full flex justify-start mb-4">
                      <HorizontalDecorationBadge decoration={props.status} />
                    </div>
                  )}
                  <h2 className="text-[20px] line-clamp-2">
                    {props.title || props.name}
                  </h2>
                </div>
                {cardDropDownOptions && (
                  <div className="ml-auto" onClick={(e) => e.stopPropagation()}>
                    <FDropdown
                      options={cardDropDownOptions}
                      onChange={(value) =>
                        onOptionSelect &&
                        onOptionSelect(value as string, props.id)
                      }
                      menuUp
                      width="auto"
                      height="28"
                      iconOnly={true}
                      iconColor="black"
                      icon="ellipsis"
                      className="ml-auto relative"
                      zIndex={999}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center gap-[4px] items-start">
                <div className="flex flex-row items-center justify-start space-x-2">
                  <p className="text-[15px] text-neutral_600 truncate whitespace-nowrap">
                    {props.date}
                    {dates}
                  </p>
                </div>
                <div className="flex items-center justify-start space-x-2">
                  <p className="text-[15px] text-neutral_600 truncate whitespace-nowrap">
                    {/* TODO: AssetCard is hard to customize now so this is an ugly hack to support bulletin responses */}
                    {has(props, "responses_count") ? (
                      <>
                        {props.responses_count || "0"} response
                        {props.responses_count === 1 ? "" : "s"}
                      </>
                    ) : (
                      <>
                        {props.participants_count || "0"} influencer
                        {props.participants_count === 1 ? "" : "s"}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetCard;
