import FIcon from "@components/FIcon";
import { platformList } from "@pages/search/constants";

import { useState } from "react";

interface PlatformSelectProps {
  selectedPlatforms: string[];
  onChange: (platforms: string[]) => void;
}

const PlatformSelect = ({
  selectedPlatforms,
  onChange,
}: PlatformSelectProps) => {
  const [hoveredPlatform, setHoveredPlatform] = useState(null);

  return (
    <div className="flex gap-[8px]">
      {platformList.map((platform) => {
        const selected = selectedPlatforms.indexOf(platform.value) !== -1;

        const hovered = hoveredPlatform === platform.value;

        return (
          <div
            key={platform.value}
            className={`
                cursor-pointer flex items-center justify-center
                border border-[#000721] rounded-full w-[32px]
                h-[32px] hover:bg-[#000721] ${selected ? "bg-[#000721]" : "bg-transparent"}
                          `}
            onClick={() => {
              let newSelectedPlatforms;

              if (selected) {
                newSelectedPlatforms = selectedPlatforms.filter(
                  (p) => p !== platform.value
                );
              } else {
                newSelectedPlatforms = [...selectedPlatforms, platform.value];
              }

              onChange(newSelectedPlatforms);
            }}
            onMouseEnter={() => setHoveredPlatform(platform.value)}
            onMouseLeave={() => setHoveredPlatform(null)}
          >
            <FIcon
              icon={platform.icon}
              size={14}
              color={selected || hovered ? "#fff" : "#000721"}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PlatformSelect;
