import React, { useState, useEffect, useRef } from "react";
import FIcon from "./FIcon";
import { isEmpty } from "lodash";
import { FCheckbox, FInput } from "./FInputs";
import FormLabel from "./Form/FormLabel";

function FMultiSelect({
  options = [],
  selected = [],
  hasSearch = false,
  onChange = null,
  height = 500,
  width = "100%",
  label = "",
  required = false,
  validate = false,
  placeholder = "",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  const handleChange = (option) => {
    if (selected.includes(option)) {
      onChange(selected.filter((item) => item !== option));
    } else {
      onChange([...selected, option]);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Add click event listener to the document body
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = searchTerm
    ? options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : options;

  const showSearch = hasSearch || options.length > 9;

  return (
    <div
      style={{
        width: window.innerWidth < 640 ? "100%" : width,
      }}
    >
      {label && (
        <div className="mb-[6px] w-full">
          <FormLabel label={label} required={required} />
        </div>
      )}
      <div
        ref={dropdownRef}
        className={`relative inline-block w-full ${isOpen ? "rounded-t" : ""}`}
        style={{
          maxWidth: width,
        }}
      >
        <div
          className={`flex w-full items-center justify-between p-2 border border-default_weak bg-white rounded${isOpen ? "-t" : ""} cursor-pointer`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex items-center gap-2">
            {selected.length > 0 ? (
              <span>{selected.length} selected</span>
            ) : (
              <span className="text-sm text-neutral_500">{placeholder}</span>
            )}
          </div>
          <FIcon
            icon="chevron-dropdown"
            size={24}
            color="#000000"
            className={`ml-[8px] ${isOpen ? "origin-center rotate-180" : ""}`}
          />
        </div>
        {validate && required && isEmpty(selected) && (
          <p className="text-red text-xs font-normal  tracking-[0.12px] mt-[6px]">
            Select one or more {label.toLowerCase()}
          </p>
        )}
        {selected && isOpen && (
          <div
            className="absolute rounded-b w-full top-[100%] left-0 z-[1080] bg-white border border-default_weak border-t-0 overflow-y-auto"
            style={{
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.25)",
              maxHeight: height,
            }}
          >
            {showSearch ? (
              <div className="p-2 h-[50px]">
                <FInput
                  value={searchTerm}
                  placeholder="Search..."
                  width="100%"
                  type="text"
                  onChange={setSearchTerm}
                  required
                />
              </div>
            ) : null}
            <div
              className={`flex flex-col flex-nowrap ${showSearch ? " overflow-y-auto h-[350px]" : ""}`}
            >
              {filteredOptions.map((option) => {
                const isSelected = selected.includes(option.value);

                return (
                  <div
                    key={option.value}
                    className={`flex items-center text-sm gap-2 px-2 py-1 cursor-pointer ${isSelected ? "bg-neutral_200" : "hover:bg-neutral_200"}`}
                    onClick={() => handleChange(option.value)}
                  >
                    <FCheckbox checked={isSelected} id={option.value} />
                    {option.label}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FMultiSelect;
