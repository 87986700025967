import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FDropdown from "@components/FDropdown";
import FIcon from "@components/FIcon";

interface TitleSectionProps {
  participant_id: string | undefined;
  titleOptions: { label: string; value: string | number }[];
  activeBrief: any;
  handleTitleChange: (value: string) => void;
  groupBriefDetails: {
    participantFirstName?: string;
    groupBriefName?: string;
    groupBriefId?: string;
  };
  campaign_id: string | undefined;
}

export const TitleSection: React.FC<TitleSectionProps> = ({
  participant_id,
  titleOptions,
  activeBrief,
  handleTitleChange,
  groupBriefDetails,
  campaign_id,
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const isSettings = location.pathname.includes("settings");

  return (
    <div className="flex flex-row justify-between items-center w-full pb-8 border-b border-light_border">
      <div className="flex flex-row gap-4 justify-between items-center">
        {!participant_id && titleOptions.length > 1 ? (
          <div className="text-2xl font-medium w-full flex flex-row gap-2">
            <FDropdown
              options={titleOptions}
              titleDropDown
              transparent
              width="100%"
              height={"40"}
              selectedValue={activeBrief?.id}
              onChange={(value) => handleTitleChange(value as string)}
            />
          </div>
        ) : (
          <h2 className="text-2xl font-medium w-full flex flex-row gap-2">
            {participant_id ? "Participant Brief" : "Master Brief"}
          </h2>
        )}
        {!isSettings ? (
          <button
            className="ml-2 text-lg text-neutral_600 hover:underline flex flex-row items-center gap-2"
            onClick={() =>
              navigate(
                `/campaigns/${campaign_id}/${participant_id && groupBriefDetails.groupBriefId ? `brief/${groupBriefDetails.groupBriefId}/settings` : `brief/${activeBrief.id}/settings`}`
              )
            }
          >
            <FIcon icon="settings" size={18} color="#000F45" />
          </button>
        ) : null}
      </div>
      {isSettings ? (
        <Link
          className="flex items-center"
          to={`/campaigns/${campaign_id}/brief/${activeBrief.id}`}
        >
          <FIcon icon="back" size={18} color="#000F45" />
          Go back to brief
        </Link>
      ) : null}
      {participant_id && (
        <Link
          to={`/campaigns/${campaign_id}/brief/${groupBriefDetails.groupBriefId}`}
          className="h-[40px] px-4 flex items-center border border-light_border rounded-sm"
        >
          <p className="text-[15px] font-medium">
            View Group Brief for {groupBriefDetails.participantFirstName}
          </p>
        </Link>
      )}
    </div>
  );
};
