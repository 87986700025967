import BlankState from "@components/BlankState";
import { ReactNode } from "react";

interface WidgetContainerProps {
  children: ReactNode;
  className?: string;
  title: string;
  value: string | number;
  showMobile?: boolean;
}

const WidgetContainer = ({
  children,
  className = "",
  title = "",
  value = "",
  showMobile = true,
}: WidgetContainerProps) => {
  return (
    <div className={`${showMobile ? "block" : "hidden sm:block"} ${className}`}>
      <div className="p-4 flex justify-between items-center border-b border-light_border">
        <h3 className="text-lg font-medium">{title}</h3>
        <p className="text-lg">{value}</p>
      </div>
      <div className="p-4">
        {value ? (
          children
        ) : (
          <BlankState
            noMarginTop={true}
            title="No Data"
            subtitle="We have no data for this breakdown. Try changing the platform or time range."
            icon="user"
          />
        )}
      </div>
    </div>
  );
};

export default WidgetContainer;
