import React from "react";
import FIcon from "@components/FIcon";
import { DateRange, dateRangeOptions } from "@utils/dashboardUtils";
import AnalyticsMessage from "./AnalyticsMessage";
import AnalyticsMessageSkeleton from "./AnalyticsMessageSkeleton";
import CampaignStats from "./CampaignStats";
import { CampaignContent, ICampaign, User } from "types/types";

interface DashboardHeaderProps {
  user: User;
  dateRange: DateRange;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
  handleDateRangeChange: (range: DateRange) => void;
  isBrandDropdownOpen: boolean;
  setIsBrandDropdownOpen: (isOpen: boolean) => void;
  brandDropdownRef: React.RefObject<HTMLDivElement>;
  currentMembership: any;
  handleBrandChange: (membership: any) => void;
  currentBrandId: number;
  sortedMemberships: any[];
  isLoading: boolean;
  campaigns: ICampaign[];
  campaignContent: CampaignContent[];
  setMetrics: (metrics: any) => void;
  analyticsData: any;
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  user,
  dateRange,
  isDropdownOpen,
  setIsDropdownOpen,
  dropdownRef,
  handleDateRangeChange,
  isBrandDropdownOpen,
  setIsBrandDropdownOpen,
  brandDropdownRef,
  currentMembership,
  handleBrandChange,
  currentBrandId,
  sortedMemberships,
  isLoading,
  campaigns,
  campaignContent,
  setMetrics,
  analyticsData,
}) => (
  <div>
    <div className="flex justify-end gap-2">
      {/* Date Range Dropdown */}
      <div className="flex items-center gap-2 relative" ref={dropdownRef}>
        <span className="text-neutral_500 text-sm">Reviewing</span>
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex items-center gap-2 border border-light_border rounded-lg px-2 py-1 text-red"
        >
          <FIcon icon="calendar" size={16} color="black" />
          <span className="text-red text-sm">
            {dateRangeOptions.find((opt) => opt.value === dateRange)?.label}
          </span>
          <FIcon
            icon={isDropdownOpen ? "chevron" : "chevron"}
            size={10}
            color="black"
          />
        </button>
        {isDropdownOpen && (
          <div className="absolute right-0 top-full mt-2 py-2 bg-white border border-neutral_400 rounded-lg shadow-lg z-10">
            {dateRangeOptions.map((option) => (
              <button
                key={option.value}
                onClick={() => handleDateRangeChange(option.value as DateRange)}
                className={`w-full px-4 py-1 text-left hover:bg-gray-50 hover:opacity-100 ${
                  dateRange === option.value ? "text-red" : "opacity-50"
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Brand Selector */}
      <div className="flex items-center gap-2 relative" ref={brandDropdownRef}>
        <span className="text-neutral_500 text-sm">for</span>
        <button
          onClick={() => setIsBrandDropdownOpen(!isBrandDropdownOpen)}
          className="flex items-center gap-2 border border-light_border rounded-lg px-2 py-1 text-red min-w-[120px]"
        >
          <FIcon icon="store" size={14} color="black" />
          <span className="text-red text-sm truncate">
            {currentMembership?.name || "Select Brand"}
          </span>
          <FIcon
            icon={isBrandDropdownOpen ? "chevron" : "chevron"}
            size={10}
            color="black"
            className="flex-shrink-0"
          />
        </button>
        {isBrandDropdownOpen && (
          <div className="absolute right-0 top-full mt-2 py-2 bg-white border border-neutral_400 rounded-lg shadow-lg z-10 w-full min-w-[200px]">
            {sortedMemberships.map((membership) => (
              <button
                key={membership.id}
                onClick={() => handleBrandChange(membership)}
                className={`w-full px-4 py-1 text-left hover:bg-gray-50 hover:opacity-100 truncate ${
                  currentBrandId === membership.brand_id
                    ? "text-red"
                    : "opacity-50"
                }`}
              >
                {membership.name}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
    <div className="flex justify-between items-center mb-2">
      <h1 className="text-[40px]">{user?.first_name}, hello! 👋</h1>
    </div>

    <div className="mb-8">
      {isLoading ? (
        <AnalyticsMessageSkeleton />
      ) : (
        <AnalyticsMessage {...analyticsData} />
      )}
    </div>

    <CampaignStats
      campaigns={campaigns}
      campaignContent={campaignContent}
      isLoading={isLoading}
      dateRange={dateRange}
      setMetrics={setMetrics}
    />
  </div>
);
