import React, { useCallback, useEffect, useState } from "react";

import { Sort } from "@types";
import {
  getBulletinResponseList,
  deleteBulletinResponse,
} from "@api/Bulletins/BulletinResponses";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

import useAuth from "@hooks/useAuth";
import { Permission } from "@constants/roles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getBulletin } from "@api/Bulletins/Bulletins";
import { groupBy, uniq } from "lodash";

import FButton from "@components/FButton";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import FIcon from "@components/FIcon";
import BlankState from "@components/BlankState";
import { FCheckbox } from "@components/FInputs";

const actions = {
  delete: async (id: number) => deleteBulletinResponse(id),
};

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    isImage: true,
    imageType: "asset" as "asset" | "profile",
    imageKey: "profile_image_url",
    isFirstColumn: true,
    linkURL: "/card/{slug}",
    linkTarget: "_blank",
    maxWidth: 200,
  },
  {
    key: "location",
    label: "Location",
    hideContentForSubItem: true,
    maxWidth: 150,
  },
  {
    key: "overall_followers",
    label: "Followers",
    isNumber: true,
    hideContentForSubItem: true,
  },
  { key: "question", label: "Question" },
  { key: "readable_answer", label: "Answer" },
];

export default function BulletinResponseListPage() {
  const { bulletin_id } = useParams<{ bulletin_id: string }>();

  const [bulletinName, setBulletinName] = useState<string>("");

  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);

  const [shouldExpandAll, setShouldExpandAll] = useState(true);
  const [areAllRowsSelected, setAllRowsSelected] = useState(false);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const { can } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    getBulletin(bulletin_id).then((response) => {
      setBulletinName(response.data.name);
    });
  }, []);

  const getBulletinResponses = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;

      const response = await getBulletinResponseList(false, page, sort, {
        bulletin_id_eq: bulletin_id,
        status_eq: filter,
        creator_full_name_cont: search,
      });

      const { bulletin_responses } = response.data;

      const creatorIds = uniq(
        bulletin_responses.map(({ creator_id }) => creator_id)
      );

      const responsesByCreator = groupBy(bulletin_responses, "creator_id");

      let items = creatorIds.reduce((result, id) => {
        const responses = responsesByCreator[id];

        const responsesResult = responses.map((response, index) =>
          index === 0
            ? { ...response, id: response.creator_id }
            : { ...response, isSubItem: true }
        );

        return [...result, ...responsesResult];
      }, []);

      items = items.map((item) => {
        const { creator } = item;

        return {
          ...item,
          ...creator,
          id: item.id,
        };
      });

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  const handleTableRowDropdownSelect = (value: string, id: number) => {
    if (value === "addToGroup") {
      setAddToGroupModalVisible(true);
      setSelectedItems([id]);
    }
  };

  const cardDropDownOptions = [
    { label: "Add to a group", value: "addToGroup" },
    can(Permission.DESTROY_BULLETINS) && { label: "Delete", value: "delete" },
  ].filter(Boolean);

  return (
    <div className="w-full px-[15px] sm:px-[40px] mt-[40px] z-10">
      <Link className="flex items-center mb-4" to={`/bulletins/${bulletin_id}`}>
        <FIcon icon="back" size={18} color="#000F45" />
        Go back to bulletin
      </Link>
      <SinglePageLayoutWithData
        entityName="bulletin_response"
        pageTitle={`Responses for bulletin "${bulletinName}"`}
        pageName="Bulletin Responses"
        sortPathName="creator_full_name"
        sortPathDate="email_last_sent_at"
        filterPath="status"
        tableColumns={tableColumns}
        cardDropDownOptions={cardDropDownOptions}
        rowActions={cardDropDownOptions}
        onTableRowDropDownSelect={handleTableRowDropdownSelect}
        asyncActions={actions}
        getItems={getBulletinResponses}
        headerButtons={
          selectedItems.length ? (
            <div className="flex gap-2">
              <FButton
                primary
                label={`(${selectedItems.length}) Add to group`}
                onClick={() => setAddToGroupModalVisible(true)}
              />
            </div>
          ) : (
            []
          )
        }
        onChangeSelectedItems={setSelectedItems}
        selectedItems={selectedItems}
        tableOnly
        customBlankState={
          <BlankState
            title="No Bulletin Responses Found"
            subtitle="Cast gifting and paid, events, and survey opportunities, and have creators register their interest today."
            icon="user"
            actionLabel="Add Recipients"
            onActionClick={() =>
              navigate(`/bulletins/${bulletin_id}/recipients`)
            }
          />
        }
        onExpandRows={setShouldExpandAll}
        areRowsExpanded={shouldExpandAll}
        topLeftTableContent={
          <div className="pl-4">
            <FCheckbox
              checked={areAllRowsSelected}
              id="select-all-rows"
              label="Select all creators that have responded"
              onChange={setAllRowsSelected}
            />
          </div>
        }
      />
      {isAddToGroupModalVisible ? (
        <AddCreatorsToGroupModal
          creatorIds={selectedItems}
          onClose={() => {
            setAddToGroupModalVisible(false);
            setSelectedItems([]);
          }}
          resource={
            areAllRowsSelected
              ? {
                  id: parseInt(bulletin_id, 10),
                  type: "bulletin",
                }
              : null
          }
        />
      ) : null}
    </div>
  );
}
