import { ApiResponse, Creator } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

const getCreator = async (
  creator_id: string | number
): Promise<ApiResponse<Creator>> => {
  return apiWrapper(`/creators/${creator_id}`, "GET");
};

const getCreatorList = (
  admin?: boolean,
  page?: number,
  sort?: Sort,
  params?: {
    off_platform_eq: string;
    search?: string;
  }
): Promise<
  ApiResponse<{
    creators: Creator[];
    total_items: number;
  }>
> => {
  const { search, ...rest } = params || {};

  const queryParams = getQueryParamsForSearchRequest(page, sort, rest);

  if (search) {
    queryParams.append("q[full_name_or_email_or_instagram_username_or_tiktok_username_cont]", search);
  }

  return apiWrapper(
    `${admin ? "/admin" : ""}/creators?${queryParams.toString()}`,
    "GET"
  );
};

const createCreator = async (
  creatorData: Partial<Creator>
): Promise<ApiResponse<Creator>> => {
  const response = await apiWrapper("/creators", "POST", creatorData);

  return response.data;
};

const createOffPlatformCreator = async (
  creatorData: Partial<Creator>
): Promise<ApiResponse<Creator>> => {
  const response = await apiWrapper(
    "/off_platform_creators",
    "POST",
    creatorData
  );

  return response.data;
};

const checkOffPlatformUsernames = async (
  username: string
): Promise<ApiResponse<Creator>> => {
  const response = await apiWrapper(
    `/off_platform_creators/check_accounts?username=${username}`,
    "GET"
  );
  return response.data;
};

const updateCreator = async (
  creator_id: string | number,
  creatorData: Partial<Creator>
): Promise<ApiResponse<Creator>> => {
  return apiWrapper(`/creators/${creator_id}`, "PUT", creatorData);
};

const deleteCreator = async (creator_id: string | number) => {
  return apiWrapper(`/creators/${creator_id}`, "DELETE");
};

export const connectToPlatform = async (platform, username) => {
  return apiWrapper(`/${platform}_discovery_connections`, "POST", {
    username,
  });
};

export const removePlatformConnection = async (platform) => {
  return apiWrapper(`/${platform}_discovery_connections`, "DELETE");
};

export {
  createCreator,
  createOffPlatformCreator,
  checkOffPlatformUsernames,
  getCreator,
  getCreatorList,
  updateCreator,
  deleteCreator,
};
