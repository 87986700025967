import React, { useContext } from "react";
import { NewReportContext } from "@contexts/index";
import ReportCreatorsTable from "@pages/reports/components/ReportCreatorsTable";
import LoadingSpinner from "@components/LoadingSpinner";

export default function Creators() {
  const {
    report,
    isLoading,
    audience,
    audienceDataFetched,
    availablePlatforms,
    selectedPlatform,
    setSelectedPlatform,
  } = useContext(NewReportContext);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="">
      <div className="mb-[48px]">
        <ReportCreatorsTable report_id={report?.id} />
      </div>
    </div>
  );
}
