import FButton from "@components/FButton";
import { capitalize } from "lodash";
import moment from "moment";

export interface Comment {
  author_name: string;
  author_email?: string;
  body: string;
  created_at: string;
  subject?: string;
}

export interface CommentProps {
  comment: Comment;
  onDelete?: () => void;
  isDeleting?: boolean;
  onEdit?: () => void;
}

const Comment = ({ comment, onDelete, isDeleting, onEdit }: CommentProps) => {
  const { author_name, author_email, body, created_at, subject } = comment;

  return (
    <div className="mb-8 relative">
      <div className="flex mb-2  items-center justify-between">
        <p className="font-bold text-sm">{author_name || author_email}</p>
        {subject ? (
          <div className="rounded-lg bg-neutral_200 mb-1 px-2 py-1 w-max text-sm">
            {capitalize(subject)}
          </div>
        ) : null}
      </div>
      <p
        className="mb-1 opacity-80 pr-[20px] break-words"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <p className="text-sm text-default_weak">
        {moment(created_at).format("MMM DD, H:mm A")}
      </p>
      <div className="flex gap-0 absolute top-0 right-0">
        {onEdit ? (
          <FButton
            className=""
            icon={{ color: "#000F45", name: "edit", size: 14 }}
            iconButton
            height={20}
            onClick={onEdit}
          />
        ) : null}
        {onDelete ? (
          <FButton
            className=""
            icon={{ color: "#000F45", name: "delete", size: 14 }}
            iconButton
            height={20}
            loading={isDeleting}
            onClick={onDelete}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Comment;
