import React, { useContext, useEffect, useState } from "react";

import Opportunities from "./components/Opportunities";
import { ProfileContext, UserContext } from "@contexts/index";
import LoadingSpinner from "@components/LoadingSpinner";
import { listAmbassadors } from "@api/Ambassadors/Ambassadors";
import { FChip } from "@components/FChip";
import {
  CampaignParticipantParams,
  getCampaignParticipantList,
} from "@api/Campaign/CampaignParticipants";
import { AmbassadorData } from "@apiTypes";
import Table from "@components/Table";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import BlankState from "@components/BlankState";

const CAMPAIGN_HISTORY_TABLE_COLUMNS = [
  {
    key: "campaign_name",
    label: "Campaign Name",
  },
  {
    key: "current_offer_price",
    label: "Accepted Offer",
    isMoney: true,
  },
  {
    key: "deliverables",
    label: "Deliverables",
    getValue: (row) => row.deliverables?.name,
  },
  {
    key: "created_at",
    label: "Start Date",
    isDate: true,
  },
  {
    key: "end_date",
    label: "End Date",
    isDate: true,
  },
  {
    key: "brand_name",
    label: "Brand",
  },
];

export default function ProfileDetails() {
  const { profile } = useContext(ProfileContext);

  const { user } = useContext(UserContext);

  const [ambassadors, setAmbassadors] = useState<AmbassadorData[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignParticipantParams[]>([]);

  const isValidProfileHash = !!user;

  useEffect(() => {
    const getData = async () => {
      if (!user) {
        return;
      }

      const ambassadorsResponse = await listAmbassadors(null, null, {
        creator_id_eq: profile.id,
      });

      const campaignsResponse = await getCampaignParticipantList(null, {
        creator_id_eq: profile.id,
      });

      setAmbassadors(ambassadorsResponse.ambassadors);
      setCampaigns(campaignsResponse.data.participants);
    };

    getData();
  }, [profile.id, user]);

  if (!profile) {
    return <LoadingSpinner />;
  }

  const cardInfo = [
    {
      label: "Email",
      value: profile.email,
      icon: "email",
      include: profile.email,
    },
    {
      label: "Mailing Address",
      value: profile.location,
      icon: "email",
      include: profile.location,
    },
    {
      label: "Birthday",
      value: `${profile.birthday} Age (${profile.age})`,
      icon: "calendar",
      include: profile.birthday,
    },
    {
      label: "Pronouns",
      value: profile.pronouns,
      icon: "chat",
      include: profile.pronouns,
    },
    {
      label: "Ethnicity",
      value: profile.ethnicity,
      icon: "skincare",
      include: profile.ethnicity,
    },
    {
      label: "Sexual Orientation",
      value: profile.sexual_orientation,
      icon: "chat",
      include: profile.sexual_orientation,
    },
    {
      label: "Joined Fohr",
      value: profile.created_at,
      icon: "letter-f",
      include: profile.created_at,
    },
  ].filter(
    (c) => c.include && (isArray(c.value) ? !isEmpty(c.value) : c.value)
  );

  const CardInfoItem = ({ info }) => (
    <div className="mb-[24px] last:mb-0" key={info.label}>
      <p className="text-default_weak text-sm mb-2 whitespace-nowrap">
        {info.label}
      </p>
      <p
        className="text-sm leading-[20px]"
        style={{
          filter: `blur(${user || isValidProfileHash ? 0 : 8}px)`,
        }}
      >
        {info.label === "Email" && (user || isValidProfileHash) ? (
          <a href={`mailto:${info.value}`}>{info.value}</a>
        ) : info.label === "Joined Fohr" && (user || isValidProfileHash) ? (
          moment(new Date(info.value)).format("MMMM D, YYYY")
        ) : user || isValidProfileHash ? (
          info.value
        ) : (
          "qwertyuiopa"
        )}
      </p>
    </div>
  );

  return (
    <div className="pt-8">
      <div className="flex flex-col justify-between items-stretch w-full">
        <div className="mb-4">
          <div className="flex flex-row">
            <div className="w-2/3 pr-6 flex flex-col">
              <div className="pt-0 py-[38px]">
                {profile.tagline && (
                  <div className="mb-[24px]">
                    <p className="text-neutral_600 text-xs uppercase font-sofia-pro mb-[4px] whitespace-nowrap">
                      Description
                    </p>
                    <p className="text-[16px] font-normal font-sofia-pro">
                      {profile.tagline}
                    </p>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-8">
                  {cardInfo
                    // Filter for even indexed items
                    .map((info, i) => (
                      <CardInfoItem key={i} info={info} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(ambassadors) ? (
        <div className="border-b border-neutral_400 border-t mb-10 py-8">
          <span>Ambassador for</span>
          <div className="flex items-center flex-wrap mt-3 gap-1.5">
            {ambassadors.map((ambassador) => {
              const { "brand_name=": brandName } = ambassador;

              return (
                <FChip
                  label={brandName}
                  className="border-neutral_400 font-sofia-pro text-sm mb-2"
                  key={brandName}
                />
              );
            })}
          </div>
        </div>
      ) : null}
      <span>Campaign History</span>
      {user ? (
        isEmpty(campaigns) ? (
          <BlankState
            title="No Campaigns"
            subtitle="There's no campaign data for this profile"
            icon="use"
          />
        ) : (
          <Table columns={CAMPAIGN_HISTORY_TABLE_COLUMNS} data={campaigns} />
        )
      ) : null}
    </div>
  );
}
