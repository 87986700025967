import React from "react";

export const ReportsAnalyticsMessageSkeleton: React.FC = () => {
  return (
    <div className="mb-8">
      <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-6 rounded w-3/4 mb-2"></div>
      <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-6 rounded w-1/2"></div>
    </div>
  );
};
