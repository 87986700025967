import React, { useContext } from "react";
import { formatNumber } from "@utils/number_utilities";
import StackedBarChart from "@components/Graphs/StackedBarChart";
import { ReportPaidSkeleton } from "./ReportPaidSkeleton";
import { NewReportContext } from "@contexts/index";

interface ReportPaidProps {
  report_id?: string;
  parentLoading?: boolean;
}

const ReportPaid = ({
  report_id: propReportId,
  parentLoading = false,
}: ReportPaidProps) => {
  const context = useContext(NewReportContext);
  const report = context?.report;
  const contentSummary = report?.content_summary;
  const isCampaignContext = window.location.pathname.includes("campaigns");

  // Use prop report_id if provided, otherwise check URL and use appropriate ID
  const report_id = isCampaignContext
    ? report?.reports?.[0]?.id?.toString()
    : propReportId || report?.id?.toString();

  const calculatePercentage = (value: number, total: number) => {
    return total > 0 ? (value / total) * 100 : 0;
  };

  // Define metrics configuration
  const metricConfigs = [
    {
      title: "Views",
      getData: () => ({
        organic: contentSummary?.organic_impressions || 0,
        paid: contentSummary?.paid_impressions || 0,
        total:
          (contentSummary?.organic_impressions || 0) +
          (contentSummary?.paid_impressions || 0),
      }),
    },
    {
      title: "Posts",
      getData: () => ({
        organic: contentSummary?.organic_posts || 0,
        paid: contentSummary?.paid_posts || 0,
        total: contentSummary?.total_posts || 0,
      }),
    },
    {
      title: "Engagements",
      getData: () => ({
        organic: contentSummary?.organic_engagements || 0,
        paid: contentSummary?.paid_engagements || 0,
        total: contentSummary?.engagements || 0,
      }),
    },
  ];

  // Show skeleton while either parent is loading or we're loading data
  if (parentLoading || !contentSummary) return <ReportPaidSkeleton />;

  const MetricCard = ({ config }: { config: (typeof metricConfigs)[0] }) => {
    const data = config.getData();
    const organicPercentage = calculatePercentage(data.organic, data.total);
    const paidPercentage = calculatePercentage(data.paid, data.total);

    return (
      <div className="border border-light_border rounded-lg bg-light_red shadow-md">
        <div className="p-4 flex justify-between items-center border-b border-light_border">
          <h3 className="text-lg font-medium">{config.title}</h3>
          <p className="text-lg">{formatNumber(data.total)}</p>
        </div>
        <div className="space-y-4 p-4">
          <div className="flex justify-between">
            <div className="flex flex-col items-center">
              <span className="text-sm text-neutral_700">
                Organic ({organicPercentage.toFixed(1)}%)
              </span>
              <span className="text-sm font-medium">
                {formatNumber(data.organic)}
              </span>
            </div>
            <div className="flex flex-col items-center">
              <span className="text-sm text-neutral_700">
                Paid ({paidPercentage.toFixed(1)}%)
              </span>
              <span className="text-sm font-medium">
                {formatNumber(data.paid)}
              </span>
            </div>
          </div>
          {(data.organic > 0 || data.paid > 0) && (
            <div className="w-full">
              <StackedBarChart
                data={[
                  {
                    name: `Organic ${config.title}`,
                    percentage: organicPercentage,
                  },
                  {
                    name: `Paid ${config.title}`,
                    percentage: paidPercentage,
                  },
                ]}
                showLegend={false}
                height={8}
                colors={["#47B37F", "#E47667"]}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="pr-8 mt-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-medium">Organic vs Paid</h2>
      </div>
      <div className="space-y-4">
        {metricConfigs.map((config) => (
          <MetricCard key={config.title} config={config} />
        ))}
      </div>
    </div>
  );
};

export default ReportPaid;
