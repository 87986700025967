import React, { useEffect, useState } from "react";
import ResultsGrid from "@components/ResultsGrid";
import PostSnapshotSkeleton from "./PostSnapshotSkeleton";
import BlankState from "@components/BlankState";
import {
  getTopPerformingContent,
  getLatestContent,
  fetchParticipantDetails,
} from "@utils/dashboardUtils";
import { CampaignContent } from "types/types";
import { format } from "date-fns";

interface PostSnapshotProps {
  campaignContent: CampaignContent[];
  isLoading: boolean;
  title: string;
  maxPosts?: number;
  cardType?: string;
  contentType: "top" | "latest";
  onCardClick?: (post: any) => void;
}

const formatPostForModal = (post: any) => {
  const formattedDate = format(new Date(post.published_at), "MMM d, yyyy");

  // Get social profile URL based on platform
  const getSocialProfileUrl = (platform: string, username: string) => {
    const urls = {
      instagram: `https://instagram.com/${username}`,
      tiktok: `https://tiktok.com/@${username}`,
      youtube: `https://youtube.com/@${username}`,
      facebook: `https://facebook.com/${username}`,
    };
    return urls[platform.toLowerCase()] || "#";
  };

  const username = post.creator?.username || post.social_username || "";
  const socialProfileUrl = getSocialProfileUrl(post.platform, username);

  return {
    id: post.id,
    full_name: post.full_name,
    username,
    profile_image: post.profile_image,
    permalink: post.permalink,
    caption: post.data?.caption || "",
    published_at: formattedDate,
    platform: post.platform,
    media_type: post.media_type,
    content_type: post.content_type,
    photo_url: post.photo_url,
    video_url: post.video_url || post.permalink,
    is_video: post.media_type === "video",

    // Profile links
    profile_link: `/card/${post.creator?.slug || ""}`,
    social_profile_url: socialProfileUrl,
    slug: post.creator?.slug,

    // Stats
    views_count: parseInt(post.stats?.views_count) || 0,
    likes_count: parseInt(post.stats?.total_interactions) || 0,
    comments_count: parseInt(post.data?.comments_count) || 0,
    reach: parseInt(post.stats?.views_count) || 0,

    // Additional fields
    source_id: post.id.toString(),
    interactions: post.stats?.total_interactions || null,
    categories: "",
    mentions: [],
    hashtags: [],
    search_cache: `${post.full_name} ${username}`.trim(),

    // UI specific
    cardType: "content",
    checkbox: false,
    statHelperText: "Influencers",
    imageOnly: false,
    cardHeight: 472,
    cardWidth: 250,
  };
};

export default function PostSnapshot({
  campaignContent,
  isLoading,
  title,
  maxPosts = 4,
  cardType = "content",
  contentType = "top",
  onCardClick,
}: PostSnapshotProps) {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setPosts([]);
  }, [campaignContent]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError(null);
      setPosts([]);

      try {
        if (campaignContent.length === 0) {
          setLoading(false);
          return;
        }

        const selectedPosts =
          contentType === "top"
            ? getTopPerformingContent(campaignContent)
            : getLatestContent(campaignContent, maxPosts);

        const postsWithDetails = await Promise.all(
          selectedPosts.map(async (post) => {
            try {
              const details = await fetchParticipantDetails(
                post.participant_id
              );
              return {
                ...details,
                engagement_rate: post.engagement_rate,
                interactions: post.interactions,
                photo_url: post.photo_url,
                platform: post.platform,
                username: details.social_username,
                cardType,
              };
            } catch (err) {
              console.error(
                `Error fetching details for post ${post.participant_id}:`,
                err
              );
              return null;
            }
          })
        );

        const validPosts = postsWithDetails.filter((post) => post !== null);
        setPosts(validPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError("Failed to load posts");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [campaignContent, maxPosts, cardType, contentType]);

  const handleCardClick = (post: any) => {
    if (onCardClick) {
      const formattedPost = formatPostForModal(post);
      console.log("Formatted post for modal:", formattedPost);
      onCardClick(formattedPost);
    }
  };

  if (isLoading || loading) {
    return <PostSnapshotSkeleton maxPosts={maxPosts} title={title} />;
  }

  if (error) {
    return (
      <div className="h-full w-full flex items-center justify-center text-red">
        {error}
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="">
        <h2 className="text-xl font-medium mb-4">{title}</h2>
        <BlankState
          title="No Content Available"
          subtitle={`There is no content to display at this time.`}
          icon="clock"
        />
      </div>
    );
  }

  return (
    <div className="">
      <h2 className="text-xl font-medium -mb-4">{title}</h2>
      <ResultsGrid
        cardData={posts}
        cardMinWidth={125}
        cardType={cardType}
        isProfile={false}
        pageSize={maxPosts}
        onCardClick={handleCardClick}
        numColumns={5}
      />
    </div>
  );
}
