import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { getBrandBySlugForAmbassadorOnboarding } from "@api/brands";
import { Brand } from "@types";

export default function AmbassadorOnboardingLayout({ children }) {
  const { brand: brand_slug } = useParams<{ brand: string }>();

  const [brand, setBrand] = useState<Brand>(null);

  useEffect(() => {
    const loadAmbassadorOnboardingSettings = async () => {
      const settings = await getBrandBySlugForAmbassadorOnboarding(brand_slug);

      setBrand(settings);
    };

    if (!brand_slug) {
      return;
    }

    loadAmbassadorOnboardingSettings();
  }, [brand_slug]);

  const { background_photo, logo_photo, amp_description, slug } = brand || {};

  const BackgroundPhotoSection = () => (
    <div className="h-full relative">
      <div className="absolute h-full w-full left-[0px] right-[0px] bg-[#0c040454]" />
      <div className="absolute top-[30%] left-[80px] w-[300px]">
        <p className="mb-12 text-white text-4xl">
          {slug === "dick-s-2025"
            ? "Join the Varsity Team"
            : "Join the Ambassador Team"}
        </p>
        <p className="text-white">
          {amp_description}
          <br />
        </p>
      </div>
      <img
        src={background_photo?.original}
        className="h-full w-[500px] object-cover"
      />
    </div>
  );

  return (
    <div className="flex flex-col sm:flex-row sm:h-screen w-full">
      <div className="sm:hidden">
        <BackgroundPhotoSection />
      </div>
      <div className="flex flex-col flex-1 items-center justify-center h-full p-2 sm:p-0 sm:overflow-y-auto">
        <a href="">
          <img
            src={logo_photo?.original}
            className="mb-[30px] w-[120px] mx-auto"
          />
        </a>
        <div className="w-full sm:w-[440px] sm:max-h-[calc(100vh-250px)]">
          {children}
        </div>
        <div className="text-center mt-8">
          <div className="mb-2">
            <p className="text-xs opacity-50">
              Need additional help? Please reach out to{" "}
              <a className="underline" href="mailto:support@fohr.co">
                support@fohr.co
              </a>
              .
            </p>
          </div>
          <div className="text-xs mb-2 opacity-50 max-w-[400px] mx-auto">
            By continuing, you acknowledge that you understand and agree to the{" "}
            <a
              href="https://www.fohr.co/terms"
              target="_blank"
              className="underline"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://www.fohr.co/privacy"
              target="_blank"
              className=" underline"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </div>
      <div className="hidden sm:block">
        <BackgroundPhotoSection />
      </div>
    </div>
  );
}
