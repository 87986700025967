import FButton from "@components/FButton";
import FIcon from "@components/FIcon";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { QuestionnaireQuestion, FieldType } from "types/questionnaire";

interface QuestionCardProps {
  onDelete: (question: QuestionnaireQuestion) => void;
  question: QuestionnaireQuestion;
  hideChildQuestions?: boolean;
}

const QuestionCard = ({
  question: questionnaireQuestion,
  onDelete,
  hideChildQuestions,
}: QuestionCardProps) => {
  const [isExpanded, setExpanded] = useState(false);

  const {
    conditional_answer: conditionalAnswer,
    id,
    question,
    question_id,
    data,
    required,
    field_type,
    children,
  } = questionnaireQuestion;

  const { options } = data || {};

  const navigate = useNavigate();

  const location = useLocation();

  const handleAddChildQuestion = () =>
    navigate(`${location.pathname}/new?question_id=${id}`);

  const handleEditQuestion = (questionId) =>
    navigate(`${location.pathname}/${questionId}`);

  const isChild = !!question_id;

  return (
    <div className="border border-neutral_400 pb-1 p-4 rounded-lg">
      <div
        className="cursor-pointer flex items-center justify-between gap-2 pb-2"
        onClick={(e) => {
          e.stopPropagation();

          setExpanded(!isExpanded);
        }}
      >
        <span>Question: {question}</span>
        <FIcon
          icon="chevron"
          size={11}
          color="#000F45"
          className={isExpanded ? "rotate-180" : ""}
        />
      </div>
      {isExpanded ? (
        <div>
          <div className="flex items-center justify-between gap-2 pb-2">
            <div className="flex flex-col gap-2">
              {conditionalAnswer ? (
                <span>Conditional answer: {conditionalAnswer}</span>
              ) : null}
              {[FieldType.SINGLE_SELECT, FieldType.MULTI_SELECT].includes(
                field_type
              ) ? (
                <span>Answers: {options ? options.join(", ") : ""}</span>
              ) : null}
              <span>Required: {required ? "true" : "false"}</span>
              <span>Field type: {field_type}</span>
            </div>
            <div className="flex gap-2">
              <div
                onClick={() => handleEditQuestion(questionnaireQuestion.id)}
                className="cursor-pointer w-[32px] h-[32px] rounded-lg border flex all-center"
              >
                <FIcon icon="edit" color="#000000" size={16} />
              </div>
              <div
                onClick={() => onDelete(questionnaireQuestion)}
                className="cursor-pointer bg-light_red w-[32px] h-[32px] rounded-lg border border-red flex all-center"
              >
                <FIcon icon="delete" color="#E47667" size={16} />
              </div>
            </div>
          </div>
          {!isEmpty(children) && options ? (
            <div className="mt-2 pl-16">
              <h2 className="text-[20px] font-medium mb-4">Child questions</h2>
              <div className="flex flex-col gap-8">
                {options.map((option) => {
                  const childQuestions = children[option];

                  if (isEmpty(childQuestions)) {
                    return null;
                  }

                  return childQuestions.map((childQuestion) => (
                    <QuestionCard
                      key={option}
                      onDelete={() => onDelete(childQuestion)}
                      question={childQuestion}
                    />
                  ));
                })}
              </div>
            </div>
          ) : null}
          {[FieldType.SINGLE_SELECT, FieldType.MULTI_SELECT].includes(
            field_type
          ) &&
          !isChild &&
          !hideChildQuestions ? (
            <div className="flex mb-2 justify-end w-full">
              <FButton
                iconLeft={{
                  name: "add",
                  size: 12,
                  color: "#000721",
                  className: "mr-2",
                }}
                className="mb-1 mt-4"
                onClick={handleAddChildQuestion}
                label="Add child question"
              ></FButton>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default QuestionCard;
