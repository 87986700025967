import React, { useState, useEffect, useContext } from "react";
import MultiPageLayout from "../../layouts/MultiPageLayout";
import { useParams, Outlet, useNavigate } from "react-router-dom";

import BlankState from "@components/BlankState";
import LoadingSpinner from "@components/LoadingSpinner";
import { OutreachContext } from "@contexts/index";

import { ButtonDropDown } from "@components/ButtonDropDown";
import { Outreach } from "@types";
import apiClient from "@apiClient";
import FButton from "@components/FButton";
import {
  deleteOutreach,
  deleteOutreachParticipants,
  sendOutreach,
  updateOutreach,
} from "@api/Outreach/Outreach";
import toast from "react-hot-toast";
import Modal from "@components/Modals/Modal";
import { FInput } from "@components/FInputs";
import { createOutreachTemplate } from "@api/Outreach/OutreachTemplates";
import useAuth from "@hooks/useAuth";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import UpdateOutreachModal from "./components/UpdateOutreachModal";
import AddCreatorsToGroupModal from "@components/Profile/AddCreatorsToGroupModal";
import ConfirmModal from "@components/Modals/ConfirmModal";

const { get } = apiClient.outreach;

const actions = [
  { label: "Edit name", value: "edit_name" },
  { label: "Archive", value: "archive" },
  { label: "Create Template", value: "create_template" },
];

const OutreachProfile = () => {
  const [outreach, setOutreach] = useState<Outreach>();
  const [loading, setLoading] = useState(true);

  const [waiting, setWaiting] = useState(false);
  const [saving, setSaving] = useState(false);

  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const [pendingName, setPendingName] = useState("");

  const [showAddRecipientsModal, setShowAddRecipientsModal] = useState(false);
  const [isRemovingParticipants, setRemovingParticipants] = useState(false);
  const [isAddToGroupModalVisible, setAddToGroupModalVisible] = useState(false);

  const [selectedRecipientIds, setSelectedRecipientIds] = useState<number[]>(
    []
  );

  const [
    isConfirmDeleteParticipantsModalVisible,
    setConfirmDeleteParticipantsModalVisible,
  ] = useState(false);

  const { id: outreachId } = useParams();

  const navigate = useNavigate();

  const { user } = useAuth();
  const { trackEvent } = useContext(MixpanelContext);

  useEffect(() => {
    const loadData = async () => {
      try {
        const outreach = await get(outreachId);
        setOutreach(outreach);
        trackEvent(EVENTS.PAGE_VIEW.OUTREACH, {outreach: outreach.title});

      } catch (e) {
        console.log("E", e);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [outreachId]);

  const subNavItems = [
    {
      label: "Message",
      value: "message",
      link: `/outreach/${outreachId}/message`,
    },
    {
      label: "Recipients",
      value: "recipients",
      link: `/outreach/${outreachId}/recipients`,
    },
  ];

  const handleAddRecipients = () => setShowAddRecipientsModal(true);

  const handleSendOutreach = async () => {
    setWaiting(true);

    try {
      await sendOutreach(outreach.id);

      toast.success("Message sent!");
    } catch (e) {
      toast.error("Error sending outreach");
    } finally {
      setWaiting(false);
    }
  };

  const handleSaveUpdates = async () => {
    setSaving(true);

    try {
      updateOutreach(outreach.id, { title: pendingName });

      toast.success("Changes saved!");

      setEditModalVisible(false);

      setOutreach({ ...outreach, title: pendingName });
    } catch (e) {
      toast.error("Error updating outreach");
    } finally {
      setSaving(false);
    }
  };

  const handleArchive = async () => {
    try {
      await deleteOutreach(outreach.id);

      toast.success("Outreach archived");

      navigate("/outreach");
    } catch (e) {
      toast.error("Error archiving outreach");
    }
  };

  const handleCreateTemplate = async () => {
    const {
      title,
      message: { subject, body },
    } = outreach;

    try {
      const response = await createOutreachTemplate({
        brand_id: user.brand_id,
        name: title,
        subject,
        body,
      });

      toast.success("Outreach template created successfully!");

      navigate(`/outreach/templates/${response.data.id}`);
    } catch (error) {
      toast.error("Failed to create outreach template. Please try again.");
    }
  };

  const handleRemoveParticipants = async () => {
    setRemovingParticipants(true);

    try {
      await deleteOutreachParticipants(outreach.id, selectedRecipientIds);

      const newRecipients = outreach.recipients.filter(
        ({ id }) => !selectedRecipientIds.includes(id)
      );

      setOutreach({ ...outreach, recipients: newRecipients });

      setConfirmDeleteParticipantsModalVisible(false);

      setSelectedRecipientIds([]);

      toast.success("Participants removed");
    } catch (e) {
      toast.error("Error removing participants");
    } finally {
      setRemovingParticipants(false);
    }
  };

  const availableActions = [...actions];

  if (selectedRecipientIds.length > 0) {
    availableActions.push({
      label: "Add to Group",
      value: "add_to_group",
    });
    availableActions.push({
      label: "Remove participants",
      value: "remove_participants",
    });
  }

  const headerButtons = () => {
    return (
      <div className="flex flex-row justify-end items-center gap-2">
        <FButton
          label="Send"
          primary
          onClick={handleSendOutreach}
          loading={waiting}
        />
        <ButtonDropDown
          label={`Edit${selectedRecipientIds.length > 0 ? `(${selectedRecipientIds.length})` : ""}`}
          className="rounded"
          onChange={function (val: string): void {
            if (val === "edit_name") {
              setEditModalVisible(true);

              setPendingName(outreach.title);
            } else if (val === "archive") {
              handleArchive();
            } else if (val === "create_template") {
              handleCreateTemplate();
            } else if (val === "remove_participants") {
              setConfirmDeleteParticipantsModalVisible(true);
            } else if (val === "add_to_group") {
              setAddToGroupModalVisible(true);
            }
          }}
          options={availableActions}
        />
        <FButton
          label="Add Recipients"
          onClick={handleAddRecipients}
          loading={waiting}
        />
      </div>
    );
  };

  if (loading) {
    return <LoadingSpinner className="w-full h-[100vh]" />;
  }

  return (
    <div>
      {outreach ? (
        <OutreachContext.Provider
          value={{
            outreach,
            setOutreach,
            selectedRecipientIds,
            setSelectedRecipientIds,
          }}
        >
          <MultiPageLayout
            pageName={outreach?.title}
            subNavItems={subNavItems}
            headerButtons={headerButtons()}
          >
            <Outlet />
          </MultiPageLayout>
        </OutreachContext.Provider>
      ) : (
        <div className="flex items-center justify-center mt-[200px]">
          <BlankState
            title={"No Outreach Found"}
            subtitle={"Please try another link or reach out to support"}
            icon={"warning-2"}
            onActionClick={() => {
              window.location.href = "/";
            }}
            actionLabel={"Go Home"}
          />
        </div>
      )}
      {isEditModalVisible ? (
        <Modal
          hasForm
          isOpen
          title="Edit outreach"
          onClose={() => setEditModalVisible(false)}
          className="w-[400px]"
        >
          <div className="modal-content">
            <FInput
              label="Name"
              value={pendingName}
              onChange={(value) => setPendingName(value)}
              width="100%"
              className="mb-[8px]"
            />
            <div className="flex justify-end">
              <FButton
                primary={true}
                onClick={handleSaveUpdates}
                label="Save"
                loading={saving}
                type="button"
              />
            </div>
          </div>
        </Modal>
      ) : null}
      {showAddRecipientsModal ? (
        <UpdateOutreachModal
          onClose={() => setShowAddRecipientsModal(false)}
          onUpdated={(outreach) => {
            setOutreach(outreach);

            setShowAddRecipientsModal(false);
          }}
          outreach={outreach}
          showJustParticipants
        />
      ) : null}
      {isAddToGroupModalVisible ? (
        <AddCreatorsToGroupModal
          creatorIds={selectedRecipientIds}
          onClose={() => {
            setAddToGroupModalVisible(false);
            setSelectedRecipientIds([]);
          }}
          resource={
            selectedRecipientIds.length == outreach.recipients.length
              ? {
                  id: parseInt(outreachId, 10),
                  type: "outreach",
                }
              : null
          }
        />
      ) : null}
      <ConfirmModal
        title="Remove participants"
        isOpen={isConfirmDeleteParticipantsModalVisible}
        isWaiting={isRemovingParticipants}
        onClose={() => setConfirmDeleteParticipantsModalVisible(false)}
        onAction={handleRemoveParticipants}
        actionLabel="Delete"
        subtitle={`Are you sure you want to remove ${selectedRecipientIds.length} participant${selectedRecipientIds.length === 1 ? "" : "s"}?`}
      />
    </div>
  );
};
export default OutreachProfile;
