import ModalContainer from "@components/Modals/ModalContainer";
import FIcon from "@components/FIcon";

const WaitingModal = ({ isOpen, onRequestClose }) => {
  return (
    <ModalContainer isOpen={isOpen} onClose={onRequestClose}>
      <div className="bg-white rounded-lg shadow-2xl p-10">
        {/* Timeline Animation */}
        <div className="timeline">
          <div className="timeline-step sparkle">
            <FIcon icon="photo" size={24} color="#000F45" />
            <p>Capturing Content</p>
          </div>
          <div className="timeline-step sparkle">
            <FIcon icon="graph" size={24} color="#000F45" />
            <p>Gathering Insights</p>
          </div>
          <div className="timeline-step sparkle">
            <FIcon icon="heart" size={24} color="#000F45" />
            <p>Building Engagement</p>
          </div>
          <div className="timeline-step sparkle">
            <FIcon icon="user" size={24} color="#000F45" />
            <p>Profile Ready</p>
          </div>
        </div>
        {/* Message */}
        <p className="text-center text-xl mt-6">Curating Your Profile</p>
        <p className="text-center mt-2">
          We’re crafting stats, pulling insights, and piecing it all together just for you.

        </p>
        <p className="text-center mt-2 text-lg">The magic is almost ready ✨</p>
      </div>
    </ModalContainer>
  );
};

export default WaitingModal;
