import React, { useCallback, useContext } from "react";
import { Sort } from "@types";
import { getReportsList, deleteReport } from "@api/Reports";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { UserContext } from "@contexts/index";
import { changeMembership } from "@api/user";
import toast from "react-hot-toast";

const filterOptions = [
  { label: "All", value: null },
  { label: "Campaigns", value: "campaign" },
  { label: "Monthly", value: "monthly_default" },
  { label: "Custom", value: "null" },
];

const actions = {
  delete: async (id: number) => deleteReport(id),
};

export default function ReportsAdmin() {
  const { user, setUser } = useContext(UserContext);

  const tableColumns = [
    {
      key: "title",
      label: "Title",
      isLink: true,
    },
    { key: "brand_name", label: "Company" },
    {
      key: "membership",
      label: "Your Membership",
      getValue: (item) => {
        const membership = user.memberships.find(
          (membership) => membership.brand_id === item.new_app_brand_id
        );
        return membership ? "User" : ""; // Return appropriate membership label
      },
    },
    { key: "source", label: "Source" },
    { key: "content_count", label: "Content Count" },
    { key: "created_at", label: "Created", isDate: true },
  ];

  const tableRowDropDownOptions = [
    { label: "Edit", value: "edit" },
    { label: "Delete", value: "delete" },
  ];

  const getReports = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search, filter } = params;
      const response = await getReportsList(true, page, sort, {
        source_type_eq: filter,
        title_cont: search,
      });

      return {
        ...response.data,
        items: response.data.reports,
      };
    },
    []
  );

  const handleTableCellClick = (item, columnKey) => {
    const membership = user.memberships.find(
      (membership) => membership.brand_id === item.new_app_brand_id
    );

    if (user.brand_id === item.new_app_brand_id) {
      redirectToGroup(item.id);
    } else if (membership) {
      handleMembershipChange(membership, item);
    } else {
      toast.error(
        "You are not a member of this brand. Add yourself via the Brands page."
      );
    }
  };

  const handleMembershipChange = async (membership, item) => {
    try {
      const result = await changeMembership({ id: membership.id });
      if (result.data.brand_id === membership.brand_id) {
        setUser((prevUser) => ({
          ...prevUser,
          brand_id: membership.brand_id,
        }));
        redirectToGroup(item.id);
      } else {
        console.error("Failed to change membership");
      }
    } catch (error) {
      console.error("Error changing membership:", error);
    }
  };

  const redirectToGroup = (itemId) => {
    window.location.href = `${window.location.origin}/new-reports/${itemId}`;
  };

  return (
    <SinglePageLayoutWithData
      entityName="reports"
      pageTitle="Reports"
      pageName="Reports"
      sortPathName="title"
      sortPathDate="date"
      filterOptions={filterOptions}
      filterPath="state"
      tableColumns={tableColumns}
      rowActions={tableRowDropDownOptions}
      asyncActions={actions}
      onTableCellClick={handleTableCellClick}
      tableOnly
      getItems={getReports}
    />
  );
}
