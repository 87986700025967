import { groupBy, partition, sortBy } from "lodash";
import { QuestionnaireQuestion } from "types/questionnaire";

export interface QuestionnaireQuestionWithChildren
  extends QuestionnaireQuestion {
  children?: Record<string, QuestionnaireQuestion[]>;
}

export const getQuestionsWithChildren = (
  questions: QuestionnaireQuestion[]
): QuestionnaireQuestionWithChildren[] => {
  const [parentQuestions, childQuestions] = partition(
    questions,
    ({ question_id }) => !question_id
  );

  const childQuestionsByParent = groupBy(childQuestions, "question_id");

  const fullQuestions = parentQuestions.map((question) => {
    const children = childQuestionsByParent[question.id];

    if (!children) {
      return question;
    }

    const childrenByAnswer = children.reduce((result, question) => {
      const questionsForAnswer = result[question.conditional_answer] || [];

      return {
        ...result,
        [question.conditional_answer]: [...questionsForAnswer, question],
      };
    }, {});

    return {
      ...question,
      children: childrenByAnswer,
    };
  });

  return sortBy(fullQuestions, "position");
};
