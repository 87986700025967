import React, { useContext, useState } from "react";

import SinglePageLayout from "@layouts/SinglePageLayout";
import { CampaignContext } from "@contexts/index";

import ConfirmModal from "@components/Modals/ConfirmModal";
import apiClient from "@apiClient";

import Modal from "@components/Modals/Modal";

import { toast } from "react-hot-toast";

import { ButtonDropDown } from "@components/ButtonDropDown";
import ParticipantEditForm from "@pages/campaigns/forms/ParticipantsEditForm";
import { Participant, ParticipantStatus } from "@types";
import { capitalize, sortBy } from "lodash";
import { STATUS_OPTIONS } from "../participants";
import BlankState from "@components/BlankState";
import { deleteCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import ParticipantsBulkEditForm from "../forms/ParticipantsBulkEditGroupForm";

const { archive, update } = apiClient.campaign_participant;

const TABLE_COLUMNS = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "{id}",
    isImage: true,
    imageType: "profile" as "asset" | "profile",
    imageKey: "profile_image",
    isFirstColumn: true,
    linkOpensInModal: true,
  },
  {
    key: "status",
    label: "Status",
    getValue: (participant) =>
      participant.status === ParticipantStatus.REVIEW_PENDING
        ? "Pending Review"
        : (participant.status || "")
            .split("_")
            .map((part) => capitalize(part))
            .join(" "),
    isStateBubble: true,
  },
  { key: "gender", label: "Gender" },
  { key: "location", label: "Location" },
  {
    key: "overall_followers",
    label: "Followers",
    getValue: (participant) => {
      const {
        creator: { socials = {} },
      } = participant;

      return Object.keys(socials)
        .reduce((result, key) => {
          const { followers_count = 0 } = socials[key];

          return result + followers_count;
        }, 0)
        .toLocaleString();
    },
  },
  {
    key: "instagram_followers",
    label: "Instagram followers",
    getValue: (participant) => {
      const {
        creator: { socials = {} },
      } = participant;

      return (socials.instagram?.followers_count || 0).toLocaleString();
    },
  },
  {
    key: "tiktok_followers",
    label: "Tiktok followers",
    getValue: (participant) => {
      const {
        creator: { socials = {} },
      } = participant;

      return (socials.tiktok?.followers_count || 0).toLocaleString();
    },
  },
  { key: "created_at", label: "Added", isDate: true },
];

const SAMPLE_CONTENT_COLUMN = {
  key: "viewSampleContent",
  label: "Sample Content",
  isLink: true,
  linkURL:
    "/campaigns/{campaign_id}/participants/{id}/content?showSampleContent=true",
  isOutsideLink: true,
  getValue: () => "Edit sample content",
};

const filterOptions = [{ label: "All", value: null }, ...STATUS_OPTIONS];

const cardDropDownOptions = [
  {
    label: "Profile",
    value: "view_profile",
  },
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Archive",
    value: "archive",
  },
  {
    label: "Delete",
    value: "delete",
  },
];

interface Props {
  participants: Participant[];
  selectedItems: number[];
  onChangeSelectedItems: (ids: number[]) => void;
  onUpdateParticipants: (participants: Participant[]) => void;
  onAddParticipants: () => void;
  isParticipantReview?: boolean;
}

const ParticipantsTable = ({
  participants,
  selectedItems,
  onChangeSelectedItems,
  onUpdateParticipants,
  onAddParticipants,
  isParticipantReview,
}: Props) => {
  const {
    campaign,
    briefs = [],
    participantBriefs = [],
    setCampaign,
  } = useContext(CampaignContext);

  const [isEditModalVisible, setEditModalVisible] = useState(false);

  const [editParticipantsType, setEditParticipantsType] = useState(null);
  const [participantEdited, setParticipantEdited] = useState<Participant>(null);
  const [participantsToArchive, setParticipantsToArchive] = useState(null);
  const [participantsToDelete, setParticipantsToDelete] = useState(null);

  const [isArchivingParticipants, setArchivingParticipants] = useState(false);
  const [isDeletingParticipants, setDeletingParticipants] = useState(false);
  const [isUpdatingParticipants, setUpdatingParticipants] = useState(false);

  const [isBulkEditGroupModalVisible, setBulkEditGroupModalVisible] =
    useState(false);

  const { participant_groups: participantGroups } = campaign || {};

  // Reform the data
  let reformedParticipants = participants.map((participant) => {
    const participantGroup =
      participantGroups.find(
        (group) => group.id === participant.participant_group_id
      ) || null;

    const groupBrief = participantGroup
      ? briefs.find((b) => b.participant_group_id === participantGroup.id)
      : null;

    const participantBrief =
      participantBriefs.find(
        (brief) => brief.participant_id === participant.id
      ) || null;

    const { creator } = participant;

    const {
      firstName,
      lastName,
      profile_image_url: profileImageUrl,
      location,
    } = creator || {};

    return {
      ...participant,
      full_name: creator ? `${firstName} ${lastName}` : "Unknown",
      gender: (participant.gender || []).filter(Boolean),
      profile_image: creator ? profileImageUrl : "",
      location,
      participant_group: participantGroup
        ? participantGroup.name
        : "All Participants",
      brief_id: participantBrief
        ? participantBrief.id
        : groupBrief
          ? groupBrief.id
          : null,
      group_brief_id: groupBrief?.id || "",
      participant_brief: participantBrief ? true : false,
    };
  });

  reformedParticipants = sortBy(
    reformedParticipants,
    ({ created_at }) => created_at
  ).reverse();

  const handleBulkAction = (value) => {
    if (value === "remove") {
      setParticipantsToArchive(
        selectedItems.map((id) =>
          participants.find((participant) => id === participant.id)
        )
      );

      return;
    }

    if (value === "updateGroup") {
      setBulkEditGroupModalVisible(true);

      return;
    }

    setEditParticipantsType(value);
    setEditModalVisible(true);
  };

  const handleArchive = async () => {
    const successfullyArchivedParticipants = [];

    setArchivingParticipants(true);

    for (const participant of participantsToArchive) {
      try {
        await archive(participant.id);

        successfullyArchivedParticipants.push(participant);
      } catch (e) {
        toast.error(
          `Error archiving participant ${participant.creator?.firstName}`
        );
      }
    }

    setArchivingParticipants(false);

    setParticipantsToArchive(null);

    const newParticipants = campaign.participants.map((participant) => {
      if (
        successfullyArchivedParticipants.find(({ id }) => id === participant.id)
      ) {
        return {
          ...participant,
          status: "archived",
        };
      }

      return participant;
    });

    onUpdateParticipants(newParticipants);

    if (
      successfullyArchivedParticipants.length === participantsToArchive.length
    ) {
      toast.success("Archived");
    }
  };

  const handleDelete = async () => {
    setDeletingParticipants(true);

    for (const participant of participantsToDelete) {
      try {
        await deleteCampaignParticipant(participant.id);
      } catch (e) {
        toast.error(
          `Error deleting participant ${participant.creator?.firstName}`
        );
      }
    }

    toast.success("Deleted");

    setDeletingParticipants(false);

    setParticipantsToDelete(null);

    const newParticipants = campaign.participants.filter(
      (participant) =>
        !participantsToDelete.map(({ id }) => id).includes(participant.id)
    );

    onUpdateParticipants(newParticipants);
  };

  const handleCloseEditModal = () => {
    setParticipantEdited(null);
    setEditModalVisible(false);
  };

  const handleTableRowDropdownSelect = (value: string, id: number) => {
    const participant = participants.find(
      (participant) => participant.id === id
    );

    if (value === "edit") {
      setParticipantEdited(participant);
      setEditModalVisible(true);
    } else if (value === "archive") {
      setParticipantsToArchive([participant]);
    } else if (value === "delete") {
      setParticipantsToDelete([participant]);
    }
  };

  const updateParticipants = async ({ offer, name, ...data }) => {
    const participantsToUpdate = participantEdited
      ? [participantEdited.id]
      : selectedItems;

    const updates = participantEdited ? data : { status: data.status };

    setUpdatingParticipants(true);

    if (editParticipantsType === "offer") {
      console.log(data, "Offer data", offer, name);
    } else if (editParticipantsType === "status" || participantEdited) {
      const updatePromises = participantsToUpdate.map(async (participantId) => {
        try {
          const response = await update(participantId, updates);
          return response;
        } catch (error) {
          console.error(`Error updating participant ${participantId}:`, error);
          return {
            success: false,
            message: `Failed to update participant ${participantId}`,
          };
        } finally {
          setUpdatingParticipants(false);
        }
      });

      const results = await Promise.all(updatePromises);

      const successCount = results.filter((result) => result.success).length;
      const failCount = results.length - successCount;

      if (successCount > 0) {
        toast.success(`Updated`);
      }
      if (failCount > 0) {
        toast.error(`Failed to update ${failCount} participant(s)`);
      }

      // Update the participants state with the new data
      const newParticipants = campaign.participants.map((participant) =>
        participantsToUpdate.includes(participant.id)
          ? { ...participant, ...updates }
          : participant
      );

      setCampaign({
        ...campaign,
        participants: newParticipants,
      });

      setEditModalVisible(false);
    }
  };

  const sortedParticipants = sortBy(reformedParticipants, [
    (item) =>
      STATUS_OPTIONS.map(({ value }) => value).indexOf(
        item.status as ParticipantStatus
      ),
    (item) => item.full_name,
  ]);

  const tableColumns = [...TABLE_COLUMNS, SAMPLE_CONTENT_COLUMN];

  const selectedItemsForGroup = selectedItems.filter((id) =>
    participants.find((p) => p.id === id)
  );

  return (
    <>
      <SinglePageLayout
        pageName=""
        cardData={sortedParticipants}
        defaultGrid={false}
        cardType="participant"
        className="pt-[0px]"
        addAssetCard={true}
        sortPathName="full_name"
        sortPathDate="created_at"
        filterOptions={filterOptions}
        filterPath="status"
        decorationPath="status"
        tableColumns={tableColumns}
        getRowActions={
          isParticipantReview
            ? null
            : (id: number) => {
                const participant = participants.find((p) => p.id === id);

                return participant?.status === ParticipantStatus.NON
                  ? cardDropDownOptions
                  : cardDropDownOptions.filter(
                      (option) => option.value !== "delete"
                    );
              }
        }
        subNavMainButton={
          selectedItemsForGroup.length > 0 && (
            <ButtonDropDown
              zIndex={99999}
              label={`Edit (${selectedItemsForGroup.length})`}
              options={[
                { label: "Status", value: "status" },
                { label: "Offer", value: "offer" },
                { label: "Update Group", value: "updateGroup" },
                { label: "Remove", value: "remove" },
              ]}
              onChange={(value) => handleBulkAction(value)}
            />
          )
        }
        cardDropDownOptions={cardDropDownOptions}
        onChangeSelectedItems={onChangeSelectedItems}
        onTableRowDropDownSelect={handleTableRowDropdownSelect}
        selectedItems={selectedItems}
        customBlankState={
          <BlankState
            title="No participants found"
            subtitle=""
            icon="user"
            actionLabel={onAddParticipants ? "Add Participants" : null}
            onActionClick={onAddParticipants}
          />
        }
      />
      {isEditModalVisible ? (
        <Modal
          isOpen
          className="w-[540px]"
          onClose={handleCloseEditModal}
          zIndex={9000}
          title="Update Participant Status"
          hasForm
          actionLabel="Save"
        >
          <ParticipantEditForm
            editType={editParticipantsType}
            isUpdating={isUpdatingParticipants}
            participant={participantEdited}
            participantGroups={participantGroups}
            onSubmit={updateParticipants}
            onClose={handleCloseEditModal}
          />
        </Modal>
      ) : null}
      {isBulkEditGroupModalVisible ? (
        <Modal
          isOpen
          className="w-[540px]"
          onClose={() => setBulkEditGroupModalVisible(false)}
          title="Update Group"
          hasForm
        >
          <ParticipantsBulkEditForm
            campaign={campaign}
            participantIds={selectedItems}
            onParticipantsUpdated={(participants) => {
              setBulkEditGroupModalVisible(false);

              onUpdateParticipants(participants);
            }}
            onClose={() => setBulkEditGroupModalVisible(false)}
          />
        </Modal>
      ) : null}
      {participantsToArchive ? (
        <ConfirmModal
          title="Archive"
          isOpen
          isWaiting={isArchivingParticipants}
          onClose={() => setParticipantsToArchive(null)}
          onAction={handleArchive}
          actionLabel="Archive"
          subtitle={`Are you sure you want to archive ${participantsToArchive.map(({ creator }) => creator?.firstName).join(", ")}?`}
        />
      ) : null}
      {participantsToDelete ? (
        <ConfirmModal
          title="Delete"
          isOpen
          isWaiting={isDeletingParticipants}
          onClose={() => setParticipantsToDelete(null)}
          onAction={handleDelete}
          actionLabel="Delete"
          subtitle={`Are you sure you want to delete ${participantsToDelete.map(({ creator }) => creator?.firstName).join(", ")}?`}
        />
      ) : null}
    </>
  );
};

export default ParticipantsTable;
