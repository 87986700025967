import moment from "moment";
import FRangePicker from "@components/FDatePicker/FRangePicker";
import LocationFilter from "./LocationFilter";
import ModalSection from "./ModalSection";

import { AdvancedSearchParams } from "@pages/search/types";
import FormField from "@components/Form/FormField";

interface ContentDetailsProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const ContentDetails = ({ filters, onFiltersUpdate }: ContentDetailsProps) => {
  const { date_from, date_to } = filters;

  return (
    <ModalSection icon="content" title="Content Details">
      <FormField label="Date Posted Range" width="700px">
        <FRangePicker
          maxDate={moment().format()}
          value={{
            from: date_from,
            to: date_to,
          }}
          onChange={({ from, to }) =>
            onFiltersUpdate({
              date_from: from,
              date_to: to,
            })
          }
        />
      </FormField>
      <div className="mb-8" />
      <LocationFilter filters={filters} onFiltersUpdate={onFiltersUpdate} />
    </ModalSection>
  );
};

export default ContentDetails;
