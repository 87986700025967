export const EVENTS = {
  PAGE_VIEW: {
    CAMPAIGNS: "Campaigns Page Viewed",
    CAMPAIGN: "Campaign Page Viewed",
    REPORTS: "Reports Page Viewed",
    REPORT: "Report Page Viewed",
    SEARCH: "Search Page Viewed",
    GROUPS: "Groups Page Viewed",
    GROUP: "Group Page Viewed",
    BULLETINS: "Bulletins Page Viewed",
    BULLETIN: "Bulletin Page Viewed",
    OUTREACHES: "Outreaches Page Viewed",
    OUTREACH: "Outreach Page Viewed",
    PROFILE: "Profile Page Viewed",
    COLLABORATORS: "Collaborators Page Viewed",
    AMBASSADORS: "Ambassadors Page Viewed",
    NOTIFICATIONS: "Notifications Page Viewed"
  },
};