import { CREATOR_CATEGORIES } from "@api/Search";
import ModalSection from "./ModalSection";
import FMultiSelect from "@components/FMultiSelect";

import { AdvancedSearchParams } from "@pages/search/types";

interface InfluencerCategoriesProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
}

const InfluencerCategories = ({
  filters,
  onFiltersUpdate,
}: InfluencerCategoriesProps) => {
  const { tags } = filters;

  return (
    <ModalSection icon="tag" title="Influencer Categories">
      <FMultiSelect
        width="225px"
        label="Categories"
        selected={tags}
        options={Object.keys(CREATOR_CATEGORIES).map((key) => ({
          value: key,
          label: CREATOR_CATEGORIES[key],
        }))}
        onChange={(value) => onFiltersUpdate({ tags: value })}
      />
    </ModalSection>
  );
};

export default InfluencerCategories;
