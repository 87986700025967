import React, { useState } from "react";

import FButton from "@components/FButton";
import FDropdown from "@components/FDropdown";
import { Campaign, Participant } from "@types";
import { updateCampaignParticipant } from "@api/Campaign/CampaignParticipants";
import toast from "react-hot-toast";

interface ParticipantsBulkEditFormProps {
  campaign: Campaign;
  participantIds?: number[];
  onParticipantsUpdated: (participants: Participant[]) => void;
  onClose: () => void;
}

const ParticipantsBulkEditForm = ({
  campaign,
  participantIds,
  onParticipantsUpdated,
  onClose,
}: ParticipantsBulkEditFormProps) => {
  const { participants, participant_groups } = campaign;

  const [selectedParticipantGroupId, setSelectedParticipantGroupId] = useState(
    participant_groups[0].id
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmit = async () => {
    setIsUpdating(true);

    try {
      for (const participantId of participantIds) {
        await updateCampaignParticipant(participantId, {
          participant_group_id: selectedParticipantGroupId,
        });
      }

      const newParticipants = participants.map((participant) => {
        if (participantIds.includes(participant.id)) {
          return {
            ...participant,
            participant_group_id: selectedParticipantGroupId,
          };
        }

        return participant;
      });

      const groupName = participant_groups.find(
        ({ id }) => id === selectedParticipantGroupId
      ).name;

      toast.success(`Participants moved to ${groupName}`);

      onParticipantsUpdated(newParticipants);
    } catch {
      toast.error("Error updating participants");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="max-h-[60vh] overflow-y-auto">
      <div className="flex flex-col gap-4 h-[100px]">
        <FDropdown
          height="40"
          width="100%"
          selectedValue={selectedParticipantGroupId}
          onChange={(value) => setSelectedParticipantGroupId(value as number)}
          options={participant_groups.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
      </div>
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 pt-6 border-t border-light_border">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={handleSubmit}
          label="Save"
          loading={isUpdating}
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
};

export default ParticipantsBulkEditForm;
