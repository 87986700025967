import React from "react";

import FormField from "@components/Form/FormField";

interface Props {
  className?: string;
  label?: string;
  id?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  maxLength?: number;
  success?: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  width?: number | string;
  name?: string;
  style?: any;
  value?: string;
  validate?: boolean;
  rows: number;
}

const FTextarea = ({
  className = "",
  label = "",
  width = 250,
  error = "",
  success = "",
  validate,
  required,
  value,
  onChange,
  rows,
  ...props
}: Props) => {
  return (
    <FormField
      className={className}
      error={
        error || (validate && required && !value && `${label} is required`)
      }
      label={label}
      required={required}
      success={success}
      width={width}
    >
      <textarea
        id={props?.id}
        className="rounded bg-white border border-default_weak p-2 w-full"
        onChange={(e) => onChange(e.target.value)}
        rows={rows}
        value={value}
        {...props}
      />
    </FormField>
  );
};

export default FTextarea;
