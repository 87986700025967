import React, { ReactNode, useEffect, useState } from "react";

import { getMe } from "@api/user";
import { User } from "@types";
import { UserContext } from "@contexts/index";

import { Role } from "@constants/roles";

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps): JSX.Element => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (window.location.pathname.includes("/login")) {
      setLoading(false);

      return;
    }

    getMe()
      .then((user) => setUser(user))
      .finally(() => setLoading(false));
  }, []);

  const can = (permission: string): boolean => {
    if (!user || !user.permissions) {
      return false;
    }

    if (user.role === Role.SUPER_ADMIN) {
      return true;
    }

    return user.permissions[permission] === true;
  };

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <UserContext.Provider value={{ user, setUser, can, loading }}>
      {!loading && children}
    </UserContext.Provider>
  );
};
