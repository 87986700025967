import * as React from "react";
import { useCallback, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import FDropdown from "@components/FDropdown";
import Notification from "./Notification";
import { getUserNotificationList } from "@api/Notifications/UserNotifications";
import LoadingSpinner from "@components/LoadingSpinner";
import FPagination from "@components/FPagination";
import { UserNotification, UserNotificationStatus } from "@types";
import { uniqBy } from "lodash";
import Heading from "@components/Heading";
import BlankState from "@components/BlankState";
import FButton from "@components/FButton";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const PAGE_SIZE = 12;

const NOTIFICATION_TYPES = [
  { label: "All Types", value: "" },
  { label: "Offers", value: "offer" },
  { label: "Content Review", value: "content_review" },
  { label: "Posts", value: "post" },
  { label: "Connections", value: "connections" },
  { label: "Live Content Due", value: "live_content_due" },
];

const STATUSES = [
  { label: "Status", value: "" },
  { label: "Unread", value: UserNotificationStatus.UNREAD },
  { label: "Read", value: UserNotificationStatus.READ },
];

const FILTERS = [
  {
    filter: "notification_campaign_id_eq",
    options: [{ label: "All Campaigns", value: "" }],
  },
  {
    filter: "notification_category_eq",
    options: NOTIFICATION_TYPES,
  },
  {
    filter: "status_eq",
    options: STATUSES,
  },
];

export default function Notifications() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const [campaigns, setCampaigns] = useState([]);

  const [filters, setFilters] = useState<{
    [key: string]: string | number | boolean;
  }>({ status_eq: UserNotificationStatus.UNREAD });

  const [page, setPage] = useState(1);

  const [total, setTotal] = useState(0);
  const { trackEvent } = useContext(MixpanelContext);

  const getNotifications = useCallback(async (page, filters) => {
    setLoading(true);

    try {
      const response = await getUserNotificationList(page, {
        ...filters,
        per_page: PAGE_SIZE,
      });

      const { user_notifications: notifications, total_items: total } =
        response.data;

      setNotifications(notifications);
      setTotal(total);
      trackEvent(EVENTS.PAGE_VIEW.NOTIFICATIONS);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadCampaigns = useCallback(async () => {
    // TODO: BE has to give us a way to load all campaign options
    const response = await getUserNotificationList(1, {
      per_page: 0,
    });

    const { user_notifications: notifications } = response.data;

    const campaigns = notifications.map(({ campaign_id, campaign_name }) => ({
      value: campaign_id,
      label: campaign_name,
    }));

    setCampaigns(uniqBy(campaigns, "value"));
  }, []);

  const handleNotificationUpdated = (notificationId, updates) => {
    if (updates.status) {
      getNotifications(page, filters);

      return;
    }

    setNotifications(
      notifications.map((notification) =>
        notification.id !== notificationId
          ? notification
          : { ...notification, ...updates }
      )
    );
  };

  useEffect(() => {
    getNotifications(page, filters);
  }, [getNotifications, filters, page]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const allFilters = FILTERS.map((filter, index) => {
    if (index === 0) {
      return {
        ...filter,
        options: [...filter.options, ...campaigns],
      };
    }

    return filter;
  });

  return (
    <div className="max-w-[1000px] m-auto -mt-[40px] pb-[80px]">
      <div className="flex flex-col justify-between items-start sticky top-0 z-50 pt-10 bg-light_red_opacity backdrop-blur-sm">
        <Heading
          title="Notifications"
          className="w-full"
          headerButtons={
            <FButton
              iconLeft={{ name: "settings", size: 20, color: "black" }}
              label="Settings"
              onClick={() => {
                navigate("/notifications/settings");
              }}
            />
          }
        />
        <div className="flex items-center gap-[6px] mt-8 my-[16px] w-full">
          {allFilters.map(({ filter, options }) => (
            <FDropdown
              key={filter}
              options={options}
              height={40}
              width={"100%"}
              className="font-sofia-pro h-[40px] text-[22px] text-dark_night_sky w-full"
              onChange={(value) => {
                const newFilters = {
                  ...filters,
                  [filter]: value,
                };

                setPage(1);
                setFilters(newFilters);
              }}
              selectedValue={filters[filter]}
            />
          ))}
        </div>
      </div>
      {loading ? (
        <LoadingSpinner className="w-full h-full mt-[20%]" />
      ) : notifications.length === 0 ? (
        <BlankState
          title="No notifications"
          subtitle="You don't have any notifications at the moment"
          icon="user"
          onActionClick={() => {}}
          actionLabel="Clear All"
        />
      ) : (
        <div className="w-full">
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              onNotificationUpdated={(updates) =>
                handleNotificationUpdated(notification.id, updates)
              }
            />
          ))}
          {total > 0 && (
            <div className="mt-[16px]">
              <FPagination
                page={page}
                pageSize={PAGE_SIZE}
                total={total}
                onPageChange={setPage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
