import React, { useState, useEffect, useRef } from "react";
import FIcon from "./FIcon";

interface ButtonDropDown {
  className?: string;
  selectedValue?: string | number | boolean;
  onChange: (val: string | number | boolean, event?: React.MouseEvent) => void;
  options: {
    label: string;
    value: any;
    static?: string;
  }[];
  disabled?: boolean;
  icon?: string;
  zIndex?: number;
  label?: string;
  primary?: boolean;
  iconOnly?: boolean;
  alignDropDownRight?: boolean;
}

export const ButtonDropDown = ({
  className = "",
  options,
  onChange,
  disabled = false,
  iconOnly = false,
  icon = "",
  label = "",
  zIndex = 1,
  primary = false,
  alignDropDownRight = false,
}: ButtonDropDown) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleOptionClick = (
    event: React.MouseEvent,
    value: string | number
  ) => {
    onChange(value, event);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div
      ref={dropdownRef}
      className={`flex flex-col items-start ${
        isOpen ? "z-50" : ""
      }`}
    >
      <div className="relative w-full">
        <div
          className={`flex items-center w-auto ${
            primary
              ? "bg-dark_night_sky border border-dark_night_sky text-white"
              : "bg-light_red border border-neutral_400 text-dark_night_sky hover:border-neutral_600"
          } h-[40px] inline-block text-sm flex items-center justify-center font-medium text-center px-[16px] py-[10px] gap-[8px] cursor-pointer ${className}`}
          onClick={handleToggleDropdown}
        >
          {icon && (
            <FIcon icon={icon} size={18} color={primary ? "#fff" : "#000F45"} />
          )}
          {label}
          {!iconOnly && (
            <FIcon
              icon="chevron"
              size={11}
              color={primary ? "#fff" : "#000F45"}
              className={isOpen ? "rotate-180" : ""}
            />
          )}
        </div>
        {isOpen && (
          <div
            className={`bg-white shadow-lg absolute border border-neutral_400 cursor-pointer  text-sm font-normal overflow-y-auto ${alignDropDownRight ? " right-0 " : "left-0"} top-[42px]`}
            style={{
              zIndex: zIndex || 50,
              minWidth: "100%",
              width: "max-content",
            }}
          >
            <div className="flex flex-col flex-nowrap w-full">
              {options.map((o) => (
                <div
                  key={o.value}
                  className="px-[12px] py-[8px] hover:bg-neutral_100 text-[15px] whitespace-nowrap"
                  onClick={(e) => handleOptionClick(e, o.value)}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
