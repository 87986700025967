import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { maxBy, uniq } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import WidgetContainer from "./WidgetContainer";

const formatAgeRange = (range: string) => {
  if (!range) return "";

  // Remove "Age " prefix if it exists
  const cleanRange = range.replace(/^Age\s+/, "");

  if (cleanRange.includes("and under")) {
    return `<17`;
  }
  if (cleanRange.includes("and over")) {
    return `>65`;
  }

  // Convert ranges like "21 to 24" to "21-24"
  return cleanRange.replace(" to ", "-");
};

interface AgeBreakdownChartProps {
  items: DemographicsBreakdownItem[];
  showCounts?: boolean;
}

const AgeBreakdownChart: React.FC<AgeBreakdownChartProps> = ({
  items = [],
  showCounts = false,
}) => {
  if (!items?.length) return null;

  const options = {
    chart: {
      height: 300,
    },
    tooltip: {
      pointFormatter: function () {
        return `<b>${this.y}%</b>${showCounts ? ` (${this.count} user${this.count === 1 ? "" : "s"})` : ``}`;
      },
    },
    xAxis: {
      type: "category",
      labels: {
        formatter: function () {
          return formatAgeRange(this.value);
        },
      },
    },
    yAxis: {
      labels: {
        formatter: function () {
          return `${this.value}%`;
        },
        style: {
          color: "#000721",
          fontSize: "12",
        },
      },
    },
  };

  const chartData = items
    .filter((item) => item && item.name) // Filter out any invalid items
    .map((item) => ({
      ...item,
      name: formatAgeRange(item.name),
    }));

  const maxValueItem = maxBy(items, "percentage");

  const series = [
    {
      categories: uniq(chartData.map((item) => item.name)),
      data: chartData.map(({ name, percentage, count }) => ({
        name,
        y: percentage,
        color: name === maxValueItem?.name ? "#1F8855" : "#C9CDD9",
        count,
      })),
      name: "Age range percentage",
      type: "column",
    },
  ];

  const optionsWithSeries = {
    ...options,
    series,
  };

  return (
    <WidgetContainer
      title="Age"
      value={formatAgeRange(maxValueItem?.name || "")}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsWithSeries}
        width="100%"
      />
    </WidgetContainer>
  );
};

export default AgeBreakdownChart;
