import React, { useState } from "react";
import FButton from "@components/FButton";
import { FInput } from "@components/FInputs";
import Modal from "@components/Modals/Modal";
import { toast } from "react-hot-toast";
import { sendTestOutreach } from "@api/Outreach/Outreach"

interface Props {
  outreachId: number | string;
  isOpen: boolean;
  onClose: () => void;
}

const TestEmailModal = ({ outreachId, isOpen, onClose }: Props) => {
  const [emailList, setEmailList] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await toast.promise(sendTestOutreach(outreachId, emailList), {
        loading: "Sending test emails...",
        success: "Emails sent successfully!",
        error: "Failed to send test emails! Have you filled every required field?",
      });

      if (response?.success) {
        console.log({response})
      }
    } catch (error) {
      console.error("Failed to send test emails:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Test Email"
      className="w-[800px]"
      hasForm
    >
      <div className="flex flex-col gap-6">
        <FInput
          label="Recipients"
          description="(add a list of email addresses separated by commas)"
          width="100%"
          required
          value={emailList}
          onChange={(value) => setEmailList(value)}
        />
      </div>
      <div className="flex justify-end space-x-2 pt-4 mt-10">
        <FButton type="submit" label="Send" onClick={handleSubmit} primary />
      </div>
    </Modal>
  );
};

export default TestEmailModal;
