import apiWrapper from "@apiWrapper";
import { CreateCampaignBriefAssetParams } from "@types";

const getFormData = (params) => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    formData.append(`campaign_brief_asset[${key}]`, params[key]);
  });

  return formData;
};

export const createCampaignBriefAsset = async (
  params: CreateCampaignBriefAssetParams
) => {
  const formData = getFormData(params);

  return apiWrapper(`/campaign_brief_assets`, "POST", formData);
};

export const updateCampaignBriefAsset = async (id: number, params: any) => {
  return apiWrapper(`/campaign_brief_assets/${id}`, "PUT", params);
};

export const deleteCampaignBriefAsset = async (id: number) => {
  return apiWrapper(`/campaign_brief_assets/${id}`, "DELETE");
};

export const uploadCampaignBriefAssetFiles = async (id: number, file: File) => {
  const formData = getFormData({ file });

  const response = await apiWrapper(
    `/campaign_brief_assets/${id}`,
    "PUT",
    formData
  );

  return response.data;
};
