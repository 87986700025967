import React from "react";
import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";
import CircleChart from "@components/Graphs/CircleChart";
import WidgetContainer from "./WidgetContainer";

interface ReligionBreakdownProps {
  items: DemographicsBreakdownItem[];
}

export default function ReligionBreakdown({ items }: ReligionBreakdownProps) {
  const maxValueItem = maxBy(items, "percentage");

  return (
    <WidgetContainer title="Religion" value={maxValueItem?.name || ""}>
      <div className="flex items-center justify-center h-[300px]">
        <div className="w-[80%] h-[80%]">
          <CircleChart data={items || []} />
        </div>
      </div>
    </WidgetContainer>
  );
}
