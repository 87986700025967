import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { CampaignProduct } from "@types";

const updateCampaignProduct = async (
  product_id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value !== null) {
      formData.append(`campaign_product[${key}]`, value);
    }
  });

  return apiWrapper(`/campaign_products/${product_id}`, "PUT", formData);
};

const deleteCampaignProduct = async (
  product_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/campaign_products/${product_id}`, "DELETE");
};

const createCampaignProduct = async (
  campaign_id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  const formData = new FormData();

  formData.append("campaign_product[campaign_id]", campaign_id.toString());
  
  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value !== null) {
      formData.append(`campaign_product[${key}]`, value);
    }
  });

  return apiWrapper("/campaign_products", "POST", formData);
};

const getCampaignProductList = async (
  campaign_id: string | number
): Promise<ApiResponse<CampaignProduct[]>> => {
  return apiWrapper(
    `/campaign_products?q[campaign_id_eq]=${campaign_id}`,
    "GET"
  );
};

export {
  updateCampaignProduct,
  deleteCampaignProduct,
  createCampaignProduct,
  getCampaignProductList,
};
