import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import SinglePageLayoutWithData from "../../../src/layouts/SinglePageLayoutWithData";
import { getOffPlatformCollaboratorsList } from "../../api/Collaborators";
import { Sort } from "@types";
import FButton from "@components/FButton";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";

const tableColumns = [
  {
    key: "full_name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{slug}",
    isFirstColumn: true,
    linkTarget: "_blank",
  },
  {
    key: "overall_followers",
    label: "Total Followers",
    isNumber: true
  },
];

const OffPlatformCollaboratorsList = () => {
  const { trackEvent } = useContext(MixpanelContext);

  const fetchCollaborators = useCallback(
    async (page?: number, sort?: Sort, params?: { search?: string }) => {
      const { search } = params;
      const response = await getOffPlatformCollaboratorsList(page, sort, {
        firstName_or_lastName_cont: search,
      });
      const { creators: items } = response.data;
      trackEvent(EVENTS.PAGE_VIEW.COLLABORATORS, { section: 'off platform'});

      return {
        ...response.data,
        items,
      };
    },
    []
  );
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-end mb-4">
        <FButton
          label="Create Off Platform"
          primary
          onClick={() => navigate(`/off-platform-creator`)}
        />
      </div>
      <SinglePageLayoutWithData
        entityName="creators"
        pageName="Off Platform Collaborators"
        sortPathName="creator_fullname"
        filterPath="state"
        tableColumns={tableColumns}
        tableOnly
        hideCreateNewButton
        getItems={fetchCollaborators}
      />
    </div>
  );
};

export default OffPlatformCollaboratorsList;
