import React from "react";
import FIcon from "@components/FIcon";

interface TitleUnderProps {
  details: Array<{
    icon: string;
    value: string;
    name: string;
  }>;
  white?: boolean;
}

const TitleUnder: React.FC<TitleUnderProps> = ({ details, white }) => {
  return (
    <div
      className={`flex gap-2 flex-wrap items-center justify-start text-neutral_500 text-[16px] ${
        white ? "text-white" : ""
      }`}
    >
      {details
        .filter((data) => data.value && data.value !== "")
        .map((data, i, filteredArray) => (
          <div key={i} className="flex gap-[5px] text-[15px] items-center">
            {data.icon && (
              <FIcon
                icon={data.icon}
                size={12}
                color={white ? "#fff" : "#000F45"}
              />
            )}
            <span className={`${white ? "text-white" : "text-dark_night_sky"}`}>
              {data.value}
            </span>
            {i < filteredArray.length - 1 && <span className=""> • </span>}
          </div>
        ))}
    </div>
  );
};

export default TitleUnder;
