import React, { useContext, useState } from "react";

import { FInput } from "../../components/FInputs";

import FButton from "../../components/FButton";
import { FCheckbox } from "../../components/FInputs";
import { UserContext } from "@contexts/index";

const ProfileSettings = () => {
  const { user } = useContext(UserContext);

  const [waiting, setWaiting] = useState(false);

  const [basicInfo, setBasicInfo] = useState({
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
  });

  const [passwordResetValues, setPasswordResetValues] = useState({
    password: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });

  const basicInfoFields = [
    { label: "First Name", name: "firstName", type: "firstName" },
    { label: "Last Name", name: "lastName", type: "lastName" },
    { label: "Email", name: "email", type: "email" },
  ];

  const passwordResetFields = [
    { label: "Current Password", name: "password", type: "password" },
    { label: "New Password", name: "newPassword", type: "newPassword" },
    {
      label: "Confirm New Password",
      name: "newPasswordConfirmation",
      type: "newPasswordConfirmation",
    },
  ];

  const handleSubmit = () => {
    setWaiting(true);
  };

  return (
    <div className="max-w-[800px]">
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Basic Info</h1>
        {basicInfoFields.map(({ name, label }) => (
          <FInput
            label={label}
            className="mb-[16px]"
            key={name}
            required
            value={basicInfo[name]}
            onChange={(value) => setBasicInfo({ ...basicInfo, [name]: value })}
            width="100%"
          />
        ))}
        <div className="mt-[30px] flex justify-end max-w-[200px]">
          <FButton
            loading={waiting}
            primary
            onClick={handleSubmit}
            label="Save"
            width="100%"
            type="submit"
          />
        </div>
      </div>
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Reset Password</h1>
        {passwordResetFields.map(({ name, label }) => (
          <FInput
            className="mb-[16px]"
            label={label}
            required
            width="100%"
            value={passwordResetValues[name]}
            onChange={(value) =>
              setPasswordResetValues({
                ...passwordResetValues,
                [name]: value,
              })
            }
          />
        ))}
        <div className="mt-[30px] flex justify-end max-w-[200px]">
          <FButton
            primary
            loading={waiting}
            label="Update Password"
            width="100%"
            type="submit"
          />
        </div>
      </div>
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Email Preferences</h1>
        <div className="flex flex-col gap-4">
          <FCheckbox
            label="Send me general information about events, news, etc."
            id="checkbox1"
            onChange={(e) => console.log("Checkbox 1:", e)}
          />
          <FCheckbox
            label="Send me a weekly digest of new and notable influencers"
            id="checkbox2"
            onChange={(e) => console.log("Checkbox 2:", e)}
          />
          <FCheckbox
            label="Send me a weekly digest of influencers who mentioned me"
            id="checkbox3"
            onChange={(e) => console.log("Checkbox 3:", e)}
          />
        </div>
      </div>
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Log Out of Fohr</h1>
        <div className="mt-[30px] flex justify-end max-w-[200px]">
          <FButton primary={true} label="Log Out" width="100%" type="submit" />
        </div>
      </div>
      <div className="py-[40px]">
        <h1 className="text-[24px] mb-[24px]">Delete Fohr Account</h1>
        <p className="mb-4">
          If you would no longer like to keep your account with Fohr, please
          reach out to product@fohr.co to cancel.
        </p>
        <div className="flex max-w-[200px]">
          <a href="mailto:product@fohr.co">
            <FButton
              primary={false}
              label="Delete my account"
              width="100%"
              type="button"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
