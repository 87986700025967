import moment from "moment";
import React from "react";
import { useState } from "react";

import { Campaign } from "@types";
import { updateCampaign } from "@api/Campaign/Campaigns";
import FButton from "@components/FButton";
import FInput from "@components/FInputs/FInput";

interface CampaignPageHeaderProps {
  campaign: Campaign;
}

export default function CampaignPageHeader({
  campaign,
}: CampaignPageHeaderProps) {
  
  const { brand_name: brandName, brief, title } = campaign;
  
  const { cover_image, go_live_start, go_live_end } = brief;
  
  const { large, original, thumb } = cover_image || {};
  
  const coverImageSrc =
  original || thumb || large || "/images/image_placeholder.png";
  
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [pendingTitle, setPendingTitle] = useState("");
  const [displayTitle, setDisplayTitle] = useState(title);
  const [isSaving, setIsSaving] = useState(false);

  const handleEditTitle = () => {
    setPendingTitle(title);
    setIsEditingTitle(true);
  };

  const handleSubmitTitle = async () => {
    if (!pendingTitle) {
      return;
    }

    setIsSaving(true);

    try {
      await updateCampaign(campaign.id, { title: pendingTitle });
    } catch (error) {
      console.error("Failed to submit title:", error);
    } finally {
      setIsSaving(false);
      setDisplayTitle(pendingTitle);
    }

    setIsEditingTitle(false);
  };

  return (
    <div className="flex items-center gap-5">
      <div
        className="w-[72px] h-[72px] bg-cover bg-center relative overflow-hidden rounded-lg"
        style={{ backgroundImage: `url("${coverImageSrc}")` }}
      />
      <div>
        <div className="flex gap-2 h-[40px]">
          {isEditingTitle ? (
            <FInput
              type="text"
              value={pendingTitle}
              onChange={setPendingTitle}
            />
          ) : (
            <h1 className="h-[30px] leading-[36px] text-[28px] self-center">
              {displayTitle}
            </h1>
          )}
          {isEditingTitle ? (
            <FButton
              label="Save"
              onClick={handleSubmitTitle}
              primary
            />
          ) : (
            <FButton
              icon={{ name: "edit", size: 14 }}
              iconButton
              onClick={handleEditTitle}
            />
          )}
        </div>

        <div className="flex items-center gap-2 mt-[8px] text-neutral_500">
          {brandName}
          <span className="text-neutral_500 text-[16px] "> • </span>
          {[
            moment(go_live_start).format("MMMM D, YYYY"),
            moment(go_live_end).format("MMMM D, YYYY"),
          ].join(" - ")}
        </div>
      </div>
    </div>
  );
}
