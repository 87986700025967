// Refactor

import React, { useState, useEffect, useRef } from "react";
import FIcon from "./FIcon";
import { FInput } from "./FInputs";
import FormLabel from "./Form/FormLabel";
import IconWarning from "./Form/icons/IconWarning";

export interface FDropdownOption {
  value: string | number | boolean;
  label: string;
  static?: string;
}

interface DropdownProps {
  className?: string;
  selectedValue?: string | number | boolean;
  onChange?: (val: string | number | boolean, event?: React.MouseEvent) => void;
  width?: number | string;
  limitedWidth?: boolean; // This will limit the width of the options to the given width prop
  options: FDropdownOption[];
  style?: any;
  iconColor?: string;
  iconOnly?: boolean;
  disabled?: boolean;
  leftLabel?: string;
  icon?: string;
  height?: string | number;
  titleDropDown?: boolean; // Makes the dropdown act as a title
  label?: string;
  required?: boolean;
  inputName?: string;
  zIndex?: number;
  hasNoDefault?: boolean;
  menuUp?: boolean;
  hardClose?: boolean;
  placeholder?: string;
  transparent?: boolean;
  validate?: boolean;
  small?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const FDropdown = ({
  className = "",
  options,
  selectedValue,
  onChange,
  placeholder,
  width = 202,
  limitedWidth = false,
  iconOnly = false,
  leftLabel = "",
  disabled = false,
  iconColor = "#000F45",
  icon = "",
  height = 40,
  titleDropDown = false,
  label = "",
  hardClose = false,
  required = false,
  menuUp = false,
  inputName = "",
  zIndex = 1,
  transparent = false,
  validate,
  small,
  onOpenChange,
  ...props
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropDownTop, setDropDownTop] = useState("44px");
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const displayedOption = options.find((o) => o.value === selectedValue);

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onOpenChange && onOpenChange(isOpen);
  };

  useEffect(() => {
    const numericHeight = Number(height);
    const calculatedTop = isNaN(numericHeight)
      ? "44px"
      : `${numericHeight + 4}px`;
    setDropDownTop(calculatedTop);
  }, [height]);

  if (disabled) {
    iconColor = "#C9CDD9"; // disabled iconColor
  }

  const handleToggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!disabled) {
      handleOpenChange(!isOpen);
    }
  };

  const handleOptionClick = (
    event: React.MouseEvent,
    value: string | number | boolean
  ) => {
    event.stopPropagation();

    onChange(value, event);
    handleOpenChange(false);
  };

  useEffect(() => {
    if (hardClose) {
      handleOpenChange(false);
    }
  }, [hardClose]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        handleOpenChange(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const optionsMatchingSearch = searchTerm
    ? options.filter(
        ({ label }) =>
          label && label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : options;

  const showSearch = options.length > 25;

  const hasError = validate && required && !selectedValue;

  return (
    <div
      ref={dropdownRef}
      className={`${className} flex ${
        leftLabel ? "items-center" : "flex-col items-center"
      } ${isOpen ? "z-50" : ""}`}
      style={{
        width: window.innerWidth < 640 ? "100%" : width,
        ...props.style,
      }}
    >
      {leftLabel && (
        <label className=" text-sm font-normal mr-[12px] whitespace-nowrap">
          {leftLabel}
        </label>
      )}
      {label && (
        <div className="mb-[6px] w-full">
          <FormLabel id={inputName} label={label} required={required} />
        </div>
      )}
      <div
        className={`relative w-full ${
          isOpen ? "rounded-t" : ""
        } ${transparent || iconOnly ? "bg-transparent" : "bg-white"}`}
      >
        <div
          className={`border-${hasError && !isOpen ? "error_red" : "default_weak"} flex items-center w-full gap-2 ${small ? "" : "p-2"} rounded${isOpen ? "-t" : ""} ${
            height ? `h-[${height}px]` : "h-[40px]"
          }
            ${
              iconOnly
                ? "bg-transparent border-none"
                : "bg-transparent border border-default_weak"
            }
            ${
              disabled
                ? "text-neutral_400 border-neutral_400 cursor-not-allowed"
                : "cursor-pointer"
            }
            ${
              titleDropDown
                ? "border-none px-[0px] w-auto justify-start"
                : "justify-between"
            }
            ${isOpen ? "border-default_weak" : ""}`}
          onClick={handleToggleDropdown}
          style={{width: limitedWidth ? width + "px" : ""}}
        >
          {icon && (
            <FIcon
              icon={icon}
              size={14}
              color={iconColor}
              className="mr-1 -mt-[2px]"
            />
          )}
          {titleDropDown ? (
            <h3 className="text-dark mr-2">
              {displayedOption?.label}
              {options[0].static ? ` ${options[0].static}` : ""}
            </h3>
          ) : iconOnly ? (
            ""
          ) : displayedOption?.label ? (
            <span className={`text-sm truncate`}>{displayedOption?.label}</span>
          ) : (
            <span className="text-neutral_500 text-sm">
              {" "}
              {placeholder || "Select"}
            </span>
          )}
          {!iconOnly && (
            <FIcon
              icon="chevron-dropdown"
              size={small ? 12 : 24}
              color={!disabled ? "#000000" : "#C9CDD9"}
              className={`-mt-[2px] ${isOpen ? "rotate-180" : ""}`}
            />
          )}
        </div>
        {isOpen && (
          <div
            className={`bg-white absolute rounded-b border border-default_weak border-t${titleDropDown || iconOnly ? "" : "-0"} cursor-pointer ${showSearch ? "" : "overflow-y-auto"} ${menuUp ? `bottom-[20px]` : `top-[${dropDownTop}]`} ${
              iconOnly ? "right-0" : "left-0"
            }`}
            style={{
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.25)",
              whiteSpace: "nowrap",
              zIndex: zIndex || 60,
              minWidth: "100%",
              maxHeight: "200px",
              width: limitedWidth ? width + "px" : "max-content",
            }}
          >
            {showSearch ? (
              <div className="p-2 h-[50px]">
                <FInput
                  value={searchTerm}
                  placeholder="Search..."
                  width="100%"
                  type="text"
                  onChange={setSearchTerm}
                  required
                />
              </div>
            ) : null}
            <div
              className={`flex flex-col ${showSearch ? " overflow-y-auto h-[150px]" : ""}`}
            >
              {optionsMatchingSearch.map((o, index) => (
                <div
                  key={index}
                  className={`px-2 py-1 text-wrap hover:bg-neutral_200 text-sm whitespace-nowrap ${selectedValue === o.value ? "bg-neutral_200" : ""}`}
                  onClick={(e) => handleOptionClick(e, o.value)}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {hasError && (
        <div className="flex items-center gap-1 mt-[6px] w-full">
          <IconWarning size={12} />
          <p
            className="bg-transparent text-error_red text-xs tracking-[0.12px]"
            dangerouslySetInnerHTML={{ __html: `${label} is required` }}
          />
        </div>
      )}
    </div>
  );
};

export default FDropdown;
