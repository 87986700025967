import apiWrapper from "@apiWrapper";

import { CreateCampaignBriefAssetParams } from "@types";

const getFormData = (params) => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    formData.append(`participant_brief_asset[${key}]`, params[key]);
  });

  return formData;
};

export const createParticipantBriefAsset = async (
  params: CreateCampaignBriefAssetParams
) => {
  const formData = getFormData(params);

  return await apiWrapper(`/participant_brief_assets`, "POST", formData);
};

export const updateParticipantBriefAsset = async (id: number, params: any) => {
  return await apiWrapper(`/participant_brief_assets/${id}`, "PUT", params);
};

export const deleteParticipantBriefAsset = async (id: number) => {
  return await apiWrapper(`/participant_brief_assets/${id}`, "DELETE");
};

export const uploadParticipantBriefAssetFiles = async (
  id: number,
  file: File
) => {
  const formData = getFormData({ file });

  const response = await apiWrapper(
    `/participant_brief_assets/${id}`,
    "PUT",
    formData
  );

  return response.data;
};
