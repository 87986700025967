import React from "react";
import AgeBreakdownChart from "./charts/demographics/AgeBreakdownChart";
import GenderBreakdownChart from "./charts/demographics/GenderBreakdownChart";
import EthnicityBreakdown from "./charts/demographics/EthnicityBreakdownChart";
import ReligionBreakdown from "./charts/demographics/ReligionBreakdown";
import KidsBreakdown from "./charts/demographics/KidsBreakdown";
import MaritalStatusBreakdown from "./charts/demographics/MaritalStatusBreakdown";
import FDropdown from "@components/FDropdown";
import LoadingSpinner from "@components/LoadingSpinner";

interface DemographicSnapshotsProps {
  audience: any;
  audienceDataFetched: boolean;
  availablePlatforms: any[];
  selectedPlatform: string;
  setSelectedPlatform: (value: string) => void;
  parentLoading?: boolean;
}

const DemographicSnapshots = ({
  audience,
  audienceDataFetched,
  availablePlatforms,
  selectedPlatform,
  setSelectedPlatform,
  parentLoading = false,
}: DemographicSnapshotsProps) => {
  if (parentLoading || !audienceDataFetched) {
    return (
      <div className="flex justify-center items-center h-40">
        <LoadingSpinner />
      </div>
    );
  }

  if (!audience) return null;

  return (
    <div className="pr-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-medium">Demographics</h2>
        {Object.values(audience).some((data) =>
          Array.isArray(data) ? data.length > 0 : data
        ) && (
          <FDropdown
            options={availablePlatforms}
            selectedValue={selectedPlatform}
            onChange={(value) => setSelectedPlatform(value as string)}
          />
        )}
      </div>

      <div className="grid grid-cols-2 gap-6">
        {audience.age?.length > 0 && audience.gender?.length > 0 && (
          <>
            <div className="border border-light_border rounded-lg bg-white shadow-md h-[400px]">
              <AgeBreakdownChart
                items={audience.age.map((item) => ({
                  ...item,
                  name: item.name.split("Age ")[1],
                }))}
              />
            </div>

            <div className="border border-light_border rounded-lg bg-white shadow-md h-[400px]">
              <GenderBreakdownChart items={audience.gender} />
            </div>
          </>
        )}

        {audience.ethnicity?.length > 0 && audience.religion?.length > 0 && (
          <>
            <div className="border border-light_border rounded-lg bg-white shadow-md h-[400px]">
              <EthnicityBreakdown items={audience.ethnicity} />
            </div>

            <div className="border border-light_border rounded-lg bg-white shadow-md h-[400px]">
              <ReligionBreakdown items={audience.religion} />
            </div>
          </>
        )}

        {audience.parental && audience.marital?.length > 0 && (
          <>
            <div className="border border-light_border rounded-lg bg-white shadow-md h-[400px]">
              <KidsBreakdown items={audience.parental} />
            </div>

            <div className="border border-light_border rounded-lg bg-white shadow-md h-[400px]">
              <MaritalStatusBreakdown items={audience.marital} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DemographicSnapshots;
