import React, { useEffect } from "react";
import moment from "moment";
import { updateUserNotification } from "@api/Notifications/UserNotifications";
import FButton from "@components/FButton";
import { UserNotification, UserNotificationStatus } from "@types";
import { formatDateMessage } from "@utils/DateUtilities";

interface NotificationProps {
  notification: UserNotification;
  className?: string;
  onNotificationUpdated: (updates: Partial<UserNotification>) => void;
}

export default function Notification({
  notification,
  className = "",
  onNotificationUpdated,
}: NotificationProps) {
  const handleUpdateNotification = async (updates: Partial<UserNotification>) => {
    try {
      await updateUserNotification(notification.id, updates);
      onNotificationUpdated(updates);
    } catch (error) {
      console.error("Failed to update notification:", error);
    }
  };

  const highlightWords = (details: string) => {
    const statusMap = {
      approved: { color: "text-[#1F8855]", label: "approved" },
      accepted: { color: "text-[#1F8855]", label: "accepted" },
      soft_approved: { color: "text-[#1F8855]", label: "brand approved" },
      client_review: { color: "text-[#000F45]", label: "client review" },
      influencer_changes: { color: "text-[#000F45]", label: "pending changes" },
      admin_review: { color: "text-[#000F45]", label: "admin review" },
      admin_changes: { color: "text-[#CB4039]", label: "rejected - needs action" },
      viewed: { color: "text-[#1F8855]", label: "viewed" },
      onboarding: { color: "text-[#1F8855]", label: "onboarding" },
      rejected: { color: "text-[#CB4039]", label: "rejected" },
      lapsed: { color: "text-[#CB4039]", label: "lapsed" },
      declined: { color: "text-[#CB4039]", label: "declined" },
    };

    return details.split(" ").map((word, index) => {
      const { color, label } = statusMap[word] || {};
      return (
        <span key={index} className={color ? `font-medium ${color}` : ""}>
          {label || word} {" "}
        </span>
      );
    });
  };

  const { created_at: createdAt, status } = notification;

  return (
    <div
      className={`notification-container flex justify-between items-center bg-white p-4 mb-4 border border-neutral-300 rounded-lg ${className}`}
    >
      <div className="flex justify-between w-full gap-2">
        <div className="flex">
          {notification.photo_url && (
            <div className="h-[101px] w-[101px] min-w-[101px] mr-2 rounded-lg overflow-hidden">
              <img src={notification.photo_url} alt="preview" />
            </div>
          )}
          <div>
            <p>
              <span className="font-medium">
                {notification.participant?.full_name} {" "}
              </span>
              {highlightWords(notification.details)}
              <span className="text-gray-500">{moment(createdAt).fromNow()}</span>
            </p>
            <h4>
              {notification.campaign_name} | {createdAt ? formatDateMessage(new Date(createdAt)) : ""}
            </h4>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <a target="_blank" rel="noopener noreferrer" href={notification.link}>
            <FButton label="View" primary />
          </a>
          <FButton
            label={status === UserNotificationStatus.UNREAD ? "Mark as Read" : "Mark as Unread"}
            onClick={() =>
              handleUpdateNotification({
                status:
                  status === UserNotificationStatus.UNREAD
                    ? UserNotificationStatus.READ
                    : UserNotificationStatus.UNREAD,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
