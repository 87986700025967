import React from "react";
import KPIWithSparkline from "@components/Dashboard/KPIWithSparkline";
import KPIWithSparklineSkeleton from "@components/Dashboard/KPIWithSparklineSkeleton";

interface ReportStatsProps {
  performanceGraphs: any;
  metrics: any;
  isLoading: boolean;
}

export default function ReportStats({
  performanceGraphs,
  metrics,
  isLoading,
}: ReportStatsProps) {
  if (isLoading) {
    return (
      <div className="grid grid-cols-4 gap-4">
        <KPIWithSparklineSkeleton />
        <KPIWithSparklineSkeleton />
        <KPIWithSparklineSkeleton />
        <KPIWithSparklineSkeleton />
      </div>
    );
  }

  // Transform chart data into sparkline format
  const getSparklineData = (chartData: any) => {
    if (!chartData || typeof chartData !== "object") {
      return [];
    }

    // Convert object format to array format and parse dates
    return Object.entries(chartData).map(([dateStr, value]) => {
      let date;

      // Check if date is in MM/DD/YY format
      if (dateStr.includes("/")) {
        const [month, day, year] = dateStr.split("/");
        date = new Date(
          2000 + parseInt(year),
          parseInt(month) - 1,
          parseInt(day)
        );
      } else {
        // Handle MMM YY format (e.g. "Sep 24")
        const [month, year] = dateStr.split(" ");
        date = new Date(2000 + parseInt(year), getMonthNumber(month), 1);
      }

      return {
        date,
        value: typeof value === "number" ? value : 0,
      };
    });
  };

  // Helper function to convert month abbreviation to month number
  const getMonthNumber = (monthAbbr: string): number => {
    const months: { [key: string]: number } = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };
    return months[monthAbbr] || 0;
  };

  // Get value from metrics instead of stat_data
  const getCurrentValue = (key: string) => {
    return metrics[key] || 0;
  };

  return (
    <div className="grid grid-cols-4 gap-4">
      {performanceGraphs.reach && (
        <KPIWithSparkline
          title="Reach"
          value={getCurrentValue("reach")}
          valueType="number"
          timeSeriesData={getSparklineData(performanceGraphs.reach.chart_data)}
        />
      )}
      {performanceGraphs.viewership && (
        <KPIWithSparkline
          title="Views"
          value={getCurrentValue("views_count")}
          valueType="number"
          timeSeriesData={getSparklineData(
            performanceGraphs.viewership.chart_data
          )}
        />
      )}
      {performanceGraphs.engagement && (
        <KPIWithSparkline
          title="Engagement"
          value={getCurrentValue("engagement_rate")}
          valueType="percent"
          timeSeriesData={getSparklineData(
            performanceGraphs.engagement.chart_data
          )}
        />
      )}
      {performanceGraphs.emv && (
        <KPIWithSparkline
          title="EMV"
          value={getCurrentValue("emv")}
          valueType="dollars"
          timeSeriesData={getSparklineData(performanceGraphs.emv.chart_data)}
        />
      )}
    </div>
  );
}
