import React, { useEffect, useState } from "react";

import FIcon from "@components/FIcon";
import FohrLogo from "@components/FohrLogo";

import { Content } from "@types";
import { isEmpty } from "lodash";

const SLIDER_DURATION = 2000;

interface ParticipantLoadingProps {
  content: Content[];
  title: string;
  onLoadingComplete: () => void;
}

const ParticipantLoading = ({
  title,
  content,
  onLoadingComplete,
}: ParticipantLoadingProps) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    // Preload images
    // Need to make timing dynamic -- 100ms * # of content
    Promise.all(
      content.slice(0, 5).map((item) => {
        const img = new Image();
        img.src = item.photo_url;
        return new Promise((resolve) => (img.onload = resolve));
      })
    ).then(() => {
      if (isEmpty(content)) {
        onLoadingComplete();

        return;
      }

      // Notify parent component that loading is complete
      const slideDuration = SLIDER_DURATION / content.length;
      const slideshowTimer = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % content.length);
      }, slideDuration);
      setTimeout(() => {
        setFadeOut(true);
      }, SLIDER_DURATION - 200);
      setTimeout(() => {
        onLoadingComplete();
        clearInterval(slideshowTimer);
      }, SLIDER_DURATION);
    });
  }, [content, onLoadingComplete]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeIn(true);
    }, 100);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onLoadingComplete();
    }
  };

  if (isEmpty(content)) {
    return null;
  }

  return (
    <div
      className={`p-4 shadow rounded-lg absolute top-0 right-0 w-full h-full z-[9999] transition-all duration-200  ${
        fadeOut ? "scale-[2] opacity-0" : "scale-100 opacity-100"
      } bg-light_red`}
    >
      <div className="absolute top-[40px] left-1/2 transform -translate-x-1/2">
        <FohrLogo />
      </div>
      <div
        className="absolute top-5 right-10 cursor-pointer z-50"
        onClick={() => onLoadingComplete()}
      >
        <FIcon
          icon="exit"
          size={20}
          color="black"
          className="absolute top-5 right-5 cursor-pointer"
        />
      </div>
      <div className="relative z-10 w-full h-full flex items-center justify-center overflow-hidden">
        <div
          className="whitespace-nowrap flex"
          style={{
            animation: "ticker 100s linear infinite",
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <h1 key={index} className="text-[10vw] font-bold text-gray-700">
              {index < 2 ? title : title}
            </h1>
          ))}
        </div>
        <div
          className="absolute slide h-full w-full flex items-center justify-center"
          style={{
            animation: `scale-up 5s linear infinite`,
          }}
        >
          <div className="bg-light_red">
            <div
              className="relative w-[300px] h-[500px] bg-cover bg-center rounded-xl flex justify-center items-center"
              style={{
                backgroundImage: `url(${content[currentSlide].photo_url})`,
              }}
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-[40px] left-1/2 transform -translate-x-1/2">
        <p className="text-[15px] font-medium text-dark_night_sky">{title}</p>
      </div>
    </div>
  );
};

export default ParticipantLoading;
