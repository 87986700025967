import React from "react";

export const DemographicsSkeletons = () => {
  const SkeletonCard = () => (
    <div className="border border-light_border rounded-lg bg-white shadow-md h-[400px]">
      <div className="p-4 flex justify-between items-center border-b border-light_border">
        <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-6 w-24 rounded"></div>
        <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-6 w-16 rounded"></div>
      </div>
      <div className="p-4 flex items-center justify-center">
        <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-[300px] w-full rounded"></div>
      </div>
    </div>
  );

  return (
    <div className="mt-[48px]">
      <div>
        <div className="flex justify-between items-center mb-6">
          <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-8 w-32 rounded"></div>
          <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-8 w-40 rounded"></div>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </div>
      </div>

      <div className="mt-[48px]">
        <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-8 w-48 rounded mb-4"></div>
        <div className="mt-8">
          <div className="grid gap-6 grid-cols-2">
            <SkeletonCard />
            <SkeletonCard />
          </div>
        </div>
      </div>
    </div>
  );
};
