import React from "react";

import {
  createAmbassadorQuestion,
  deleteAmbassadorQuestion,
  listAmbassadorQuestions,
  updateAmbassadorQuestion,
} from "@api/Ambassadors/AmbassadorQuestions";

import ManageQuestions from "@components/questions/ManageQuestions";
import { useNavigate } from "react-router-dom";

const BASE_URL = "/ambassadors/onboarding-settings/questions";

const AmbassadorQuestions = () => {
  const navigate = useNavigate();

  return (
    <ManageQuestions
      entityName="Ambassador Onboarding"
      listQuestions={listAmbassadorQuestions}
      createQuestion={createAmbassadorQuestion}
      updateQuestion={updateAmbassadorQuestion}
      deleteQuestion={deleteAmbassadorQuestion}
      onAddQuestion={() => navigate(`${BASE_URL}/new`)}
      onQuestionUpdated={() => navigate(BASE_URL)}
    />
  );
};

export default AmbassadorQuestions;
