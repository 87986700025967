import FSubNav from "@components/FSubNav";
import Heading from "@components/Heading";
import AmbassadorQuestions from "@pages/ambassadors/ambassador-questions";

import AmbassadorsOnboardingBrandSettings from "./Branding";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { capitalize } from "lodash";

const NAV_ITEMS = [
  {
    value: "branding",
  },
  {
    value: "questions",
  },
];

const AmbassadorsOnboardingSettings = () => {
  const { tab } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavChange = (newValue: string) => {
    const item = NAV_ITEMS.find((item) => item.value === newValue);

    if (item && item.value !== location.pathname) {
      if (tab) {
        navigate(`../${item.value}`, { relative: "path" });
      } else {
        navigate(item.value);
      }
    }
  };

  const selectedNav =
    NAV_ITEMS.find(({ value }) => value === tab)?.value || NAV_ITEMS[0].value;

  return (
    <div>
      <div className="flex items-center justify-between mb-8">
        <Heading title="Ambassador onboarding settings" />
      </div>
      <FSubNav
        items={NAV_ITEMS.map(({ value }) => ({
          label: capitalize(value),
          value,
          active: value === selectedNav,
        }))}
        selectedValue={selectedNav}
        onChange={handleNavChange}
      />
      <div className="pt-8">
        {selectedNav === "branding" ? (
          <AmbassadorsOnboardingBrandSettings />
        ) : (
          <AmbassadorQuestions />
        )}
      </div>
    </div>
  );
};

export default AmbassadorsOnboardingSettings;
