import React, { ReactNode, useEffect, useState } from "react";
import FInput from "@components/FInputs/FInput";
import FButton from "@components/FButton";
import { ParticipantGroup } from "@types";
import FDropdown from "@components/FDropdown";
import { FTextarea } from "@components/FInputs";

interface ParticipantGroupEditFormProps {
  onSubmit: (values: any) => void;
  onClose: () => void;
  group: ParticipantGroup;
  isSaving: boolean;
  isSubmitted: boolean;
  children: ReactNode;
}

const ParticipantGroupEditForm = ({
  onSubmit,
  group,
  isSaving,
  isSubmitted,
  onClose,
  children,
}: ParticipantGroupEditFormProps) => {
  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Paused", value: "paused" },
    { label: "Archived", value: "archived" },
  ];

  const [values, setValues] = useState({
    id: null,
    name: "",
    status: "",
    creator_review_description: "",
  });

  useEffect(() => {
    const { id, name, status, creator_review_description } = group;

    setValues({
      id,
      name,
      status,
      creator_review_description,
    });
  }, [group]);

  const { name, status, creator_review_description } = values;

  const handleUpdate = (updates) => setValues({ ...values, ...updates });

  return (
    <div className={`max-h-[${children ? 80 : 60}vh] overflow-y-auto`}>
      <div className="space-y-4">
        <div className="flex mb-[6px] gap-4 flex-col items-start w-full">
          <FInput
            value={name}
            validate={isSubmitted}
            type="text"
            required
            onChange={(value) => handleUpdate({ name: value })}
            label="Name"
            width="100%"
          />
          <FTextarea
            label="Description"
            className="flex-1"
            rows={3}
            value={creator_review_description}
            onChange={(value) =>
              handleUpdate({ creator_review_description: value })
            }
            width="100%"
          />
          <div className="flex flex-col w-full items-start">
            <label
              className="mb-[6px] text-sm font-medium leading-[18px]"
              htmlFor="platform"
            >
              Status
            </label>
            <FDropdown
              selectedValue={status}
              onChange={(value) => handleUpdate({ status: value })}
              height="40"
              width="100%"
              options={statusOptions}
            ></FDropdown>
          </div>
        </div>
      </div>
      {children}
      <div className="flex justify-end space-x-2 mt-8 pb-3 sticky bottom-0 pt-6 border-t border-light_border">
        <FButton onClick={onClose} label="Cancel" width="100%" height="40px" />
        <FButton
          onClick={() => onSubmit(values)}
          label="Save"
          loading={isSaving}
          width="100%"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
};

export default ParticipantGroupEditForm;
