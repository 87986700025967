import React, { useState } from "react";

import { toast } from "react-hot-toast";

import { FInput } from "@components/FInputs";

import FDropdown from "@components/FDropdown";

import { CampaignContent, Participant, UploadedFile } from "@types";

import Modal from "@components/Modals/Modal";

import moment from "moment";
import { capitalize, isEmpty } from "lodash";

import FFileUpload from "@components/FFileUpload/FFileUpload";
import FormField from "@components/Form/FormField";
import { platformList } from "@constants/constants";
import FDatePicker from "@components/FDatePicker/FDatePicker";
import {
  createParticipantContent,
  ParticipantContentBody,
} from "@api/Campaign/CampaignParticipantContent";
import FMultiSelect from "@components/FMultiSelect";
import { POST_CATEGORIES } from "@api/Search";
import { isValidUrl } from "@utils/validation";

const MEDIA_TYPES = ["image", "video", "text"];

interface AddParticipantContentModalProps {
  onClose: () => void;
  onCreated: (campaign: CampaignContent) => void;
  participant: Participant;
}

export default function AddParticipantContentModal({
  onClose,
  onCreated,
  participant,
}: AddParticipantContentModalProps) {
  const [loading, setLoading] = useState(false);

  const [isSubmitted, setSubmitted] = useState(false);

  const [uploadedImage, setUploadedImage] = useState<UploadedFile>(null);
  const [uploadedStatsScreenshots, setUploadedStatsScreenshots] = useState<
    UploadedFile[]
  >([]);

  const { campaign_id, deliverables } = participant;

  const [values, setValues] = useState<Partial<ParticipantContentBody>>({
    caption: "",
    type: MEDIA_TYPES[0],
    platform:
      participant.deliverables.find(({ platform }) => !!platform)?.platform ||
      platformList[0].value,
    published_at: moment().format(),
    deliverable_id: deliverables[0]?.id,
    permalink: "",
    insight_categories: [],
    insight_media_product_type: "",
    insight_interactions: 0,
    insight_likes_count: 0,
    insight_comments_count: 0,
    insight_impressions: 0,
    insight_reach_count: 0,
  });

  const handleUpdate = (updates: Partial<ParticipantContentBody>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    setSubmitted(true);

    const stats_screenshot = uploadedStatsScreenshots[0]?.file;

    const {
      caption,
      insight_categories,
      insight_media_product_type,
      permalink,
    } = values;

    if (
      !caption ||
      !stats_screenshot ||
      !uploadedImage ||
      isEmpty(insight_categories) ||
      !insight_media_product_type ||
      !isValidUrl(permalink)
    ) {
      return;
    }

    setLoading(true);

    try {
      const newContentResponse = await createParticipantContent({
        campaign_id: campaign_id,
        participant_id: participant.id,
        image: uploadedImage?.file,
        stats_screenshot: uploadedStatsScreenshots[0]?.file,
        ...values,
      });

      const newContent = newContentResponse.data;

      onCreated(newContent);
    } catch (e) {
      console.log("Content", e);
      toast.error(`Error creating content`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="h-[700px] w-[700px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title="Add Content"
      waiting={loading}
    >
      <div className="flex flex-col gap-6">
        <div className="flex gap-4">
          <FormField
            label="Asset"
            error={isSubmitted && !uploadedImage ? "Image is required" : ""}
            width="100%"
          >
            <FFileUpload
              className="w-full"
              onUpload={(files) => setUploadedImage(files[0])}
              uploadedFiles={[uploadedImage]}
            />
          </FormField>
          <FInput
            onChange={(value) => handleUpdate({ caption: value })}
            label="Caption"
            value={values.caption}
            required
            validate={isSubmitted}
            width="100%"
          />
        </div>
        <div className="flex gap-4 w-full">
          <FDropdown
            label="Type"
            selectedValue={values.type}
            onChange={(value) =>
              handleUpdate({
                type: value as string,
              })
            }
            options={MEDIA_TYPES.map((type) => ({
              value: type,
              label: capitalize(type),
            }))}
            width="100%"
          />
          <FDropdown
            label="Platform"
            selectedValue={values.platform}
            onChange={(value) =>
              handleUpdate({
                platform: value as string,
              })
            }
            options={platformList}
            width="100%"
          />
        </div>
        <div className="flex gap-4 w-full">
          <FMultiSelect
            options={Object.keys(POST_CATEGORIES).map((key) => ({
              value: key,
              label: POST_CATEGORIES[key],
            }))}
            label="Categories"
            onChange={(values) =>
              handleUpdate({
                insight_categories: values,
              })
            }
            required
            selected={values.insight_categories}
            validate={isSubmitted}
          />
          <FInput
            label="Media type"
            onChange={(value) =>
              handleUpdate({
                insight_media_product_type: value,
              })
            }
            required
            value={`${values.insight_media_product_type}`}
            validate={isSubmitted}
            width="100%"
          />
        </div>
        <div className="flex gap-4 w-full">
          <FInput
            label="Interactions"
            onChange={(value) =>
              handleUpdate({
                insight_interactions: parseInt(value, 10) || 0,
              })
            }
            value={`${values.insight_interactions}`}
            type="number"
            width="100%"
          />
          <FInput
            label="Likes"
            onChange={(value) =>
              handleUpdate({
                insight_likes_count: parseInt(value, 10) || 0,
              })
            }
            value={`${values.insight_likes_count}`}
            type="number"
            width="100%"
          />
        </div>
        <div className="flex gap-4 w-full">
          <FInput
            label="Comments"
            onChange={(value) =>
              handleUpdate({
                insight_comments_count: parseInt(value, 10) || 0,
              })
            }
            value={`${values.insight_comments_count}`}
            type="number"
            width="100%"
          />
          <FInput
            label="Impressions"
            onChange={(value) =>
              handleUpdate({
                insight_impressions: parseInt(value, 10) || 0,
              })
            }
            value={`${values.insight_impressions}`}
            type="number"
            width="100%"
          />
        </div>
        <FInput
          label="Reach"
          onChange={(value) =>
            handleUpdate({
              insight_reach_count: parseInt(value, 10) || 0,
            })
          }
          value={`${values.insight_reach_count}`}
          type="number"
          width="100%"
        />
        <div className="flex gap-4 w-full">
          <FormField
            label="Stats screenshot"
            required
            error={
              isSubmitted && isEmpty(uploadedStatsScreenshots)
                ? "Stats screenshot is required"
                : ""
            }
            width="100%"
          >
            <FFileUpload
              className="w-full"
              onUpload={(files) =>
                setUploadedStatsScreenshots([
                  ...uploadedStatsScreenshots,
                  ...files,
                ])
              }
              multiple
              uploadedFiles={uploadedStatsScreenshots}
              onDelete={(fileName) => {
                const filteredFiles = uploadedStatsScreenshots.filter(
                  (f) => f.file.name !== fileName
                );

                setUploadedStatsScreenshots(filteredFiles);
              }}
            />
          </FormField>
          <FDatePicker
            label="Published at"
            value={values.published_at}
            onSelectedValue={(value) => handleUpdate({ published_at: value })}
          />
        </div>
        <div className="flex gap-4 w-full">
          <FInput
            label="Permalink"
            onChange={(value) => handleUpdate({ permalink: value })}
            error={
              isSubmitted && values.permalink && !isValidUrl(values.permalink)
                ? "Please input a valid URL, e.g: https://www.fohr.co"
                : ""
            }
            required
            validate={isSubmitted}
            value={`${values.permalink}`}
            width="100%"
          />
        </div>
        <FDropdown
          label="Deliverable"
          selectedValue={values.deliverable_id}
          onChange={(value) =>
            handleUpdate({
              deliverable_id: value as number,
            })
          }
          options={deliverables.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          width="100%"
        />
      </div>
    </Modal>
  );
}
