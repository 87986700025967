import React, { useCallback } from "react";
import { Sort } from "@types";
import { getCreators } from "@api/Reports";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";

const tableColumns = [
  {
    key: "name",
    label: "Name",
    isLink: true,
    linkURL: "/card/{creator_slug}",
    isFirstColumn: true,
  },
  {
    key: "followers",
    label: "Followers",
    isNumber: true,
  },
  {
    key: "follower_tier",
    label: "Follower Tier",
    isStateBubble: true,
  },
  {
    key: "posts",
    label: "Posts",
    isNumber: true,
  },
  {
    key: "video_views",
    label: "Video Views",
    isNumber: true,
  },
  {
    key: "impressions",
    label: "Views",
    isNumber: true,
  },
  {
    key: "engagements",
    label: "Engagements",
    isNumber: true,
  },
  {
    key: "engagement_rate",
    label: "Engagement %",
  },
  {
    key: "viewership",
    label: "Viewership %",
  },
];

export default function ReportCreatorsTable({ report_id }) {
  const getContent = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      const { search } = params;

      if (sort?.key === "name") {
        sort.key = "creator_lastName";
      }

      const response = await getCreators(report_id, page, sort, {
        creator_firstName_or_creator_lastName_cont: search,
      });

      const { creators: items } = response.data;
      return {
        ...response.data,
        items,
      };
    },
    []
  );

  return (
    <div className="py-10">
      <SinglePageLayoutWithData
        entityName="creators"
        pageName=""
        tableColumns={tableColumns}
        tableOnly
        hideCreateNewButton
        getItems={getContent}
      />
    </div>
  );
}
