import React, { ReactNode, useEffect, useContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import { UserContext } from "@contexts/index";

interface MixpanelProviderProps {
  children: ReactNode;
  userProperties?: Record<string, any>;
}

export const MixpanelContext = React.createContext<{
  trackEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
  isInitialized: boolean;
} | null>(null);

export const MixpanelProvider = ({
  children,
  userProperties,
}: MixpanelProviderProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const [isInitialized, setIsInitialized] = useState(false);

  const trackEvent = (eventName: string, eventProperties: Record<string, any> = {}) => {
    if (!isInitialized) {
      console.warn("Mixpanel is not initialized yet.");
      return;
    }

    const propertiesWithBrand = {
      ...eventProperties,
      brand: user?.brand_name || null,
    };

    mixpanel.track(eventName, propertiesWithBrand);
  };

  useEffect(() => {
    const mixpanelProjectToken = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN;

    if (!mixpanelProjectToken) {
      console.error("Mixpanel project token is missing!");
      return;
    }

    // Initialize Mixpanel
    mixpanel.init(mixpanelProjectToken, {
      debug: true,
      persistence: "localStorage",
    });

    // Mark initialization as complete
    setIsInitialized(true);

    // Identify user if `user` exists
    if (user?.id) {
      const mixpanelId = user.brand_id ? `${user.id}_${user.brand_id}` : user.id;
      const userType = user.brand_id ? "brand" : "creator";

      const userAttributes: Record<string, any> = {
        ...userProperties,
        id: mixpanelId,
        user_type: userType,
        $email: user.email,
      };

      if (user.brand_name) {
        userAttributes.brand = user.brand_name;
      }

      mixpanel.identify(mixpanelId);
      mixpanel.people.set(userAttributes);

      // Track session start after user has been identified
      trackEvent("Session Started", {
        user_id: mixpanelId,
        user_type: userType,
      });
    }
  }, [user, userProperties]);

  return (
    <MixpanelContext.Provider value={{ trackEvent, isInitialized }}>
      {children}
    </MixpanelContext.Provider>
  );
};
