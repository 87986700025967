import React from "react";

import { Campaign } from "@types";

import Modal from "@components/Modals/Modal";

import UpdateCampaignForm from "./UpdateCampaignForm";

interface Props {
  entity?: Campaign;
  onClose: () => void;
  onUpdated?: (campaign: Campaign) => void;
  onCreated?: (campaign: Campaign) => void;
}

export default function CreateOrUpdateCampaignModal({
  onClose,
  entity,
  ...rest
}: Props) {
  return (
    <Modal
      actionLabel="Save"
      className="w-[758px] h-[700px] overflow-auto"
      isOpen
      onClose={onClose}
      hasForm
      title={`${entity?.id ? "Edit" : "Add"} Campaign`}
    >
      <UpdateCampaignForm onClose={onClose} entity={entity} {...rest} />
    </Modal>
  );
}
