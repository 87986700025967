import React, { useState } from "react";

import { toast } from "react-hot-toast";

import { FInput } from "@components/FInputs";

import { CampaignBriefAsset, UploadedFile } from "@types";

import Modal from "@components/Modals/Modal";

import FFileUpload from "@components/FFileUpload/FFileUpload";

import {
  createCampaignBriefAsset,
  updateCampaignBriefAsset,
  uploadCampaignBriefAssetFiles,
} from "@api/CampaignBriefs/CampaignBriefAssets";
import RichTextEditor from "@components/RichTextEditor";
import FormField from "@components/Form/FormField";
import {
  createParticipantBriefAsset,
  updateParticipantBriefAsset,
  uploadParticipantBriefAssetFiles,
} from "@api/ParticipantBriefs/ParticipantBriefAssets";
import { isValidUrl } from "@utils/validation";

interface UpdateSectionAssetModalProps {
  onClose: () => void;
  asset: CampaignBriefAsset;
  onUpdated: (asset: CampaignBriefAsset) => void;
  onCreated: (asset: CampaignBriefAsset) => void;
  participantId: number;
}

export default function UpdateSectionAssetModal({
  onClose,
  asset,
  onUpdated,
  onCreated,
  participantId,
}: UpdateSectionAssetModalProps) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { body, url, file } = asset;

  const [uploadedImage, setUploadedImage] = useState<UploadedFile>(
    file?.original ? { file: null, url: file.original } : null
  );

  const [values, setValues] = useState({ body, url });

  const handleUpdate = (updates: Partial<CampaignBriefAsset>) =>
    setValues({ ...values, ...updates });

  const handleNext = async () => {
    const { body, url } = values;

    setIsSubmitted(true);

    if (!url || !isValidUrl(url)) {
      return;
    }

    setLoading(true);

    try {
      if (!asset?.id) {
        const response = await (
          participantId ? createParticipantBriefAsset : createCampaignBriefAsset
        )({
          ...asset,
          body,
          url,
          file: uploadedImage.file,
        });

        const newAsset = response.data;

        onCreated(newAsset);

        return;
      }

      const response = await (
        participantId ? updateParticipantBriefAsset : updateCampaignBriefAsset
      )(asset.id, {
        body,
        url,
      });

      let updatedAsset = response.data;

      if (uploadedImage?.file) {
        const response = await (
          participantId
            ? uploadParticipantBriefAssetFiles
            : uploadCampaignBriefAssetFiles
        )(asset.id, uploadedImage.file);

        updatedAsset = { ...updatedAsset, ...response };
      }

      onUpdated(updatedAsset);
    } catch (e) {
      toast.error(`Error updating asset`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      actionLabel="Save"
      className="h-[700px] overflow-auto"
      isOpen
      onClose={onClose}
      onAction={handleNext}
      title="Update asset"
      waiting={loading}
    >
      <div className="flex flex-col gap-6">
        <FFileUpload
          className="w-full"
          onUpload={(files) => setUploadedImage(files[0])}
          uploadedFiles={[uploadedImage]}
        />
        <FInput
          onChange={(value) => handleUpdate({ url: value })}
          label="Url"
          error={
            isSubmitted && values.url && !isValidUrl(values.url)
              ? "Please input a valid URL, e.g: https://www.fohr.co"
              : ""
          }
          required
          value={values.url}
          validate={isSubmitted}
          width="100%"
        />
        <FormField label="Body" width="100%">
          <RichTextEditor
            value={values.body}
            id="notes"
            onChange={(value) => handleUpdate({ body: value })}
          />
        </FormField>
      </div>
    </Modal>
  );
}
