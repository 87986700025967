import React, { useEffect, useState } from "react";

import { DragDropContext, Draggable } from "react-beautiful-dnd";

import QuestionForm from "./QuestionForm";
import {
  QuestionnaireQuestion,
  QuestionnaireQuestionRequest,
  FieldType,
} from "types/questionnaire";
import QuestionCard from "./QuestionCard";
import Heading from "@components/Heading";
import FButton from "@components/FButton";

import { sortBy } from "lodash";
import toast from "react-hot-toast";
import ConfirmModal from "@components/Modals/ConfirmModal";
import { useParams } from "react-router-dom";
import Droppable from "@components/drag-and-drop/Droppable";
import { getItemStyle, getListStyle, reorder } from "@components/drag-and-drop";
import useAuth from "@hooks/useAuth";
import {
  getQuestionsWithChildren,
  QuestionnaireQuestionWithChildren,
} from "@utils/questions";
import { ApiResponse } from "@apiTypes";

const defaultQuestion = {
  question: "",
  required: true,
  position: 1,
  data: {
    options: [],
  },
  field_type: FieldType.SINGLE_SELECT,
};

interface ManageQuestionsProps {
  entityName: string;
  listQuestions: (params: object) => Promise<QuestionnaireQuestion[]>;
  createQuestion: (
    question: QuestionnaireQuestionRequest
  ) => Promise<ApiResponse<QuestionnaireQuestion>>;
  updateQuestion: (
    id: number,
    updates: Partial<QuestionnaireQuestionRequest>
  ) => Promise<ApiResponse<QuestionnaireQuestion>>;
  deleteQuestion: (id: number) => Promise<ApiResponse<any>>;
  onAddQuestion: () => void;
  onQuestionUpdated: () => void;
  hideChildQuestions?: boolean;
}

const ManageQuestions = ({
  entityName,
  listQuestions,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  onAddQuestion,
  onQuestionUpdated,
  hideChildQuestions,
}: ManageQuestionsProps) => {
  const { question_id } = useParams<{
    question_id: string;
  }>();

  const [questions, setQuestions] = useState<
    QuestionnaireQuestionWithChildren[]
  >([]);

  const { user } = useAuth();

  const [questionToDelete, setQuestionToDelete] =
    useState<QuestionnaireQuestion>(null);

  const [isDeleting, setDeleting] = useState(false);

  const [waiting, setWaiting] = useState(false);
  const [hasOrderChanges, setHasOrderChanges] = useState(false);
  const [isSavingOrdering, setIsSavingOrdering] = useState(false);

  const [activeQuestion, setActiveQuestion] =
    useState<QuestionnaireQuestion>(null);

  useEffect(() => {
    if (!question_id) {
      setActiveQuestion(null);
    } else if (question_id === "new") {
      setActiveQuestion(defaultQuestion);
    } else {
      const allQuestions = questions.reduce((result, item) => {
        const { children } = item;

        if (!children) {
          return [...result, item];
        }

        const childQuestions = Object.keys(children).reduce((result, key) => {
          const questions = children[key];

          return [...result, ...questions];
        }, []);

        return [...result, item, ...childQuestions];
      }, []);

      const question = allQuestions.find(
        ({ id }) => id === parseInt(question_id, 10)
      );

      console.log("All questions", question);

      setActiveQuestion(question);
    }
  }, [question_id, questions]);

  useEffect(() => {
    const loadQuestions = async () => {
      const questions = await listQuestions({
        q: { brand_id_eq: user.brand_id },
      });

      setQuestions(getQuestionsWithChildren(questions));
    };

    loadQuestions();
  }, []);

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({
      ...item,
      order: index,
    }));

    setQuestions(
      newQuestions.map((question, index) => ({
        ...question,
        position: index + 1,
      }))
    );

    setHasOrderChanges(true);
  };

  const handleSaveOrdering = async () => {
    setIsSavingOrdering(true);

    for (const question of questions) {
      await updateQuestion(question.id, {
        position: question.position,
      });
    }

    toast.success("Order saved");

    setIsSavingOrdering(false);
    setHasOrderChanges(false);
  };

  const handleSave = async (newQuestion: QuestionnaireQuestionRequest) => {
    setWaiting(true);

    try {
      if (!newQuestion.id) {
        const response = await createQuestion({
          ...newQuestion,
          brand_id: user.brand_id,
        });

        const createdQuestion = response.data;

        const parentQuestionId = newQuestion.question_id;

        if (parentQuestionId) {
          const newQuestions = questions.map((question) => {
            if (question.id !== parentQuestionId) {
              return question;
            }

            const { children = {} } = question;

            const { conditional_answer } = newQuestion;

            const questionsForAnswer = children[conditional_answer] || [];

            return {
              ...question,
              children: {
                ...children,
                [conditional_answer]: [...questionsForAnswer, createdQuestion],
              },
            };
          });

          setQuestions(newQuestions);

          return;
        }

        setQuestions(sortBy([createdQuestion, ...questions], "position"));
      } else {
        const response = await updateQuestion(
          newQuestion.id,
          newQuestion as QuestionnaireQuestionRequest
        );

        const updatedQuestion = response.data;

        const { conditional_answer, question_id: parentQuestionId } =
          updatedQuestion;

        const newQuestions = questions.map((question) => {
          if (parentQuestionId === question.id) {
            const oldConditionalAnswer = activeQuestion.conditional_answer;

            const questionsForAnswer =
              question.children[conditional_answer] || [];

            if (conditional_answer === oldConditionalAnswer) {
              const updatedQuestions = questionsForAnswer.map((question) => {
                if (question.id === updatedQuestion.id) {
                  return updatedQuestion;
                }

                return question;
              });

              return {
                ...question,
                children: {
                  ...question.children,
                  [conditional_answer]: updatedQuestions,
                },
              };
            } else {
              const questionsForOldConditionalAnswer =
                question.children[oldConditionalAnswer] || [];

              return {
                ...question,
                children: {
                  ...question.children,
                  [conditional_answer]: [
                    ...questionsForAnswer,
                    updatedQuestion,
                  ],
                  [oldConditionalAnswer]:
                    questionsForOldConditionalAnswer.filter(
                      (question) => question.id !== updatedQuestion.id
                    ),
                },
              };
            }
          }

          return question.id === updatedQuestion.id
            ? { ...question, ...updatedQuestion }
            : question;
        });

        setQuestions(newQuestions);
      }

      toast.success("Question created");
    } catch (e) {
      console.log("E", e);
      toast.error("Error saving changes");
    } finally {
      onQuestionUpdated();

      setWaiting(false);
    }
  };

  return activeQuestion ? (
    <QuestionForm
      entityName={entityName}
      question={activeQuestion}
      questions={questions}
      onSave={handleSave}
      waiting={waiting}
    />
  ) : (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="flex items-center justify-between">
        <Heading title={`Questions (${questions.length})`} />
        <div className="flex gap-4 items-center">
          {hasOrderChanges ? (
            <FButton
              loading={isSavingOrdering}
              onClick={handleSaveOrdering}
              label="Save ordering"
            />
          ) : null}
          <FButton
            primary={true}
            onClick={onAddQuestion}
            label="Add question"
            type="button"
            iconLeft={{
              name: "add",
              size: 12,
              color: "#fff",
              className: "mr-2",
            }}
          />
        </div>
      </div>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            <div className="flex flex-col gap-8 mt-16 pb-16">
              {questions.map((question, index) => (
                <Draggable
                  key={`key-${question.id}`}
                  draggableId={`ID-${question.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div {...provided.dragHandleProps}>
                        <QuestionCard
                          question={question}
                          key={question.id}
                          onDelete={(question) => setQuestionToDelete(question)}
                          hideChildQuestions={hideChildQuestions}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {questionToDelete ? (
              <ConfirmModal
                title="Delete question"
                isOpen
                onClose={() => setQuestionToDelete(null)}
                onAction={async () => {
                  setDeleting(true);

                  try {
                    await deleteQuestion(questionToDelete.id);

                    setQuestionToDelete(null);

                    if (questionToDelete.question_id) {
                      setQuestions(
                        questions.map((question) => {
                          if (question.id !== questionToDelete.question_id) {
                            return question;
                          }

                          const { conditional_answer } = questionToDelete;

                          const questionsForConditionalAnswer =
                            question.children[conditional_answer];

                          const newQuestions =
                            questionsForConditionalAnswer.filter(
                              (question) => question.id !== questionToDelete.id
                            );

                          return {
                            ...question,
                            children: {
                              ...question.children,
                              [conditional_answer]: newQuestions,
                            },
                          };
                        })
                      );
                    } else {
                      setQuestions(
                        questions.filter(
                          (question) => question.id !== questionToDelete.id
                        )
                      );
                    }
                  } catch (e) {
                    console.log("e", e);
                    toast.error("Error deleting question");
                  } finally {
                    setDeleting(false);
                  }
                }}
                actionLabel="Delete"
                isWaiting={isDeleting}
                subtitle={`Are you sure you want to delete this question?`}
              />
            ) : null}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default ManageQuestions;
