import ModalSection from "../ModalSection";

import { AdvancedSearchParams } from "@pages/search/types";

import { useState } from "react";
import PlatformSelect from "../PlatformSelect";
import PlatformStatistics from "./PlatformStatistics";
import { platformList } from "@pages/search/constants";
import { isEmpty } from "lodash";

interface SocialAnalyticsProps {
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
  onSetFilters: (filters: Partial<AdvancedSearchParams>) => void;
  platforms: string[];
}

const SocialAnalytics = ({
  filters,
  onFiltersUpdate,
  onSetFilters,
  platforms,
}: SocialAnalyticsProps) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState(platforms);

  const handleSelectedPlatforms = (platforms: string[]) => {
    const notSelectedPlatforms = platformList
      .filter(({ value }) => !platforms.includes(value))
      .map(({ value }) => value);

    const newFilters = Object.keys(filters)
      .filter(
        (key) =>
          !notSelectedPlatforms.find((platform) =>
            `${key}`.startsWith(platform)
          )
      )
      .reduce(
        (result, key) => ({
          ...result,
          [key]: filters[key],
        }),
        {}
      );

    onSetFilters(newFilters);

    setSelectedPlatforms(platforms);
  };

  const visiblePlatforms = platformList.filter((p) =>
    selectedPlatforms.includes(p.value)
  );

  return (
    <ModalSection icon="graph" title="Social Analytics">
      <div className="flex items-center gap-8">
        <PlatformSelect
          selectedPlatforms={selectedPlatforms}
          onChange={handleSelectedPlatforms}
        />
        <span>Filter By Social Platforms</span>
      </div>
      <div className="flex flex-col gap-8 mt-8">
        {isEmpty(selectedPlatforms) ? (
          <p className="text-neutral_500">
            Please select social platforms to apply followers count, growth rate
            and engagement rate filters.
          </p>
        ) : (
          visiblePlatforms.map(({ value }, index) => (
            <PlatformStatistics
              platform={value}
              filters={filters}
              onFiltersUpdate={onFiltersUpdate}
              hideBorder={index === visiblePlatforms.length - 1}
            />
          ))
        )}
      </div>
    </ModalSection>
  );
};

export default SocialAnalytics;
