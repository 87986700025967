import * as React from "react";
import { useState, useEffect, useRef } from "react";

import placeholderImage from "@public/images/image_placeholder.png";
import FDropdown from "@components/FDropdown";

const BriefProductPost = ({
  product,
  editProduct = null,
  deleteProduct = null,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [hasReadMore, setHasReadMore] = useState<boolean>(false);
  const productNoteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (productNoteRef.current) {
        const isOverflowing =
          productNoteRef.current.offsetHeight <
          productNoteRef.current.scrollHeight;
        setHasReadMore(isOverflowing);
      }
    };

    // Check overflow after the component mounts and images are loaded
    window.addEventListener("load", checkOverflow);
    checkOverflow(); // Also check on initial mount

    return () => {
      window.removeEventListener("load", checkOverflow);
    };
  }, []);

  const toggleExpand = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="border flex-row cursor-pointer gap-10 flex w-full border-neutral_400 hover:border-neutral_600 mb-4 bg-white px-4 py-[40px]"
      onClick={() => editProduct(product)}
    >
      <div
        className="bg-cover bg-center bg-highlight_red h-[200px] w-[200px] min-w-[200px]"
        style={{
          backgroundImage: `url(${
            product.image_url ? `${product.image_url}` : placeholderImage
          })`,
        }}
      />
      <div className="flex flex-col justify-start w-full relative max-w-[calc(100%-240px)]">
        <div
          ref={productNoteRef}
          className={`overflow-hidden flex-col gap-0 ${
            isExpanded ? "" : "max-h-[180px]"
          }`}
        >
          <div className="flex items-center mb-[6px] w-full relative">
            <a
              href={product.url?.startsWith('http') ? product.url : `https://${product.url}`}
              target="_blank"
              className="block hover:underline text-dark_night_sky text-[22px] overflow-hidden text-ellipsis whitespace-nowrap flex-1"
            >
              <span className="block truncate">{product.name}</span>
            </a>
          </div>
          <div
            className="text-dark_night_sky text-[15px] mt-2"
            dangerouslySetInnerHTML={{ __html: product.notes }}
          ></div>
        </div>
        {hasReadMore && (
          <div className="flex justify-start">
            <button
              onClick={toggleExpand}
              className="text-dark_night_sky underline text-[15px]"
            >
              {isExpanded ? "Show less" : "Show more"}
            </button>
          </div>
        )}
        {editProduct && deleteProduct ? (
          <div className="absolute -top-[25px] right-0 ">
            <FDropdown
              width="auto"
              height="30px"
              options={[
                { label: "Edit", value: "Edit" },
                { label: "Delete", value: "Delete" },
              ]}
              onChange={(event) => {
                if (event === "Edit" && editProduct) {
                  editProduct(product);
                } else if (event === "Delete" && deleteProduct) {
                  deleteProduct(product);
                }
              }}
              iconOnly={true}
              iconColor="#000F45"
              icon="ellipsis"
            />
          </div>
        ) : null}
        {/* <div className="flex flex-row justify-end gap-2 absolute -bottom-[10px] right-0">
					<button
						onClick={() => editProduct(product)}
						className="text-red  flex flex-row text-xs justify-center  w-fit px-2 py-1 items-center rounded gap-1 text-[15px] mt-2"
					>
						Delete
					</button>

					<button
						onClick={() => deleteProduct(product)}
						className="text-dark_night_sky  flex flex-row text-xs justify-center border border-light_border w-fit px-2 py-1 items-center rounded gap-1 text-[15px] mt-2"
					>
						Edit
					</button>
				</div> */}
      </div>
    </div>
  );
};

export default BriefProductPost;
