import { AmbassadorData, ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { getQueryParamsForSearchRequest } from "@constants/api";
import { Sort } from "@types";

const listAmbassadors = async (
  page?: number,
  sort?: Sort,
  params?: {
    brand_id_eq?: number;
    status_eq?: string;
    creator_firstName_or_creator_lastName_or_creator_slug_cont?: string;
    creator_id_eq?: number;
    per_page?: number;
  }
): Promise<{ ambassadors: AmbassadorData[]; total_items: number }> => {
  const queryParams = getQueryParamsForSearchRequest(page, sort, params);

  const response = await apiWrapper(
    `/ambassadors?${queryParams.toString()}`,
    "GET"
  );

  const { ambassadors } = response.data;

  return {
    ...response.data,
    ambassadors: ambassadors,
  };
};

const createAmbassador = async (
  params: Partial<any>
): Promise<ApiResponse<any>> => {
  return apiWrapper("/api/v1/ambassadors", "POST", params);
};

const updateAmbassador = async (
  ambassador_id: string | number,
  params: Partial<any>
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/ambassadors/${ambassador_id}`, "PUT", params);
};

const deleteAmbassador = async (ambassador_id: string | number) => {
  return apiWrapper(`/ambassadors/${ambassador_id}`, "DELETE");
};

const showAmbassador = async (
  ambassador_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/ambassadors/${ambassador_id}`, "GET");
};

export {
  listAmbassadors,
  createAmbassador,
  updateAmbassador,
  deleteAmbassador,
  showAmbassador,
};
