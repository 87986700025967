import React, { useEffect, useState } from "react";

import ConfirmModal from "@components/Modals/ConfirmModal";

import SinglePageLayout, {
  SinglePageLayoutProps,
} from "@layouts/SinglePageLayout";

import { Pagination, Sort } from "@types";
import { ApiResponse } from "@apiTypes";
import EntityCRUD from "@pages/campaigns/EntityCRUD";
import { capitalize } from "lodash";
import toast from "react-hot-toast";

interface FormField {
  key: string;
  label: string;
  options?: string[];
}

interface SinglePageLayoutWithDataProps<T extends { id?: number }>
  extends SinglePageLayoutProps {
  entityName: string;
  isArchiveNotDelete?: boolean;
  headerButtons?: React.ReactNode;
  entityCRUD?: {
    createFormFields: FormField[];
    editFormFields: FormField[];
  };
  onChangeSelectedItems?: (items: T[]) => void;
  selectedItems?: T[];
  reformItems?: (items: T[]) => T[];
  getItems: (
    page: number,
    sort: Sort,
    params
  ) => Promise<{ total_items: number; items: T[] }>;
  asyncActions?: {
    [key: string]: (args) => Promise<ApiResponse>;
  };
  updateModal?: React.ComponentType<{
    entity?: T;
    onClose: () => void;
    onUpdated?: (item: T) => void;
    onCreated?: (item: T) => void;
  }>;
}

export default function SinglePageLayoutWithData<T extends { id?: number }>({
  entityCRUD,
  cardDropDownOptions,
  tableRowDropDownOptions,
  rowActions,
  onTableRowDropDownSelect,
  isArchiveNotDelete = false,
  entityName,
  headerButtons,
  reformItems,
  tableColumns,
  getItems,
  asyncActions,
  pageSwitch,
  updateModal,
  ...rest
}: SinglePageLayoutWithDataProps<T>) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<T[]>([]);

  const [selectedEntity, setSelectedEntity] = useState(null);

  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    pageSize: 24,
    total: null,
  });
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<Sort>(null);
  const [filter, setFilter] = useState(null);

  const [entityToDelete, setEntityToDelete] = useState<{
    id: number;
    action: "archive" | "delete";
  }>(null);

  const [isDeleting, setDeleting] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      const { currentPage } = pagination;

      try {
        const params = {
          filter,
          search,
        };

        const result = await getItems(currentPage, sort, params);

        const { items, total_items: total } = result;

        setItems(reformItems ? reformItems(items) : items);

        pagination.total = total;
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [getItems, pagination, search, sort, filter]);

  const handlePageChange = (page: number) => {
    setPagination({ ...pagination, currentPage: page });
  };

  const handleSearchChange = (term: string) => {
    setPagination({ ...pagination, currentPage: 1 });
    setSearch(term);
  };

  const handleSortChange = (sort: Sort) => {
    setPagination({ ...pagination, currentPage: 1 });
    setSort(sort);
  };

  const handleFilterChange = (filter: string) => {
    setPagination({ ...pagination, currentPage: 1 });
    setFilter(filter);
  };

  const handleTableRowDropDownSelect = (value: string, id: number) => {
    if (value === "archive" || value === "delete") {
      setEntityToDelete({ id, action: value });
      // TODO: update this once the transition from old EntityCRUD to updateModal is complete
    } else if (onTableRowDropDownSelect && !(updateModal && value === "edit")) {
      // TODO: Add generics to SinglePageLayout and consolidate these callbacks
      onTableRowDropDownSelect(value, id);
    } else {
      const item = items.find((item) => item.id === id);

      setSelectedEntity(item);
    }
  };

  const handleItemUpdated = (updatedItem) => {
    setSelectedEntity(null);

    setItems(
      items.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }

        return item;
      })
    );
  };

  const handleItemCreated = (createdItem) => {
    setSelectedEntity(null);

    setItems([createdItem, ...items]);
  };

  const options = cardDropDownOptions || tableRowDropDownOptions || rowActions;
  const actionLabel = entityToDelete
    ? options.find(({ value }) => value === entityToDelete.action)?.label
    : null;

  const { createFormFields, editFormFields } = entityCRUD || {};

  const UpdateModalComponent = updateModal;

  return (
    <>
      {entityCRUD ? (
        <EntityCRUD
          apiNamespace={`${entityName}s`}
          pageName={entityName}
          entityData={items}
          setEntityData={setItems}
          createFormFields={createFormFields}
          editFormFields={editFormFields}
          isArchiveNotDelete={isArchiveNotDelete}
          includeBrandId
          includeUserId
          navigateToCreated={`/${entityName}s/{id}`}
          entityName={capitalize(entityName)}
          entitySelected={selectedEntity}
          setEntitySelected={setSelectedEntity}
        />
      ) : null}
      <SinglePageLayout
        cardData={items}
        cardDropDownOptions={cardDropDownOptions}
        tableRowDropDownOptions={tableRowDropDownOptions}
        rowActions={rowActions}
        tableColumns={tableColumns}
        loading={loading}
        headerButtons={headerButtons}
        pagination={pagination}
        onTableRowDropDownSelect={handleTableRowDropDownSelect}
        createWithEntityCrud={(value, id) => {
          if (value === "create" && id === "create") {
            setSelectedEntity({});
          }
        }}
        onPageChange={handlePageChange}
        onSearchChange={handleSearchChange}
        onSortChange={handleSortChange}
        onFilterChange={handleFilterChange}
        externalSort={sort}
        pageSwitch={pageSwitch}
        {...rest}
      />
      {selectedEntity && updateModal ? (
        <UpdateModalComponent
          entity={selectedEntity}
          onClose={() => setSelectedEntity(null)}
          onUpdated={handleItemUpdated}
          onCreated={handleItemCreated}
        />
      ) : null}
      {entityToDelete ? (
        <ConfirmModal
          title={`${actionLabel} ${entityName}`}
          isOpen
          onClose={() => setEntityToDelete(null)}
          onAction={async () => {
            const { id, action } = entityToDelete;

            setDeleting(true);

            try {
              await asyncActions[action](id);
            } catch (e) {
              toast.error("Error deleting entity");
            } finally {
              setDeleting(false);
            }

            setEntityToDelete(null);

            setItems(
              items.filter(
                (item) => (item as { id: number }).id !== entityToDelete.id
              )
            );
          }}
          actionLabel={actionLabel}
          isWaiting={isDeleting}
          subtitle={`Are you sure you want to ${actionLabel.toLocaleLowerCase()} this ${entityName}?`}
        />
      ) : null}
    </>
  );
}
