import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { sortBy } from "lodash";
import { formatLargeNumber } from "@utils/number_utilities";
import Legend from "@components/Graphs/components/Legend";
import { DataAnalysis } from "./DataAnalysis";

const INPUT_DATA_FORMAT = "MM/DD/YY";

interface GraphData {
  [key: string]: number;
}

interface PostsEngagementGraphProps {
  postsEngagement: {
    posts_graph_data: GraphData;
    reach_graph_data: GraphData;
    impressions_graph_data: GraphData;
    engagement_graph_data: GraphData;
  };
}

interface InterestingPoint {
  date: string;
  posts: number;
  reach: number;
  impressions: number;
  engagement: number;
  isInteresting: boolean;
}

interface ChartPoint {
  x: number;
  y: number;
  marker?: {
    fillColor?: string;
    radius?: number;
    lineWidth?: number;
    lineColor?: string;
  };
}

const COLORS = {
  posts: "rgba(249, 233, 231, 1)",
  reach: "#47B37F",
  impressions: "#59BBDB",
  engagement: "#E47667",
};

function calculateCorrelation(x: number[], y: number[]): number {
  const n = x.length;
  if (n !== y.length || n === 0) return 0;

  // Calculate means
  const xMean = x.reduce((a, b) => a + b) / n;
  const yMean = y.reduce((a, b) => a + b) / n;

  // Calculate covariance and standard deviations
  let covariance = 0;
  let xStdDev = 0;
  let yStdDev = 0;

  for (let i = 0; i < n; i++) {
    const xDiff = x[i] - xMean;
    const yDiff = y[i] - yMean;
    covariance += xDiff * yDiff;
    xStdDev += xDiff * xDiff;
    yStdDev += yDiff * yDiff;
  }

  // Prevent division by zero
  if (xStdDev === 0 || yStdDev === 0) return 0;

  return covariance / Math.sqrt(xStdDev * yStdDev);
}

const options: Highcharts.Options = {
  chart: {
    type: "line",
    height: 500,
  },
  title: {
    text: "Posts vs Metrics",
    style: {
      fontSize: "14px",
      fontWeight: "normal",
    },
  },
  xAxis: {
    type: "datetime",
    labels: {
      format: "{value:%b %Y}",
      style: {
        color: "#000721",
      },
    },
  },
  yAxis: [
    {
      // Left y-axis for posts
      title: {
        text: "Posts",
        style: {
          color: "#000721",
        },
      },
      labels: {
        style: {
          color: "#000721",
        },
      },
    },
    {
      // Right y-axis for other metrics
      title: {
        text: "Metrics",
        style: {
          color: "#000721",
        },
      },
      opposite: true,
      labels: {
        style: {
          color: "#000721",
        },
      },
    },
  ],
  tooltip: {
    shared: true,
    useHTML: true,
    formatter: function () {
      let tooltip = `<div style="margin-bottom: 10px"><b>${moment(this.x).format("MMM YYYY")}</b></div>`;
      this.points?.forEach((point) => {
        tooltip += `<div style="margin-bottom: 3px">${point.series.name}: <b>${formatLargeNumber(point.y)}</b></div>`;
      });

      return tooltip;
    },
    style: {
      fontSize: "14px",
      boxShadow: "0 4px 3px rgb(0 0 0 / 0.07)",
    },
  },
  legend: {
    enabled: true,
  },
  plotOptions: {
    column: {
      borderRadius: 5,
    },
    series: {
      marker: {
        enabled: true,
        radius: 4,
        symbol: "circle",
        states: {
          hover: {
            enabled: true,
            radius: 6,
          },
        },
      },
    },
  } as Highcharts.PlotOptions,
};

export default function SummaryGraph({
  postsEngagement,
}: PostsEngagementGraphProps) {
  if (!postsEngagement) {
    return (
      <p className="text-center mt-[23px] text-neutral_500 text-[18px]">
        No recent posts
      </p>
    );
  }

  const formatData = (dataObject: { [key: string]: number }) => {
    return Object.entries(dataObject).map(([date, value]) => [
      moment(date, INPUT_DATA_FORMAT).valueOf(),
      value,
    ]);
  };

  // Extract arrays of values in chronological order
  const dates = Object.keys(postsEngagement.posts_graph_data).sort();
  const metrics = {
    posts: dates.map((date) => postsEngagement.posts_graph_data[date]),
    reach: dates.map((date) => postsEngagement.reach_graph_data[date]),
    impressions: dates.map(
      (date) => postsEngagement.impressions_graph_data[date]
    ),
    engagement: dates.map(
      (date) => postsEngagement.engagement_graph_data[date]
    ),
  };

  // Calculate averages
  const averages = {
    posts: metrics.posts.reduce((a, b) => a + b, 0) / metrics.posts.length,
    reach: metrics.reach.reduce((a, b) => a + b, 0) / metrics.reach.length,
    engagement:
      metrics.engagement.reduce((a, b) => a + b, 0) / metrics.engagement.length,
    impressions:
      metrics.impressions.reduce((a, b) => a + b, 0) /
      metrics.impressions.length,
  };

  // Calculate correlations
  const correlations = {
    "Posts vs Reach": calculateCorrelation(metrics.posts, metrics.reach),
    "Posts vs Views": calculateCorrelation(metrics.posts, metrics.impressions),
    "Posts vs Engagement": calculateCorrelation(
      metrics.posts,
      metrics.engagement
    ),
    "Reach vs Views": calculateCorrelation(metrics.reach, metrics.impressions),
    "Reach vs Engagement": calculateCorrelation(
      metrics.reach,
      metrics.engagement
    ),
    "Views vs Engagement": calculateCorrelation(
      metrics.impressions,
      metrics.engagement
    ),
  };

  // Log correlations with interpretation
  Object.entries(correlations).forEach(([comparison, correlation]) => {
    const strength =
      Math.abs(correlation) > 0.7
        ? "Strong"
        : Math.abs(correlation) > 0.5
          ? "Moderate"
          : Math.abs(correlation) > 0.3
            ? "Weak"
            : "Very weak";

    const direction = correlation > 0 ? "positive" : "negative";

    console.log(
      `${comparison}: ${correlation.toFixed(3)} - ${strength} ${direction} correlation`
    );
  });

  // Add correlation data to the chart
  const correlationSeries = {
    name: "Correlations",
    type: "bubble",
    data: Object.entries(correlations).map(([name, value]) => ({
      name,
      value: parseFloat(value.toFixed(3)),
    })),
  };

  const getInterestingPoints = (): InterestingPoint[] => {
    return dates.map((date) => ({
      date,
      posts: postsEngagement.posts_graph_data[date],
      reach: postsEngagement.reach_graph_data[date],
      impressions: postsEngagement.impressions_graph_data[date],
      engagement: postsEngagement.engagement_graph_data[date],
      isInteresting:
        postsEngagement.engagement_graph_data[date] >
          averages.engagement * 1.2 ||
        postsEngagement.posts_graph_data[date] > averages.posts * 1.2 ||
        postsEngagement.reach_graph_data[date] > averages.reach * 1.2 ||
        postsEngagement.impressions_graph_data[date] >
          averages.impressions * 1.2,
    }));
  };

  const chartOptions: Highcharts.Options = {
    ...options,
    series: [
      {
        name: "Posts",
        type: "column",
        data: formatData(postsEngagement.posts_graph_data).map(
          ([x, y]): ChartPoint => ({
            x: x as number,
            y: y as number,
            marker: getInterestingPoints().find(
              (p) => moment(p.date, INPUT_DATA_FORMAT).valueOf() === x
            )?.isInteresting
              ? {
                  fillColor: "#E47667",
                  radius: 6,
                  lineWidth: 2,
                  lineColor: "#ffffff",
                }
              : undefined,
          })
        ),
        color: COLORS.posts,
        yAxis: 0,
      },
      {
        name: "Reach",
        type: "line",
        data: formatData(postsEngagement.reach_graph_data),
        color: COLORS.reach,
        yAxis: 1,
      },
      {
        name: "Views",
        type: "line",
        data: formatData(postsEngagement.impressions_graph_data),
        color: COLORS.impressions,
        yAxis: 1,
      },
      {
        name: "Engagement",
        type: "line",
        data: formatData(postsEngagement.engagement_graph_data),
        color: COLORS.engagement,
        yAxis: 1,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <DataAnalysis data={getInterestingPoints()} correlations={correlations} />
    </div>
  );
}
