import { ApiResponse } from "@apiTypes";
import apiWrapper from "@apiWrapper";

import {
  QuestionnaireQuestion,
  QuestionnaireQuestionRequest,
} from "types/questionnaire";

const BASE_ENDPOINT = "/bulletin_questions";

export const listBulletinQuestions = async (params?: {
  bulletin_id?: number;
}): Promise<QuestionnaireQuestion[]> => {
  const queryParams = new URLSearchParams();

  queryParams.append("q[bulletin_id_eq]", params.bulletin_id.toString());

  queryParams.append("per_page", "1000");

  const response = await apiWrapper(
    `${BASE_ENDPOINT}?${queryParams.toString()}`,
    "GET"
  );

  return response.data.bulletin_questions;
};

export const createBulletinQuestion = async (
  params: QuestionnaireQuestionRequest
): Promise<ApiResponse<QuestionnaireQuestion>> => {
  return apiWrapper(`${BASE_ENDPOINT}`, "POST", params);
};

export const updateBulletinQuestion = async (
  question_id: number,
  params: Partial<QuestionnaireQuestionRequest>
): Promise<ApiResponse<QuestionnaireQuestion>> => {
  return apiWrapper(`${BASE_ENDPOINT}/${question_id}`, "PUT", params);
};

export const deleteBulletinQuestion = async (question_id: number) => {
  return apiWrapper(`${BASE_ENDPOINT}/${question_id}`, "DELETE");
};

export const showBulletinQuestion = async (
  question_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`${BASE_ENDPOINT}/${question_id}`, "GET");
};
