import FDropdown from "@components/FDropdown";
import FRangeInput from "@components/Form/FRangeInput";
import { platformList } from "@pages/search/constants";
import { AdvancedSearchParams } from "@pages/search/types";
import { capitalize } from "lodash";

const GROWTH_RATE_OPTIONS = ["weekly", "monthly", "quarterly", "anually"];

interface PlatformStatisticsProps {
  platform: string;
  filters: Partial<AdvancedSearchParams>;
  onFiltersUpdate: (filters: Partial<AdvancedSearchParams>) => void;
  hideBorder?: boolean;
}

const PlatformStatistics = ({
  platform,
  filters,
  onFiltersUpdate,
  hideBorder,
}: PlatformStatisticsProps) => {
  const { label } = platformList.find(({ value }) => value === platform);

  return (
    <div className={`${hideBorder ? "" : "border-b"} border-light_border pb-4`}>
      <h2 className="mb-4 text-lg">{label}</h2>
      <div className="flex items-center gap-8 w-full">
        <FRangeInput
          minValue={0}
          label={platform === "youtube" ? "Subscribers" : "Follower Count"}
          value={{
            min: parseInt(filters[`${platform}_followers`]?.min, 10),
            max: parseInt(filters[`${platform}_followers`]?.max, 10),
          }}
          onChange={(value) =>
            onFiltersUpdate({
              [`${platform}_followers`]: {
                min: `${value.min}`,
                max: `${value.max}`,
              },
            })
          }
        />
        {["instagram", "tiktok"].includes(platform) ? (
          <FRangeInput
            minValue={0}
            label="Growth Rate"
            value={{
              min: parseInt(filters[`${platform}_growth_rate_range`]?.min, 10),
              max: parseInt(filters[`${platform}_growth_rate_range`]?.max, 10),
            }}
            onChange={(value) =>
              onFiltersUpdate({
                [`${platform}_growth_rate_range`]: {
                  min: `${value.min}`,
                  max: `${value.max}`,
                },
              })
            }
            topRightContent={
              <FDropdown
                className="text-default_weak text-sm"
                height={18}
                small
                options={GROWTH_RATE_OPTIONS.map((value) => ({
                  value,
                  label: capitalize(value),
                }))}
                selectedValue={
                  filters[`${platform}_growth_period`] || GROWTH_RATE_OPTIONS[0]
                }
                onChange={(value) =>
                  onFiltersUpdate({
                    [`${platform}_growth_period`]: value,
                  })
                }
                width="max-content"
                titleDropDown
              />
            }
          />
        ) : null}
        <FRangeInput
          minValue={0}
          label="Engagement Rate"
          value={{
            min: parseInt(filters[`${platform}_engagement_rate`]?.min, 10),
            max: parseInt(filters[`${platform}_engagement_rate`]?.max, 10),
          }}
          onChange={(value) =>
            onFiltersUpdate({
              [`${platform}_engagement_rate`]: {
                min: `${value.min}`,
                max: `${value.max}`,
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default PlatformStatistics;
