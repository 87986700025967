import React from "react";
import { Report } from "types/types";
import StatsBar from "@components/Dashboard/StatsBar";
import ReportStats from "./ReportStats";
import { ReportsAnalyticsMessage } from "./ReportsAnalyticsMessage";
import { ReportsAnalyticsMessageSkeleton } from "./ReportsAnalyticsMessageSkeleton";
import FButton from "@components/FButton";
import { downloadReportCSV } from "@api/Reports";
import toast from "react-hot-toast";

interface ReportHeaderProps {
  report: Report | null;
  metrics: any;
  performanceGraphs: any;
  isLoading: boolean;
  campaignReport?: boolean;
}

export const ReportHeader: React.FC<ReportHeaderProps> = ({
  report,
  metrics,
  performanceGraphs,
  isLoading,
  campaignReport = false,
}) => {
  const handleDownloadReportCSV = async (reportId: number) => {
    try {
      const response = await downloadReportCSV(reportId);
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${report?.title || "report"}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast.success("Report exported to CSV");
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toast.error("Failed to download CSV");
    }
  };

  const summaryStats = Object.entries(metrics).reduce(
    (
      acc: {
        cellTitle: string;
        statNumber: number | string;
        statType: string;
      }[],
      [key, value]
    ) => {
      if (["views", "creators", "posts"].includes(key)) {
        return acc;
      }

      if (typeof value === "number" || typeof value === "string") {
        const title = key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        const statType =
          key === "emv"
            ? "dollars"
            : key === "engagement"
              ? "percent"
              : "number";
        acc.push({
          cellTitle: title,
          statNumber: value,
          statType: statType,
        });
      }
      return acc;
    },
    []
  );

  const renderHeader = () => {
    if (isLoading) {
      return (
        <div className="flex justify-between items-center mb-4">
          <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-8 w-64 rounded"></div>
          <div className="min-w-[130px]">
            <div className="animate-pulse bg-gradient-to-r from-neutral_200 to-neutral_300 h-10 w-full rounded"></div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`flex justify-between items-center mb-4 ${
          campaignReport ? "mt-8" : ""
        }`}
      >
        <h1 className="text-2xl font-bold capitalize">
          {campaignReport ? "Reporting" : report?.title}
        </h1>
        <div className="min-w-[130px]">
          <FButton
            label="Download CSV"
            onClick={() => report?.id && handleDownloadReportCSV(report.id)}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${campaignReport ? "border-b border-light_border pb-4" : ""}`}
    >
      {renderHeader()}
      {isLoading ? (
        <ReportsAnalyticsMessageSkeleton />
      ) : (
        <ReportsAnalyticsMessage report={report} metrics={metrics} />
      )}
      <ReportStats
        performanceGraphs={performanceGraphs}
        metrics={metrics}
        isLoading={isLoading}
      />
    </div>
  );
};
