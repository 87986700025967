import React, { useEffect, useState } from "react";
import RichTextEditor from "@components/RichTextEditor";
import FInput from "@components/FInputs/FInput";
import FButton from "@components/FButton";
import FFileUpload from "@components/FFileUpload/FFileUpload";
import { Campaign, CampaignProduct } from "@types";
import {
  createCampaignProduct,
  updateCampaignProduct,
} from "@api/Campaign/CampaignProducts";
import toast from "react-hot-toast";
import FormField from "@components/Form/FormField";


const MISSING_IMAGE_URL =
  "https://s3.amazonaws.com/assets.fohrcard.com/uploads/x4OGW62/missing_default.png";

interface AddProductProps {
  campaign: Campaign;
  product: CampaignProduct;
  onUpdateCampaign: (campaign: Campaign) => void;
  onClose: () => void;
}

const AddProduct = ({
  campaign,
  product,
  onUpdateCampaign,
  onClose,
}: AddProductProps) => {
  const [values, setValues] = useState<Partial<CampaignProduct>>(null);
  const [isSaving, setSaving] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);

  const { products } = campaign;

  useEffect(() => {
    setValues(product || { name: "", url: "", notes: "", image_url: "", image: "" });
  }, [product]);

  const handleUpdate = (updates) => {
    setValues({ ...values, ...updates });
  };

  const handleSubmit = async () => {
    setSaving(true);

    if (uploadedImage) {
      values.image = uploadedImage;
    }
    try {
      if (product) {
        // Update existing product
        await updateCampaignProduct(product.id, values);

        const updatedProducts = products.map((p) =>
          p.id === product.id ? { ...p, ...values } : p
        );
        onUpdateCampaign({ ...campaign, products: updatedProducts });
      } else {
        // Add new product
        const response = await createCampaignProduct(campaign.id, values);

        const newProducts = [
          ...products,
          { ...values, id: response.data.id },
        ];

        onUpdateCampaign({
          ...campaign,
          products: newProducts as CampaignProduct[]  ,
        });
      }

      onClose();
    } catch (e) {
      toast.error("Error updating campaign producte");
    } finally {
      setSaving(false);
    }
  };

  if (!values) {
    return null;
  }

  const { name, notes, url, image } = values;

  return (
    <div className="max-h-[62vh] overflow-y-auto">
      <div className="space-y-4 relative">
        <FInput
          value={name}
          onChange={(value) => handleUpdate({ name: value })}
          type="text"
          required
          label="Name"
          width="100%"
        />
        <FInput
          value={url}
          type="text"
          onChange={(value) => handleUpdate({ url: value })}
          label="Product URL"
          width="100%"
        />
        <div className="mb-4">
          <FormField
            label="Product Image"
            width="100%"
          >
            <FFileUpload
              onUpload={(file) => {
                setUploadedImage(file[0].file);
                handleUpdate({ image_url: file[0].url });
              }}
              onDelete={() => {
                setValues({ ...values, image_url: MISSING_IMAGE_URL });
                handleUpdate({ image: null });
              }}
              multiple={false}
              uploadedFiles={[{ file: null, url: values.image_url || MISSING_IMAGE_URL }]}
            />
          </FormField>
        </div>
        <div className="mb-4">
          <label
            className="mb-[6px] inline-block text-sm font-medium leading-[18px]"
            htmlFor="notes"
          >
            Description
          </label>
          <div className="bg-white rounded-lg">
            <RichTextEditor
              value={notes}
              onChange={(value) => handleUpdate({ notes: value })}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-2 mt-8 pb-3">
        <FButton onClick={onClose} label="Cancel" width="140px" height="40px" />
        <FButton
          onClick={handleSubmit}
          loading={isSaving}
          label="Save"
          width="140px"
          height="40px"
          primary
          type="submit"
        />
      </div>
    </div>
  );
};

export default AddProduct;
