export const decorationColor = (decoration: string | boolean) => {
  if (typeof decoration === "string") {
    decoration = decoration.toLowerCase();
  }

  switch (decoration) {
    case true:
      return "bg-green";
    case false:
      return "bg-dark_night_sky";
    case "n/a":
      return "bg-neutral_400";
    case "approved":
    case "paid":
    case "sent":
    case "active":
    case "accepted":
      return "bg-green";
    case "interested":
      return "bg-neutral_500";
    case "proposal":
      return "bg-neutral_500";
    case "submitted":
    case "rejected":
      return "bg-red";
    case "conditional":
    case "viewed":
    case "draft":
      return "bg-yellow";
    case "pending":
      return "bg-blue";
    case "pending review":
      return "bg-blue_medium";
    case "review rejected":
      return "bg-red";
    case "review approved":
      return "bg-dill";
    default:
      return "bg-dark_night_sky";
  }
};

export const constructURL = (template: string, item: any): string => {
  return template.replace(/\{(\w+)\}/g, (_, key) => item[key] || "");
};

export const mapCategories = (
  categoryIds: (string | number)[],
  type: "creator" | "post"
): string[] => {
  const CREATOR_CATEGORIES = {
    23: "Art and Design",
    3: "Fashion",
    4: "Lifestyle",
    10: "Photography",
    17: "Parenting",
    24: "Travel",
    30: "Fragrance",
    31: "Fitness and Health",
    32: "Technology and Gaming",
    33: "Hair",
    34: "Wellness and Mindfulness",
    35: "Home Decor and DIY",
    37: "Make Up",
    38: "Sustainability",
    39: "Skincare",
    40: "Food and Cooking",
    41: "Music and Entertainment",
  };

  const POST_CATEGORIES = {
    "150": "Attractions",
    "1": "Automotive",
    "42": "Books and Literature",
    "52": "Business and Finance",
    "123": "Careers",
    "646": "Comedy",
    "132": "Education",
    JLBCU7: "Entertainment",
    "8VZQHL": "Events",
    "186": "Family and Relationships",
    "201": "Fine Art",
    "210": "Food & Drink",
    SPSHQ5: "Genres",
    "223": "Healthy Living",
    "239": "Hobbies & Interests",
    "1KXCLD": "Holidays",
    "274": "Home & Garden",
    "383": "Law",
    "602": "Maps & Navigation",
    WQC6HR: "Maps & Navigation",
    "286": "Medical Health",
    "342": "Music",
    "163": "Personal Celebrations & Life Events",
    "397": "Personal Finance",
    "422": "Pets",
    "386": "Politics",
    "432": "Pop Culture",
    W3CW2J: "Productivity",
    "441": "Real Estate",
    "453": "Religion & Spirituality",
    "464": "Science",
    "476": "Shopping",
    "483": "Sports",
    "552": "Style & Fashion",
    "553": "Beauty",
    "559": "Skincare",
    "596": "Technology & Computing",
    "653": "Travel",
    "680": "Video Gaming",
  };

  const categories = type === "creator" ? CREATOR_CATEGORIES : POST_CATEGORIES;

  return categoryIds.map((id) => categories[id] || "Unknown Category");
};
