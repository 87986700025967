import FButton from "@components/FButton";
import ContentHighlight from "@components/media/content-highlight";
import Modal from "@components/Modals/Modal";
import { CampaignContent } from "@types";
import { useState } from "react";

interface ViewStatsScreenshotModalProps {
  onClose: () => void;
  post: CampaignContent;
}

const ViewStatsScreenshotModal = ({
  onClose,
  post,
}: ViewStatsScreenshotModalProps) => {
  const [activeAssetIndex, setActiveAssetIndex] = useState(0);

  const assets = [post.stats_screenshot].map(({ original }) => ({
    photo_url: original,
  }));

  return (
    <Modal
      title="Stats screenshot"
      className="w-[501px]"
      hasForm
      onClose={onClose}
      isOpen
      zIndex={9999}
    >
      <div className="flex flex-col gap-4 p-4">
        <ContentHighlight
          data={assets}
          currentSlide={activeAssetIndex}
          highlightRight={false}
          isStatic
          onSlideChange={(index) => setActiveAssetIndex(index)}
          width={401}
          height="400px"
        />
      </div>

      <div className="flex justify-end space-x-2">
        <FButton onClick={onClose} label="Cancel" height="40px" />
      </div>
    </Modal>
  );
};

export default ViewStatsScreenshotModal;
