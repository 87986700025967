import React from "react";
import moment from "moment";

interface DataPoint {
  date: string;
  posts: number;
  reach: number;
  impressions: number;
  engagement: number;
}

interface AnalysisProps {
  data: DataPoint[];
  correlations: Record<string, number>;
}

export const DataAnalysis: React.FC<AnalysisProps> = ({
  data,
  correlations,
}) => {
  const getCorrelationInsight = () => {
    const strongCorrelations = Object.entries(correlations)
      .filter(([_, value]) => Math.abs(value) > 0.7)
      .sort((a, b) => Math.abs(b[1]) - Math.abs(a[1]));

    if (strongCorrelations.length === 0) return null;

    const [strongest] = strongCorrelations;
    const [metrics, value] = strongest;
    const direction =
      value > 0 ? "increase together" : "move in opposite directions";

    return (
      <div className="">
        <h4 className="text-lg font-medium mb-1">Key Relationship</h4>
        <p className="text-neutral_700">
          {metrics.replace(" vs ", " and ")} {direction}, showing a{" "}
          {Math.abs(value) > 0.8 ? "very strong" : "strong"} correlation (
          {(value * 100).toFixed(1)}% aligned).
        </p>
      </div>
    );
  };

  const findInterestingMonths = () => {
    const results = data
      .map((month) => {
        const insights = [];
        const previousMonth = data[data.indexOf(month) - 1];

        if (previousMonth) {
          const changes = {
            posts: (month.posts - previousMonth.posts) / previousMonth.posts,
            reach: (month.reach - previousMonth.reach) / previousMonth.reach,
            views:
              (month.impressions - previousMonth.impressions) /
              previousMonth.impressions,
          };

          // Only report significant changes (>30%)
          Object.entries(changes).forEach(([metric, change]) => {
            if (Math.abs(change) > 0.3) {
              insights.push({
                metric,
                change,
                value: month[metric],
                date: month.date,
              });
            }
          });
        }

        return insights;
      })
      .flat();

    // Get the most significant change
    return results.sort((a, b) => Math.abs(b.change) - Math.abs(a.change))[0];
  };

  const getBestPerformingMonth = () => {
    return data.reduce((best, current) => {
      const currentScore = current.reach + current.impressions * 0.5;
      const bestScore = best.reach + best.impressions * 0.5;
      return currentScore > bestScore ? current : best;
    });
  };

  const bestMonth = getBestPerformingMonth();
  const significantChange = findInterestingMonths();

  return (
    <div className="pt-8 space-y-4 border-t border-light_border">
      <div className="flex items-start space-x-6 p-4">
        <div className="flex-1  pr-2">{getCorrelationInsight()}</div>

        <div className="flex-1 pr-2 ">
          <h4 className="text-lg font-medium mb-1">Best Performance</h4>
          <p className="text-neutral_700">
            {moment(bestMonth.date, "MM/DD/YY").format("MMMM YYYY")} was the
            strongest month, reaching {bestMonth.reach.toLocaleString()} users
            through {bestMonth.posts} posts.
          </p>
        </div>

        {significantChange && (
          <div className="flex-1">
            <h4 className="text-lg font-medium mb-1">Notable Change</h4>
            <p className="text-neutral_700">
              {significantChange.metric.charAt(0).toUpperCase() +
                significantChange.metric.slice(1)}{" "}
              {significantChange.change > 0 ? "increased" : "decreased"} by{" "}
              {Math.abs(significantChange.change * 100).toFixed(0)}% in{" "}
              {moment(significantChange.date, "MM/DD/YY").format("MMMM")}.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
