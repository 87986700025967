import React from "react";
import FButton from "@components/FButton";
import FIcon from "@components/FIcon";
import { formatNumber } from "@utils/number_utilities";

const AccountDisplay = ({ platform, handle, image, followers, selectedHandle, onAdd }) => {
  const isSelected = selectedHandle === handle.toLowerCase();

  return (
    <div className="border rounded-lg p-4 mb-4 flex items-center w-1/2">
      {/* Profile Image */}
      <div
        className="mr-4 w-24 h-24 rounded-full bg-center bg-cover"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      {/* Account Details */}
      <div className="flex-grow">
        <div className="flex items-center mb-2">
          <FIcon
            icon={platform}
            size={24}
            color="#000F45"
            className="mr-2"
          />
          <span className="text-lg">{`${platform.charAt(0).toUpperCase() + platform.slice(1)} account found`}</span>
        </div>
        <p>
          <a
            href={`https://${platform}.com/@${handle}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500"
          >
            @{handle}
          </a>
        </p>
        <p>{formatNumber(followers)} Followers</p>
      </div>
      {/* Add Platform Button */}
      <FButton
        label={isSelected ? "Added" : "Add Platform"}
        onClick={onAdd}
        className="ml-auto"
        disabled={isSelected}
      />
    </div>
  );
};

export default AccountDisplay;
