import React, { useState } from "react";
import FIcon from "./FIcon";

interface Props {
  className?: string;
  disabled?: boolean;
  onChange?: (query: string) => void;
  onSubmit?: (query: string) => void;
  placeholder?: string;
  style?: any;
  value?: string;
}

const FSearch = ({
  className = "",
  disabled = false,
  onChange,
  onSubmit,
  placeholder = "Search",
  ...props
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [isActive, setIsActive] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);

    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div
      className={`${className} bg-white border border-default_weak rounded ${
        isActive ? "border-neutral_600" : ""
      } flex`}
      style={props?.style}
    >
      <FIcon
        icon="search"
        size={18}
        color="black"
        className={`mr-[5px] ml-[8px] -mt-[4px] ${
          disabled ? "text-neutral_400" : ""
        }`}
      />
      <input
        className={
          "outline-none bg-white relative text-[15px] h-[38px] w-full p-[10px] focus:border-dark_night_sky text-dark_night_sky placeholder:text-dark_night_sky rounded"
        }
        onChange={handleChange}
        type="text"
        placeholder={placeholder}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        onKeyUp={(e) => {
          e.preventDefault();
          if (e.key === "Enter" && onSubmit && !disabled) {
            onSubmit(searchTerm);
          }
        }}
      />
    </div>
  );
};

export default FSearch;
