import React from "react";

import { maxBy } from "lodash";
import { DemographicsBreakdownItem } from "@types";

import WidgetContainer from "./WidgetContainer";

import StackedBarGraph from "@components/Graphs/StackedBarGraph";

const COLORS = [
  "#3C90AC",
  "#4BA6C4",
  "#59BBDB",
  "#95D4E9",
  "#D0EEF8",
  "#E2F4FA",
  "#F3FAFD",
  "#E3E6ED",
];

const ethnicityNameMap = {
  "White/caucasian": "Caucasian",
  "African American": "Afr. Am.",
};

interface EthnicityBreakdownProps {
  items: DemographicsBreakdownItem[];
  showCounts?: boolean;
}

export default function EthnicityBreakdown({
  items,
  showCounts,
}: EthnicityBreakdownProps) {
  const data = items.map(({ name, percentage, count }) => ({
    name: ethnicityNameMap[name] || name,
    value: percentage,
    count,
  }));

  const maximumValueItem = maxBy(data, "value");

  return (
    <WidgetContainer title="Ethnicity" value={maximumValueItem?.name}>
      <div className="flex flex-col items-center">
        <span className="text-blue text-xl">{maximumValueItem?.value}%</span>
        <StackedBarGraph colors={COLORS} data={data} showCounts={showCounts} />
      </div>
    </WidgetContainer>
  );
}
