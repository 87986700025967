import { ApiResponse, CreatorFromSearch } from "@apiTypes";
import apiWrapper from "@apiWrapper";
import { isEmpty } from "lodash";

export const CREATOR_CATEGORIES = {
  23: "Art and Design",
  3: "Fashion",
  4: "Lifestyle",
  10: "Photography",
  17: "Parenting",
  24: "Travel",
  30: "Fragrance",
  31: "Fitness and Health",
  32: "Technology and Gaming",
  33: "Hair",
  34: "Wellness and Mindfulness",
  35: "Home Decor and DIY",
  37: "Make Up",
  38: "Sustainability",
  39: "Skincare",
  40: "Food and Cooking",
  41: "Music and Entertainment",
};

export const POST_CATEGORIES = {
  "150": "Attractions",
  "1": "Automotive",
  "42": "Books and Literature",
  "52": "Business and Finance",
  "123": "Careers",
  "646": "Comedy",
  "132": "Education",
  JLBCU7: "Entertainment",
  "8VZQHL": "Events",
  "186": "Family and Relationships",
  "201": "Fine Art",
  "210": "Food & Drink",
  SPSHQ5: "Genres",
  "223": "Healthy Living",
  "239": "Hobbies & Interests",
  "1KXCLD": "Holidays",
  "274": "Home & Garden",
  "383": "Law",
  "602": "Maps & Navigation",
  // WQC6HR: "Maps & Navigation",
  "286": "Medical Health",
  "342": "Music",
  "163": "Personal Celebrations & Life Events",
  "397": "Personal Finance",
  "422": "Pets",
  "386": "Politics",
  "432": "Pop Culture",
  W3CW2J: "Productivity",
  "441": "Real Estate",
  "453": "Religion & Spirituality",
  "464": "Science",
  "476": "Shopping",
  "483": "Sports",
  "552": "Style & Fashion",
  "553": "Beauty",
  "559": "Skincare",
  "596": "Technology & Computing",
  "653": "Travel",
  "680": "Video Gaming",
};

const getSearchString = (params) => {
  const queryParams = new URLSearchParams();

  Object.keys(params).forEach((param) => {
    if (params[param] && (param === "p" || !isEmpty(params[param]))) {
      queryParams.append(param, params[param]);
    }
  });

  return queryParams.toString();
};

export const searchCreators = (params: {
  hashtags?: string[];
  mentions?: string[];
  keywords?: string[];
  platforms?: string[];
  tags?: string[];
  p?: number;
}): Promise<
  ApiResponse<{
    results: CreatorFromSearch[];
    total: number;
  }>
> => apiWrapper(`/search/ambassadors?${getSearchString(params)}`, "GET");

export const searchPosts = (params: {
  hashtags?: string[];
  mentions?: string[];
  any?: string[];
  platforms?: string[];
  categories?: string[];
  creator_ids?: number[];
  p?: number;
}): Promise<
  ApiResponse<{
    results: CreatorFromSearch[];
    total: number;
  }>
> => apiWrapper(`/search/ambassador_content?${getSearchString(params)}`, "GET");
