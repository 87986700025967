import apiWrapper from "@apiWrapper";
import { ApiResponse } from "@apiTypes";
import { CampaignContent, Content } from "@types";

export interface CampaignParticipantParams {
  influencer_id_eq?: number;
  participant_group_id_eq?: number;
  status_eq?: string;
  creator_id_eq?: number;
}

const getCampaignParticipantList = async (
  campaignId?: string | number,
  params?: CampaignParticipantParams
): Promise<ApiResponse<{ participants: CampaignParticipantParams[] }>> => {
  const queryParams = new URLSearchParams();

  // Add the campaignId to the query parameters
  if (campaignId) {
    queryParams.append("q[campaign_id_eq]", campaignId.toString());
  }

  // Add other optional parameters to the query string
  if (params?.influencer_id_eq) {
    queryParams.append(
      "q[influencer_id_eq]",
      params.influencer_id_eq.toString()
    );
  }
  if (params?.participant_group_id_eq) {
    queryParams.append(
      "q[participant_group_id_eq]",
      params.participant_group_id_eq.toString()
    );
  }
  if (params?.status_eq) {
    queryParams.append("q[status_eq]", params.status_eq);
  }

  if (params?.creator_id_eq) {
    queryParams.append("q[creator_id_eq]", params.creator_id_eq.toString());
  }

  return apiWrapper(`/participants?${queryParams.toString()}`, "GET");
};

const createCampaignParticipant = async (
  campaign_id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper("/participants", "POST", {
    ...params,
    campaign_id: campaign_id,
  });
};

export const createCampaignParticipantFromGroups = async (
  campaign_id: number,
  params: {
    participant_group_id: number;
    group_ids: number[];
    status?: string;
  }
): Promise<ApiResponse<any>> => {
  return apiWrapper("/participant_groups/from_groups", "POST", {
    campaign_id: campaign_id,
    ...params,
  });
};

const getCampaignParticipant = async (
  participant_id: string | number
): Promise<ApiResponse<any>> =>
  apiWrapper(`/participants/${participant_id}`, "GET");

const updateCampaignParticipant = async (
  participant_id: string | number,
  params: any
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participants/${participant_id}`, "PUT", params);
};

export const deleteCampaignParticipant = async (
  participant_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participants/${participant_id}`, "DELETE");
};

const archiveCampaignParticipant = async (
  participant_id: string | number
): Promise<ApiResponse<any>> => {
  return apiWrapper(`/participants/${participant_id}/archive`, "PUT");
};

export const addToParticipantSampleContent = (params: {
  contents: { participant_id: number; post_id: number; permalink?: string }[];
}): Promise<ApiResponse<{ data: Content[] }>> =>
  apiWrapper("/creator_review/contents", "POST", params);

export const updateParticipantSampleContent = (
  id,
  params: {
    permalink: string;
  }
): Promise<ApiResponse<{ data: Content[] }>> =>
  apiWrapper(`/creator_review/contents/${id}`, "PUT", params);

export const deleteParticipantSampleContent = (
  post_id: number
): Promise<ApiResponse<null>> =>
  apiWrapper(`/creator_review/contents/${post_id}`, "DELETE");

export const getParticipantSampleContent = (
  participant_id: number | string
): Promise<ApiResponse<{ contents: CampaignContent[] }>> => {
  const queryParams = new URLSearchParams();

  queryParams.append("q[participant_id_eq]", participant_id.toString());
  queryParams.append("per_page", "1000");

  return apiWrapper(
    `/creator_review/contents?${queryParams.toString()}`,
    "GET"
  );
};

export {
  getCampaignParticipantList,
  createCampaignParticipant,
  getCampaignParticipant,
  updateCampaignParticipant,
  archiveCampaignParticipant,
};
