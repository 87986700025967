import React, { useCallback, useContext, useState } from "react";
import { Sort } from "@types";
import { getReportsList, deleteReport } from "@api/Reports";
import SinglePageLayoutWithData from "@layouts/SinglePageLayoutWithData";
import { MixpanelContext } from "@hooks/MixpanelProvider";
import { EVENTS } from "@utils/mixpanel_utilities";
import FButton from "@components/FButton";
import Modal from "@components/Modals/Modal";
import CombineReportsForm from "./components/CombineReportsForm";

const filterOptions = [
  { label: "All", value: null },
  { label: "Campaigns", value: "campaign" },
  { label: "Monthly", value: "monthly_default" },
  { label: "Content Search", value: "content_search" },
];

const tableColumns = [
  {
    key: "title",
    label: "Title",
    isLink: true,
    linkURL: "/reports/{id}",
  },
  { key: "source", label: "Source" },
  { key: "content_count", label: "Content Count", isNumber: true },
  { key: "created_at", label: "Created", isDate: true },
];

const tableRowDropDownOptions = [
  { label: "Edit", value: "edit" },
  { label: "Delete", value: "delete" },
];

const actions = {
  delete: async (id: number) => deleteReport(id),
};

export default function ReportsPage() {
  const { trackEvent } = useContext(MixpanelContext);
  const [selectedReports, setSelectedReports] = useState([]);
  const [combineReportsModalVisible, setCombineReportsModalVisible] =
    useState(false);
  const [reportsList, setReportsList] = useState([]);

  const getReports = useCallback(
    async (page?: number, sort?: Sort, params?) => {
      if (sort && sort.direction === "none") {
        sort = null;
      }
      const { search, filter } = params;
      const response = await getReportsList(false, page, sort, {
        source_type_eq: filter,
        title_cont: search,
      });

      const { reports: items } = response.data;
      trackEvent(EVENTS.PAGE_VIEW.REPORTS);

      const reportsData = response.data.reports.map((report) => ({
        id: report.id,
        title: report.title,
      }));
      setReportsList(reportsData);

      return {
        ...response.data,
        items,
      };
    },
    []
  );

  const handleOpenCombineReportsModal = () => {
    setCombineReportsModalVisible(true);
  };

  const handleCloseCombineReportsModal = () => {
    setCombineReportsModalVisible(false);
  };

  const onChangeSelectedItems = (items) => {
    setSelectedReports(items);
  };

  return (
    <>
      <SinglePageLayoutWithData
        entityName="reports"
        pageTitle="Reports"
        pageName="Reports"
        sortPathName="title"
        sortPathDate="date"
        filterOptions={filterOptions}
        filterPath="state"
        tableColumns={tableColumns}
        rowActions={tableRowDropDownOptions}
        tableRowDropDownOptions={tableRowDropDownOptions}
        asyncActions={actions}
        tableOnly
        getItems={getReports}
        selectedItems={selectedReports}
        onChangeSelectedItems={onChangeSelectedItems}
        subNavMainButton={
          selectedReports.length > 0 && (
            <FButton
              label={"Combine Reports"}
              onClick={() => handleOpenCombineReportsModal()}
            />
          )
        }
      />
      {combineReportsModalVisible && (
        <Modal
          isOpen
          className="w-[540px]"
          onClose={handleCloseCombineReportsModal}
          zIndex={9000}
          title="Combine Reports"
          hasForm
          actionLabel="Save"
        >
          <div>
            <p className="text-md">
              Would you like to combine the following reports?
            </p>
            <div className="flex flex-col pt-3 pl-1 pb-4">
              {reportsList
                .filter((report) => selectedReports.includes(report.id))
                .map((report, index) => (
                  <p key={index} className="text-sm text-gray-700 pt-1">
                    {report.title}
                  </p>
                ))}
            </div>

            <CombineReportsForm
              onClose={handleCloseCombineReportsModal}
              selectedReports={selectedReports}
              getReports={getReports}
              setSelectedReports={setSelectedReports}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
