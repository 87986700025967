import React from "react";
import FIcon from "@components/FIcon";

const SelectedPlatforms = ({
  instagramHandle,
  tiktokHandle,
  setInstagramHandle,
  setTiktokHandle,
}) => (
  <div className="p-4 border rounded-lg bg-gray-50 w-1/2 mt-8 mb-8">
    <h2 className="text-lg mb-4">Selected Platforms</h2>

    {/* Instagram Section */}
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center">
        <span className="font-medium mr-2">Instagram:</span>
        <span className={instagramHandle ? "" : "text-neutral_600 italic"}>
          {instagramHandle ? `@${instagramHandle}` : "Not Selected"}
        </span>
      </div>
      {instagramHandle && (
        <button
          onClick={() => setInstagramHandle("")}
          className="flex items-center text-red-500 hover:underline"
        >
          <FIcon icon="delete" size={20} color="#FF5C5C" className="mr-2" />
          <span>Remove</span>
        </button>
      )}
    </div>

    {/* TikTok Section */}
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <span className="font-medium mr-2">TikTok:</span>
        <span className={tiktokHandle ? "" : "text-neutral_600 italic"}>
          {tiktokHandle ? `@${tiktokHandle}` : "Not Selected"}
        </span>
      </div>
      {tiktokHandle && (
        <button
          onClick={() => setTiktokHandle("")}
          className="flex items-center text-red-500 hover:underline"
        >
          <FIcon icon="delete" size={20} color="#FF5C5C" className="mr-2" />
          <span>Remove</span>
        </button>
      )}
    </div>
  </div>
);

export default SelectedPlatforms;
